import { DesktopDatePicker, DesktopDatePickerProps, LocalizationProvider } from '@mui/lab'
import { TextField } from '@mui/material'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useState, useMemo } from 'react'
import { useListContext, useTranslate } from 'react-admin'

import { dateAdapter, DateType, toTimezoneISOString } from 'libs/date'
import { Row, Spacer } from 'utils/spacing'
import { useEffectOnce } from 'utils/useEffectOnce'

interface Props {
  filterName: string
}

const renderDesktopDatePickerInput: DesktopDatePickerProps['renderInput'] = (params) => (
  <TextField {...params} autoComplete="off" sx={{ ...params.sx, width: 150 }} />
)

export const DateRangePicker = (props: Props) => {
  const t = useTranslate()
  const { filterValues, setFilters } = useListContext()
  const filterValue = filterValues[props.filterName]

  const currentDate = useMemo(() => dateAdapter.date(), [])
  const [endDate, setEndDate] = useState<DateType>(
    filterValue?.end ? dateAdapter.date(filterValue?.end) : currentDate
  )
  const [startDate, setStartDate] = useState<DateType>(
    filterValue?.start ? dateAdapter.date(filterValue?.start) : dateAdapter.addMonths(endDate, -1)
  )

  useEffectOnce(() => {
    addFilters(startDate, endDate)
  })

  function onChangeStart(date: DateType | null) {
    if (!date) return
    setStartDate(date)
    addFilters(date, endDate)
  }

  function onChangeEnd(date: DateType | null) {
    if (!date) return
    setEndDate(date)
    addFilters(startDate, date)
  }

  function addFilters(newStartDate: DateType | null, newEndDate: DateType | null) {
    const dateRange: { start?: string; end?: string } = {}

    if (newStartDate) {
      dateRange.start = toTimezoneISOString(dateAdapter.startOfDay(newStartDate))
    }
    if (newEndDate) {
      dateRange.end = toTimezoneISOString(dateAdapter.endOfDay(newEndDate))
    }

    const newFilterValues = { ...filterValues, [props.filterName]: dateRange }
    if (!newStartDate && !newEndDate) {
      delete newFilterValues[props.filterName]
    }
    setFilters(newFilterValues, null)
  }

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Row>
        <DesktopDatePicker
          label={t('filters.date.start')}
          value={startDate}
          inputFormat="dd/MM/yyyy"
          maxDate={endDate}
          onChange={onChangeStart}
          renderInput={renderDesktopDatePickerInput}
        />
        <Spacer x={1} />
        <DesktopDatePicker
          label={t('filters.date.end')}
          value={endDate}
          inputFormat="dd/MM/yyyy"
          minDate={startDate}
          maxDate={currentDate}
          onChange={onChangeEnd}
          renderInput={renderDesktopDatePickerInput}
        />
      </Row>
    </LocalizationProvider>
  )
}
