import { Box, Card, CardContent, Typography, Stack, styled } from '@mui/material'
import React, { FC, ReactElement } from 'react'
import { TopToolbar } from 'react-admin'

import { Column, FLEX_ROW_SX, Spacer } from 'utils/spacing'

const Actions: FC = (props) => (
  <StyledToolbar>
    <Box flex="1">
      <Typography variant="h1" id="react-admin-title" sx={{ p: '15px', fontWeight: 500 }} />
    </Box>
    <Stack direction="row" spacing={2} sx={{ alignSelf: 'center', p: '15px' }}>
      {props.children}
    </Stack>
  </StyledToolbar>
)

const Filters: FC = (props) => (
  <Card sx={{ display: 'flex' }}>
    <CardContent sx={{ ...FLEX_ROW_SX, padding: '15px !important', alignItems: 'center' }}>
      {props.children}
    </CardContent>
  </Card>
)

type ListHeaderProps = {
  actions?: ReactElement | ReactElement[]
  filters?: ReactElement | ReactElement[]
}

export const ListHeader: FC<ListHeaderProps> = (props) => {
  const { actions, filters } = props
  return (
    <Column sx={{ mb: 3, width: '100%' }}>
      {actions && <Actions>{actions}</Actions>}
      {actions && filters && <Spacer y={2} />}
      {filters && <Filters>{filters}</Filters>}
    </Column>
  )
}

const StyledToolbar = styled(TopToolbar)(({ theme }) => ({
  margin: '-16px -16px 0px -16px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
  backgroundColor: theme.palette.text.secondary,
}))
