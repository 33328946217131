import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  SxProps,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useTranslate, useRefresh, useListContext, useUnselectAll } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { Contract } from 'api/gen'
import { useCanAccess } from 'libs/auth'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { ROLES_WITHOUT_ADMIN_AND_EMPLOYEE, RoleType } from 'resources/types'
import { Row, Column, Spacer } from 'utils/spacing'

interface Props {
  contractId: string
  roleToRemove: RoleType | undefined
  hasManyRoles: boolean
  shouldDeactivateContract: boolean
}

export const ConfirmRemoveRoleDialog = (props: Props) => {
  const t = useTranslate()
  const api = useApi()
  const theme = useTheme()
  const refresh = useRefresh()
  const notification = useNotifications()
  const { isModalOpen, closeModal } = useModal('removeRole')

  const { canAccess } = useCanAccess()
  const isAdmin = canAccess({ roles: [Contract.RolesEnum.Admin] })

  const removeRolesMutation = useMutation('removeRoles', removeRoles)
  const deactivateContractMutation = useMutation('deactivateContract', deactivateContract)

  const unselectIds = useUnselectAll('contract')
  const { selectedIds } = useListContext()
  const onlyOneIdSelected = !selectedIds || !selectedIds.length

  const { contractId, roleToRemove, hasManyRoles, shouldDeactivateContract } = props

  React.useEffect(() => {
    const mutationsError = removeRolesMutation.isError || deactivateContractMutation.isError
    const mutationsSuccess = removeRolesMutation.isSuccess || deactivateContractMutation.isSuccess
    if (mutationsSuccess) {
      notification(t(`notifications.roleManagementTab.removeSuccess.${roleToRemove}`), {
        variant: 'success',
      })
      removeRolesMutation.reset()
      deactivateContractMutation.reset()
      closeModal()
    }
    if (mutationsError) {
      notification(t(`notifications.roleManagementTab.removeError.${roleToRemove}`), {
        variant: 'error',
      })
      removeRolesMutation.reset()
      deactivateContractMutation.reset()
      closeModal()
    }
  }, [removeRolesMutation, deactivateContractMutation, roleToRemove, closeModal, notification, t])

  async function deactivateContract() {
    if (onlyOneIdSelected) {
      await api.contractControllerDeactivateContract(contractId)
    } else {
      for (const id of selectedIds) {
        await api.contractControllerDeactivateContract(id)
      }
    }
    unselectIds()
    closeModal()
    refresh()
  }

  async function removeRoles() {
    if (onlyOneIdSelected) {
      if (isAdmin && roleToRemove === Contract.RolesEnum.Admin) {
        await api.contractControllerRemoveAdminRole(contractId)
      } else {
        await api.contractControllerRemoveRoles(
          {
            roles: roleToRemove
              ? [roleToRemove as Contract.RolesEnum]
              : ROLES_WITHOUT_ADMIN_AND_EMPLOYEE,
          },
          contractId
        )
      }
    } else {
      for (const id of selectedIds) {
        await api.contractControllerRemoveRoles(
          {
            roles: roleToRemove
              ? [roleToRemove as Contract.RolesEnum]
              : ROLES_WITHOUT_ADMIN_AND_EMPLOYEE,
          },
          id
        )
      }
    }
    closeModal()
    refresh()
  }

  async function handleRemoveRoles() {
    if (!shouldDeactivateContract) await removeRolesMutation.mutateAsync()
    else await deactivateContractMutation.mutateAsync()
  }

  function getRoleToRemove(role: RoleType | undefined) {
    if (role === Contract.RolesEnum.Admin) return Contract.RolesEnum.Employer
    return role
  }

  function getDialogTitle() {
    if (onlyOneIdSelected) {
      return t(
        `administration.roleManagementTab.dialogTitle.${getRoleToRemove(
          roleToRemove
        )}.hasManyRoles.${hasManyRoles}`
      )
    } else {
      return t(`administration.roleManagementTab.dialogTitle.bulkDelete`)
    }
  }

  function getDialogText() {
    if (onlyOneIdSelected) {
      return t(
        `administration.roleManagementTab.dialogText.${getRoleToRemove(
          roleToRemove
        )}.hasManyRoles.${hasManyRoles}`
      )
    } else {
      return t(`administration.roleManagementTab.dialogText.bulkDelete`)
    }
  }

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle sx={{ backgroundColor: theme.palette.secondary.main, maxHeight: '55px' }}>
        <Typography color="primary" sx={{ textAlign: 'center' }}>
          {getDialogTitle()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Column sx={{ display: 'flex', alignItems: 'center' }}>
          <Spacer y={3} />
          <Typography variant="body1" color="secondary" sx={{ maxWidth: '90%' }}>
            {getDialogText()}
          </Typography>
          <Row sx={buttonsRowSx}>
            <Button variant="outlined" color="secondary" sx={buttonSx} onClick={closeModal}>
              {t('buttons.close')}
            </Button>
            <Spacer x={2} />
            <LoadingButton
              variant="contained"
              color="error"
              onClick={handleRemoveRoles}
              loading={removeRolesMutation.isLoading || deactivateContractMutation.isLoading}
              sx={{ ...buttonSx, color: theme.palette.text.secondary }}>
              {hasManyRoles ? t('buttons.remove') : t('buttons.delete')}
            </LoadingButton>
          </Row>
        </Column>
      </DialogContent>
    </Dialog>
  )
}

const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '650px',
      height: '100%',
      maxHeight: '230px',
    },
  },
}

const buttonsRowSx: SxProps = {
  width: '100%',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '25px',
}

const buttonSx: SxProps = {
  padding: 0,
  fontWeight: 400,
  height: '34px',
  width: '150px',
  textTransform: 'none',
}
