import {
  Box,
  styled,
  SxProps,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody as MuiTableBody,
  CircularProgress,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { FinancingCreditLine } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { Column } from 'utils/spacing'

export const CreditLines = () => {
  const api = useApi()
  const t = useTranslate()

  const [creditLines, setCreditLines] = useState<FinancingCreditLine[]>([])

  const { isFetching } = useQuery(QueryKey.GetCreditLines, async () => {
    try {
      const creditLines = await api.financingControllerGetCreditLines()
      setCreditLines(creditLines)
    } catch (error) {
      throw error
    }
  })

  const TableHeader = () => (
    <StyledTableHead>
      <TableRow sx={{ backgroundColor: 'lightblue' }}>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.creditLinesTab.table.contractNumber')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.creditLinesTab.table.balanceAvailable')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.creditLinesTab.table.feesDailyRate')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.creditLinesTab.table.startDate')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.creditLinesTab.table.endDate')}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  )

  const TableBody = () => (
    <MuiTableBody>
      {creditLines.map((creditLine) => {
        let endDate = ''
        if (creditLine.end_date) endDate = new Date(creditLine.end_date).toLocaleDateString()
        return (
          <TableRow key={creditLine.id}>
            <TableCell scope="row" sx={tableCellSx}>
              {creditLine.contract_number}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {creditLine.balance_available ? creditLine.balance_available / 100 : 0} €
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {creditLine.fees_daily_rate}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {new Date(creditLine.start_date).toLocaleDateString()}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {endDate}
            </TableCell>
          </TableRow>
        )
      })}
    </MuiTableBody>
  )

  const LoadingIcon = () => (
    <Box sx={loadingSx}>
      <CircularProgress />
    </Box>
  )

  return (
    <Column>
      <TableContainer component={Box} sx={{ height: '450px', width: '100%' }}>
        <Table stickyHeader={true}>
          <TableHeader />
          {!isFetching && <TableBody />}
        </Table>
        {isFetching && <LoadingIcon />}
      </TableContainer>
    </Column>
  )
}

const loadingSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}
const tableCellSx: SxProps = {
  width: '10px',
  borderBottom: 0,
  fontSize: '12px',
  pt: '5px',
  pb: '5px',
}

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.colors.GREY,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
  },
}))
