import { Card, CardContent, Tabs, Tab, SxProps } from '@mui/material'
import React, { useMemo } from 'react'
import { Translate, useTranslate } from 'react-admin'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'

import { config } from 'config'

import { CreateFakeDataTabView } from './CreateFakeDataTabView'
import { CreateOrganizationTabView } from './CreateOrganizationTabView'

type TabValue = 'createOrganization' | 'createFakeData'

type TabConfig = {
  value: TabValue
  path: string
  label: string
  sx: SxProps
  component: React.FC
  visible: boolean
}
const adminPath = (path: string) => '/super-admin/' + path

function getTabsConfigs(t: Translate): TabConfig[] {
  const tabsConfigs: TabConfig[] = [
    {
      value: 'createOrganization',
      path: adminPath('create-organization'),
      label: t('admin.createOrganization.title'),
      sx: { ...tabSx, width: '200px' },
      component: CreateOrganizationTabView,
      visible: true,
    },
    {
      value: 'createFakeData',
      path: adminPath('create-fake-data'),
      label: t('admin.createFakeData.title'),
      sx: { ...tabSx, width: '200px' },
      component: CreateFakeDataTabView,
      visible: !config.IS_PROD,
    },
  ]
  return tabsConfigs.filter((tabConfig) => tabConfig.visible)
}

function matchPathnameToTabConfig(pathname: string, tabsConfigs: TabConfig[]) {
  for (const tabConfig of tabsConfigs) {
    if (matchPath(tabConfig.path, pathname)) {
      return tabConfig
    }
  }
  return tabsConfigs[0]
}

export function SuperAdminPage() {
  const t = useTranslate()
  const tabsConfigs = useMemo(() => getTabsConfigs(t), [t])
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [selectedTabConfig, setSelectedTabConfig] = React.useState<TabConfig>(
    matchPathnameToTabConfig(pathname, tabsConfigs)
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    for (const tabConfig of tabsConfigs) {
      if (newValue === tabConfig.value) {
        navigate(tabConfig.path)
        setSelectedTabConfig(tabConfig)
        return
      }
    }
  }

  return (
    <Card sx={{ width: '1100px', minHeight: 400 }}>
      <Tabs value={selectedTabConfig.value} onChange={handleTabChange}>
        {tabsConfigs.map((tabConfig) => {
          return (
            <Tab
              key={tabConfig.value}
              label={tabConfig.label}
              value={tabConfig.value}
              sx={tabConfig.sx}
            />
          )
        })}
      </Tabs>
      <CardContent sx={{ p: 3 }}>
        <selectedTabConfig.component />
      </CardContent>
    </Card>
  )
}

const tabSx: SxProps = { p: '16px 24px 16px 16px' }
