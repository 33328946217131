import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
  SxProps,
  Dialog,
} from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslate, useGetList } from 'react-admin'

import { Contract } from 'api/gen'
import { ReactComponent as CreateAccountLogo } from 'assets/onboarding_create_account.svg'
import { ReactComponent as ImportEmployeesLogo } from 'assets/onboarding_import_employee.svg'
import { ReactComponent as ReloadAccountLogo } from 'assets/onboarding_reload_account.svg'
import { useModal } from 'libs/modal'
import { ContractRecord } from 'resources/types'

import { OnboardingItem } from './OnboardingItems'

const LIST_PARAMS = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: 'user.firstName', order: 'ASC' },
}

export function OnboardingModal() {
  const t = useTranslate()
  const { isModalOpen, closeModal } = useModal('onboarding')
  const { data: users } = useGetList<ContractRecord>('contract', LIST_PARAMS, {
    enabled: isModalOpen,
  })

  const swanOnboardingSteps = () => {
    // TODO: add logic here for Swan onboarding steps
    return false
  }

  const doesEmployeesExist = useCallback(() => {
    if (!users) return false
    for (const user of users) {
      if (user.roles && user.roles.includes(Contract.RolesEnum.Employee)) {
        return true
      }
    }
    return false
  }, [users])

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle>
        <Typography variant="h1" sx={dialogTitleSx}>
          {t('onboarding.discoverNessPay')}
        </Typography>
        <IconButton onClick={closeModal} color="secondary" sx={closeButtonSx}>
          <CloseIcon sx={{ width: '50px', height: '50px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ textAlign: 'center' }}>
          <Grid item xs={12} sx={{ marginBottom: '30px' }}></Grid>
          <Grid item xs={4}>
            <OnboardingItem
              message="createAccount"
              logo={<CreateAccountLogo />}
              isCompleted={true}
            />
          </Grid>
          <Grid item xs={4}>
            <OnboardingItem
              message="createEmployee"
              logo={<ImportEmployeesLogo />}
              isCompleted={doesEmployeesExist()}
              id="create-user-item"
            />
          </Grid>
          <Grid item xs={4}>
            <OnboardingItem
              message="reloadAccount"
              logo={<ReloadAccountLogo style={{ marginRight: '10px' }} />}
              isCompleted={swanOnboardingSteps()}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

const dialogTitleSx: SxProps = {
  textAlign: 'center',
  fontWeight: 500,
}

const closeButtonSx: SxProps = {
  position: 'absolute',
  right: 25,
  top: 18,
}

const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '1120px',
      height: '100%',
      maxHeight: '380px',
    },
  },
}
