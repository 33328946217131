import type { FieldValues } from 'react-hook-form'

import { Contract } from 'api/gen'
import { ContractRecord, RoleType, UserRecord } from 'resources/types'
import { strictCapitalizeString } from 'utils/format'
import { DeepPartial } from 'utils/types'

export const getUserData = (userData: UserRecord): DeepPartial<UserRecord> => {
  const userToCreate: DeepPartial<UserRecord> = {}
  userToCreate.firstName = strictCapitalizeString(userData.firstName)
  userToCreate.lastName = strictCapitalizeString(userData.lastName)
  userToCreate.email = userData.email
  if (userData.iban) userToCreate.iban = userData.iban.replace(/ /g, '')
  if (userData.phone) userToCreate.phone = userData.phone
  return userToCreate
}

export const getContractData = (
  contractData: FieldValues,
  organizationId: string,
  roles: RoleType[]
) => ({
  organizationId,
  roles: roles as Contract.RolesEnum[],
  user: getUserData(contractData.user),
  netSalary: contractData.netSalary,
  staffNumber: contractData.staffNumber,
  availableSalaryFraction: contractData.availableSalaryFraction,
  isAdvanceServiceEnabled: contractData.isAdvanceServiceEnabled,
})
