import { ThemeOptions } from '@mui/material'
import merge from 'lodash/merge'
import { defaultTheme } from 'react-admin'

const COLORS = {
  BLUE_NIGHT: '#06113F',
  BLUE_PASTEL: '#E5E8F9',
  BLUE: '#334AC0',
  CREAM: '#F8F8F8',
  GREEN: '#7EBA20',
  GREEN_PASTEL: '#C8E6C9',
  GREY_LIGHT: '#EDEDF3',
  GREY: '#A9AFC5',
  ORANGE: '#FFA412',
  RED: '#D10000',
  RED_PASTEL: '#FFDBDB',
  WHITE: '#FFF',
  YELLOW_DARK: '#f9b715',
  YELLOW_LIGHT: 'rgba(250, 194, 56, 0.6)',
  YELLOW_PASTEL: '#FFEDC1',
  YELLOW: '#FAC238',
}
type Colors = typeof COLORS

declare module '@mui/material/styles' {
  interface Theme {
    sidebar: { width: number; closedWidth: number }
    colors: Colors
  }
  // allow configuration using `createTheme` :
  interface ThemeOptions extends Theme {}
}

const customThemeWithoutDefault: ThemeOptions = {
  colors: COLORS,
  spacing: 8,
  sidebar: { width: 250, closedWidth: 70 },
  palette: {
    primary: { main: COLORS.YELLOW, dark: COLORS.YELLOW_DARK, light: COLORS.YELLOW_LIGHT },
    secondary: { main: COLORS.BLUE_NIGHT, light: COLORS.GREY_LIGHT },
    background: { default: COLORS.CREAM },
    text: { primary: COLORS.BLUE_NIGHT, secondary: COLORS.WHITE, disabled: COLORS.GREY_LIGHT },
  },
  typography: {
    fontFamily: ['Outfit', '-apple-system', 'Arial', 'sans-serif'].join(','),
    h1: { fontSize: '32px' },
    h2: { fontSize: '20px' },
    h3: { fontSize: '14px' },
    h4: { fontSize: '12px' },
    h5: { fontSize: '10px' },
    body1: { fontSize: '16px' },
    body2: { fontSize: '14px' },
    button: { textTransform: 'none' },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: { borderRadius: 10, boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)' },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 10,
          minHeight: 42,
          color: COLORS.BLUE_NIGHT,
          fontSize: '16px',
          '&:disabled': {
            backgroundColor: COLORS.GREY_LIGHT,
            color: COLORS.GREY,
          },
        },
        containedPrimary: {
          '&:hover': { backgroundColor: COLORS.YELLOW_LIGHT },
        },
        containedSecondary: {
          backgroundColor: COLORS.BLUE_PASTEL,
          '&:hover': { backgroundColor: COLORS.GREY_LIGHT },
        },
        outlinedSecondary: { '&:hover': { backgroundColor: COLORS.BLUE_PASTEL } },
        textPrimary: { '&:hover': { backgroundColor: COLORS.BLUE_PASTEL }, fontWeight: 400 },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: '42px',
          width: '38px',
          '&:hover': { border: '1px solid', borderRadius: '10px' },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minHeight: '56px',
          // Only way found to properly target label of TextField
          '& label.Mui-disabled': { color: COLORS.GREY },
          // Only way found to properly target helper text of TextField
          '& > p.Mui-disabled': { color: COLORS.GREY },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: COLORS.BLUE_NIGHT },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          '& :disabled': { cursor: 'not-allowed' },
        },
        input: { color: COLORS.BLUE_NIGHT, borderRadius: '10px' },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { color: COLORS.GREY, fontSize: '14px' },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: { color: COLORS.BLUE_NIGHT },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: { borderRadius: '10px' },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: { backgroundColor: COLORS.BLUE_NIGHT },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: { fontSize: '16px', fontWeight: 400 },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: { borderRadius: 0 },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: { color: COLORS.GREY },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: { color: COLORS.BLUE_NIGHT },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: { backgroundColor: COLORS.GREY, textAlign: 'center' },
      },
    },
  },
}

export const customTheme: ThemeOptions = merge({}, defaultTheme, customThemeWithoutDefault)
