export enum QueryKey {
  SwanAutoPayment = 'SwanAutoPayment',
  SwanOnboarding = 'SwanOnboarding',
  UpdateRoles = 'UpdateRoles',
  ImportValidate = 'ImportValidate',
  SwanAccount = 'SwanAccount',
  SwanAccountWithTransactions = 'SwanAccountWithTransactions',
  DashboardAnalytics = 'DashboardAnalytics',
  KomboLink = 'KomboLink',
  RolleeAccount = 'RolleeAccount',
  Me = 'Me',
  Axeptio = 'Axeptio',
  EnrollMFA = 'EnrollMFA',
  VerifyMFAEnrollment = 'VerifyMFAEnrollment',
  DisableMFA = 'DisableMFA',
  GetApiTokens = 'GetApiTokens',
  CreateApiToken = 'CreateApiToken',
  DeleteApiToken = 'DeleteApiToken',
  GetBorrower = 'GetBorrower',
  CreateBorrower = 'CreateBorrower',
  GetLoanRequests = 'GetLoanRequests',
  CreateLoanRequest = 'CreateLoanRequest',
  GetCreditLines = 'GetCreditLines',
  GetBills = 'GetBills',
  GetLoansPayments = 'GetLoansPayments',
  Maintenance = 'Maintenance',
  EntityNames = 'EntityNames',
  UpdateOnboarding = 'UpdateOnboarding',
  SendInvitationEmails = 'SendInvitationEmails',
  GenerateCode = 'GenerateCode',
  ValidateCode = 'ValidateCode',
  RefreshToken = 'RefreshToken',
}
