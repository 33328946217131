import { Switch, styled } from '@mui/material'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { ReactComponent as CheckedSVG } from 'assets/checked_boolean.svg'
import { ReactComponent as ClosedSVG } from 'assets/closed_boolean.svg'

const checkedSvgString = encodeURIComponent(renderToStaticMarkup(<CheckedSVG />))
const closedSvgString = encodeURIComponent(renderToStaticMarkup(<ClosedSVG />))

export const StyledSwitch = styled(Switch)(({ theme, color }) => ({
  width: 42,
  height: 24,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url("data:image/svg+xml,${checkedSvgString}")`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
    width: 18,
    height: 18,
    borderRadius: 9,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url("data:image/svg+xml,${closedSvgString}")`,
    },
  },
  '& .MuiSwitch-track': {
    boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.15)',
    borderRadius: 12,
    opacity: 1,
    backgroundColor: '#F8F8F8',
    boxSizing: 'border-box',
  },
}))
