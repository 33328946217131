import { Box, Paper, SxProps, Typography, CircularProgress, SvgIcon, useTheme } from '@mui/material'
import React, { ReactElement } from 'react'

import { Row, Column, Spacer } from 'utils/spacing'

interface Props {
  title: string
  color: 'primary' | 'secondary'
  value: string
  icon: ReactElement
  loading: boolean
}

export const KpiContainer = (props: Props) => {
  const theme = useTheme()
  const { title, color, value, icon, loading } = props

  return (
    <Paper sx={kpiSx}>
      <Row sx={{ height: '100%' }}>
        <Spacer x={1} />
        <SvgIcon sx={{ height: '50px', width: '50px' }}>{icon}</SvgIcon>
        <Spacer x={2} />
        <Column sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ color: theme.colors.GREY }}>
            {title}
          </Typography>
          {loading && (
            <Box sx={circularProgressSx}>
              <CircularProgress color={color} size="25px" thickness={5} />
            </Box>
          )}
          {!loading && (
            <Typography variant="h1" color={color} sx={{ fontSize: '40px', fontWeight: 500 }}>
              {value}
            </Typography>
          )}
        </Column>
      </Row>
    </Paper>
  )
}

const kpiSx: SxProps = {
  p: '0px 5px',
  width: '22%',
  height: '80%',
  minWidth: '220px',
  maxWidth: '350px',
  maxHeight: '100px',
  borderRadius: '8px',
}
const circularProgressSx: SxProps = {
  display: 'flex',
  height: '45px',
  justifyContent: 'center',
  alignItems: 'center',
}
