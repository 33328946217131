import { DownloadRounded } from '@mui/icons-material'
import {
  styled,
  Box,
  SxProps,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody as MuiTableBody,
  CircularProgress,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { FinancingBill } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { Column } from 'utils/spacing'

export const Bills = () => {
  const api = useApi()
  const t = useTranslate()
  const [bills, setBills] = useState<FinancingBill[]>([])

  const { isFetching } = useQuery([QueryKey.GetBills], async () => {
    try {
      const { data: bills } = await api.financingControllerGetBills()
      if (bills) {
        setBills(bills)
      }
    } catch (error) {
      throw error
    }
  })

  const DownloadBill = ({ url }: { url: string | undefined }) => {
    return (
      <IconButton
        sx={iconButtonSx}
        disabled={!Boolean(url)}
        onClick={() => window.open(url, '_blank')}>
        <DownloadRounded />
      </IconButton>
    )
  }

  const TableHeader = () => (
    <StyledTableHead>
      <TableRow sx={{ backgroundColor: 'lightblue' }}>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.billsTab.table.id')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.billsTab.table.dueDate')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.billsTab.table.status')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.billsTab.table.download')}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  )

  const TableBody = () => (
    <MuiTableBody>
      {bills.map((bill) => (
        <TableRow key={bill.id}>
          <TableCell scope="row" sx={tableCellSx}>
            {bill.id}
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            {bill.due_date}
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            {bill.status}
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            <DownloadBill url={bill.document_url} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  )

  const LoadingIcon = () => (
    <Box sx={loadingSx}>
      <CircularProgress />
    </Box>
  )

  return (
    <Column sx={{ height: 510 }}>
      <TableContainer component={Box} sx={{ height: '100%', width: '100%' }}>
        <Table stickyHeader={true}>
          <TableHeader />
          {!isFetching && <TableBody />}
        </Table>
        {isFetching && <LoadingIcon />}
      </TableContainer>
    </Column>
  )
}

const iconButtonSx: SxProps = { ':hover': { border: 'none' } }
const loadingSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}
const tableCellSx: SxProps = {
  width: '10px',
  borderBottom: 0,
  fontSize: '12px',
  pt: '7px',
  pb: '7px',
}

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.colors.GREY,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
  },
}))
