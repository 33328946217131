import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { ComponentProps, useCallback, useMemo } from 'react'
import { useListFilterContext, useTranslate } from 'react-admin'

import { Role, SwanTransactionStatus, TransactionStatus } from 'resources/types'
import { arrayToObject } from 'utils/array'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps: ComponentProps<typeof Select>['MenuProps'] = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type SelectValue = string | Role | TransactionStatus | SwanTransactionStatus

type Option = {
  label: SelectValue
  value: SelectValue
}

type Props = {
  label: string
  source: string
  options: Option[]
  defaultSelectedValues?: SelectValue[]
}

const defaultProps: Partial<Props> = {
  defaultSelectedValues: [],
}

export function MultipleSelectChip(props: Props) {
  const t = useTranslate()
  const theme = useTheme()
  const { filterValues, setFilters } = useListFilterContext()
  const selectedValues: SelectValue[] = filterValues[props.source] || props.defaultSelectedValues

  const valueOptionMap = useMemo(() => arrayToObject(props.options, 'value'), [props.options])

  function handleChange(event: SelectChangeEvent<string[]>) {
    const {
      target: { value },
    } = event
    const newValues = typeof value === 'string' ? value.split(',') : value
    const newFilter = { [props.source]: newValues }
    setFilters({ ...filterValues, ...newFilter }, null, false)
  }

  const renderChips = useCallback(
    (values: string[]) => {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {values.map((value) => {
            const option = valueOptionMap[value]
            if (option) {
              return <StyledChip key={value} label={option.label} size="small" />
            }
            return null
          })}
        </Box>
      )
    },
    [valueOptionMap]
  )

  return (
    <FormControl>
      <InputLabel id={`multiple-select-label-${props.label}`}>{t(props.label)}</InputLabel>
      <Select
        multiple
        labelId={`multiple-select-label-${props.label}`}
        value={selectedValues as string[]}
        onChange={handleChange}
        input={<StyledOutlinedInput label={t(props.label)} />}
        MenuProps={MenuProps}
        renderValue={renderChips}>
        {props.options.map((option) => {
          const isSelected = selectedValues.includes(option.value)
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{
                fontWeight: isSelected
                  ? theme.typography.fontWeightMedium
                  : theme.typography.fontWeightRegular,
              }}>
              <Checkbox checked={isSelected} />
              <ListItemText primary={option.label} />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

MultipleSelectChip.defaultProps = defaultProps

const StyledOutlinedInput = styled(OutlinedInput)({
  width: 160,
})

const StyledChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.colors.YELLOW_LIGHT,
  '& .MuiChip-label': {
    fontSize: 12,
  },
}))
