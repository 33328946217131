import SearchIcon from '@mui/icons-material/Search'
import { styled, InputBase } from '@mui/material'
import React, { ComponentProps, FC } from 'react'

import { useBreakpoints } from 'utils/useBreakpoints'

type Props = {
  containerProps?: ComponentProps<typeof StyledContainer>
  inputProps: ComponentProps<typeof StyledInputBase>
}

export const SearchBar: FC<Props> = ({ containerProps = {}, inputProps = {} }) => {
  const { isLarge } = useBreakpoints()
  const { sx = {}, ...restOfContainerProps } = containerProps
  const width = isLarge ? 600 : 350
  return (
    <StyledContainer {...restOfContainerProps} sx={{ width, ...sx }}>
      <StyledIconWrapper>
        <SearchIcon />
      </StyledIconWrapper>
      <StyledInputBase {...inputProps} />
    </StyledContainer>
  )
}

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: theme.colors.BLUE_PASTEL,
  height: '45px',
}))

const StyledIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.main,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  height: '100%',
  width: '100%',
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
}))
