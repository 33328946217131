import lodashGet from 'lodash/get'

import { storage } from 'user/storage'

const LocalStorage = {
  get(key: string) {
    const savedValue = storage.getObject<Record<string, any>>('raColumnsConfig')
    return lodashGet(savedValue, key)
  },
  set(key: string, value: object) {
    const savedValue = storage.getObject<Record<string, any>>('raColumnsConfig') || {}
    storage.save('raColumnsConfig', { ...savedValue, [key]: value })
  },
}

export default LocalStorage
