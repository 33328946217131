import { Box, BoxProps, SxProps, useTheme } from '@mui/material'
import React, { FC, useMemo } from 'react'

export const FLEX_ROW_SX: SxProps = { display: 'flex', flexDirection: 'row', alignItems: 'center' }
export const Row: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ ...FLEX_ROW_SX, ...props.sx }}>
      {children}
    </Box>
  )
}

export const FLEX_COLUMN_SX: SxProps = { display: 'flex', flexDirection: 'column' }
export const Column: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ ...FLEX_COLUMN_SX, ...props.sx }}>
      {children}
    </Box>
  )
}

type SpacerProps = {
  x?: number
  y?: number
}

export const Spacer: FC<SpacerProps> = (props) => {
  const { spacing } = useTheme()
  const { x = 0, y = 0 } = props
  const sx = useMemo<SxProps>(
    () => ({
      width: spacing(x),
      height: spacing(y),
      flexGrow: 0,
      flexShrink: 0,
    }),
    [x, y, spacing]
  )
  return <Box sx={sx} />
}
