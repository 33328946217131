import { Divider } from '@mui/material'
import React from 'react'

export const VerticalDivider = () => {
  return <Divider orientation="vertical" flexItem />
}

export const HorizontalDivider = () => {
  return <Divider orientation="horizontal" flexItem />
}
