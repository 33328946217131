import { SxProps, Card, Tabs, Tab, CardContent } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslate, Translate } from 'react-admin'
import { useQuery } from 'react-query'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { FinancingBorrower } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { useCanAccess } from 'libs/auth'
import { CustomHeader } from 'libs/components/CustomHeader'
import { usePersistedUser } from 'user/context'
import { Spacer } from 'utils/spacing'

import { Bills } from './Bills'
import { BorrowerStatus } from './BorrowerStatus'
import { CreateBorrower } from './createBorrower/CreateBorrower'
import { CreditLines } from './CreditLines'
import { Informations } from './Informations'
import { LoanRequests } from './loanRequests/LoanRequests'
import { LoansPayments } from './LoansPayments'

type TabValue = 'loans' | 'payments' | 'creditLines' | 'status' | 'bills'

type TabConfig = {
  value: TabValue
  path: string
  label: string
  sx: SxProps
  component: React.FC
}

const financingPath = (path: string) => '/financing/' + path

function getTabsConfigs(
  t: Translate,
  canBorrow: boolean | undefined,
  isAdmin: boolean
): TabConfig[] {
  return [
    {
      value: 'loans',
      path: financingPath('loans'),
      label: !canBorrow
        ? t('financing.loansTab.createBorrower.dialog.title')
        : isAdmin
        ? t('financing.loansTab.title')
        : t('financing.infoTab.title'),
      sx: { ...tabSx, width: canBorrow ? '270px' : '370px' },
      component: isAdmin ? LoanRequests : Informations,
    },
    ...(canBorrow && isAdmin
      ? [
          {
            value: 'payments',
            path: financingPath('payments'),
            label: t('financing.paymentsTab.title'),
            sx: tabSx,
            component: LoansPayments,
          } as TabConfig,
          {
            value: 'creditLines',
            path: financingPath('creditLines'),
            label: t('financing.creditLinesTab.title'),
            sx: tabSx,
            component: CreditLines,
          } as TabConfig,
          {
            value: 'status',
            path: financingPath('status'),
            label: t('financing.borrowerTab.title'),
            sx: tabSx,
            component: BorrowerStatus,
          } as TabConfig,
          {
            value: 'bills',
            path: financingPath('bills'),
            label: t('financing.billsTab.title'),
            sx: tabSx,
            component: Bills,
          } as TabConfig,
        ]
      : []),
  ]
}

function matchPathnameToTabConfig(pathname: string, tabsConfigs: TabConfig[]) {
  for (const tabConfig of tabsConfigs) {
    if (matchPath(tabConfig.path, pathname)) {
      return tabConfig
    }
  }
  return tabsConfigs[0]
}

export const FinancingPage: React.FC = () => {
  const t = useTranslate()
  const api = useApi()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { canAccess } = useCanAccess()
  const isAdmin = canAccess({ roles: ['admin'] })

  const { borrowerId } = usePersistedUser().organization
  const [canBorrow, setCanBorrow] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (!Boolean(borrowerId)) {
      setCanBorrow(false)
    }
  }, [borrowerId])

  useQuery([QueryKey.GetBorrower, borrowerId], async () => {
    if (!Boolean(borrowerId)) return
    try {
      const borrower = await api.financingControllerGetBorrower()
      if (borrower.status === FinancingBorrower.StatusEnum.TOSIGN) {
        setCanBorrow(false)
      } else setCanBorrow(true)
    } catch (error) {
      console.error(error)
    }
  })

  const tabsConfigs = useMemo(() => getTabsConfigs(t, canBorrow, isAdmin), [t, canBorrow, isAdmin])
  const [selectedTabConfig, setSelectedTabConfig] = React.useState<TabConfig>(
    matchPathnameToTabConfig(pathname, tabsConfigs)
  )

  const handleTabChange = (_: React.SyntheticEvent, newValue: TabValue) => {
    for (const tabConfig of tabsConfigs) {
      if (newValue === tabConfig.value) {
        navigate(tabConfig.path)
        setSelectedTabConfig(tabConfig)
        return
      }
    }
  }

  if (canBorrow === undefined)
    return (
      <>
        <CustomHeader title={t('financing.title')} />
      </>
    )

  return (
    <>
      <CustomHeader title={t('financing.title')} />
      <Spacer y={3} />
      <Card sx={cardSx(canBorrow, isAdmin)}>
        <Tabs value={selectedTabConfig.value} onChange={handleTabChange}>
          {tabsConfigs.map((tabConfig) => {
            return (
              <Tab
                key={tabConfig.value}
                label={tabConfig.label}
                value={tabConfig.value}
                sx={tabConfig.sx}
              />
            )
          })}
        </Tabs>
        <CardContent sx={{ p: 3 }}>
          {!canBorrow && <CreateBorrower />}
          {canBorrow && <selectedTabConfig.component />}
        </CardContent>
      </Card>
    </>
  )
}

const tabSx: SxProps = { p: '16px 24px 16px 16px', width: '19%' }
const cardSx = (canBorrow: boolean | undefined, isAdmin: boolean) =>
  ({
    width: !canBorrow ? '360px' : isAdmin ? '100%' : '1000px',
    height: !canBorrow ? '220px' : isAdmin ? '600px' : '500px',
  } as SxProps)
