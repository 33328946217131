import { Typography, Divider, SxProps, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useSidebarState } from 'react-admin'

import { usePersistedUser } from 'user/context'
import { getInitials } from 'utils/format'
import { Row, Spacer, Column } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

import { CompanyBalance } from './CompanyBalance'
import { TenantSelector } from './TenantSelector'

export function UserProfile() {
  const { tRoleMap } = useRoleTranslation()
  const theme = useTheme()
  const [isSideBarOpen] = useSidebarState()
  const { user, contract } = usePersistedUser()

  const initials = getInitials(user?.firstName, user?.lastName)
  const roles = tRoleMap(contract?.roles)
  return (
    <>
      <StyledDivider />
      <Spacer y={2} />
      <Row>
        <StyledCircle>
          <Typography color={theme.palette.text.secondary} sx={initialsSx}>
            {initials}
          </Typography>
        </StyledCircle>
        <Spacer x={1} />
        {isSideBarOpen && (
          <Column>
            <Typography
              color={theme.palette.text.secondary}
              variant="h2"
              sx={{ overflowWrap: 'anywhere', fontWeight: 500 }}>
              {user?.name}
            </Typography>
            <Spacer y={1} />
            <Typography color={theme.palette.text.secondary} variant="h3">
              {roles}
            </Typography>
          </Column>
        )}
      </Row>
      <Spacer y={2} />
      {isSideBarOpen ? <CompanyBalance /> : <Spacer y={3} />}
      <Spacer y={1} />
      <TenantSelector />
      <Spacer y={2} />
      <StyledDivider />
    </>
  )
}

const initialsSx: SxProps = {
  textAlign: 'center',
  mt: 1.4,
  fontSize: '20px',
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: theme.palette.background.default,
  opacity: 0.12,
}))

const StyledCircle = styled('div')(({ theme }) => ({
  marginLeft: '-4px',
  height: '51px',
  width: '51px',
  minWidth: '51px',
  borderRadius: '50px',
  border: '3px solid',
  borderColor: theme.palette.primary.main,
}))
