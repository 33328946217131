import { LoadingButton } from '@mui/lab'
import { Autocomplete, Chip, TextField, Typography, useTheme, Link } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQueryClient } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { useNotifications } from 'libs/notifications'
import { sleep } from 'utils/async'
import { Row, Spacer } from 'utils/spacing'

const SILAE_LOGO_PATH = '/silae-new-logo-300x100.png'
const SILAE_FORM_LINK = 'https://airtable.com/appR6s5qwLVQ81uci/shrQsuot807vtxTPO'

export const SilaeLogin: React.FC = () => {
  const theme = useTheme()
  const t = useTranslate()
  const api = useApi()
  const notification = useNotifications()
  const queryClient = useQueryClient()

  const [numerosDossiers, setNumerosDossiers] = useState<string[]>([])
  const [subscriptionKey, setSubscriptionKey] = useState('')
  const [id, setId] = useState('')
  const [secret, setSecret] = useState('')

  const silaeIntegrationMutation = useMutation(
    'silaeIntegration',
    async () => {
      const body = { numerosDossiers, subscriptionKey, id, secret }
      const response = await api.integrationControllerSilaeIntegration(body)
      await sleep(1000)
      return response
    },
    {
      onSuccess: async () => {
        notification(t('notifications.silaeConnect.success'), { variant: 'success' })
        queryClient.invalidateQueries(QueryKey.Me)
        await sleep(1000)
        location.reload()
      },
      onError: (error: any) => {
        notification(t('notifications.silaeConnect.error'), { variant: 'error' })
        console.error(error)
      },
    }
  )
  return (
    <>
      <Row sx={{ width: '100%' }}>
        <img src={SILAE_LOGO_PATH} style={{ width: '120px' }} />
        <Spacer x={3} />
        <Typography variant="h2" color={theme.palette.text.primary}>
          {t('administration.silaeHelperTexts.instructions')}
        </Typography>
      </Row>
      <Spacer y={2} />
      <Typography sx={{ width: '100%' }} variant="h4" color={theme.colors.GREY}>
        1. {t('administration.silaeHelperTexts.0')}
        <Link href={SILAE_FORM_LINK} target="_blank">
          {t('administration.silaeHelperTexts.formLink')}
        </Link>
      </Typography>
      <Spacer y={1} />
      <Typography sx={{ width: '100%' }} variant="h4" color={theme.colors.GREY}>
        2. {t('administration.silaeHelperTexts.1')}
      </Typography>
      <Spacer y={1} />
      <Typography sx={{ width: '100%' }} variant="h4" color={theme.colors.GREY}>
        3. {t('administration.silaeHelperTexts.2')}
      </Typography>
      <Spacer y={1} />
      <Typography sx={{ width: '100%' }} variant="h4" color={theme.colors.GREY}>
        4. {t('administration.silaeHelperTexts.3')}
      </Typography>
      <Spacer y={2} />
      <Row sx={{ width: '100%' }}>
        <TextField
          label={t('administration.labels.silaeId')}
          sx={{ width: '50%' }}
          onChange={(event) => setId(event.target.value)}
          value={id}
          inputProps={{ autocomplete: 'off' }} // to disable autofill
        />
        <Spacer x={2} />
        <TextField
          label={t('administration.labels.silaePassword')}
          sx={{ width: '50%' }}
          onChange={(event) => setSecret(event.target.value)}
          value={secret}
          type="password"
          inputProps={{ autocomplete: 'new-password' }} // to disable autofill //
        />
      </Row>
      <Spacer y={2} />
      <TextField
        label={'Clé de souscription'}
        sx={{ width: '100%' }}
        onChange={(event) => setSubscriptionKey(event.target.value)}
        value={subscriptionKey}
        inputProps={{ autocomplete: 'off' }} // to disable autofill
      />
      <Spacer y={2} />
      <Autocomplete
        sx={{ width: '100%' }}
        multiple
        options={[]}
        freeSolo
        autoSelect
        selectOnFocus
        onChange={(event, values) => {
          setNumerosDossiers(values)
        }}
        renderTags={(values, getTagProps) => {
          return values.map((value, index) => {
            const { key, ...tagProps } = getTagProps({ index })
            return (
              <Chip variant="outlined" label={value} {...tagProps} key={key} onDelete={undefined} />
            )
          })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('administration.labels.silaeNumerosDossiers')}
            inputProps={{ ...params.inputProps, autocomplete: 'off' }}
          />
        )}
      />
      <Spacer y={5} />
      <LoadingButton
        variant="contained"
        sx={{ width: '100%' }}
        onClick={() => silaeIntegrationMutation.mutate()}
        loading={silaeIntegrationMutation.isLoading}
        disabled={!subscriptionKey || !id || !secret}>
        {t('administration.integration.silae.connect')}
      </LoadingButton>
    </>
  )
}
