import { AddCircleOutlineRounded, CloseRounded, FileDownload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Drawer, Button, SxProps, Stack, IconButton, Tooltip, useTheme } from '@mui/material'
import React, { useState, useCallback, FC } from 'react'
import {
  TextField,
  DateField,
  ListBase,
  ListView,
  useTranslate,
  NumberField,
  useLocaleState,
  FunctionField,
  useRefresh,
  useListContext,
  BulkActionProps,
  useUnselectAll,
} from 'react-admin'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { Contract } from 'api/gen'
import { config } from 'config'
import { ListHeader } from 'layout/ListHeader'
import { AddOrImportUserModal } from 'libs/AddOrImportUserModal/AddOrImportUserModal'
import { analytics } from 'libs/analytics'
import { CanAccess, useCanAccess } from 'libs/auth'
import { SelectColumnsButton } from 'libs/columnModal/SelectColumnsButton'
import { DeactivateNesspayToggle } from 'libs/datagrid/CustomBooleanField'
import { CustomizableDatagrid } from 'libs/datagrid/CustomizableDatagrid'
import { ExportButton } from 'libs/export'
import { ExportModal } from 'libs/export/ExportModal'
import { useModal } from 'libs/modal'
import { ConfirmRemoveRoleDialog } from 'pages/administration/roleManagement/RemoveRoleDialog'
import { ContractRecord, NESSPAY_ONLY_ROLES, ROLES_WITH_WRITE_PERMISSION } from 'resources/types'
import { usePersistedUser } from 'user/context'
import { Row, Spacer } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

import {
  EntityNameFilter,
  ContractActiveFilter,
  ContractServiceEnabledFilter,
  ContractUsageStatusFilter,
} from '../filters'
import { CustomPagination, RowsPerPage } from '../Pagination'

import { UserEdit } from './UserEdit.Sidebar'
import { userListExporters } from './UserList.exporters'
import { UserSearchBar } from './UserSearchBar'

const UserListHeader = () => {
  const t = useTranslate()
  const { openModal: openAddOrImportModal } = useModal('addOrImportUsers')
  const { openModal: openExportModal } = useModal('export')
  const { hasIntegration, hasEntityNames, isSwanActive } = usePersistedUser()
  const match = matchPath('/contract/:id', location.pathname)

  const onExportUsers = () => {
    analytics.log('click on Export', { resource: 'users' })
    openExportModal()
  }

  return (
    <ListHeader
      actions={
        <>
          <CanAccess roles={NESSPAY_ONLY_ROLES}>
            <SelectColumnsButton />
          </CanAccess>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FileDownload />}
            onClick={onExportUsers}
            disabled={!isSwanActive}>
            {t('buttons.export.name')}
          </Button>
          <CanAccess roles={ROLES_WITH_WRITE_PERMISSION}>
            {hasIntegration ? null : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineRounded />}
                onClick={openAddOrImportModal}
                disabled={!isSwanActive}
                id="create-employee-button">
                {t('buttons.create')}
              </Button>
            )}
          </CanAccess>
        </>
      }
      filters={
        <>
          <UserSearchBar resource="contract" />
          <Spacer x={1} />
          {(hasEntityNames || !config.IS_PROD) && <EntityNameFilter />}
          <Spacer x={1} />
          {!match && <ContractServiceEnabledFilter />}
          <Spacer x={1} />
          {!match && <ContractUsageStatusFilter />}
        </>
      }
    />
  )
}

export const UserList = () => {
  const api = useApi()
  const { canAccess } = useCanAccess()
  const t = useTranslate()
  const theme = useTheme()
  const { tRole } = useRoleTranslation()
  const [locale] = useLocaleState()
  const location = useLocation()
  const navigate = useNavigate()
  const refresh = useRefresh()
  const { hasEntityNames, organization } = usePersistedUser()
  const match = matchPath('/contract/:id', location.pathname)

  const { openModal: openRemoveRoleModal } = useModal('removeRole')
  const { isModalOpen: isAddOrImportModalOpen } = useModal('addOrImportUsers')
  const { isModalOpen: isExportModalOpen } = useModal('export')

  const [idToRemove, setIdToRemove] = useState('')
  const [hasManyRoles, setHasManyRoles] = useState(false)

  const isAdmin = canAccess({ roles: [Contract.RolesEnum.Admin] })
  const isAccountManager = canAccess({ roles: [Contract.RolesEnum.AccountManager] })
  const isOuiHelpOrganization = organization.id === config.OUIHELP_ORG_ID
  const canDeleteUser = canAccess({
    roles: [Contract.RolesEnum.Employer, Contract.RolesEnum.Admin],
  })
  const isNesspayTeam = canAccess({
    roles: [Contract.RolesEnum.Admin, Contract.RolesEnum.AccountManager],
  })
  const PERMANENT_FILTERS = isNesspayTeam
    ? { roles: [Contract.RolesEnum.Employee] }
    : { roles: [Contract.RolesEnum.Employee], active: ['true'] }
  const DEFAULT_FILTERS = { active: ['true'] }

  const closeEditView = useCallback(() => {
    navigate('/contract')
  }, [navigate])

  function handleDeleteUser(record?: ContractRecord) {
    if (record) {
      setIdToRemove(record.id)
      setHasManyRoles(record.roles.length > 1)
    } else {
      setIdToRemove('')
      setHasManyRoles(false)
    }
    openRemoveRoleModal()
  }

  const BulkDeleteButtons: FC<BulkActionProps> = () => {
    const [loading, setLoading] = useState(false)
    const unselectIds = useUnselectAll('contract')
    const { selectedIds } = useListContext()
    async function deleteSelectedUsersContract() {
      setLoading(true)
      for (const id of selectedIds) {
        await api.contractControllerDeleteContract(id)
        refresh()
      }
      setLoading(false)
      unselectIds()
    }
    return (
      <>
        {canDeleteUser && (
          <Button variant="contained" color="primary" onClick={() => handleDeleteUser()}>
            {t('buttons.delete')}
          </Button>
        )}
        {isAdmin && config.ENV !== 'production' && (
          <LoadingButton
            variant="contained"
            color="error"
            onClick={deleteSelectedUsersContract}
            loading={loading}>
            {t('buttons.delete') + ' [ADMINS]'}
          </LoadingButton>
        )}
      </>
    )
  }

  const DeleteUserIcon = ({ record }: { record: ContractRecord }) => (
    <Row
      onClick={(event) => event.stopPropagation()}
      sx={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip
        title={t(`resources.user.tooltips.deactivate`, record.roles.length)}
        componentsProps={{ tooltip: { sx: { maxWidth: '180px' } } }}>
        <IconButton onClick={() => handleDeleteUser(record)} sx={buttonSx}>
          <CloseRounded sx={{ ...iconSx, color: theme.colors.GREY }} />
        </IconButton>
      </Tooltip>
    </Row>
  )

  const FieldsList = () => (
    <CustomizableDatagrid bulkActionButtons={canDeleteUser ? <BulkDeleteButtons /> : false}>
      {isNesspayTeam && <TextField source="id" sortable={false} />}
      {isNesspayTeam && <TextField source="user.id" label={t('userId')} sortable={false} />}
      <TextField source="user.firstName" />
      <TextField source="user.lastName" />
      {isNesspayTeam && <TextField source="user.email" sortable={false} />}
      {isNesspayTeam && <TextField source="user.phone" sortable={false} />}
      {isNesspayTeam && <TextField source="staffNumber" />}
      <TextField source="organization.name" label={t('resources.organization.name')} />
      {(hasEntityNames || !config.IS_PROD) && <TextField source="entityName" />}
      {!isOuiHelpOrganization && (
        <NumberField
          source="netSalary"
          locales={locale}
          options={{ style: 'currency', currency: 'EUR' }}
          sx={{ width: '45%' }}
        />
      )}
      <FunctionField
        source="availableSalaryFraction"
        textAlign="right"
        render={(record: ContractRecord) => `${record.availableSalaryFraction} %`}
        sx={{ width: '70%' }}
      />
      {(isNesspayTeam || isOuiHelpOrganization) && (
        <NumberField
          source="availableBalance"
          locales={locale}
          options={{ style: 'currency', currency: 'EUR' }}
        />
      )}
      {isNesspayTeam && <DateField source="contractStartDate" />}
      {isNesspayTeam && <DateField source="contractEndDate" />}
      {isNesspayTeam && <DateField source="user.createdAt" />}
      {isNesspayTeam && <DateField source="user.updatedAt" />}
      {isNesspayTeam && <TextField source="user.fronteggId" />}
      {isNesspayTeam && <DeactivateNesspayToggle source="hasAdvancesEnabled" textAlign="center" />}
      {isNesspayTeam && <DeactivateNesspayToggle source="isNotAbsent" textAlign="center" />}
      {isNesspayTeam && <DeactivateNesspayToggle source="isAppAccessEnabled" textAlign="center" />}
      <FunctionField
        textAlign="center"
        source="usageStatus"
        render={(record: ContractRecord) =>
          t(`resources.contract.usageStatus.${record.usageStatus}`)
        }
      />
      {canDeleteUser && (
        <FunctionField
          textAlign="center"
          source="active"
          label={t('resources.activity.fields.action')}
          render={(record: ContractRecord) => <DeleteUserIcon record={record} />}
        />
      )}
    </CustomizableDatagrid>
  )

  return (
    <Box display="flex">
      <ListBase
        perPage={RowsPerPage.TEN}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filterDefaultValues={DEFAULT_FILTERS}
        filter={PERMANENT_FILTERS}>
        <Box
          sx={{
            flexGrow: 1,
            transition: (theme: any) =>
              theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
              }),
            marginRight: !!match ? '300px' : 0,
          }}>
          <ListView
            empty={false}
            title={tRole('employee', true)}
            filters={<UserListHeader />}
            actions={false}
            pagination={<CustomPagination />}>
            <FieldsList />
          </ListView>
        </Box>
        {isExportModalOpen && (
          <ExportModal>
            <Stack direction="row" spacing={20} sx={stackSx}>
              <ExportButton
                type="xls"
                exporter={
                  isAccountManager ? userListExporters.xlsxWithoutIban : userListExporters.xls
                }
              />
              <ExportButton
                type="csv"
                exporter={
                  isAccountManager ? userListExporters.csvWithoutIban : userListExporters.csv
                }
              />
            </Stack>
          </ExportModal>
        )}
        <ConfirmRemoveRoleDialog
          contractId={idToRemove}
          hasManyRoles={hasManyRoles}
          shouldDeactivateContract={true}
          roleToRemove={Contract.RolesEnum.Employee}
        />
      </ListBase>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={closeEditView}
        sx={{ zIndex: 100 }}>
        {!!match && <UserEdit id={(match as any).params.id} onCancel={closeEditView} />}
      </Drawer>
      {isAddOrImportModalOpen && <AddOrImportUserModal />}
    </Box>
  )
}

const iconSx: SxProps = { width: '15px', height: '15px' }
const stackSx: SxProps = { height: '75%', justifyContent: 'center', alignItems: 'center' }
const buttonSx: SxProps = { ':hover': { border: 'none' } }
