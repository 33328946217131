import { styled, useTheme, SxProps } from '@mui/material'
import React, { FC } from 'react'

import { ConfirmLogoutDialog } from 'libs/components/ConfirmLogoutDialog'
import { OnboardingModal } from 'libs/onboardingModal/OnboardingModal'
import { useBreakpoints } from 'utils/useBreakpoints'

import { CustomSidebar } from './Sidebar'
import { CustomMenu } from './SidebarMenu'
import { CustomSidebarToggleButton } from './SidebarToggleButton'

export const CustomLayout: FC = (props) => {
  const { isSmall } = useBreakpoints()
  const theme = useTheme()
  return (
    <Root>
      <OnboardingModal />
      <AppFrame>
        <ContentWithSidebar>
          {isSmall && (
            <CustomSidebarToggleButton
              sx={{ ...toggleButtonSx, backgroundColor: theme.palette.secondary.main }}
            />
          )}
          <CustomSidebar sx={{ backgroundColor: theme.palette.secondary.main, height: '100%' }}>
            <CustomMenu />
          </CustomSidebar>
          <Content>{props.children}</Content>
        </ContentWithSidebar>
      </AppFrame>
      <ConfirmLogoutDialog />
    </Root>
  )
}

const toggleButtonSx: SxProps = {
  position: 'fixed',
  bottom: 15,
  right: 15,
  zIndex: 4,
  width: 50,
  height: 50,
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 1,
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
}))

const AppFrame = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
})

const ContentWithSidebar = styled('main')({
  display: 'flex',
  height: '100%',
  zIndex: 2,
  '& .RaSidebar-fixed': {
    height: '100vh !important',
    zIndex: 3,
  },
})

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(2),
  margin: 0,
  overflowX: 'auto',
}))
