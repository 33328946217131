import { format, parseISO } from 'date-fns'
import { saveAs } from 'file-saver'
import { ColInfo, utils, WorkSheet, writeFile } from 'xlsx'

import { FileFormat } from 'api/gen'

function convertDateStringToXlsDate(worksheet: WorkSheet, lastIndex?: number): WorkSheet {
  let index = 1
  const sheetColumn = 'E'
  if (!lastIndex) return worksheet
  while (index++ < lastIndex) {
    const key = sheetColumn + index
    // set value type to date
    worksheet[key]['t'] = 'd'
    // format date to XSLX format
    worksheet[key]['v'] = format(parseISO(worksheet[key]['v']), 'dd/MM/yyyy')
  }
  return worksheet
}

export function exportTo(
  type: FileFormat.TypeEnum,
  rows: object[],
  name: string,
  columnsInfos?: ColInfo[]
) {
  if (type === FileFormat.TypeEnum.Xlsx) return exportToExcel(rows, name, columnsInfos)
  else return exportToCSV(rows, name)
}

export function exportToExcel(rows: any[], name: string, columnsInfos?: ColInfo[]) {
  let worksheet = utils.json_to_sheet(rows)

  // convert dates if worksheet contains a date column
  if (worksheet['E1']['v'] === 'Date comptable')
    worksheet = convertDateStringToXlsDate(worksheet, parseInt(worksheet['!ref']?.slice(-1) || '0'))

  if (columnsInfos) {
    worksheet['!cols'] = columnsInfos
  } else {
    worksheet['!cols'] = Object.keys(rows[0]).map((name) => ({ wch: Math.max(name.length, 15) }))
  }

  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, worksheet, 'Dates')
  const currentDateString = new Date().toLocaleDateString()
  writeFile(workbook, `${name}-${currentDateString}.xlsx`)
}

export function exportToCSV(rows: object[], name: string) {
  const currentDateString = new Date().toLocaleDateString()
  const worksheet = utils.json_to_sheet(rows)
  const payload = utils.sheet_to_csv(worksheet, { FS: ';' })
  const blob = new Blob([payload], { type: 'text/csv;charset=utf-8' })
  saveAs(blob, `${name}-${currentDateString}.csv`)
}
