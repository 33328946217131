import { useApi } from 'api'
import { useAuthContext } from 'libs/auth/provider'
import { useInterval } from 'utils/useInterval'

const HALF_HOUR_IN_MILLISECONDS = 30 * 60 * 1000

export function useRefreshSwanToken() {
  const api = useApi()
  const { auth, setAuth } = useAuthContext()
  useInterval(
    () => {
      if (!auth.swanRefreshToken) return
      api
        .swanControllerGetUserTokenFromRefreshToken({
          refreshToken: auth.swanRefreshToken,
        })
        .then(({ access_token, refresh_token }) => {
          setAuth({ swanAccessToken: access_token, swanRefreshToken: refresh_token })
        })
        .catch(() => {
          // TODO Reset token only when status code is 401 or 403
          setAuth({ swanAccessToken: undefined, swanRefreshToken: undefined })
        })
    },
    true,
    auth.swanRefreshToken ? HALF_HOUR_IN_MILLISECONDS : null
  )
}
