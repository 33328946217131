import { ViewWeekRounded } from '@mui/icons-material'
import { IconButton, IconButtonProps } from '@mui/material'
import React, { ReactElement } from 'react'

import { useModal } from 'libs/modal'

interface Props extends IconButtonProps {
  icon?: ReactElement
}

const defaultProps: Props = { icon: <ViewWeekRounded />, color: 'secondary' }

export const SelectColumnsButton = (props: Props) => {
  const { className, icon, ...rest } = props
  const { openModal } = useModal('selectColumns')
  return (
    <IconButton onClick={openModal} className={className} {...(rest as any)}>
      {icon}
    </IconButton>
  )
}

SelectColumnsButton.defaultProps = defaultProps
