import { SxProps, Typography, TextField, Divider, useTheme } from '@mui/material'
import { addMonths } from 'date-fns'
import React, { useState } from 'react'
import { useTranslate, useLocaleState } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { FinancingBill, FinancingCreditLine } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { Column, Row, Spacer } from 'utils/spacing'

const PAGE_SIZE = 10
const EMPTY_AMOUNT = '0€'
const EMPTY_DATE = ' . / . / . '

export const Informations = () => {
  const t = useTranslate()
  const api = useApi()
  const theme = useTheme()
  const [locale] = useLocaleState()

  const [bills, setBills] = useState<FinancingBill[]>([])
  const [creditLines, setCreditLines] = useState<FinancingCreditLine[]>([])

  useQuery(QueryKey.GetCreditLines, async () => {
    try {
      const creditLines = await api.financingControllerGetCreditLines()
      setCreditLines(creditLines)
    } catch (error) {
      throw error
    }
  })

  useQuery([QueryKey.GetBills], async () => {
    try {
      const { data: bills } = await api.financingControllerGetBills()
      if (bills) {
        setBills(bills)
      }
    } catch (error) {
      throw error
    }
  })

  function getCreditLinesField(field: keyof FinancingCreditLine): string {
    if (field !== 'end_date') {
      if (!creditLines || !creditLines.length) return EMPTY_AMOUNT
      const creditLine = creditLines[0]
      if (!creditLine[field] || typeof creditLine[field] !== 'number') return EMPTY_AMOUNT
      return (creditLine[field] / 100).toLocaleString(locale) + '€'
    }
    if (!creditLines || !creditLines.length) return EMPTY_DATE
    const creditLine = creditLines[0]
    if (!creditLine[field] || typeof creditLine[field] !== 'string') return EMPTY_DATE
    return new Date(creditLine[field] as string).toLocaleDateString(locale)
  }

  function getBillsField(field: 'amount' | 'scheduled_at'): string {
    if (field === 'amount') {
      if (!bills || !bills.length) return EMPTY_AMOUNT
      const payment = bills[0].payment
      if (!payment) return EMPTY_AMOUNT
      return (payment.amount / 100).toLocaleString(locale)
    }
    if (!bills || !bills.length) return EMPTY_DATE
    const payment = bills[0].payment
    if (!payment || !payment[field] || typeof payment[field] !== 'string') return EMPTY_DATE
    return new Date(payment[field] as string).toLocaleDateString(locale)
  }

  const InformationDivider = () => (
    <>
      <Spacer y={1} />
      <Divider orientation="horizontal" sx={{ width: '65%' }} />
      <Spacer y={1} />
    </>
  )

  const InformationRow = ({ title, value }: { title: string; value: string }) => (
    <Row sx={rowSx}>
      <Typography>{title}</Typography>
      <TextField
        disabled
        value={value}
        size="small"
        variant="standard"
        sx={textFieldSx}
        InputProps={{ disableUnderline: true }}
        FormHelperTextProps={{ style: { height: 0 } }}
        inputProps={{ style: { ...inputStyle, color: theme.palette.secondary.main } }}
      />
    </Row>
  )

  return (
    <Column sx={columnSx}>
      <InformationRow
        title={t('financing.infoTab.capacity')}
        value={getCreditLinesField('pool_amount')}
      />
      <InformationDivider />
      <InformationRow
        title={t('financing.infoTab.expirationDate')}
        value={getCreditLinesField('end_date')}
      />
      <InformationDivider />
      <InformationRow
        title={t('financing.infoTab.outstanding')}
        value={getCreditLinesField('balance_pending')}
      />
      <InformationDivider />
      <InformationRow
        title={t('financing.infoTab.balance')}
        value={getCreditLinesField('balance_available')}
      />
      <InformationDivider />
      <InformationRow
        title={t('financing.infoTab.debitDate')}
        value={getBillsField('scheduled_at')}
      />
      <InformationDivider />
      <InformationRow title={t('financing.infoTab.debitAmount')} value={getBillsField('amount')} />
      <InformationDivider />
    </Column>
  )
}

const columnSx: SxProps = { height: '100%', display: 'flex' }
const rowSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '65%',
  minHeight: '50px',
}
const textFieldSx: SxProps = {
  minHeight: '28px',
  width: '108px',
  boxShadow: '0px 0px 4px rgba(6, 17, 63, 0.2)',
  borderRadius: '10px',
  padding: '0px 30px',
}
const inputStyle: React.CSSProperties = {
  textAlign: 'center',
  height: '28px',
  fontSize: '14px',
  marginTop: '3px',
}
