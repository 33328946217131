import React, { createContext, useContext, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { doNothing } from 'utils/doNothing'
import { useStateWithStorage } from 'utils/useStateWithStorage'

import { Auth, AuthContextType } from './types'
import { useAuth } from './useAuth'

const DEFAULT_SWAN_SECURITY_STATE = uuid()

const INITIAL_AUTH = {} as Auth

export const INITIAL_AUTH_CONTEXT: AuthContextType = {
  auth: INITIAL_AUTH,
  setAuth: doNothing,
  swanSecurityState: DEFAULT_SWAN_SECURITY_STATE,
}

const AuthContext = createContext<AuthContextType>(INITIAL_AUTH_CONTEXT)

export const AuthProvider: React.FC = (props) => {
  const { accessToken, isLoading: loading } = useAuth()
  const [auth, setAuth] = useStateWithStorage<Auth>('@Auth', INITIAL_AUTH)
  const [swanSecurityState] = useStateWithStorage<string>(
    '@SwanSecurityState',
    DEFAULT_SWAN_SECURITY_STATE
  )

  const contextValue = useMemo(() => {
    const newAuth = { ...auth }
    if (accessToken) {
      newAuth.loading = loading
      newAuth.accessToken = accessToken
      newAuth.isAuthenticated = true
    }

    return {
      auth: newAuth,
      setAuth,
      swanSecurityState,
    }
  }, [auth, setAuth, accessToken, loading, swanSecurityState])

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export function useAuthContext() {
  return useContext(AuthContext)
}
