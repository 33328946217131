import { CloseRounded } from '@mui/icons-material'
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme,
  SxProps,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { useModal } from 'libs/modal'
import { Column, Spacer, Row } from 'utils/spacing'

import { CreateLoanRequestForm } from './LoanRequestsForm'

export const CreateLoanRequestDialog = () => {
  const t = useTranslate()
  const theme = useTheme()

  const { isModalOpen, closeModal } = useModal('createLoanRequest')

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle sx={{ backgroundColor: theme.palette.secondary.main, maxHeight: '55px' }}>
        <Row sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={iconButtonSx} />
          <Typography color="primary">{t('financing.loansTab.createLoanRequest.title')}</Typography>
          <IconButton onClick={closeModal} sx={{ ...iconButtonSx, ':hover': { border: 'none' } }}>
            <CloseRounded sx={{ color: theme.colors.WHITE }} />
          </IconButton>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Column sx={{ display: 'flex', alignItems: 'center' }}>
          <Spacer y={2} />
          <CreateLoanRequestForm />
        </Column>
      </DialogContent>
    </Dialog>
  )
}

const iconButtonSx: SxProps = { width: '15px', height: '15px' }
const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '600px',
      height: '100%',
      maxHeight: '360px',
    },
  },
}
