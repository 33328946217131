import { SxProps, useTheme, Dialog, DialogTitle, Typography, DialogContent } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { useModal } from 'libs/modal'
import { ContractRecord } from 'resources/types'
import { Spacer } from 'utils/spacing'

import { UpdateRolesForm } from './UpdateRolesForm'

interface Props {
  record: ContractRecord[]
}

export const UpdateRolesDialog = ({ record }: Props) => {
  const t = useTranslate()
  const theme = useTheme()
  const { isModalOpen, closeModal } = useModal('editRole')

  if (!record || !record.length) return <></>

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle sx={{ backgroundColor: theme.palette.secondary.main, maxHeight: '55px' }}>
        <Typography color="primary" sx={{ textAlign: 'center' }}>
          {t('administration.roleManagementTab.updateUserDialog.dialogTitle')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Spacer y={2} />
        <Typography variant="h2" sx={{ fontWeight: 500 }}>
          {t('administration.roleManagementTab.updateUserDialog.dialogSubtitle')}
          <Spacer y={4} />
          <UpdateRolesForm record={record} />
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '600px',
      minHeight: '250px',
      maxHeight: '1700px',
    },
  },
}
