export enum NesspayFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  STAFF_NUMBER = 'staffNumber',
  IBAN = 'iban',
  NET_SALARY = 'netSalary',
  PHONE = 'phone',
  AVAILABLE_SALARY_FRACTION = 'availableSalaryFraction',
  IS_ADVANCE_SERVICE_ENABLED = 'isAdvanceServiceEnabled',
  CONTRACT_START_DATE = 'contractStartDate',
  CONTRACT_END_DATE = 'contractEndDate',
}

export enum AdvanceServiceValues {
  YES = 'yes',
  NO = 'no',
}

const NAME = 'Template_Import_Employees'
const ENGLISH_1 = {
  first_name: 'John',
  last_name: 'Doe',
  email: 'john.doe@nesspay.co',
  staff_number: 'AB 123 456',
  iban: 'FR1717569000504493469553B73',
  net_salary: 3000,
  available_salary_percentage: 50,
  phone: '0612345678',
  can_request_money: 'yes',
  contract_start_date: '01/01/2021',
  contract_end_date: '01/01/2025',
}
const ENGLISH_2 = {
  ...ENGLISH_1,
  first_name: 'Jane',
  email: 'jane.doe@nesspay.co',
  staff_number: 'AB 234 567',
  iban: 'FR2517569000506321676658H71',
  phone: '0623456789',
  can_request_money: 'no',
}
const FRENCH_1 = {
  prénom: 'Jean',
  nom: 'Doe',
  email: 'jean.doe@nesspay.co',
  matricule: 'AB 123 456',
  iban: 'FR1717569000504493469553B73',
  salaire_net: 3000,
  pourcentage_salaire_disponible: 50,
  téléphone: '0612345678',
  service_nesspay: 'yes',
}
const FRENCH_2 = {
  ...FRENCH_1,
  prénom: 'Jeanne',
  email: 'jeanne.doe@nesspay.co',
  matricule: 'AB 234 567',
  iban: 'FR2517569000506321676658H71',
  téléphone: '0623456789',
  service_nesspay: 'no',
  début_de_contract: '01/01/2021',
  fin_de_contract: '01/01/2025',
}
export const TEMPLATES = {
  NAME,
  ENGLISH_1,
  ENGLISH_2,
  FRENCH_1,
  FRENCH_2,
}

export const VALID_EXTENSIONS = ['csv', 'xlsx']

export const REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'staffNumber',
  'iban',
  'isAdvanceServiceEnabled',
]

export const USER_FIELDS = ['firstName', 'lastName', 'email', 'iban', 'phone']

export const FIELDS = [
  ...REQUIRED_FIELDS,
  'netSalary',
  'availableSalaryFraction',
  'phone',
  'contractStartDate',
  'contractEndDate',
]

export const VALID_COLUMNS_NAMES = {
  firstName: ['first_name', 'prénom', 'firstname', 'first name', 'prenom'],
  lastName: ['last_name', 'nom', 'lastname', 'last name'],
  email: ['email', 'mail'],
  staffNumber: ['staff_number', 'matricule', 'staffNumber'],
  iban: ['iban'],
  netSalary: ['net_salary', 'salaire net', 'salaire', 'net salary', 'salaire_net'],
  availableSalaryFraction: [
    'available_salary_fraction',
    'available_salary_percentage',
    '% du salaire disponible',
    'pourcentage_salaire_disponible',
  ],
  phone: ['phone', 'téléphone', 'telephone'],
  isAdvanceServiceEnabled: [
    'can_request_money',
    'isAdvanceServiceEnabled',
    'service nesspay',
    'service_nesspay',
    'service',
    'accès',
    'access',
  ],
  contractStartDate: [
    'start date',
    'start_date',
    'contract start',
    'contract_start',
    'contract start date',
    'contract_start_date',
    'début contract',
    'début_contract',
    'début de contract',
    'début_de_contract',
  ],
  contractEndDate: [
    'end date',
    'end_date',
    'contract end',
    'contract_end',
    'contract end date',
    'contract_end_date',
    'fin contract',
    'fin_contract',
    'fin de contract',
    'fin_de_contract',
  ],
}
