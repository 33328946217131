import { CloseRounded, InfoRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  SxProps,
  useTheme,
  SvgIcon,
  Theme,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { SilaeLogin } from 'pages/administration/software/SilaeLogin'
import { usePersistedUser } from 'user/context'
import { Row, Column, Spacer } from 'utils/spacing'

import { SwanOnboarding } from './SwanOnboarding'

const CONNECT_ROLLEE_NOTION_URL =
  'https://nesspay.notion.site/Connectez-votre-logiciel-de-paie-51c836fb490946aaaa02153bbf417e40?pvs=4'
const CONNECT_SILAE_NOTION_URL =
  'https://nesspay.notion.site/Connectez-Silae-9fddea3fe59248b7831a93cb6f7da921?pvs=4'

const ConnectSilaeDialog = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const t = useTranslate()
  const theme = useTheme()
  return (
    <Dialog open={isOpen} onClose={onClose} sx={dialogSx}>
      <DialogTitle sx={{ backgroundColor: theme.palette.secondary.main, maxHeight: '55px' }}>
        <Row sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box />
          <Typography color="primary">{t('onboarding.connectHrSoftware.dialogTitle')}</Typography>
          <IconButton onClick={onClose} sx={{ ...iconButtonSx, ':hover': { border: 'none' } }}>
            <CloseRounded sx={{ color: theme.colors.WHITE }} />
          </IconButton>
        </Row>
      </DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        <Spacer y={3} />
        <SilaeLogin />
      </DialogContent>
    </Dialog>
  )
}

const HrSoftwareConnection = () => {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()
  const { organization, setOrganization } = usePersistedUser()

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)

  const isSilae = organization.onboarding.hrSoftware === 'Silae'
  const isPayfit = organization.onboarding.hrSoftware === 'Payfit'

  const updateOnboardingMutation = useMutation(QueryKey.UpdateOnboarding, async () => {
    const org = await api.organizationControllerUpdateOnboardingState({
      ...organization.onboarding,
      hasConnectedHrSoftware: true,
    })
    setOrganization(org)
  })

  useEffect(() => {
    if (organization.integrations.length) {
      updateOnboardingMutation.mutate()
    }
  }, [organization.integrations])

  function openInstructions() {
    const url = isSilae ? CONNECT_SILAE_NOTION_URL : CONNECT_ROLLEE_NOTION_URL
    window.open(url, '_blank')
  }

  const PayfitHelperText = () => (
    <Typography sx={typographySx(theme, organization.onboarding.hasConnectedHrSoftware)}>
      {t('onboarding.connectHrSoftware.helperTexts.3')}&nbsp;
      <span style={{ fontWeight: 500 }}>{'private@nesspay.co'}</span>&nbsp;
      {t('onboarding.connectHrSoftware.helperTexts.4')} {organization.onboarding.hrSoftware}.
    </Typography>
  )

  return (
    <Box sx={{ maxWidth: '75%' }}>
      <Typography variant="h2" sx={{ fontWeight: 500 }}>
        {t('onboarding.connectHrSoftware.title')}
      </Typography>
      <Spacer y={3} />
      <Typography sx={typographySx(theme, organization.onboarding.hasConnectedHrSoftware)}>
        {t('onboarding.connectHrSoftware.helperTexts.1')} {organization.onboarding.hrSoftware}&nbsp;
        {t('onboarding.connectHrSoftware.helperTexts.2')}.
      </Typography>
      <Spacer y={1} />
      {isPayfit && <PayfitHelperText />}
      <Spacer y={2} />
      <Row>
        <Button sx={buttonSx} color="secondary" variant="outlined" onClick={openInstructions}>
          {t('onboarding.connectHrSoftware.buttons.how')}
        </Button>
        <Spacer x={2} />
        {isPayfit && (
          <LoadingButton
            sx={buttonSx}
            variant="contained"
            loading={updateOnboardingMutation.isLoading}
            onClick={() => updateOnboardingMutation.mutate()}
            disabled={organization.onboarding.hasConnectedHrSoftware}>
            {organization.onboarding.hasConnectedHrSoftware
              ? '✓'
              : t('onboarding.connectHrSoftware.buttons.done')}
          </LoadingButton>
        )}
        {isSilae && (
          <LoadingButton
            sx={buttonSx}
            variant="contained"
            loading={updateOnboardingMutation.isLoading}
            onClick={() => setDialogOpen((prevState) => !prevState)}
            disabled={organization.onboarding.hasConnectedHrSoftware}>
            {t('onboarding.connectHrSoftware.buttons.connectSilae')}
          </LoadingButton>
        )}
      </Row>
      <ConnectSilaeDialog isOpen={isDialogOpen} onClose={() => setDialogOpen(false)} />
    </Box>
  )
}

const SwanConnection = () => {
  const t = useTranslate()
  const theme = useTheme()
  const { organization } = usePersistedUser()

  const hasSwanAccountId = Boolean(organization.swanAccountId)
  const isNotLegalRepresentative = Boolean(organization.onboarding.legalRepresentativeEmail)

  const HelperText = ({ type }: { type: 'review' | 'legalRepresentative' }) => {
    const helperText =
      type === 'review'
        ? t('onboarding.connectHrSoftware.reviewInProgress')
        : t('onboarding.connectHrSoftware.notLegalRepresentative')
    return (
      <Row>
        <SvgIcon sx={{ color: theme.colors.BLUE, width: '20px', height: '20px' }}>
          <InfoRounded />
        </SvgIcon>
        <Spacer x={1} />
        <Typography variant="h3">{helperText}</Typography>
      </Row>
    )
  }

  return (
    <Box>
      <Typography variant="h2" sx={{ fontWeight: 500 }}>
        {t('bank.onboarding.title')}
      </Typography>
      {hasSwanAccountId && <Spacer y={1} />}
      {hasSwanAccountId && <HelperText type="review" />}
      {isNotLegalRepresentative && <Spacer y={1} />}
      {isNotLegalRepresentative && <HelperText type="legalRepresentative" />}
      <Spacer y={hasSwanAccountId || isNotLegalRepresentative ? 1 : 3} />
      <SwanOnboarding />
    </Box>
  )
}

export const ConnectSoftwares = () => {
  const { organization } = usePersistedUser()
  const isPayfit = organization.onboarding.hrSoftware === 'Payfit'
  return (
    <Column sx={{ height: '100%' }}>
      <HrSoftwareConnection />
      <Spacer y={isPayfit ? 3 : 5} />
      <SwanConnection />
    </Column>
  )
}

const buttonSx: SxProps = { width: '220px', fontWeight: 400 }
const iconButtonSx: SxProps = { width: '15px', height: '15px' }
const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '950px',
      height: '100%',
      maxHeight: '630px',
    },
  },
}
const typographySx = (theme: Theme, hasConnectedHrSoftware: boolean | undefined): SxProps => ({
  fontWeight: 400,
  color: Boolean(hasConnectedHrSoftware) ? theme.colors.GREY : theme.palette.text.primary,
})
