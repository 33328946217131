import {
  AccountBalanceRounded,
  BarChartRounded,
  SwapHorizRounded,
  CalendarTodayRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import {
  Box,
  Stack,
  Paper,
  SxProps,
  Typography,
  SvgIcon,
  Select,
  InputBase,
  MenuItem,
  styled,
} from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ActiveElement,
  Point,
} from 'chart.js'
import { startOfMonth, subMonths } from 'date-fns'
import React from 'react'
import { useTranslate, useLocaleState, TopToolbar } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { AnalyticsBody } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { Row, Spacer } from 'utils/spacing'

import { AnalyticsChart } from './AnalyticsChart'
import { KpiContainer } from './KpiContainer'

enum DateSelector {
  CURRENT_MONTH = 'currentMonth',
  LAST_THREE_MONTHS = 'lastThreeMonths',
  LAST_YEAR = 'lastYear',
}

declare module 'chart.js' {
  interface TooltipPositionerMap {
    linePosition: TooltipPositionerFunction<ChartType>
    barPosition: TooltipPositionerFunction<ChartType>
  }
}

Tooltip.positioners.linePosition = (elements: readonly ActiveElement[], eventPosition: Point) => ({
  x: elements && elements.length ? elements[0].element.x : eventPosition.x,
  y: -10,
  xAlign: 'center',
  yAlign: 'top',
})

Tooltip.positioners.barPosition = (elements: readonly ActiveElement[], eventPosition: Point) => {
  return {
    x: elements && elements.length ? elements[0].element.x : eventPosition.x,
    y: elements && elements.length ? elements[0].element.y : eventPosition.y,
    xAlign: 'center',
    yAlign: 'top',
  }
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

export const AnalyticsPage = () => {
  const t = useTranslate()
  const api = useApi()
  const [locale] = useLocaleState()
  const [selectedDate, setSelectedDate] = React.useState<DateSelector>(DateSelector.CURRENT_MONTH)

  const swanAccountResponse = useQuery(QueryKey.SwanAccount, () => api.swanControllerGetAccount(), {
    onError: console.error,
  })
  const balance = swanAccountResponse.data?.account?.balances?.available?.value

  const analyticsResponse = useQuery(
    [QueryKey.DashboardAnalytics, selectedDate],
    () => api.analyticsControllerGetAnalytics(getAnalyticsBody()),
    { onError: console.error, enabled: Boolean(selectedDate) }
  )
  const isLoading = analyticsResponse.isLoading || swanAccountResponse.isLoading

  function getAnalyticsBody(): AnalyticsBody {
    const currentDate = new Date()
    let startDate: Date = startOfMonth(currentDate)
    if (selectedDate === DateSelector.LAST_THREE_MONTHS) {
      startDate = subMonths(startOfMonth(currentDate), 2)
    }
    if (selectedDate === DateSelector.LAST_YEAR) {
      startDate = subMonths(startOfMonth(currentDate), 11)
    }
    return { startDate: startDate.toISOString(), endDate: currentDate.toISOString() }
  }

  const renderSelectedDate = () => (
    <Row>
      <SvgIcon component={CalendarTodayRounded} />
      <Spacer x={1} />
      <Typography>{t(`dashboard.dateSelector.${selectedDate}`)}</Typography>
    </Row>
  )

  const renderSelectOptions = () => {
    const dates = [
      DateSelector.CURRENT_MONTH,
      DateSelector.LAST_THREE_MONTHS,
      DateSelector.LAST_YEAR,
    ]
    return dates.map((date) => (
      <MenuItem key={date} value={date}>
        {t(`dashboard.dateSelector.${date}`)}
      </MenuItem>
    ))
  }

  const AnalyticsHeader = () => (
    <StyledToolbar>
      <Box flex="1">
        <Typography variant="h1" sx={{ p: '15px', fontWeight: 500 }}>
          {t('dashboard.title')}
        </Typography>
      </Box>
      <Box sx={{ alignSelf: 'center', p: '15px' }}>
        <Select
          value={selectedDate}
          input={<InputBase />}
          renderValue={renderSelectedDate}
          IconComponent={KeyboardArrowDownRounded}
          onChange={(event) => setSelectedDate(event.target.value as DateSelector)}
          disabled={isLoading}
          sx={selectSx}>
          {renderSelectOptions()}
        </Select>
      </Box>
    </StyledToolbar>
  )

  return (
    <Box sx={{ height: '100%' }}>
      <AnalyticsHeader />
      <Stack direction="row" spacing={6} sx={kpiStackSx}>
        <KpiContainer
          title={t('dashboard.balance')}
          color="primary"
          value={
            (balance ? Number(balance).toLocaleString(locale, { maximumFractionDigits: 0 }) : '0') +
            ' €'
          }
          icon={<AccountBalanceRounded />}
          loading={isLoading}
        />
        <KpiContainer
          title={t('dashboard.registrationRate')}
          color="secondary"
          value={
            Math.round((analyticsResponse.data?.loggedInUsersRate as number) * 100).toString() +
            ' %'
          }
          icon={<BarChartRounded />}
          loading={isLoading}
        />
        <KpiContainer
          title={t('dashboard.numberOfTransactions')}
          color="secondary"
          value={Math.round(analyticsResponse.data?.numberOfTransactions as number).toString()}
          icon={<SwapHorizRounded />}
          loading={isLoading}
        />
        <KpiContainer
          title={t('dashboard.averageAmount')}
          color="primary"
          value={
            Math.round(analyticsResponse.data?.averageTransactionAmount as number).toLocaleString(
              locale,
              { minimumFractionDigits: 0 }
            ) + ' €'
          }
          icon={<SwapHorizRounded />}
          loading={isLoading}
        />
      </Stack>
      <Paper sx={{ height: '35%', width: '100%' }}>
        <AnalyticsChart
          series={analyticsResponse.data?.numberOfLoggedUsersSeries}
          type="line"
          gradient={false}
          title={t('dashboard.loggedUsersSeries')}
        />
      </Paper>
      <Spacer y={2} />
      <Row sx={{ height: '35%' }}>
        <Paper sx={{ height: '100%', width: '100%' }}>
          <AnalyticsChart
            series={analyticsResponse.data?.numberOfTransactionsSeries}
            type="line"
            gradient={true}
            title={t('dashboard.transactionsSeries')}
          />
        </Paper>
        <Spacer x={4} />
        <Paper sx={{ height: '100%', width: '100%' }}>
          <AnalyticsChart
            series={analyticsResponse.data?.averageTransactionAmountSeries}
            type="bar"
            gradient={true}
            title={t('dashboard.averageTransactionsSeries')}
          />
        </Paper>
      </Row>
    </Box>
  )
}

const selectSx: SxProps = {
  p: '5px 10px',
  borderRadius: '10px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}
const kpiStackSx: SxProps = {
  height: '150px',
  width: '100%',
  alignItems: 'center',
}

const StyledToolbar = styled(TopToolbar)(({ theme }) => ({
  margin: '-16px -16px 0px -16px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
  backgroundColor: theme.palette.text.secondary,
}))
