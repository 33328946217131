import { CloseRounded, FileDownload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Drawer, SxProps, Button, Stack, Tooltip } from '@mui/material'
import React, { ComponentProps, FC, useCallback, useMemo } from 'react'
import {
  TextField,
  DateField,
  ListBase,
  ListView,
  useTranslate,
  BulkActionProps,
  FunctionField,
  useRefresh,
  BooleanField,
} from 'react-admin'
import { useMutation } from 'react-query'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { ExportConfig, Transaction } from 'api/gen'
import { config } from 'config'
import { ListHeader } from 'layout/ListHeader'
import { analytics } from 'libs/analytics'
import { CanAccess, useCanAccess } from 'libs/auth'
import { SelectColumnsButton } from 'libs/columnModal/SelectColumnsButton'
import { CustomizableDatagrid } from 'libs/datagrid/CustomizableDatagrid'
import { dateAdapter } from 'libs/date'
import { ExportAdvanceRequestsButton } from 'libs/export'
import { ExportModal } from 'libs/export/ExportModal'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { NESSPAY_ONLY_ROLES, TransactionRecord, TransactionStatus } from 'resources/types'
import { UserSearchBar } from 'resources/user/UserSearchBar'
import { SwanInitiatePaymentsButton } from 'swan/SwanInitiatePaymentsButton'
import { usePersistedUser } from 'user/context'
import { Spacer } from 'utils/spacing'
import { useBreakpoints } from 'utils/useBreakpoints'

import { FIXED_WIDTH_COLUMN } from '../fields'
import {
  CompanyFilter,
  NesspayStatusFilter,
  SwanStatusFilter,
  TransactionDateFilter,
} from '../filters'
import { CustomPagination, RowsPerPage } from '../Pagination'

import { TransactionEdit } from './TransactionEdit'

function enableTransactionValidation(record: TransactionRecord) {
  const inProgress = record.status === Transaction.StatusEnum.INPROGRESS
  const swanTransactionDoesNotExist = !Boolean(record.swanId)
  const swanTransactionIsNotPendingConsent = !Boolean(record.pendingSwanConsent)
  return inProgress && swanTransactionDoesNotExist && swanTransactionIsNotPendingConsent
}

const TransactionListHeader = () => {
  const t = useTranslate()
  const { isSmall } = useBreakpoints()
  const { openModal } = useModal('export')

  const onExportTransactions = () => {
    analytics.log('click on Export', { resource: 'transactions' })
    openModal()
  }

  return (
    <ListHeader
      actions={
        <>
          <CanAccess roles={NESSPAY_ONLY_ROLES}>
            <SelectColumnsButton />
          </CanAccess>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FileDownload />}
            onClick={onExportTransactions}>
            {t('buttons.export.name')}
          </Button>
        </>
      }
      filters={
        !isSmall ? (
          <>
            <>
              <UserSearchBar resource="transactions" />
              <Spacer x={1} />
            </>
            <>
              <TransactionDateFilter />
              <CanAccess roles={NESSPAY_ONLY_ROLES}>
                <Spacer x={1} />
                <NesspayStatusFilter />
                <Spacer x={1} />
                <SwanStatusFilter />
                <Spacer x={1} />
                <CompanyFilter />
              </CanAccess>
            </>
          </>
        ) : (
          <>
            <CompanyFilter />
          </>
        )
      }
    />
  )
}

const TransactionBulkActionButtons: FC<BulkActionProps> = () => {
  return (
    <>
      <SwanInitiatePaymentsButton />
    </>
  )
}

export const TransactionList = () => {
  const { canAccess } = useCanAccess()
  const t = useTranslate()
  const location = useLocation()
  const navigate = useNavigate()
  const { isModalOpen } = useModal('export')
  const { isSmall } = useBreakpoints()
  const { hasEntityNames } = usePersistedUser()

  const match = matchPath('/transactions/:id', location.pathname)
  const isAdmin = canAccess({ roles: ['admin'] })
  const isNesspayTeam = canAccess({ roles: NESSPAY_ONLY_ROLES })

  const defaultEndDate = useMemo(() => dateAdapter.date(), [])
  const defaultStartDate = useMemo(
    () => dateAdapter.addMonths(defaultEndDate, -1),
    [defaultEndDate]
  )
  const permanentFilters = isNesspayTeam
    ? undefined
    : { status: [TransactionStatus.DONE, TransactionStatus.INPROGRESS] }
  const defaultFilterValues = {
    createdAt: { start: defaultStartDate, end: defaultEndDate },
  }
  const defaultSortValues = isAdmin
    ? { field: 'swanStatus', order: 'DESC' }
    : { field: 'createdAt', order: 'DESC' }

  const handleClose = useCallback(() => {
    navigate('/transactions')
  }, [navigate])

  const isRowSelectable: ComponentProps<typeof CustomizableDatagrid>['isRowSelectable'] =
    useCallback(
      (record: TransactionRecord) => isNesspayTeam && enableTransactionValidation(record),
      [isNesspayTeam]
    )

  const FieldList = () => (
    <CustomizableDatagrid
      isRowSelectable={isRowSelectable}
      resource="transactions"
      bulkActionButtons={isAdmin ? <TransactionBulkActionButtons /> : false}>
      {isNesspayTeam && !isSmall && <TextField source="id" />}
      {!isSmall ? <DateField showTime source="createdAt" /> : null}
      {isNesspayTeam && !isSmall ? <DateField showTime source="swanBookingDate" /> : null}
      <FunctionField
        source="amount"
        render={(record: any) => `${record.amount} €`}
        width={53}
        sx={{ ...justifyRightSx }}
      />
      {!isSmall ? (
        <TextField source="user.firstName" label={t('resources.user.fields.firstName')} />
      ) : null}
      <TextField source="user.lastName" label={t('resources.user.fields.lastName')} />
      {isNesspayTeam && !isSmall && <DateField source="updatedAt" />}
      {isNesspayTeam && <TextField source="status" />}
      {isNesspayTeam && !isSmall && <TextField source="swanStatus" />}
      <TextField source="organization.name" label={t('resources.organization.name')} />
      {(hasEntityNames || !config.IS_PROD) && (
        <TextField source="entityName" label={t('resources.contract.fields.entityName')} />
      )}
      {isNesspayTeam && !isSmall && <TextField source="swanId" sx={{ FIXED_WIDTH_COLUMN }} />}
      {isNesspayTeam && !isSmall && <TextField source="swanPaymentId" />}
      {isNesspayTeam && <BooleanField source="pendingSwanConsent" />}
      {isNesspayTeam && !isSmall && <TextField source="swanConsentId" />}
      {isNesspayTeam && !isSmall && <DateField source="swanExecutionDate" />}
      {isAdmin && !isSmall && (
        <FunctionField
          label={t('resources.activity.fields.action')}
          render={(record: TransactionRecord) => <CancelTransactionField record={record} />}
        />
      )}
    </CustomizableDatagrid>
  )

  return (
    <Box display="flex">
      <ListBase
        perPage={RowsPerPage.FIFTY}
        sort={defaultSortValues}
        filterDefaultValues={defaultFilterValues}
        filter={permanentFilters}>
        <Box
          sx={{
            flexGrow: 1,
            transition: (theme: any) =>
              theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
              }),
            marginRight: !!match ? '300px' : 0,
          }}>
          <ListView
            empty={false}
            title={t('resources.transactions.pluralName')}
            filters={<TransactionListHeader />}
            actions={false}
            pagination={<CustomPagination />}>
            <FieldList />
          </ListView>
        </Box>
        {isModalOpen && (
          <ExportModal>
            <Stack direction="row" spacing={20} sx={stackSx}>
              <ExportAdvanceRequestsButton type={ExportConfig.TypeEnum.Raw} />
              <ExportAdvanceRequestsButton type={ExportConfig.TypeEnum.Silae} />
              <ExportAdvanceRequestsButton type={ExportConfig.TypeEnum.Payfit} />
            </Stack>
          </ExportModal>
        )}
      </ListBase>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}>
        {!!match && <TransactionEdit id={(match as any).params.id} onCancel={handleClose} />}
      </Drawer>
    </Box>
  )
}

const CancelTransactionField = ({ record }: { record: TransactionRecord }) => {
  const api = useApi()
  const refresh = useRefresh()
  const t = useTranslate()
  const notification = useNotifications()
  const cancelTransactionMutation = useMutation(
    async () => {
      await api.transactionsControllerCancel(record.id)
    },
    {
      onSuccess: () => {
        refresh()
        notification(t(`notifications.cancelTransaction.success`), { variant: 'success' })
      },
      onError: () => {
        notification(t(`notifications.cancelTransaction.error`), { variant: 'error' })
      },
    }
  )
  const isCancelable = record.status === Transaction.StatusEnum.INPROGRESS && !record.swanId
  if (!isCancelable) {
    return null
  }
  const isLoading = cancelTransactionMutation.isLoading
  return (
    <Tooltip
      title={t(`resources.transactions.tooltips.cancel`)}
      componentsProps={{ tooltip: { sx: { maxWidth: '180px' } } }}>
      <LoadingButton
        loading={isLoading}
        onClick={(event) => {
          event.stopPropagation()
          cancelTransactionMutation.mutate()
        }}
        sx={{ ':hover': { border: 'none' } }}>
        {isLoading ? null : <CloseRounded sx={{ width: '15px', height: '15px' }} />}
      </LoadingButton>
    </Tooltip>
  )
}

const stackSx: SxProps = { height: '75%', justifyContent: 'center', alignItems: 'center' }
const justifyRightSx: SxProps = { display: 'flex', justifyContent: 'right' }
