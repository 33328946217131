import {
  Box,
  SxProps,
  styled,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody as MuiTableBody,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { FronteggAccessToken } from 'api/gen'
import { CanAccess } from 'libs/auth'
import { ROLES_WITH_WRITE_PERMISSION } from 'resources/types'

interface Props {
  tokens: FronteggAccessToken[]
  button: ({ id }: { id: string }) => JSX.Element
}

export function ApiTokensTable(props: Props) {
  const { tokens, button: DeleteTokenButton } = props

  const t = useTranslate()

  const TableHeader = () => (
    <StyledTableHead>
      <TableRow sx={{ backgroundColor: 'lightblue' }}>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('administration.apiTab.table.tokenId')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('administration.apiTab.table.createdAt')}
        </TableCell>
        <CanAccess roles={ROLES_WITH_WRITE_PERMISSION}>
          <TableCell scope="header" sx={{ borderBottom: 'none' }}>
            {t('administration.apiTab.table.action')}
          </TableCell>
        </CanAccess>
      </TableRow>
    </StyledTableHead>
  )

  const TableBody = () => (
    <MuiTableBody>
      {tokens.map((token) => {
        const date = new Date(token.createdAt)
        return (
          <TableRow key={token.id}>
            <TableCell scope="row" sx={tableCellSx}>
              {token.id}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {date.toLocaleDateString() + ' - ' + date.toLocaleTimeString()}
            </TableCell>
            <CanAccess roles={ROLES_WITH_WRITE_PERMISSION}>
              <TableCell scope="row" sx={{ ...tableCellSx }}>
                <DeleteTokenButton id={token.id} />
              </TableCell>
            </CanAccess>
          </TableRow>
        )
      })}
    </MuiTableBody>
  )

  return (
    <TableContainer component={Box} sx={{ height: '450px', width: '800px' }}>
      <Table stickyHeader={true}>
        <TableHeader />
        <TableBody />
      </Table>
    </TableContainer>
  )
}

const tableCellSx: SxProps = {
  width: '10px',
  borderBottom: 0,
  fontSize: '12px',
  pt: '5px',
  pb: '5px',
}

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.colors.GREY,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
  },
}))
