import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material'
import {
  styled,
  Box,
  SxProps,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody as MuiTableBody,
  CircularProgress,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { FinancingPayment, PaginationBody } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { Column, Row } from 'utils/spacing'

const PAGE_SIZE = 13

export const LoansPayments = () => {
  const api = useApi()
  const t = useTranslate()
  const [loansPayments, setLoansPayments] = useState<FinancingPayment[]>([])

  const [cursors, setCursors] = useState<Array<string | undefined>>([undefined])
  const [nextPage, setNextPage] = useState<string | undefined>(undefined)

  const { isFetching } = useQuery([QueryKey.GetLoansPayments, cursors], async () => {
    try {
      const cursor = cursors[cursors.length - 1]
      const body: PaginationBody = { pageSize: PAGE_SIZE, cursor: cursor }
      const { data: loansPayments, next_page } = await api.financingControllerGetLoansPayments(body)
      if (loansPayments) {
        setLoansPayments(loansPayments)
        setNextPage(next_page)
      }
    } catch (error) {
      throw error
    }
  })

  function getPrevPage() {
    const cursorsArray = [...cursors]
    cursorsArray.pop()
    setCursors(cursorsArray)
  }

  function getNextPage() {
    const urlParams = new URLSearchParams(nextPage)
    const newCursor = urlParams.get('cursor')
    if (newCursor) {
      const cursorsArray = [...cursors]
      cursorsArray.push(newCursor)
      setCursors(cursorsArray)
    }
  }

  const TableHeader = () => (
    <StyledTableHead>
      <TableRow sx={{ backgroundColor: 'lightblue' }}>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.reference')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.type')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.amount')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.status')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.paymentsTab.paidAt')}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  )

  const TableBody = () => (
    <MuiTableBody>
      {loansPayments.map((payment) => {
        let reference = ''
        if (payment.from_references && payment.from_references.length)
          reference = payment.from_references[0]
        return (
          <TableRow key={payment.id}>
            <TableCell scope="row" sx={tableCellSx}>
              {reference}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {payment.payment_type}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {payment.amount / 100}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {payment.status}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {payment.paid_at}
            </TableCell>
          </TableRow>
        )
      })}
    </MuiTableBody>
  )

  const PaginationButtons = () => (
    <Row sx={{ display: 'flex', justifyContent: 'end', alignItems: 'self-end' }}>
      <IconButton onClick={getPrevPage} sx={iconButtonSx} disabled={cursors.length <= 1}>
        <KeyboardArrowLeftRounded />
      </IconButton>
      <IconButton onClick={getNextPage} sx={iconButtonSx} disabled={!Boolean(nextPage)}>
        <KeyboardArrowRightRounded />
      </IconButton>
    </Row>
  )

  const LoadingIcon = () => (
    <Box sx={loadingSx}>
      <CircularProgress />
    </Box>
  )

  return (
    <Column sx={{ height: 510 }}>
      <TableContainer component={Box} sx={{ height: '100%', width: '100%' }}>
        <Table stickyHeader={true}>
          <TableHeader />
          {!isFetching && <TableBody />}
        </Table>
        {isFetching && <LoadingIcon />}
      </TableContainer>
      <PaginationButtons />
    </Column>
  )
}

const iconButtonSx: SxProps = { ':hover': { border: 'none' } }
const loadingSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}
const tableCellSx: SxProps = {
  width: '10px',
  borderBottom: 0,
  fontSize: '12px',
  pt: '7px',
  pb: '7px',
}

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.colors.GREY,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
  },
}))
