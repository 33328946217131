import React, { useMemo, useState } from 'react'
import { useGetList, useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { Contract, Transaction } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { MultipleSelectChip } from 'layout/MultiSelectChip'
import { useCanAccess } from 'libs/auth'
import { SwanTransactionStatus, TransactionStatus, CompanyRecord, Role } from 'resources/types'
import { usePersistedUser } from 'user/context'

import { DateRangePicker } from './transaction/TransactionDatePicker'

type FilterOptionsType = Array<{ label: string; value: string }>

const getListParams = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: 'name', order: 'ASC' },
}

export const TransactionDateFilter = () => {
  const fieldName: keyof Transaction = 'createdAt'
  return <DateRangePicker filterName={fieldName} />
}

export const SwanStatusFilter = () => {
  return (
    <MultipleSelectChip
      label="resources.transactions.fields.swanStatus"
      source="swanStatus"
      options={Object.values(SwanTransactionStatus).map((status) => ({
        label: status as string,
        value: status as string,
      }))}
    />
  )
}

export const NesspayStatusFilter = () => {
  return (
    <MultipleSelectChip
      label="resources.transactions.fields.status"
      source="status"
      options={Object.values(TransactionStatus).map((status) => ({
        label: status as string,
        value: status as string,
      }))}
    />
  )
}

export const EntityNameFilter = () => {
  const api = useApi()
  const { organization } = usePersistedUser()
  const [options, setOptions] = useState<FilterOptionsType>([])

  useQuery(QueryKey.EntityNames, getEntityNames, {
    onError: () => console.error,
    enabled: !options.length,
  })

  async function getEntityNames() {
    const filterOptions: FilterOptionsType = []
    const entityNames = await api.contractControllerGetEntityNames(organization.id)
    for (const entityName of entityNames) {
      filterOptions.push({ label: entityName, value: entityName })
    }
    setOptions(filterOptions.sort((a, b) => a.value.localeCompare(b.value)))
  }

  return (
    <MultipleSelectChip
      label="resources.contract.fields.entityName"
      source="entityName"
      options={options}
    />
  )
}

export const CompanyFilter = () => {
  const { data: organizations = [] } = useGetList<CompanyRecord>('organization', getListParams)
  const options = useMemo(
    () => organizations.map((org) => ({ label: org.name, value: org.id })),
    [organizations]
  )
  return (
    <MultipleSelectChip
      label="resources.organization.name"
      source="organizationId"
      options={options}
    />
  )
}

export const ContractActiveFilter = () => {
  return (
    <MultipleSelectChip
      label="resources.contract.fields.active"
      source="active"
      options={[
        { label: 'Actif', value: 'true' },
        { label: 'Inactif', value: 'false' },
      ]}
    />
  )
}

export const ContractServiceEnabledFilter = () => {
  return (
    <MultipleSelectChip
      label="resources.contract.fields.isAdvanceServiceEnabled"
      source="isAdvanceServiceEnabled"
      options={[
        { label: 'Activé', value: 'true' },
        { label: 'Désactivé', value: 'false' },
      ]}
    />
  )
}

export const ContractUsageStatusFilter = () => {
  const t = useTranslate()
  const { LoggedInAtLeastOnce, NeverLoggedIn } = Contract.UsageStatusEnum
  return (
    <MultipleSelectChip
      label="resources.contract.fields.usageStatus"
      source="usageStatus"
      options={[
        {
          label: t(`resources.contract.usageStatus.${LoggedInAtLeastOnce}`),
          value: LoggedInAtLeastOnce,
        },
        {
          label: t(`resources.contract.usageStatus.${NeverLoggedIn}`),
          value: NeverLoggedIn,
        },
      ]}
    />
  )
}

export const RoleFilter = () => {
  const { canAccess } = useCanAccess()
  const options = [
    { label: Role.Employee, value: Role.Employee },
    { label: Role.Employer, value: Role.Employer },
  ]
  if (canAccess({ roles: ['admin'] })) {
    options.push({ label: Role.Admin, value: Role.Admin })
  }
  return (
    <MultipleSelectChip label="resources.contract.fields.roles" source="roles" options={options} />
  )
}
