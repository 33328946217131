import { EuroRounded, PercentRounded } from '@mui/icons-material'
import { useTheme, styled, SxProps, InputAdornment } from '@mui/material'
import * as React from 'react'
import {
  SimpleForm,
  TextInput,
  NumberInput,
  useTranslate,
  useCreate,
  Toolbar,
  SaveButton,
  useRefresh,
} from 'react-admin'
import type { FieldValues } from 'react-hook-form'

import { useApi } from 'api'
import { Contract } from 'api/gen'
import { CustomBooleanInput } from 'libs/components/CustomBooleanInput'
import { useNotifications } from 'libs/notifications'
import { useFormValidation } from 'libs/validation/form'
import { usePersistedUser } from 'user/context'
import { maskIBAN } from 'utils/format'
import { Row, Column, Spacer } from 'utils/spacing'

import { getContractData } from './utils'

const getStaffNumberFilter = (value: string) => 'staffNumber||$eq||' + value
const getOrganizationIdFilter = (value: string) => 'organizationId||$eq||' + value

type Props = {
  onSuccess?: () => void
}

export const UserCreate: React.FC<Props> = (props) => {
  const t = useTranslate()
  const api = useApi()
  const theme = useTheme()
  const notification = useNotifications()
  const [create] = useCreate()
  const refresh = useRefresh()
  const { organization } = usePersistedUser()
  const validation = useFormValidation()

  const checkStaffNumberValidity = async (staffNumber: string) => {
    if (!staffNumber) {
      return t('error.required')
    }
    const filters = [getOrganizationIdFilter(organization.id), getStaffNumberFilter(staffNumber)]
    const response = await api.getManyBaseContractControllerContract(undefined, undefined, filters)
    const { data: contracts } = response
    if (contracts && contracts.length > 0) {
      if (contracts.some((contract) => contract.active === true)) {
        return t('error.invalidStaffNumber')
      }
    }
    return undefined
  }

  async function inviteUser(data: FieldValues) {
    await create(
      'contract',
      { data: getContractData(data, organization.id, [Contract.RolesEnum.Employee]) },
      {
        onSuccess: () => {
          notification(t('notifications.userCreate.success'), { variant: 'success' })
          refresh()
          if (props.onSuccess) props.onSuccess()
        },
        onError: (error: any) => {
          console.log(error)
          if (error.status === 409) {
            notification(t(`error.uniqueness.${error.body.field}`), { variant: 'error' })
          } else notification(t('notifications.userCreate.error'), { variant: 'error' })
        },
      }
    )
  }

  return (
    <SimpleForm
      sx={{ height: '70vh' }}
      onSubmit={inviteUser}
      toolbar={
        <Toolbar
          sx={{
            backgroundColor: theme.palette.text.secondary,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <SaveButton
            label={t('buttons.create')}
            variant="contained"
            color="primary"
            sx={saveButtonSx}
          />
        </Toolbar>
      }>
      <Column sx={columnSx}>
        <Spacer y={6} />
        <Row sx={rowsSx}>
          <StyledTextInput
            source="user.firstName"
            variant="outlined"
            isRequired
            validate={[validation.required, validation.maxLength]}
            autoComplete="off"
            fullWidth
          />
          <Spacer x={2} />
          <StyledTextInput
            source="user.lastName"
            variant="outlined"
            isRequired
            validate={[validation.required, validation.maxLength]}
            autoComplete="off"
            fullWidth
          />
        </Row>
        <StyledTextInput
          source="user.email"
          variant="outlined"
          isRequired
          validate={[validation.required, validation.email]}
          autoComplete="off"
          sx={rowsSx}
        />
        <Row sx={rowsSx}>
          <StyledTextInput
            source="user.phone"
            variant="outlined"
            isRequired={false}
            validate={validation.frenchPhone}
            autoComplete="off"
            fullWidth
          />
        </Row>
        <StyledTextInput
          source="user.iban"
          variant="outlined"
          isRequired
          validate={[validation.required, validation.iban]}
          autoComplete="off"
          parse={(value) => maskIBAN(value).unmaskedValue}
          format={(value) => maskIBAN(value).value}
          sx={rowsSx}
        />
        <Row sx={rowsSx}>
          <StyledNumberInput
            source="netSalary"
            variant="outlined"
            defaultValue={0}
            autoComplete="off"
            validate={validation.netSalary}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: theme.palette.secondary.main }}>
                  <EuroRounded />
                </InputAdornment>
              ),
            }}
          />
          <Spacer x={2} />
          <StyledNumberInput
            source="availableSalaryFraction"
            variant="outlined"
            defaultValue={0}
            autoComplete="off"
            validate={validation.salaryFraction}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: theme.palette.secondary.main }}>
                  <PercentRounded />
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <StyledTextInput
          source="staffNumber"
          variant="outlined"
          isRequired
          autoComplete="off"
          validate={[validation.required, checkStaffNumberValidity]}
          sx={rowsSx}
        />
        <CustomBooleanInput
          source="isAdvanceServiceEnabled"
          label={t('resources.contract.fields.isAdvanceServiceEnabled')}
          labelColor="primary"
        />
      </Column>
    </SimpleForm>
  )
}

const rowsSx: SxProps = { width: '460px' }
const columnSx: SxProps = {
  width: '100%',
  alignItems: 'center',
}
const saveButtonSx: SxProps = { width: '180px', height: '35px' }

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))

const StyledNumberInput = styled(NumberInput)(({ theme }) => ({
  // remove the arrows Mui displays on NumberInputs
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))
