import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { TextField, Button, Select, InputBase, Typography, MenuItem, SxProps } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { Contract, Organization, OrganizationRequestDto } from 'api/gen'
import { CanAccess } from 'libs/auth'
import { HorizontalDivider, VerticalDivider } from 'libs/components/Divider'
import { LocalSelector } from 'libs/components/LocalSelector'
import { useNotifications } from 'libs/notifications'
import { isValidFrenchPhone } from 'libs/validation/phone'
import { SwanAuthButton } from 'swan/SwanAuthButton'
import { usePersistedUser } from 'user/context'
import { Row, Column, Spacer } from 'utils/spacing'

type ReminderFrequencyType = Organization.ReminderFrequencyUpdateUsersEnum

export function AdminTabProfileView() {
  const api = useApi()
  const t = useTranslate()
  const notification = useNotifications()
  const { user, organization, setUser } = usePersistedUser()

  const [invalidPhone, setInvalidPhone] = useState(false)
  const [phone, setPhone] = useState(user.phone)
  const [selectedFrequency, setSelectedFrequency] = useState<ReminderFrequencyType>(
    organization.reminderFrequencyUpdateUsers
  )

  useEffect(() => {
    setPhone(user.phone)
    setSelectedFrequency(organization.reminderFrequencyUpdateUsers)
  }, [user, organization])

  const updateProfileMutation = useMutation(
    'updateUserProfile',
    async () => {
      await api.meControllerUpdate({ user: { phone } } as Contract)
      await api.organizationControllerUpdateOne({
        reminderFrequencyUpdateUsers: selectedFrequency,
      } as OrganizationRequestDto)
      setUser({ phone })
    },
    {
      onSuccess: () => {
        notification(t('notifications.profileEdit.success'), { variant: 'success' })
      },
      onError: (error) => {
        console.log(error)
        notification(t('notifications.profileEdit.error'), { variant: 'error' })
      },
    }
  )

  function disableSubmitButton() {
    if (invalidPhone) return true
    if (user.phone === phone && organization.reminderFrequencyUpdateUsers === selectedFrequency)
      return true
    return false
  }

  function handlePhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInvalidPhone(false)
    setPhone(event?.target?.value)
    if (!isValidFrenchPhone(event?.target?.value)) setInvalidPhone(true)
  }

  const renderSelectedFrequency = () => (
    <Row>
      <Spacer x={1} />
      <Typography variant="h3" sx={{ lineHeight: '30px' }}>
        {t(`administration.profile.reminderFrequency.${selectedFrequency}`)}
      </Typography>
    </Row>
  )

  const renderSelectOptions = () => {
    const FrequencyEnum = Organization.ReminderFrequencyUpdateUsersEnum
    const frequencies = [FrequencyEnum.Weekly, FrequencyEnum.Bimonthly, FrequencyEnum.Monthly]
    return frequencies.map((frequency) => (
      <MenuItem key={frequency} value={frequency}>
        {t(`administration.profile.reminderFrequency.${frequency}`)}
      </MenuItem>
    ))
  }

  return (
    <Row>
      <Column sx={{ width: '450px' }}>
        <Row>
          <TextField
            label={t('resources.user.fields.firstName')}
            value={user.firstName}
            autoComplete="off"
            disabled
            sx={{ width: '50%' }}
          />
          <Spacer x={2} />
          <TextField
            label={t('resources.user.fields.lastName')}
            value={user.lastName}
            autoComplete="off"
            disabled
            sx={{ width: '50%' }}
          />
        </Row>
        <Spacer y={2} />
        <TextField
          label={t('resources.user.fields.email')}
          value={user.email}
          autoComplete="off"
          disabled
          sx={{ width: '100%' }}
        />
        <Spacer y={2} />
        <TextField
          label={t('resources.user.fields.phone')}
          value={phone || ''}
          error={invalidPhone}
          autoComplete="off"
          helperText={invalidPhone ? t('error.invalidPhone') : ''}
          onChange={handlePhoneNumberChange}
          sx={{ width: '100%' }}
        />
        <Spacer y={3} />
        <LoadingButton
          variant="contained"
          onClick={() => updateProfileMutation.mutate()}
          loading={updateProfileMutation.isLoading}
          disabled={disableSubmitButton()}
          sx={{ width: '100%' }}>
          {t('buttons.save')}
        </LoadingButton>
        <Spacer y={3} />
        <HorizontalDivider />
        <Spacer y={3} />
        <LocalSelector sx={{ width: '100%' }} />
        <CanAccess roles={['admin']}>
          <Spacer y={3} />
          <SwanAuthButton sx={{ width: '100%' }} />
        </CanAccess>
        <Spacer y={2} />
        <Button
          variant="text"
          color="primary"
          onClick={() => window.openAxeptioCookies()}
          sx={{ width: '170px', alignSelf: 'center' }}>
          {t('administration.profile.cookies')}
        </Button>
      </Column>
      <Spacer x={4} />
      <VerticalDivider />
      <Spacer x={2} />
      <Column sx={{ width: '450px', height: '50%', alignItems: 'center' }}>
        <Typography sx={{ width: '65%', textAlign: 'center' }}>
          {t('administration.profile.reminderHelperText')}
        </Typography>
        <Spacer y={3} />
        <Select
          value={selectedFrequency}
          input={<InputBase />}
          renderValue={renderSelectedFrequency}
          IconComponent={KeyboardArrowDownRounded}
          onChange={(event) => setSelectedFrequency(event.target.value as ReminderFrequencyType)}
          disabled={updateProfileMutation.isLoading}
          sx={selectSx}>
          {renderSelectOptions()}
        </Select>
      </Column>
    </Row>
  )
}

const selectSx: SxProps = {
  p: '5px 10px',
  borderRadius: '10px',
  boxShadow: '0px 0px 4px rgba(6, 17, 63, 0.2)',
  width: '200px',
  height: '36px',
}
