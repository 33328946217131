import {
  AddCircleOutlineRounded,
  CloudDownloadRounded,
  SimCardDownloadRounded,
  CheckCircleRounded,
  CancelRounded,
  MoodRounded,
  SentimentDissatisfiedRounded,
} from '@mui/icons-material'
import { Typography, useTheme, styled, SxProps, Button, SvgIcon } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import { useTranslate, useLocaleState } from 'react-admin'
import * as XLSX from 'xlsx'

import { exportToExcel } from 'libs/export/exporter'
import { HorizontalStepper } from 'libs/import/components/Stepper'
import {
  TEMPLATES,
  REQUIRED_FIELDS,
  VALID_EXTENSIONS,
  VALID_COLUMNS_NAMES,
} from 'libs/import/constants'
import { ImportUsersContext, ValidationSteps } from 'libs/import/context'
import { useModal } from 'libs/modal'
import { Column, Spacer, Row } from 'utils/spacing'

enum LocaleState {
  FRENCH = 'fr',
  ENGLISH = 'en',
}

function blurButton(event: React.SyntheticEvent) {
  const target = event.target as HTMLElement
  target.blur()
}

export const ImportUploadFile = () => {
  const t = useTranslate()
  const theme = useTheme()
  const [locale] = useLocaleState()
  const { closeModal } = useModal('addOrImportUsers')
  const [isFileValid, setFileValid] = useState(false)
  const {
    filename,
    currentStep,
    fileContent,
    setFilename,
    setFileContent,
    setCurrentStep,
    setMissingFields,
    setValidationStep,
    setImportedFields,
  } = useContext(ImportUsersContext)

  useEffect(() => {
    if (!filename) return
    const extension = filename.slice(filename.lastIndexOf('.') + 1)
    if (VALID_EXTENSIONS.includes(extension)) setFileValid(true)
    else setFileValid(false)
  }, [filename])

  function downloadTemplateFile(event: React.SyntheticEvent) {
    const { NAME, FRENCH_1, FRENCH_2, ENGLISH_1, ENGLISH_2 } = TEMPLATES
    if (locale === LocaleState.FRENCH) exportToExcel([FRENCH_1, FRENCH_2], NAME)
    if (locale === LocaleState.ENGLISH) exportToExcel([ENGLISH_1, ENGLISH_2], NAME)
    blurButton(event)
  }

  function uploadFile(event: React.SyntheticEvent) {
    const input = document.createElement('input')
    input.type = 'file'
    input.click()

    input.onchange = (event: any) => {
      const file = event?.target?.files[0]
      setFilename(file.name)

      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
        const rawFileData = readerEvent.target?.result
        const workbook = XLSX.read(rawFileData, { type: 'binary' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const sheetToJsonOptions: XLSX.Sheet2JSONOpts = {
          defval: '',
          // The `raw` option set to false is necessary if we wish to prevent the conversion of
          // text to number. For example, we want to avoid the following transformation : '00123' ==> 123
          // Warning : all columns will be treated as string, so be sure to convert the actual number columns afterwards.
          raw: false,
        }
        const fileData = XLSX.utils.sheet_to_json(worksheet, sheetToJsonOptions)
        fileData.forEach((row: any) => {
          if (row.hasOwnProperty('__EMPTY')) delete row['__EMPTY']
        })
        setFileContent(fileData)
      }
    }
    blurButton(event)
  }

  function helperTextMessage() {
    if (!filename) return t('resources.contract.helperTexts.importTab.uploadFile')
    if (isFileValid) return t('resources.contract.helperTexts.importTab.validFileUploaded')
    return t('resources.contract.helperTexts.importTab.invalidFileUploaded')
  }

  function checkMatching() {
    const expectedColumns = { ...VALID_COLUMNS_NAMES }
    const importedColumns = Object.keys(fileContent[0])
    const importedFields: any = {
      firstName: '',
      lastName: '',
      email: '',
      staffNumber: '',
      iban: '',
    }

    importedColumns.forEach((column: string) => {
      Object.entries(expectedColumns).forEach(([key, values]) => {
        if (values.includes(column.toLowerCase())) {
          delete expectedColumns[key as keyof typeof expectedColumns]
          importedFields[key] = column
          return
        }
      })
    })

    const remainingImportedFields = importedColumns.filter(
      (x) => !Object.values(importedFields).includes(x)
    )
    remainingImportedFields.forEach((field, index) => {
      importedFields[Object.keys(expectedColumns)[index]] = field
    })

    setMissingFields(Object.keys(expectedColumns).filter((key) => REQUIRED_FIELDS.includes(key)))
    setImportedFields(importedFields)

    setValidationStep(ValidationSteps.COLUMNS_MATCHING)
    setCurrentStep(1)
  }

  const MoodIcon = () => {
    if (!filename) return <SimCardDownloadRounded />
    if (isFileValid) return <MoodRounded />
    return <SentimentDissatisfiedRounded />
  }

  const StatusIcon = () => {
    if (!filename) return <></>
    return (
      <SvgIcon sx={{ color: isFileValid ? theme.colors.GREEN : theme.colors.RED }}>
        {isFileValid ? <CheckCircleRounded /> : <CancelRounded />}
      </SvgIcon>
    )
  }

  const UploadedFileName = () => (
    <Row sx={{ width: '200px' }}>
      <StatusIcon />
      <Spacer x={1} />
      <Typography
        noWrap
        sx={{ color: filename ? theme.palette.secondary.main : theme.colors.GREY }}>
        {filename ? filename : t('resources.contract.helperTexts.importTab.noFileSelected')}
      </Typography>
    </Row>
  )

  return (
    <Column sx={columnSx}>
      <Spacer y={2} />
      <HorizontalStepper activeStep={currentStep} />
      <Spacer y={5} />
      <StyledSquare>
        <StyledCircle>
          <SvgIcon sx={{ width: '40px', height: '50px' }}>
            <MoodIcon />
          </SvgIcon>
        </StyledCircle>
        <Spacer y={2} />
        <StyledTypography>{helperTextMessage()}</StyledTypography>
        <Spacer y={1} />
        {!isFileValid && (
          <Typography sx={{ ...typographySx, color: theme.colors.GREY }}>
            {t('resources.contract.helperTexts.importTab.numberFormatHelperText')}
          </Typography>
        )}
        <Spacer y={3} />
        <Row>
          <Button
            variant="contained"
            color="primary"
            onClick={uploadFile}
            startIcon={filename ? false : <AddCircleOutlineRounded />}
            sx={buttonSx}>
            {filename ? t('buttons.import.replaceFile') : t('buttons.import.importFile')}
          </Button>
          <Spacer x={4} />
          <UploadedFileName />
        </Row>
        <Spacer y={2} />
        <Row>
          <Button
            variant="text"
            color="primary"
            onClick={downloadTemplateFile}
            startIcon={<CloudDownloadRounded />}>
            {t('buttons.downloadImportTemplate')}
          </Button>
        </Row>
      </StyledSquare>
      <Spacer y={5} />
      <Row>
        <Button variant="outlined" color="secondary" onClick={closeModal} sx={buttonSx}>
          {t('buttons.import.backToUsers')}
        </Button>
        <Spacer x={4} />
        <Button
          variant="contained"
          color="primary"
          sx={buttonSx}
          disabled={!isFileValid}
          onClick={checkMatching}>
          {t('buttons.import.nextStep')}
        </Button>
      </Row>
    </Column>
  )
}

const typographySx: SxProps = {
  fontSize: '14px',
  maxWidth: '450px',
  textAlign: 'center',
}
const buttonSx: SxProps = {
  width: '180px',
  height: '45px',
  fontWeight: 400,
  padding: 0,
}
const columnSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '90%',
  alignItems: 'center',
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  height: '50px',
  width: '400px',
  textAlign: 'center',
  fontSize: '16px',
  color: theme.palette.secondary.main,
}))

const StyledSquare = styled(Column)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '650px',
  height: '390px',
  border: '2px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '10px',
}))

const StyledCircle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '50px',
}))
