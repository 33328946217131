import { Theme, useMediaQuery } from '@mui/material'
import { useMemo } from 'react'

export function useBreakpoints() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return useMemo(
    () => ({
      isSmall,
      isMedium,
      isLarge: !isMedium,
    }),
    [isSmall, isMedium]
  )
}
