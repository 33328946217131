import React, { useEffect } from 'react'
import { Loading } from 'react-admin'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { useAuthContext } from 'libs/auth/provider'
import { usePersistedUser } from 'user/context'

export const SwanInitiatePaymentPage: React.FC = () => {
  const api = useApi()
  const { auth } = useAuthContext()
  const { isUserReady } = usePersistedUser()
  const navigate = useNavigate()

  useQuery(
    [QueryKey.SwanAutoPayment, auth.swanAccessToken],
    async () => {
      if (!auth.swanAccessToken) return
      const { createMultiConsent } = await api.swanControllerCreateAutoPayment(auth.swanAccessToken)
      if (
        createMultiConsent.__typename === 'CreateMultiConsentSuccessPayload' &&
        createMultiConsent.consent
      ) {
        window.open(createMultiConsent.consent.consentUrl, '_self')
      }
    },
    {
      enabled: isUserReady && Boolean(auth.swanAccessToken),
      onError: () => {
        navigate('/administration/profil')
      },
    }
  )

  useEffect(() => {
    if (isUserReady && !auth.swanAccessToken) {
      navigate('/administration/profil')
    }
  }, [auth.swanAccessToken, isUserReady, navigate])

  return <Loading />
}
