import { CondOperator, SCondition } from '@dataui/crud-request'

import { ActivityLog, Contract, Transaction } from 'api/gen'

const { IN, INTERSECTS_ARRAY, CONTAINS_LOW, EQUALS, GREATER_THAN, LOWER_THAN } = CondOperator

export function getContractSearch(filter: Record<keyof Contract, any>[]): SCondition {
  const $and: SCondition['$and'] = []
  const $or: SCondition['$or'] = []
  Object.entries(filter).forEach(([field, filterValue]: [string, any]) => {
    if (field === 'organizationId') {
      $and?.push({ [field]: { [IN]: filterValue } })
    }
    if (field === 'entityName') {
      $and?.push({ [field]: { [IN]: filterValue } })
    }
    if (field === 'active') {
      $and?.push({ [field]: { [IN]: filterValue } })
    }
    if (field === 'usageStatus') {
      $and?.push({ [field]: { [IN]: filterValue } })
    }
    if (field === 'isAdvanceServiceEnabled') {
      if (filterValue.length === 1 && filterValue[0] === 'true') {
        $and?.push({
          $and: [
            { isNotAbsent: { [IN]: ['true'] } },
            { isManuallyDisabledByAdmin: { [IN]: ['false'] } },
            { isAppAccessEnabled: { [IN]: ['true'] } },
          ],
        })
      } else if (filterValue.length === 1 && filterValue[0] === 'false') {
        $and?.push({
          $or: [
            { isNotAbsent: { [IN]: ['false'] } },
            { isManuallyDisabledByAdmin: { [IN]: ['true'] } },
            { isAppAccessEnabled: { [IN]: ['false'] } },
          ],
        })
      }
    }
    if (field === 'roles') {
      $and?.push({ [field]: { [INTERSECTS_ARRAY]: filterValue } })
    }
    if (
      ['staffNumber', 'user.firstName', 'user.lastName', 'user.email', 'user.iban'].includes(field)
    ) {
      filterValue.forEach((value: string) => {
        $or?.push({ [field]: { [CONTAINS_LOW]: value } })
      })
    }
    if (['id', 'user.id', 'user.fronteggId'].includes(field)) {
      filterValue.forEach((value: string) => {
        $or?.push({ [field]: { [EQUALS]: value } })
      })
    }
  })
  if ($or.length > 0) {
    $and?.push({ $or })
  }
  return { $and }
}

export function getActivitySearch(filter: Record<keyof ActivityLog, any>[]): SCondition {
  const $and: SCondition['$and'] = []
  const $or: SCondition['$or'] = []
  Object.entries(filter).forEach(([field, filterValue]: [string, any]) => {
    if (field === 'organizationId') {
      $and?.push({ [field]: { [IN]: filterValue } })
    }
    if (['user.firstName', 'user.lastName', 'user.email'].includes(field)) {
      filterValue.forEach((value: string) => {
        $or?.push({ [field]: { [CONTAINS_LOW]: value } })
      })
    }
    if (['id', 'user.id', 'user.fronteggId'].includes(field)) {
      filterValue.forEach((value: string) => {
        $or?.push({ [field]: { [EQUALS]: value } })
      })
    }
  })
  if ($or.length > 0) {
    $and?.push({ $or })
  }
  return { $and }
}

export function getTransactionsSearch(filter: Record<keyof Transaction, any>[]): SCondition {
  const $and: SCondition['$and'] = []
  const $or: SCondition['$or'] = []
  Object.entries(filter).forEach(([field, filterValue]: [string, any]) => {
    if (
      field === 'status' ||
      field === 'swanStatus' ||
      field === 'organizationId' ||
      field === 'entityName'
    ) {
      $and?.push({ [field]: { [IN]: filterValue } })
    }
    if (field === 'swanBookingDate' || field === 'createdAt') {
      const { start, end } = filterValue
      $and?.push({
        $and: [{ [field]: { [GREATER_THAN]: start } }, { [field]: { [LOWER_THAN]: end } }],
      })
    }
    if (['user.firstName', 'user.lastName', 'user.email', 'user.iban'].includes(field)) {
      filterValue.forEach((value: string) => {
        $or?.push({ [field]: { [CONTAINS_LOW]: value } })
      })
    }
    if (['id', 'swanId', 'swanPaymentId'].includes(field)) {
      filterValue.forEach((value: string) => {
        $or?.push({ [field]: { [EQUALS]: value } })
      })
    }
  })
  if ($or.length > 0) {
    $and?.push({ $or })
  }
  return { $and }
}

export function getCrudSearch(
  resource: string,
  filter: Record<string, any>[]
): SCondition | undefined {
  if (resource === 'contract') return getContractSearch(filter)
  if (resource === 'activity') return getActivitySearch(filter)
  if (resource === 'transactions') return getTransactionsSearch(filter)
  return undefined
}
