import { LoadingButton } from '@mui/lab'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQueryClient } from 'react-query'

import { useApi } from 'api'
import { Integration } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { CanAccess } from 'libs/auth'
import { VerticalDivider } from 'libs/components/Divider'
import { useNotifications } from 'libs/notifications'
import { usePersistedUser } from 'user/context'
import { sleep } from 'utils/async'
import { Row, Column, Spacer } from 'utils/spacing'

import { OtherHrisLogin } from './OtherHrisLogin'
import { RolleeLogin } from './RolleeLogin'
import { SilaeLogin } from './SilaeLogin'

const SILAE = Integration.TypeEnum.SILAE
const ROLLEE = Integration.TypeEnum.ROLLEE
const KOMBO = Integration.TypeEnum.KOMBO

export function AdminTabSoftwareView() {
  const t = useTranslate()
  const api = useApi()
  const notification = useNotifications()
  const queryClient = useQueryClient()
  const { integration } = usePersistedUser()

  const [integrationType, setIntegrationType] = useState<Integration.TypeEnum>(SILAE)

  const syncIntegrationsMutation = useMutation(async () => {
    return api.integrationControllerIntegrationsSync()
  })

  const deleteIntegrationMutation = useMutation(
    async (_integration: Integration) => {
      return api.integrationControllerDeleteIntegration({ type: _integration.type })
    },
    {
      onSuccess: async () => {
        notification(t('notifications.deleteIntegration.success'), { variant: 'success' })
        queryClient.invalidateQueries(QueryKey.Me)
        await sleep(1000)
        location.reload()
      },
      onError: (error: any) => {
        notification(t('notifications.deleteIntegration.error'), { variant: 'error' })
        console.error(error)
      },
    }
  )

  function onIntegrationTypeChange(event: SelectChangeEvent<Integration.TypeEnum>) {
    setIntegrationType(event.target.value as Integration.TypeEnum)
  }

  function getIntegrationName(type: Integration.TypeEnum): string {
    if (type === SILAE) return t('administration.integration.silae.name')
    if (type === ROLLEE) return t('administration.integration.rollee.name')
    if (type === KOMBO) return t('administration.integration.kombo.name')
    return ''
  }

  if (integration) {
    return (
      <Row>
        <Column sx={{ width: '500px' }}>
          <Typography variant="h2">
            {t('administration.integration.configured', {
              type: getIntegrationName(integration.type),
            })}
          </Typography>
          <Typography variant="body1" mt={4}>
            {t('administration.integration.deactivate')}
          </Typography>
          <CanAccess roles={['admin']}>
            <LoadingButton
              variant="contained"
              sx={{ marginTop: 6 }}
              onClick={() => syncIntegrationsMutation.mutate()}
              loading={syncIntegrationsMutation.isLoading}>
              {t('administration.integration.sync')}
            </LoadingButton>
          </CanAccess>
          <CanAccess roles={['admin']}>
            <LoadingButton
              variant="contained"
              sx={{ marginTop: 6 }}
              onClick={() => deleteIntegrationMutation.mutate(integration)}
              loading={deleteIntegrationMutation.isLoading}>
              {t('administration.integration.delete')}
            </LoadingButton>
          </CanAccess>
        </Column>
      </Row>
    )
  }

  return (
    <Row minHeight={460}>
      <Column sx={{ width: '250px' }}>
        <Select value={integrationType} onChange={onIntegrationTypeChange}>
          <MenuItem value={SILAE}>{getIntegrationName(SILAE)}</MenuItem>
          <MenuItem value={ROLLEE}>{getIntegrationName(ROLLEE)}</MenuItem>
          <MenuItem value={KOMBO}>{getIntegrationName(KOMBO)}</MenuItem>
        </Select>
      </Column>
      <Spacer x={3} />
      <VerticalDivider />
      <Spacer x={3} />
      <Column sx={{ width: '500px', justifyContent: 'center', alignItems: 'center' }}>
        {integrationType === SILAE && <SilaeLogin />}
        {integrationType === ROLLEE && <RolleeLogin />}
        {integrationType === KOMBO && <OtherHrisLogin />}
      </Column>
    </Row>
  )
}
