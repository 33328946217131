import React, { FC, useState } from 'react'
import { useListContext, useTranslate } from 'react-admin'
import isUUID from 'validator/lib/isUUID'

import { SearchBar } from 'layout/SearchBar'
import { ContractRecord, TransactionRecord, UserRecord } from 'resources/types'

const getContractFieldName = (field: keyof ContractRecord) => field
const CONTRACT_STAFF_NUMBER = getContractFieldName('staffNumber')

const getTransactionFieldName = (field: keyof TransactionRecord) => field
const TRANSACTION_SWAN_ID = getTransactionFieldName('swanId')
const TRANSACTION_SWAN_PAYMENT_ID = getTransactionFieldName('swanPaymentId')

const getUserFieldName = (field: keyof UserRecord) => 'user.' + field
const USER_ID = getUserFieldName('id')
const USER_FRONTEGG_ID = getUserFieldName('fronteggId')
const USER_FIRST_NAME = getUserFieldName('firstName')
const USER_LAST_NAME = getUserFieldName('lastName')
const USER_EMAIL = getUserFieldName('email')
const USER_IBAN = getUserFieldName('iban')

type Props = {
  resource: 'contract' | 'transactions' | 'activity'
}

export const UserSearchBar: FC<Props> = (props) => {
  const t = useTranslate()
  const { filterValues, setFilters } = useListContext()

  const isContractResource = props.resource === 'contract'
  const isTransactionResource = props.resource === 'transactions'

  const [value, setValue] = useState(
    filterValues[USER_FIRST_NAME] ? filterValues[USER_FIRST_NAME].join(' ') : ''
  )

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value)
    addFilters(event.target.value)
  }

  function addFilters(values: string) {
    const whitespacesRegex = /\b\s+/
    const valuesArray = values.trim().toLowerCase().split(whitespacesRegex)
    // The search crashes if the id values are not UUIDs :
    const uuidValues = valuesArray.filter((word) => isUUID(word))
    const newSearchFilterValues = {
      ...filterValues,
      [USER_FIRST_NAME]: valuesArray,
      [USER_LAST_NAME]: valuesArray,
      [USER_EMAIL]: valuesArray,
      [USER_IBAN]: valuesArray,
      id: uuidValues,
      [USER_ID]: uuidValues,
      [USER_FRONTEGG_ID]: uuidValues,
    }
    if (isContractResource) {
      newSearchFilterValues[CONTRACT_STAFF_NUMBER] = valuesArray
    }
    if (isTransactionResource) {
      newSearchFilterValues[TRANSACTION_SWAN_ID] = valuesArray
      newSearchFilterValues[TRANSACTION_SWAN_PAYMENT_ID] = valuesArray
    }

    const newFilterValues = { ...filterValues, ...newSearchFilterValues }

    if (!values) {
      for (const key of Object.keys(newSearchFilterValues)) {
        delete newFilterValues[key]
      }
    }
    setFilters(newFilterValues, null)
  }

  return (
    <SearchBar
      inputProps={{ value, onChange, placeholder: t(`resources.user.helperTexts.searchbar`) }}
    />
  )
}
