import { HelpOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { CircularProgress, IconButton, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useLocaleState, useTranslate } from 'react-admin'
import { useMutation, useQuery } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { intercom, Locale } from 'libs/intercom'
import { useNotifications } from 'libs/notifications'
import { Row, Column, Spacer } from 'utils/spacing'

export function AdminTabAuthenticationView() {
  const theme = useTheme()
  const api = useApi()
  const t = useTranslate()
  const notify = useNotifications()
  const [locale] = useLocaleState()

  const [qrCode, setQrCode] = useState<string | null>(null)
  const [recoveryCode, setRecoveryCode] = useState<string | null>(null)
  const [token, setToken] = useState('')

  const { isFetching: isLoadingMfaQrCode, refetch: refetchMfaQrCode } = useQuery(
    QueryKey.EnrollMFA,
    async () => {
      try {
        const { qrCode } = await api.userControllerEnrollWithAuthenticatorApp()
        setQrCode(qrCode)
      } catch (error: any) {
        if (error.status && error.status === 409) {
          setQrCode(null)
        } else {
          throw error
        }
      }
    },
    {
      onError: () => {
        notify(t('administration.authentication.notifyCouldNotDetermineIfMfaEnabled'), {
          variant: 'error',
        })
      },
    }
  )
  const verifyEnrollmentMfaMutation = useMutation(
    QueryKey.VerifyMFAEnrollment,
    async (token: string) => {
      const response = await api.userControllerVerifyEnrolledWithAuthenticatorApp({ token })
      setRecoveryCode(response.recoveryCode)
    },
    {
      onSuccess: () => {
        notify(t('administration.authentication.notifyCodeVerified'), { variant: 'success' })
        refetchMfaQrCode()
      },
      onError: () => {
        notify(t('administration.authentication.notifyCouldNotVerifyCode'), { variant: 'error' })
      },
    }
  )
  const disableMfaMutation = useMutation(
    QueryKey.DisableMFA,
    async () => {
      return api.userControllerUnenrollFromMfa()
    },
    {
      onSuccess: () => {
        notify(t('administration.authentication.notify2FADisabled'), { variant: 'success' })
        refetchMfaQrCode()
      },
      onError: () => {
        notify(t('administration.authentication.notifyCouldNotDisable2FA'), { variant: 'error' })
      },
    }
  )

  function getView() {
    if (!isLoadingMfaQrCode && !qrCode) {
      return (
        <>
          <Typography>{t('administration.authentication.mfaEnabled')}</Typography>
          {recoveryCode ? (
            <>
              <Spacer y={5} />
              <Typography>{t('administration.authentication.recoveryCodeInstructions')}</Typography>
              <Spacer y={2} />
              <Typography>{recoveryCode}</Typography>
            </>
          ) : null}
          <Spacer y={5} />
          <LoadingButton
            variant="contained"
            loading={disableMfaMutation.isLoading}
            onClick={() => disableMfaMutation.mutate()}
            sx={{ width: '60%' }}>
            {t('administration.authentication.disableMfa')}
          </LoadingButton>
        </>
      )
    }
    if (!isLoadingMfaQrCode && qrCode) {
      return (
        <>
          <Row>
            <Typography>{t('administration.authentication.instructions')}</Typography>
            <IconButton
              onClick={() => intercom.openHelpCenter(locale as Locale, '2FA_setup')}
              sx={{ padding: '20px', color: theme.palette.primary.main }}>
              <HelpOutline sx={{ height: '40px', width: '40px' }} />
            </IconButton>
          </Row>
          <Spacer y={2} />
          <Typography>{t('administration.authentication.scanQrCode')}</Typography>
          <Spacer y={1} />
          <img src={qrCode} width={'35%'} height={'35%'} />
          <Spacer y={2} />
          <Typography>{t('administration.authentication.enterToken')}</Typography>
          <Spacer y={2} />
          <Row>
            <TextField
              label={t('administration.authentication.tokenInputLabel')}
              value={token}
              onChange={(event) => setToken(event.target.value)}
              InputLabelProps={{ style: { color: theme.colors.GREY } }}
              sx={{ width: '35%' }}
            />
            <Spacer x={2} />
            <LoadingButton
              variant="contained"
              loading={verifyEnrollmentMfaMutation.isLoading}
              onClick={() => verifyEnrollmentMfaMutation.mutate(token)}
              disabled={!token}
              sx={{ width: '40%' }}>
              {t('administration.authentication.verifyToken')}
            </LoadingButton>
          </Row>
          <Spacer y={3} />
          <Typography variant="caption">
            {t('administration.authentication.disableMfaNote')}
          </Typography>
        </>
      )
    }
    return <CircularProgress sx={{ marginLeft: '150px', marginTop: '50px' }} size={80} />
  }
  return (
    <Column sx={{ width: '500px' }}>
      <Typography variant="h2">{t('administration.authentication.title2')}</Typography>
      <Spacer y={4} />
      {getView()}
    </Column>
  )
}
