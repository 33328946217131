import raFrenchMessages from 'ra-language-french'

import { ReactAdminTranslationMessage } from './types'

export const frenchMessages: ReactAdminTranslationMessage = {
  language: {
    name: 'Langage',
    english: 'Anglais',
    french: 'Français',
  },
  error: {
    lengthExceeded: 'Valeur trop longue',
    invalidPhone: 'Numéro de téléphone invalide',
    invalidIban: 'IBAN invalide',
    invalidEmail: 'Email invalide',
    invalidSiren: 'SIREN invalide',
    companyNotFound: "Vous n'êtes pas associée à une entreprise",
    invalidNetSalary: 'Le salaire doit être supérieur à 0',
    invalidSalaryRefund: 'La note de frais doit être supérieur à 0',
    invalidSalaryFraction: {
      outOfRange: 'La valeur doit être comprise entre 0 et 100',
      decimalNumber: 'La valeur doit être un nombre entier',
    },
    invalidStaffNumber: 'Ce matricule existe déjà',
    invalidServiceActivation: "La valeur doit être 'yes' ou 'no'",
    invalidDate: 'Date invalide : le format des dates doit être français ou ISO',
    invalidStartDate: 'La date doit être inférieure à la date de fin',
    invalidEndDate1: 'La date doit être supérieure à la date de début',
    invalidEndDate2: 'La date doit être supérieure à la date du jour',
    required: 'Champ obligatoire',
    uniqueness: {
      unspecific: 'La valeur existe déjà pour ce champ',
      iban: "L'IBAN renseigné est déjà utilisé",
      email: "L'email renseigné est déjà utilisé",
      phone: 'Le numéro de téléphone renseigné est déjà utilisé',
    },
    stringRequired: 'La valeur doit être au format "Text Brut"',
  },
  unauthorized: {
    youAreNotAuthorized: "Vous n'êtes pas autorisé à utiliser l'application administrateur 😢",
    chooseAnotherTenant: "Changer d'organisation :",
    goToEmployeeApp: "En tant qu'utilisateur, vous pouvez aller sur l'application NessPay :",
    goToEmployeeAppButton: "Aller sur l'application utilisateur 🚀",
  },
  notifications: {
    onboarding: {
      createAccount: {
        success: 'Votre compte a été créé avec succès',
        error: 'Une erreur est survenue lors de la création de votre compte',
      },
      bookAppointment: {
        success: 'Votre demande de rendez-vous a bien été prise en compte',
        error: 'Une erreur est survenue lors de votre prise de rendez-vous',
      },
      sendInvites: {
        success: 'Vos employés ont bien été invités sur Nesspay !',
        error: "Une erreur est survenue lors de l'envoi des invitations",
      },
    },
    createOrganization: {
      success: "L'organisation a été créée avec succès",
      error: "Erreur : il y a eu un problème avec la création de l'organisation",
    },
    createFakeData: {
      success: 'Les données ont été créées avec succès',
      error: 'Erreur : il y a eu un problème avec la création de des données',
    },
    cancelTransaction: {
      success: "La demande d'acompte a été annulée avec succès",
      error: "Erreur : la demande d'acompte n'a pas été annulée",
    },
    deleteIntegration: {
      error: "Erreur : la connexion au logiciel RH n'a pas être supprimée",
      success: 'La connexion au logiciel RH a été supprimée',
    },
    silaeConnect: {
      error: 'Échec de la connexion à Silae',
      success: 'Connexion à Silae réussie',
    },
    komboConnect: {
      error: 'Échec de la connexion au logiciel RH',
      success: 'Connexion au logiciel RH réussie',
    },
    organizationEdit: {
      error: "Erreur lors de la mise à jour de l'organisation",
      success: 'Organisation mise à jour avec succès',
    },
    userCreate: {
      error: "Erreur lors de la création de l'utilisateur",
      success: 'Utilisateur créé avec succès',
    },
    userEdit: {
      error: "Erreur lors de la mise à jour de l'utilisateur",
      success: 'Utilisateur mis à jour avec succès',
      refundModal: {
        success: 'Note de frais ajoutée avec succès',
        error: "Erreur lors de l'ajout de la note de frais",
      },
    },
    profileEdit: {
      error: 'Erreur lors de la mise à jour de votre profil',
      success: 'Votre profil a été mis à jour avec succès',
    },
    advanceServiceSwitchEdit: {
      true: 'Accès à Nesspay activé',
      false: 'Accès à Nesspay désactivé',
    },
    roleManagementTab: {
      removeSuccess: {
        undefined: 'Rôles supprimés avec succès',
        admin: 'Administrateur Nesspay supprimé avec succès',
        employer: 'Administrateur supprimé avec succès',
        analyst: 'Gestionnaire supprimé avec succès',
        employee: 'Utilisateur supprimé avec succès',
        accountManager: 'CSM retiré avec succès',
      },
      removeError: {
        undefined: 'Une erreur est survenue lors de la suppression des rôles',
        admin: "Une erreur est survenue lors de la suppression de l'administrateur Nesspay",
        employer: "Une erreur est survenue lors de la suppression de l'administrateur",
        analyst: 'Une erreur est survenue lors de la suppression du gestionnaire',
        employee: "Une erreur est survenue lors de la suppression de l'utilisateur",
        accountManager: 'Une erreur est survenue lors de la suppression du CSM',
      },
      addSuccess: {
        employer: 'Administrateur ajouté avec succès',
        analyst: 'Gestionnaire ajouté avec succès',
        accountManager: 'CSM ajouté avec succès',
      },
      addError: {
        employer: "Une erreur est survenue lors de l'ajout de l'administrateur",
        analyst: "Une erreur est survenue lors de l'ajout du gestionnaire",
        accountManager: "Une erreur est survenue lors de l'ajout du CSM",
      },
      editSuccess: {
        employer: 'Administateur mis à jour avec succès',
        analyst: 'Gestionnaire mis à jour avec succès',
      },
      editError: {
        employer: "Une erreur est survenue lors de la mise à jour de l'administrateur",
        analyst: 'Une erreur est survenue lors de la mise à jour du gestionnaire',
      },
      onlyOneAdminWarning:
        'Vous ne pouvez pas supprimer cet administrateur. Il vous faut obligatoirement un administrateur',
    },
    copyToClipboard: {
      error: 'Une erreur est survenue lors de la copie',
      success: 'Copié dans le presse-papier',
    },
    apiTab: {
      createToken: {
        error: 'Une erreur est survenue lors de la création du token',
        success: 'Token créé avec succès',
      },
      deleteToken: {
        error: 'Une erreur est survenue lors de la suppression du token',
        success: 'Token supprimé avec succès',
      },
    },
    financing: {
      requestLoan: {
        error: 'Une erreur est survenue lors de la demande de prêt',
        success: 'Demande de prêt réussie',
      },
    },
    transactionsList: {
      insufficientBalance:
        "Le solde disponible du compte n'est pas suffisant pour la somme des transferts",
      loanRequested: "Un prêt vient d'être demandé. Veuillez réessayer dans quelques instants",
      loanAlreadyRequested:
        'Un prêt a déjà été demandé au cours des dernières 24 heures. Veuillez réessayer dans quelques instants',
    },
    intercomSync: {
      success: 'Synchronisation réussie',
      error: 'Echec de la synchronisation',
    },
    invalidCode: 'Code invalide',
  },
  buttons: {
    admin: 'Admin',
    logout: {
      main: 'Déconnexion',
      title: 'Se déconnecter ?',
      confirm: 'Oui, se déconnecter',
      cancel: 'Annuler',
    },
    analytics: 'Tableau de bord',
    bank: 'Wallet',
    financing: 'Financement',
    settings: 'Paramètres',
    intercom: {
      open: 'Fermer le chat',
      close: 'Ouvrir le chat',
      faq: 'FAQs',
      needHelp: "Besoin d'aide ?",
    },
    save: 'Enregistrer',
    import: {
      importFile: 'Importer le fichier',
      replaceFile: 'Remplacer le fichier',
      backToUsers: 'Retour aux utilisateurs',
      nextStep: 'Suivant',
      prevStep: 'Étape précédente',
      updateFileContent: 'Corriger',
      completeImport: "Terminer l'import",
    },
    downloadImportTemplate: 'Télécharger le modèle',
    export: {
      name: 'Exporter',
      tooltip: 'Exporter les données dans la limite de 2000 lignes',
      format: {
        xls: 'Format .xls',
        csv: 'Format .csv',
        chooseFormat: 'Choisissez le format du fichier :',
      },
      type: { raw: 'Export brut', silae: 'Pour SILAE', payfit: 'Pour Payfit' },
    },
    exportSilae: 'Exporter Silae',
    getStarted: 'Installation',
    onboarding: 'Onboarding',
    close: 'Fermer',
    create: 'Ajouter',
    remove: 'Retirer',
    delete: 'Supprimer',
    validate: 'Valider',
    modify: 'Modifier',
    confirm: 'Confirmer',
    swanInitiatePaymentsButton: {
      isLogged: 'Initier les virements bancaires',
      isNotLogged: "Tu n'es pas connecté à Swan",
    },
    intercomSync: 'Synchroniser avec Intercom',
  },
  filters: {
    date: {
      start: 'Début',
      end: 'Fin',
      clear: 'Enlever la date',
    },
    columnsModal: 'Configuration',
  },
  userMenu: {
    administration: 'Administration',
    balance: 'Solde',
  },
  onboarding: {
    title: 'Onboarding',
    step: 'Etape',
    outOf: 'sur',
    createAccount: {
      title: 'Créez votre compte Nesspay',
      helperText: 'Je suis responsable légal',
      fields: {
        company: "Nom de l'entreprise",
        hrSoftware: 'Logiciel de paie',
        siren: 'SIREN',
      },
      button: 'Créer mon compte',
      legalRepresentativeEmail: 'Email du responsable légal',
    },
    bookAppointment: {
      title: 'Bonjour, ',
      subtitle:
        "Veuillez choisir un créneau de rendez-vous afin d'ouvrir votre compte de paiement NessPay.",
      requirements: {
        message: 'Lors du rendez-vous, munissez-vous de :',
        id: "Votre pièce d'identité",
        computer: 'Votre ordinateur',
        phone: 'Votre smartphone',
      },
      buttons: {
        book: 'Voir les disponibilités',
        done: "C'est fait !",
      },
    },
    connectHrSoftware: {
      title: 'Connectez votre logiciel de paie',
      dialogTitle: 'Connexion à Silae',
      helperTexts: {
        1: 'Connectez',
        2: "afin d'importer vos salariés automatiquement sur NessPay",
        3: "Pour ce faire, ajoutez l'adresse",
        4: 'comme administrateur à votre compte',
        5: 'Pour ce faire, souscrivez au contrat',
        6: "Mise à disposition d'un catalogue API",
        7: 'sur votre compte',
      },
      buttons: {
        how: 'Comment faire ?',
        done: "C'est fait !",
        connectSilae: 'Connecter SILAE',
      },
      reviewInProgress: "Votre compte est en cours de validation par l'équipe NessPay",
      notLegalRepresentative:
        'Assurez-vous que votre représentant légal vous a fait une procuration',
    },
    fundAccount: {
      title: 'Approvisionner votre compte',
      helperText:
        'Veuillez effectuer un virement du montant désiré sur le compte ci-dessous pour que vos salariés commencent à utiliser Nesspay.',
      buttons: {
        how: 'Comment faire ?',
        done: "C'est fait !",
      },
    },
    sendInvitationEmails: {
      title: 'Félicitation, vous êtes prêt à <br /> utiliser NessPay.',
      button: "Envoyer les mails d'invitation à mes salariés",
    },
    helperText:
      "Veillez à finaliser les étapes d'inscription pour avoir entièrement accès à l'application",
    discoverNessPay: 'Découvrir NessPay',
    createEmployee: 'Créer un utilisateur',
    reloadAccount: 'Recharger le solde de votre compte NessPay',
  },
  dashboard: {
    title: 'Tableau de bord',
    dateSelector: {
      currentMonth: 'Mois en cours',
      lastThreeMonths: 'Trimestre en cours',
      lastYear: 'Année en cours',
    },
    balance: 'Solde',
    registrationRate: "Taux d'inscription (Total)",
    numberOfTransactions: 'Transactions',
    averageAmount: 'Montant moyen',
    loggedUsersSeries: 'Evolution du nombre des salariés ayant accès au service NessPay',
    transactionsSeries: 'Evolution du nombre de transactions',
    averageTransactionsSeries: 'Evolution du montant moyen des transactions',
  },
  refundModal: {
    title: 'Ajouter une note de frais',
    subtitle: 'Rentrer un montant de note de frais à créditer',
  },
  bank: {
    title: 'Wallet',
    downloadStatements: 'Télécharger vos relevés',
    downloadDetails: 'Télécharger',
    fund: {
      title: 'Approvisionner votre wallet',
      helperText:
        'Veuillez transférer le montant désiré en utilisant les coordonnées bancaires renseignées ci-dessous',
    },
    tooltipCopy: 'Copier dans le presse-papier',
    onboarding: {
      title: 'Ouverture de votre compte bancaire',
      openSwanOnboarding: "Ouvrir le processus d'inscription",
      openSwanDashboardOnboarding: "Ouvrir l'onboarding sur le dashboard Swan",
      statusTitle: "États d'avancement de l'inscription",
      isInformationCollected: "Les informations du processus d'inscription ont été remplies",
      isUsersVerified: "Les représentants légaux ont finalisé leur vérification d'identité",
      isDocumentsCollected: "Tous les documents de l'entreprise ont été collectés",
      isDocumentsVerified: "Tous les documents de l'entreprise ont été vérifiés",
      accountAvailableSoon:
        'Toutes les étapes ont été remplies avec succès, votre compte sera disponible dans quelques heures !',
      circleProgress: 'Votre progression :',
    },
    details: {
      title: 'Coordonnées bancaires',
      helperText:
        'Premier virement. Partagez votre IBAN ou utilisez-le pour approvisionnez votre compte',
      bic: 'BIC / SWIFT',
      institutionNameTitle: "Nom de l'établissement financier",
      institutionName: 'Swan',
      institutionAdressTitle: "Adresse de l'établissement financier",
      institutionAdress: '28 rue des Paradisiers, 75 001 Paris',
      footer: 'alimenté par Swan',
    },
    supplies: {
      title: 'Vos derniers approvisionnements',
      helperText: 'Aucun approvisionnement ces six derniers mois',
    },
    downloadModal: {
      title: 'Télécharger les relevés de compte',
      subtitle: 'Veuillez selectionner le format ainsi que la période de votre relevé bancaire',
      csvButton: 'Format CSV',
      pdfButton: 'Format PDF',
      loading: 'Nous préparons votre fichier ',
      helperTexts: {
        1: "Le téléchargement peut prendre jusqu'a 30 secondes",
        2: 'Rien ne se passe ?',
        3: "Cliquer dans l'url de votre navigateur et vérifier que celui-ci ne bloque pas le téléchargement",
        4: "Vérifier que vos bloqueurs de publicité n'empêchent pas le téléchargement (Adblock, uBlock, AdGuard...)",
      },
    },
    pdfContent: {
      title: "Relevé d'Identité Bancaire",
      institution: 'Établissement financier',
      downloadedBy: 'Téléchargé par',
      downloadedOn: 'Le',
      downloadedAt: 'à',
      bankCode: 'Code Banque',
      branchCode: 'Code guichet',
      accountNumber: 'Numéro de compte',
      RIBKey: 'Clé RIB',
    },
  },
  financing: {
    title: 'Financement',
    infoTab: {
      title: 'INFORMATIONS',
      capacity: 'Capacité de financement',
      expirationDate: "Date d'expiration",
      outstanding: 'Encours',
      balance: 'Solde actuel',
      debitDate: 'Date du prochain prélèvement',
      debitAmount: 'Montant du prochain prélèvement',
    },
    loansTab: {
      title: 'Demandes de prêt',
      table: {
        reference: 'Référence',
        submittedAt: 'Soumis le',
        amount: 'Montant',
        status: 'Status',
        toPayAt: 'À recevoir le',
        toRepayAt: 'À rembourser avant le',
        type: 'Type',
      },
      createBorrower: {
        message: "Vous n'avez pas encore activé votre capacité de financement",
        button: 'Activer',
        dialog: {
          title: 'Créer un emprunteur',
          subtitle1: 'Informations sur le représentant légal',
          subtitle2: 'Informations bancaires',
          country: 'Pays de résidence',
          nationality: 'Nationalité',
          email: 'Email professionnel',
          birthdate: 'Date de naissance',
          siren: 'SIREN',
          tosHelperText: 'Avant de continuer, veuillez accepter',
          TOS: "les conditions générales d'utilisation",
          iban: 'IBAN',
          button: "J'accepte",
        },
      },
      createLoanRequest: {
        title: 'Demande de prêt',
        message: 'Informations sur la demande de prêt',
        toPayAt: 'Date du prêt',
        toRepayAt: 'Date de remboursement du prêt',
        button: 'Faire une nouvelle demande',
      },
    },
    paymentsTab: {
      title: 'Paiements liés aux prêts',
      paidAt: 'Payé le',
    },
    creditLinesTab: {
      title: 'Lignes de crédit',
      table: {
        contractNumber: 'Contrat',
        balanceAvailable: 'Solde disponible',
        feesDailyRate: 'Tarif journalier',
        startDate: 'Date de début',
        endDate: 'Date de fin',
      },
    },
    borrowerTab: {
      title: "Status de l'emprunteur",
      message: "Informations sur l'emprunteur",
      tosSignedAt: 'T&C signés le',
    },
    billsTab: {
      title: 'Factures',
      table: {
        id: 'Id',
        dueDate: "Date d'échéance",
        status: 'Status',
        download: 'Télécharger',
      },
    },
  },
  months: {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Août',
    9: 'Septembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre',
  },
  admin: {
    createOrganization: {
      title: 'Nouvelle organisation',
      description:
        'Choisissez un nom pour la nouvelle organisation. Les super admins seront automatiquement associés à cette organisation une fois créée.',
    },
    createFakeData: {
      title: 'Données de démo',
      description:
        "Générez aléatoirement des données de démo avec les paramètres suivants : 1. nombre d'utilisateurs à créer, 2. nombre de demandes d'acomptes à créer, 3. jusqu'à combien de jours dans le passé les données peuvent être générées. Si le nombre d'utilisateurs est 0, alors les demandes d'acomptes seront attribuées à des utilisateurs existants.",
      nbOfUsers: "Nombre d'utilisateurs",
      nbOfTransactions: "Nombre de demandes d'acomptes",
      nbOfDaysFromNow: 'Date la plus tôt à laquelle les données seront générées',
    },
  },
  administration: {
    profile: {
      title: 'Profil',
      cookies: 'Gestion des cookies',
      reminderHelperText:
        "Fréquence à laquelle vous souhaitez être relancé afin de ne pas oublier de mettre à jour votre base d'utilisateurs Nesspay :",
      reminderFrequency: {
        weekly: 'Toutes les semaines',
        bimonthly: 'Une semaine sur deux',
        monthly: 'Tous les mois',
      },
    },
    companyTab: {
      title: 'Entreprise',
      bic: 'BIC',
      bankName: "Nom de l'établissement financier",
    },
    authentication: {
      title: 'Authentification',
      title2: "Configurer l'authentification à 2 facteurs",
      instructions:
        "1. Vous trouverez les instructions pour configurer l'authentification à 2 facteurs sur l'article de la FAQ disponible :",
      scanQrCode:
        "2. Scanner le QR code suivant avec l'application d'authentification de votre choix :",
      enterToken:
        "3. Entrez le code à usage unique qui est affiché sur votre application d'authentification :",
      tokenInputLabel: 'Code à usage unique',
      verifyToken: 'Vérifier le code',
      mfaEnabled: "L'authentification à 2 facteurs (2FA) est activée 🚀.",
      recoveryCodeInstructions:
        "⚠️ Veuillez prendre note du code de restauration suivant. Celui-ci ne sera affiché qu'une fois et vous en aurez besoin si jamais vous ne pouvez plus vous connecter :",
      disableMfa: 'Désactiver le 2FA',
      disableMfaNote:
        "Note : si vous avez précédemment désactivé le 2FA, n'oubliez pas de remplacer le compte sur votre application d'authentification avant d'activer à nouveau le 2FA.",
      notifyCouldNotDetermineIfMfaEnabled: "Le status de votre 2FA n'a pas pu être déterminé",
      notifyCodeVerified: 'Le code a été vérifié avec succès',
      notifyCouldNotVerifyCode: "Le code n'a pas pu être vérifié",
      notify2FADisabled: "L'authentification 2FA est désactivée",
      notifyCouldNotDisable2FA: "L'authentification 2FA n'a pas pu être désactivée",
    },
    hrSoftware: 'Intégration RH & paie',
    integration: {
      configured: 'Votre intégration est configurée 🚀.',
      deactivate: 'Pour désactiver votre integration, veuillez contacter le support.',
      delete: "Supprimer l'intégration (irréversible)",
      silae: {
        name: 'Silae',
        connect: 'Se connecter à Silae',
      },
      rollee: {
        name: 'Rollee',
        connect: 'Se connecter à Rollee',
        reconnect: 'Se reconnecter à Rollee',
        reconnectDescription: 'Nous avons détecté que votre session Rollee est toujours active.',
      },
      kombo: {
        name: 'Autres logiciels de paie / RH',
        connect: 'Connecter votre logiciel de paie / RH',
        disconnect: 'Déconnecter votre logiciel de paie / RH',
      },
      sync: 'Lancer la synchronization',
    },
    silaeHelperTexts: {
      instructions: 'Instructions',
      0: 'Remplissez ce formulaire de demande de connexion à SILAE : ',
      1: 'Choisissez le modèle "Modèle 1A : Usage interne paie"',
      2: 'À la suite du remplissage de ce formulaire, vous (ou votre cabinet de paie) recevrez, de la part de Silae, les accès à leur portail API',
      3: 'Remplissez les informations reçues dans les champs ci-dessous :',
      formLink: 'Formulaire SILAE',
    },
    roleManagementTab: {
      title: 'Gestion des rôles',
      account: 'Compte',
      allAccounts: 'Tous',
      actions: 'Actions',
      addButton: 'Ajouter un compte',
      mainText: 'Comptes Administrateurs',
      secondaryText:
        "Retrouvez ici l'ensemble des administrateurs actifs au sein de votre entreprise. Vous pouvez ajouter ou supprimer un administrateur.",
      adminHelperText: 'Administrateur : Lecture, écriture et gestion des rôles',
      analystHelperText: 'Gestionnaire : Lecture seule',
      dialogTitle: {
        undefined: {
          hasManyRoles: {
            true: 'Retirer les rôles administateurs',
            false: "Supprimer l'administrateur",
          },
        },
        employer: {
          hasManyRoles: {
            true: "Retirer l'administrateur",
            false: "Supprimer l'administrateur",
          },
        },
        analyst: {
          hasManyRoles: {
            true: 'Retirer le gestionnaire',
            false: 'Supprimer le gestionnaire',
          },
        },
        employee: {
          hasManyRoles: {
            true: "Retirer l'utilisateur",
            false: "Supprimer l'utilisateur",
          },
        },
        accountManager: {
          hasManyRoles: {
            true: 'Retirer le CSM',
            false: 'Supprimer le CSM',
          },
        },
        bulkDelete: 'Supprimer les utilisateurs',
      },
      dialogText: {
        undefined: {
          hasManyRoles: {
            true: "Êtes-vous certain de vouloir retirer les rôles administrateurs à cet utilisateur ? Suite à cette action, le rôle d'utilisateur du service NessPay sera toujours actif",
            false: 'Êtes-vous certain de vouloir supprimer cet administrateur ?',
          },
        },
        employer: {
          hasManyRoles: {
            true: "Êtes-vous certain de vouloir retirer le rôle administrateur à cet utilisateur ? Suite à cette action, le rôle d'utilisateur du service NessPay sera toujours actif",
            false: 'Êtes-vous certain de vouloir supprimer cet administrateur ?',
          },
        },
        analyst: {
          hasManyRoles: {
            true: "Êtes-vous certain de vouloir retirer le rôle gestionnaire à cet utilisateur ? Suite à cette action, le rôle d'utilisateur du service NessPay sera toujours actif",
            false: 'Êtes-vous certain de vouloir supprimer ce gestionnaire ?',
          },
        },
        employee: {
          hasManyRoles: {
            true: "Êtes-vous certain de vouloir retirer le rôle d'utilisateur du service Nesspay ? Suite à cette action, les autres rôles de l'utilisateur seront toujours actif",
            false: 'Êtes-vous certain de vouloir supprimer cet utilisateur ?',
          },
        },
        accountManager: {
          hasManyRoles: {
            true: "Êtes-vous certain de vouloir retirer le rôle de CSM à cet utilisateur ? Suite à cette action, les autres rôles de l'utilisateur seront toujours actif",
            false: 'Êtes-vous certain de vouloir supprimer ce CSM ?',
          },
        },
        bulkDelete:
          "Êtes-vous certain de vouloir retirer le rôle d'utilisateur du service Nesspay ? Suite à cette action, les autres rôles des utilisateurs seront toujours actif",
      },
      permissions: {
        header: 'Droits',
        manager: 'Lecture, Écriture, gestion des rôles',
        analyst: 'Lecture seule',
      },
      addUserDialog: {
        dialogTitle: 'Ajouter un compte',
        existingUserTitle: 'Ce rôle est pour :',
        existingUserSubtitle: 'Utilisateurs existants',
        existingUserRadio: 'Un utilisateur du service NessPay',
        newUserTitle: 'Ce compte est pour :',
        newUserRadio: "Une personne externe à l'entreprise",
      },
      updateUserDialog: {
        dialogTitle: 'Modifier le compte',
        dialogSubtitle: 'Modifier le compte de :',
      },
      tooltips: {
        edit: 'Modifier les rôles',
        remove: 'Retirer les rôles',
      },
    },
    labels: {
      silaeNumerosDossiers: 'Numéros de dossiers Silae',
      silaeServerUrl: 'URL du serveur Silae',
      silaeId: 'Identifiant',
      silaePassword: 'Mot de passe',
      silaeWebServiceId: 'Identifiant Web Service Client',
      silaeWebServicePassword: 'Mot de passe Web Service Client',
    },
    apiTab: {
      title: 'API',
      mainText: "Tokens d'accès",
      secondaryText:
        "Retrouvez ici l'ensemble des tokens actifs au sein de votre entreprise. Vous pouvez ajouter ou supprimer un token.",
      addButton: 'Ajouter un token',
      table: {
        tokenId: 'Token Id',
        createdAt: 'Date de création',
        action: 'Action',
        tooltip: 'Supprimer le token',
      },
      dialog: {
        title: 'Votre token',
        tooltip: 'Copier le token',
        helperTexts: {
          1: "Attention : vous ne pourrez pas revoir votre token d'accès !",
          2: 'Assurez-vous de copier votre token maintenant.',
        },
      },
      confirm: {
        title: 'Supprimer le token',
        helperTexts: {
          1: 'Attention : cette action est irréversible !',
          2: 'Êtes-vous certain de vouloir supprimer ce token ?',
        },
      },
    },
  },
  resources: {
    contract: {
      helperTexts: {
        user: {
          firstName: 'Recherche par prénom...',
          lastName: 'Recherche par nom...',
        },
        importTab: {
          steps: {
            0: 'Sélection du fichier',
            1: 'Vérification du fichier',
            2: 'Import',
          },
          errorMessage: {
            fileError:
              'Une valeur est erronée ou est en doube dans le fichier |||| Certaines valeurs sont erronées ou sont en doubles dans le fichier',
            dbError:
              'Une valeur existe déjà dans la base de donnée |||| Certaines valeurs existent déjà dans la base de donnée',
          },
          uploadFile:
            'Ajouter un fichier Excel pour créer, supprimer ou modifier des collaborateurs',
          validFileUploaded: 'Super, votre fichier est au bon format !',
          invalidFileUploaded: "Votre fichier n'est pas un fichier Excel ! (.xlsx ou .csv)",
          noFileSelected: 'Aucun fichier sélectionné',
          noColumnsMissing: 'Super, votre fichier est prêt !',
          allColumnsMatch: 'Toutes les propriétés ont une correspondance',
          numberFormatHelperText:
            'Veuillez importer un fichier dont les nombres sont aux formats US (exemple : 1,355.50€)',
          missingColumnName: 'Oh, oh, une erreur a été trouvée',
          columnDidntMatch:
            'Une propriété  est manquante |||| Certaines propriétés sont manquantes',
          requiredColumnDidntMatch:
            'Une propriété obligatoire est manquante |||| Certaines propriétés obligatoires sont manquantes',
          columnDidntMatchSubtitleStart: "Pour terminer votre import, vous devez disposer d'un ",
          columnDidntMatchSubtitleEnd: ' en tant que propriété NessPay',
          userColumnsNames: 'Titre de la colonne du fichier',
          nesspayColumnsNames: 'Propriété NessPay',
          ignoreField: 'Ignorer',
          noCellsMissingTitle: 'Aucune propriété ne manque',
          noCellsMissingSubtitle: 'Les cellules sont correctement renseignées',
          missingCellsTitle: 'Propriétés obligatoires manquantes',
          missingCellsSubtitle:
            'cellule est vide ou mal renseignée |||| cellules sont vides ou mal renseignées',
          importIsReady: "Vous êtes maintenant prêt pour l'import !",
          importHasFailed: 'Une erreur est survenue...',
          importHasSucceded: 'Import réussi !',
          createSummary: 'Collaborateur à ajouter |||| Collaborateurs à ajouter',
          createFinalSummary: 'Collaborateur ajouté |||| Collaborateurs ajoutés',
          modifySummary: 'Collaborateur à éditer |||| Collaborateurs à éditer',
          modifySummaryFinal: 'Collaborateur édité |||| Collaborateurs édités',
          deleteSummary: 'Collaborateur à supprimer |||| Collaborateurs à supprimer',
          deleteSummaryFinal: 'Collaborateur supprimé |||| Collaborateurs supprimés',
          importInProgress:
            "Import en cours... Veuillez ne pas actualiser ou fermer cette page avant la fin de l'import",
          displayAllRows: 'Afficher uniquement les lignes avec valeurs invalides',
          createDialog: 'utilisateur sera ajouté |||| utilisateurs seront ajoutés',
          createDialogSuccess: 'utilisateur ajouté |||| utilisateurs ajoutés',
          updateDialog: 'utilisateur sera modifié |||| utilisateurs seront modifiés',
          updateDialogSuccess: 'utilisateur modifié |||| utilisateurs modifiés',
          deleteDialog: 'utilisateur sera supprimé |||| utilisateurs seront supprimés',
          deleteDialogSuccess: 'utilisateur supprimé |||| utilisateurs supprimés',
          flushImportTitle: 'Un import de fichier est en cours',
          flushImportConfirm: 'Importer un nouveau fichier',
          flushImportContinue: "Continuer l'import",
        },
      },
      addImportTabs: {
        create: 'Ajouter un utilisateur',
        import: 'Importer un fichier',
      },
      roles: {
        employee: 'Utilisateur |||| Utilisateurs',
        employer: 'Administrateur |||| Administrateurs',
        analyst: 'Gestionnaire |||| Gestionnaires',
        admin: 'Admin NessPay |||| Admins NessPay',
        accountManager: 'CSM NessPay |||| CSMs NessPay',
      },
      exportModal: {
        title: 'Comment souhaitez-vous exporter vos utilisateurs ?',
      },
      bulkUpdateModal: {
        title: 'Modifier plusieurs utilisateurs',
      },
      usageStatus: {
        NeverLoggedIn: 'Non inscrit',
        LoggedInAtLeastOnce: 'Inscrit',
      },
      accessValue: {
        yes: 'Oui',
        no: 'Non',
      },
      swanStatus: {
        booked: 'Booked',
        canceled: 'Canceled',
        pending: 'Pending',
        rejected: 'Rejected',
        released: 'Released',
        upcoming: 'Upcoming',
      },
      fields: {
        id: 'Id',
        silaePayrollFolder: 'N° dossier Silae',
        entityName: 'Établissement',
        isAdvanceServiceEnabled: 'Service NessPay',
        availableSalaryFraction: 'Acompte autorisé',
        contractEndDate: 'Fin de contrat',
        contractStartDate: 'Début de contrat',
        createdAt: 'Crée le',
        netSalary: 'Salaire net',
        organization: {
          name: 'Entreprise',
          canEmployeeRequest: 'Service NessPay',
        },
        organizationId: 'OrganizationId',
        roles: 'Rôles',
        staffNumber: 'Matricule',
        updatedAt: 'Modifié le',
        usageStatus: 'Statut',
        active: 'Actif',
        hasAdvancesEnabled: 'Acompte activé',
        isAppAccessEnabled: 'Accès application',
        isNotAbsent: 'Présent',
        refundSalary: 'Note de frais',
        user: {
          id: 'Id',
          createdAt: 'Crée le',
          email: 'Email',
          firstName: 'Prénom',
          fronteggId: 'Frontegg Id',
          iban: 'IBAN',
          lastName: 'Nom',
          phone: 'Téléphone',
          socialSecurityNumber: 'Numéro de sécurité sociale',
          swanId: 'SwanId',
          updatedAt: 'Modifié le',
        },
        userId: 'Id utilisateur',
        inviteRemindersSent: "Rappels d'invitation envoyés",
        availableBalance: 'Solde disponible',
      },
    },
    organization: {
      name: 'Entreprise',
      pluralName: 'Entreprises',
      helperTexts: {
        bufferDays:
          'En nombre de jours ouvrés. Valeur comprise entre 0 et 15. Explication : si ce réglage a pour valeur 3, les utilisateurs ne pourront plus faire de demandes à partir de 3 jours ouvrés avant la fin du cycle de paie.',
        payPeriodStart:
          'Date choisie dans le mois. Valeur comprise entre 1 et 30. Pour la modifier, veuillez contacter le support.',
        iban: 'Vous pouvez faire un virement SEPA sur le compte ci-dessus afin de recharger le solde disponible pour vos utilisateurs.',
      },
      fields: {
        id: 'id',
        integrations: 'Intégrations',
        contracts: 'contrats',
        transactions: 'transactions',
        alertBalance: "Seuil d'alerte du solde disponible",
        bufferDays: 'Nombre de jours de décalage',
        isToutesMesAidesEnabled: 'Service Klaro activé',
        payPeriodStart: 'Jour démarrage de la paie',
        name: 'Nom',
        swanAccountId: 'Id du compte Swan',
        swanOnboardingId: "Id de l'onboarding Swan",
        isSwanOnboardingComplete: 'Onboarding swan terminé',
        canEmployeeRequest: "Service d'acomptes activé",
        createdAt: 'Créée le',
        updatedAt: 'Modifiée le',
        mergeAccountToken: 'Token du compte Merge',
        activityLogs: "Logs d'activité",
        reminderFrequencyUpdateUsers: 'Fréquence de rappel de mise à jour des utilisateurs',
        defaultNetSalary: 'Salaire net par défaut',
        defaultAvailableSalaryFraction: '% salaire net par défaut',
        borrowerId: "Id de l'emprunteur",
        borrowingLoanAmount: 'Montant emprunté',
        borrowingRepaymentDelay: 'Délai de paiement',
        borrowingEnabled: 'Financement activé',
        sendInvitationEmails: "Envoi des mails d'invitation",
        klaroPrivateKey: 'Private key Klaro',
        encryptionIV: 'encryption IV',
        onboarding: 'Onboarding',
      },
    },
    transactions: {
      name: 'Transaction',
      pluralName: 'Transactions',
      exportModal: {
        title: 'Comment souhaitez-vous exporter vos transactions ?',
      },
      status: {
        done: 'validée',
        inprogress: 'en cours',
        cancel: 'annulée',
      },
      createdDate: 'Créée le',
      tooltips: {
        cancel: "Annuler la demande d'acompte",
      },
      fields: {
        id: 'id',
        amount: 'Montant',
        status: 'Statut',
        swanId: 'Id Swan',
        swanStatus: 'Statut Swan',
        swanConsentId: 'Id du consent Swan',
        pendingSwanConsent: 'Swan consent en attente',
        swanExecutionDate: "Date d'exécution",
        swanBookingDate: "Date d'exécution comptable",
        swanPaymentId: 'id du paiement Swan',
        organizationId: "id de l'entreprise",
        organization: 'entreprise',
        userId: "id de l'utilisateur",
        user: 'utilisateur',
        createdAt: 'Créée le',
        updatedAt: 'Modifiée le',
        entityName: 'Etablissement',
      },
    },
    user: {
      name: 'Utilisateur',
      helperTexts: {
        searchbar: 'Nom, prénom, email, IBAN...',
        selectInput: 'Sélectionner',
      },
      tooltips: {
        deactivate: "Supprimer l'utilisateur |||| Retirer l'utilisateur",
        delete: "Supprimer l'utilisateur [ADMINS] Attention, cette action est irréversible",
      },
      emptySelection: '-- aucun --',
      modifiedDate: 'Dernière modification le',
      withdrawn: 'retirés sur',
      fields: {
        id: 'id',
        contracts: 'contrats',
        transactions: 'transactions',
        fronteggId: 'id Frontegg',
        iban: 'IBAN',
        phone: 'Téléphone',
        name: 'Nom',
        organizationIds: 'IDs des organisations',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Email',
        createdAt: 'créé le',
        updatedAt: 'modifié le',
        activityLogs: "Logs d'activité",
        byActivityLogs: "Logs d'activité",
        mfaEnabled: '2FA',
        metadata: 'Metadata',
      },
    },
    activity: {
      name: "Log d'activité",
      pluralName: "Logs d'activité",
      exportModal: {
        title: "Comment souhaitez-vous exporter vos logs d'activité ?",
      },
      fields: {
        id: 'Id',
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
        organizationId: "Id de l'entreprise",
        organization: 'Entreprise',
        userId: "Id de l'utilisateur",
        user: 'Utilisateur',
        action: 'Action',
        info: 'Information',
        entity: 'Entité',
        modifiedFields: 'Champs modifiés',
        byUser: 'Par utilisateur',
        byUserId: "Par ID d'utilisateur",
      },
    },
  },
  ra: { ...raFrenchMessages.ra },
}
