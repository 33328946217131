import { EuroRounded, PercentRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { SxProps, useTheme, styled, Typography, Button, InputAdornment } from '@mui/material'
import React, { useState, useEffect } from 'react'
import {
  useTranslate,
  TextInput,
  NumberInput,
  SimpleForm,
  useRefresh,
  useUpdate,
} from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { Contract, UpdateRolesContractBody } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { CustomBooleanInput } from 'libs/components/CustomBooleanInput'
import { StyledSwitch } from 'libs/components/StyledSwitch'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { useFormValidation } from 'libs/validation/form'
import { ContractRecord, RoleType } from 'resources/types'
import { usePersistedUser } from 'user/context'
import { maskIBAN } from 'utils/format'
import { Column, Row, Spacer } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

import { RoleSelector } from './RoleSelector'

interface Props {
  record: ContractRecord[]
}

const getStaffNumberFilter = (value: string) => 'staffNumber||$eq||' + value
const getOrganizationIdFilter = (value: string) => 'organizationId||$eq||' + value

export const UpdateRolesForm = ({ record }: Props) => {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()
  const [update] = useUpdate()
  const refresh = useRefresh()
  const notification = useNotifications()
  const validation = useFormValidation()
  const { organization } = usePersistedUser()
  const { closeModal } = useModal('editRole')

  const Roles = Contract.RolesEnum
  const { tRole } = useRoleTranslation()
  const [selectedRole, setSelectedRole] = useState<RoleType>(Roles.Employer)
  const isEmployee = record[0]?.roles.includes(Roles.Employee)
  const [shouldAddEmployeeRole, setAddEmployeeRole] = useState<boolean>(false)

  const [iban, setIban] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [staffNumber, setStaffNumber] = useState<string>('')
  const [netSalary, setNetSalary] = useState<number>(0)
  const [availableSalaryFraction, setSalaryFraction] = useState<number>(0)
  const [isAdvanceServiceEnabled, setAdvanceService] = useState<boolean>(false)

  const updateRolesMutation = useMutation(QueryKey.UpdateRoles, updateRoles)

  const checkStaffNumberValidity = async (staffNumber: string) => {
    if (!staffNumber) return undefined
    const filters = [getOrganizationIdFilter(organization.id), getStaffNumberFilter(staffNumber)]
    const response = await api.getManyBaseContractControllerContract(undefined, undefined, filters)
    const { data: contracts } = response
    if (contracts && contracts.length > 0) {
      if (contracts.some((contract) => contract.active === true)) {
        return t('error.invalidStaffNumber')
      }
    }
    return undefined
  }

  useEffect(() => {
    const clearMutation = () => {
      updateRolesMutation.reset()
      refresh()
    }

    if (updateRolesMutation.isSuccess) {
      notification(t(`notifications.roleManagementTab.editSuccess.${selectedRole}`), {
        variant: 'success',
      })
      clearMutation()
    }
    if (updateRolesMutation.isError) {
      notification(t(`notifications.roleManagementTab.editError.${selectedRole}`), {
        variant: 'error',
      })
      clearMutation()
    }

    if (!record || !record.length) return
    if (record[0].roles.includes(Roles.Employer)) setSelectedRole(Roles.Employer)
    else setSelectedRole(Roles.Analyst)
    setAddEmployeeRole(record[0].roles.includes(Roles.Employee))
  }, [Roles, record, refresh, notification])

  function getRoleToRemove() {
    if (selectedRole === Roles.Employer) {
      if (shouldAddEmployeeRole) return [Roles.Analyst]
      return [Roles.Analyst, Roles.Employee]
    }
    if (shouldAddEmployeeRole) return [Roles.Employer]
    return [Roles.Employer, Roles.Employee]
  }

  function hasRoleChanged() {
    if (shouldAddEmployeeRole === isEmployee) {
      if (record[0].roles.includes(Roles.Employer) && selectedRole === Roles.Employer) return false
      if (!record[0].roles.includes(Roles.Employer) && selectedRole === Roles.Analyst) return false
    } else if (shouldAddEmployeeRole) {
      return Boolean(iban) && Boolean(staffNumber) && !Boolean(validation.iban(iban))
    }
    return true
  }

  async function updateRoles() {
    const id = record[0].id
    const roles = shouldAddEmployeeRole ? [selectedRole, Roles.Employee] : [selectedRole]
    const body: UpdateRolesContractBody = {
      rolesToAdd: roles as UpdateRolesContractBody.RolesToAddEnum[],
      rolesToDelete: getRoleToRemove() as unknown as UpdateRolesContractBody.RolesToDeleteEnum[],
    }
    await api.contractControllerUpdateRoles(body, id)
    if (shouldAddEmployeeRole && shouldAddEmployeeRole !== isEmployee) {
      const data: any = { staffNumber: staffNumber, user: { iban: iban } }
      if (Boolean(phone)) data.user.phone = phone
      if (netSalary) data.netSalary = netSalary
      if (availableSalaryFraction) data.availableSalaryFraction = availableSalaryFraction
      if (isAdvanceServiceEnabled) data.isAdvanceServiceEnabled = isAdvanceServiceEnabled
      await update('contract', { id, data: data })
    }
    closeModal()
    refresh()
  }

  if (!record || !record.length) return <></>

  return (
    <SimpleForm sx={{ p: 0, m: 0 }} toolbar={false} mode="all">
      <Column>
        <Row>
          <StyledTextInput
            source="user.firstName"
            label={t('resources.user.fields.firstName')}
            variant="outlined"
            defaultValue={record[0].user.firstName}
            disabled
          />
          <Spacer x={2} />
          <StyledTextInput
            source="user.lastName"
            label={t('resources.user.fields.lastName')}
            variant="outlined"
            defaultValue={record[0].user.lastName}
            disabled
          />
        </Row>
        <StyledTextInput
          source="user.emil"
          label={t('resources.user.fields.email')}
          variant="outlined"
          defaultValue={record[0].user.email}
          disabled
        />
        {shouldAddEmployeeRole && shouldAddEmployeeRole !== isEmployee && (
          <Column>
            <Row>
              <StyledTextInput
                source="user.phone"
                label={t('resources.user.fields.phone')}
                variant="outlined"
                validate={[validation.frenchPhone]}
                onChange={(event) => setPhone(event.target.value)}
                autoComplete="off"
                fullWidth
              />
            </Row>
            <StyledTextInput
              source="user.iban"
              label={t('resources.user.fields.iban')}
              variant="outlined"
              validate={[validation.required, validation.iban]}
              onChange={(event) => setIban(event.target.value)}
              parse={(value) => maskIBAN(value).unmaskedValue}
              format={(value) => maskIBAN(value).value}
              autoComplete="off"
              isRequired
            />
            <Row>
              <StyledNumberInput
                source="netSalary"
                label={t('resources.contract.fields.netSalary')}
                variant="outlined"
                defaultValue={0}
                autoComplete="off"
                validate={[validation.netSalary]}
                onChange={(event) => setNetSalary(event.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: theme.palette.secondary.main }}>
                      <EuroRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <Spacer x={2} />
              <StyledNumberInput
                source="availableSalaryFraction"
                label={t('resources.contract.fields.availableSalaryFraction')}
                variant="outlined"
                defaultValue={0}
                autoComplete="off"
                validate={[validation.salaryFraction]}
                onChange={(event) => setSalaryFraction(event.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: theme.palette.secondary.main }}>
                      <PercentRounded />
                    </InputAdornment>
                  ),
                }}
              />
            </Row>
            <Row>
              <StyledTextInput
                source="staffNumber"
                label={t('resources.contract.fields.staffNumber')}
                variant="outlined"
                validate={[validation.required, checkStaffNumberValidity]}
                onChange={(event) => setStaffNumber(event.target.value)}
                autoComplete="off"
                isRequired
              />
              <Spacer x={10} />
              <CustomBooleanInput
                source="isAdvanceServiceEnabled"
                label={t('resources.contract.fields.isAdvanceServiceEnabled')}
                labelColor="primary"
                checked={isAdvanceServiceEnabled}
                onChange={(event) => setAdvanceService(event.target.checked)}
              />
            </Row>
          </Column>
        )}
        <Row>
          <RoleSelector selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
          <Spacer x={12} />
          <Typography variant="h2" sx={{ fontWeight: 500 }}>
            {tRole('employee')}
          </Typography>
          <Spacer x={3} />
          <StyledSwitch
            checked={shouldAddEmployeeRole}
            onChange={() => setAddEmployeeRole(!shouldAddEmployeeRole)}
          />
        </Row>
        <Spacer y={1} />
        <Column>
          <Typography variant="h4" sx={{ color: theme.colors.GREY }}>
            {t('administration.roleManagementTab.adminHelperText')}
          </Typography>
          <Typography variant="h4" sx={{ color: theme.colors.GREY }}>
            {t('administration.roleManagementTab.analystHelperText')}
          </Typography>
        </Column>
        <Spacer y={5} />
        <Row sx={buttonsRowSx}>
          <Button variant="outlined" color="secondary" onClick={closeModal} sx={buttonSx}>
            {t('buttons.close')}
          </Button>
          <Spacer x={2} />
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!hasRoleChanged()}
            loading={updateRolesMutation.isLoading}
            onClick={() => updateRolesMutation.mutate()}
            sx={buttonSx}>
            {t('buttons.validate')}
          </LoadingButton>
        </Row>
      </Column>
    </SimpleForm>
  )
}

const buttonSx: SxProps = {
  padding: 0,
  fontWeight: 400,
  height: '34px',
  width: '150px',
  textTransform: 'none',
}
const buttonsRowSx: SxProps = {
  width: '100%',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '25px',
  left: 0,
  right: 0,
}

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  margin: '2px',
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    height: '42px',
    minWidth: '250px',
    maxWidth: '520px',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))
const StyledNumberInput = styled(NumberInput)(({ theme }) => ({
  margin: '2px',
  // remove the arrows Mui displays on NumberInputs
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    height: '42px',
    minWidth: '250px',
    maxWidth: '520px',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))
