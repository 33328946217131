import { AddCircleOutlineRounded } from '@mui/icons-material'
import {
  Box,
  Typography,
  Button,
  SxProps,
  Tabs,
  Tab,
  useTheme,
  Divider,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'

import { useCanAccess } from 'libs/auth'
import { useModal } from 'libs/modal'
import { RoleType } from 'resources/types'
import { Column, Row, Spacer } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

import { AddRoleDialog } from './AddRoleDialog'
import { RoleManagementTable } from './RoleManagementTable'

type RoleManagementType = RoleType | 'all'

export function AdminTabRoleManagementView() {
  const t = useTranslate()
  const { tRole } = useRoleTranslation()
  const theme = useTheme()
  const { canAccess } = useCanAccess()
  const isAdmin = canAccess({ roles: ['admin'] })
  const [value, setValue] = useState<RoleManagementType>('all')

  const { openModal } = useModal('addRole')

  function toggleValue(event: React.SyntheticEvent, newValue: RoleManagementType) {
    const target = event.target as HTMLElement
    setValue(newValue)
    target.blur()
  }

  return (
    <Column sx={{ height: '600px' }}>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h2" align="left" sx={{ fontWeight: 500 }}>
          {t('administration.roleManagementTab.mainText')}
        </Typography>
        <Button sx={buttonSx} startIcon={<AddCircleOutlineRounded />} onClick={openModal}>
          {t('administration.roleManagementTab.addButton')}
        </Button>
      </Row>
      <Typography sx={{ maxWidth: '550px', fontWeight: 300 }}>
        {t('administration.roleManagementTab.secondaryText')}
      </Typography>
      <Spacer y={3} />
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{ backgroundColor: theme.palette.text.secondary }}>
          <StyledTab
            value="all"
            onClick={(event) => toggleValue(event, 'all')}
            label={t('administration.roleManagementTab.allAccounts')}
          />
          <StyledTab
            value="employer"
            onClick={(event) => toggleValue(event, 'employer')}
            label={tRole('employer', true)}
          />
          <StyledTab
            value="analyst"
            onClick={(event) => toggleValue(event, 'analyst')}
            label={tRole('analyst', true)}
          />
          {isAdmin && (
            <StyledTab
              value="accountManager"
              onClick={(event) => toggleValue(event, 'accountManager')}
              label={tRole('accountManager', true)}
            />
          )}
          {isAdmin && (
            <StyledTab
              value="admin"
              onClick={(event) => toggleValue(event, 'admin')}
              label={tRole('admin', true)}
            />
          )}
        </Tabs>
        <Divider orientation="horizontal" flexItem sx={{ mt: '-1px', width: '900px' }} />
        <Spacer y={1} />
        <RoleManagementTable role={value !== 'all' ? value : undefined} />
      </Box>
      <AddRoleDialog />
    </Column>
  )
}

const buttonSx: SxProps = {
  width: '190px',
  height: '30px',
  fontWeight: 400,
}

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.primary,
}))
