import { Send } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import * as React from 'react'
import { Translate, useListContext, useTranslate } from 'react-admin'

import { useApi } from 'api'
import { useAuthContext } from 'libs/auth/provider'
import { useNotifications } from 'libs/notifications'
import { TransactionRecord } from 'resources/types'

const SNACKBAR_DURATION = 10000

function getButtonText(t: Translate, isLoggedToSwan: boolean) {
  if (!isLoggedToSwan) return t('buttons.swanInitiatePaymentsButton.isNotLogged')
  return t('buttons.swanInitiatePaymentsButton.isLogged')
}

export const SwanInitiatePaymentsButton: React.FC = () => {
  const t = useTranslate()
  const api = useApi()
  const { auth } = useAuthContext()
  const notify = useNotifications()

  const [isLoading, setIsLoading] = React.useState(false)

  const { selectedIds: selectedTransactionIds } = useListContext<TransactionRecord>()

  const isLoggedToSwan = Boolean(auth.swanAccessToken)

  async function onClick() {
    if (!auth.swanAccessToken) return

    setIsLoading(true)

    await api
      .swanControllerCreatePayment({ transactionIds: selectedTransactionIds }, auth.swanAccessToken)
      .then((response) => {
        const { createMultiConsent } = response
        if (createMultiConsent.__typename === 'CreateMultiConsentSuccessPayload') {
          const { consent } = createMultiConsent
          if (consent) {
            const url = consent.consentUrl
            window.open(url, '_self')
          }
        }
      })
      .catch(async (error) => {
        // Not-Acceptable-Error when balance is insufficient
        if (error.status === 406) {
          notify(t('notifications.transactionsList.loanRequested'), {
            variant: 'info',
            autoHideDuration: SNACKBAR_DURATION,
          })
          // Conflict-Error when a loan has already been requested
        } else if (error.status === 409) {
          notify(t('notifications.transactionsList.loanAlreadyRequested'), {
            variant: 'info',
            autoHideDuration: SNACKBAR_DURATION,
          })
          // Internal-Server-Error when swan throws an error
        } else {
          const { message } = JSON.parse(await error.text())
          notify(message, { variant: 'error' })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <LoadingButton
      onClick={() => onClick()}
      loading={isLoading}
      disabled={!isLoggedToSwan}
      variant="contained"
      loadingPosition="start"
      startIcon={<Send />}>
      {getButtonText(t, isLoggedToSwan)}
    </LoadingButton>
  )
}
