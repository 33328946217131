import { MenuRounded } from '@mui/icons-material'
import { styled, IconButton } from '@mui/material'
import React, { ComponentProps, FC, useCallback } from 'react'
import { useSidebarState } from 'react-admin'

import { usePersistedUser } from 'user/context'

type Props = Omit<ComponentProps<typeof StyledSidebarToggleButton>, 'onClick'>

export const CustomSidebarToggleButton: FC<Props> = (props) => {
  const { organization } = usePersistedUser()
  const [sidebarOpen, setSidebarOpen] = useSidebarState()

  const isEnabled = organization?.onboarding ? Boolean(organization.onboarding.company) : false

  const toggleSidebar = useCallback(
    (event: React.SyntheticEvent) => {
      const target = event.target as HTMLElement
      setSidebarOpen(!sidebarOpen)
      target.blur()
    },
    [sidebarOpen, setSidebarOpen]
  )

  return (
    <StyledSidebarToggleButton
      {...props}
      disabled={!isEnabled}
      sx={{ transform: sidebarOpen ? '' : 'rotate(-90deg)', ...props.sx }}
      onClick={toggleSidebar}>
      <MenuRounded />
    </StyledSidebarToggleButton>
  )
}

const StyledSidebarToggleButton = styled(IconButton)(({ theme }) => ({
  width: 50,
  color: theme.palette.text.secondary,
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.short,
  }),
}))
