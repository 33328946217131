import DayjsAdapter from '@date-io/date-fns'
import { formatInTimeZone } from 'date-fns-tz'

export const dateAdapter = new DayjsAdapter()

export type DateType = Date

const TIMEZONE = 'Europe/Paris'
const ISO_FORMAT_WITH_TIMEZONE = 'yyyy-MM-dd HH:mm:ssx'

export function toTimezoneISOString(date: Date | string | number) {
  return formatInTimeZone(date, TIMEZONE, ISO_FORMAT_WITH_TIMEZONE)
}
