import disableScroll from 'disable-scroll'
import { useCallback, useContext, useMemo } from 'react'

import { ModalContext, ModalName } from './context'

export const useModal = (modalName: ModalName, options: { preventScroll?: boolean } = {}) => {
  const { preventScroll = false } = options

  const { modals, setModalState } = useContext(ModalContext)
  const { isOpen } = modals[modalName]

  const open = useCallback(() => {
    setModalState(modalName, { isOpen: true })
    if (preventScroll) disableScroll.on()
  }, [setModalState, modalName, preventScroll])

  const close = useCallback(() => {
    setModalState(modalName, { isOpen: false })
    if (preventScroll) disableScroll.off()
  }, [setModalState, modalName, preventScroll])

  return useMemo(
    () => ({ openModal: open, closeModal: close, isModalOpen: isOpen }),
    [open, close, isOpen]
  )
}
