import { Typography, Box, SxProps } from '@mui/material'
import React from 'react'

import { ReactComponent as Logo } from 'assets/logo_blue_night_big.svg'
import { ReactComponent as MaintenanceSvg } from 'assets/maintenance.svg'
import { Column, Spacer } from 'utils/spacing'

const TITLE = 'EN MAINTENANCE'
const FIRST_MESSAGE = "Nous sommes désolés, l'application NessPay est"
const SECOND_MESSAGE = 'actuellement en maintenance...'

export const MaintenancePage = () => (
  <Box sx={{ p: 2 }}>
    <Logo />
    <Column sx={columnSx}>
      <Typography fontWeight={500} fontFamily={'outfit'} sx={{ fontSize: '40px' }}>
        {TITLE}
      </Typography>
      <Box sx={{ mt: -3 }}>
        <MaintenanceSvg />
      </Box>
      <Typography fontWeight={500} fontFamily={'outfit'} sx={typographySx}>
        {FIRST_MESSAGE}
      </Typography>
      <Spacer y={2} />
      <Typography fontWeight={500} fontFamily={'outfit'} sx={typographySx}>
        {SECOND_MESSAGE}
      </Typography>
    </Column>
  </Box>
)

const typographySx: SxProps = {
  fontSize: '20px',
  maxWidth: '430px',
  mt: -3,
}
const columnSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: -6,
}
