import { AddRounded, Close, EuroRounded, PercentRounded } from '@mui/icons-material'
import { Box, IconButton, useTheme, Button, InputAdornment, SxProps, styled } from '@mui/material'
import React, { useState, useEffect, FC } from 'react'
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  FunctionField,
  useTranslate,
  useRedirect,
  EditBase,
  useEditContext,
  useRefresh,
  useUpdate,
  TextInput,
} from 'react-admin'

import { useApi } from 'api'
import { config } from 'config'
import { useCanAccess } from 'libs/auth'
import { CustomBooleanInput } from 'libs/components/CustomBooleanInput'
import { ProgressBar } from 'libs/components/ProgressBar/ProgressBar'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { useFormValidation } from 'libs/validation/form'
import { ContractRecord, ROLES_WITH_WRITE_PERMISSION } from 'resources/types'
import {
  SecondaryOutlinedNumberInput,
  SecondaryOutlinedTextInput,
  SecondaryOutlinedDateInput,
} from 'ui/inputs'
import { usePersistedUser } from 'user/context'
import { strictCapitalizeString, maskIBAN, getOnlyModifiedFieldsObject } from 'utils/format'
import { Row, Column, Spacer } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

import { RefundSalaryDialog } from './UserRefundDialog'

interface Props {
  onCancel: () => void
  id: string
}

const getStaffNumberFilter = (value: string) => 'staffNumber||$eq||' + value
const getOrganizationIdFilter = (value: string) => 'organizationId||$eq||' + value

export const UserEdit: FC<Props> = (props) => {
  const api = useApi()
  const theme = useTheme()
  const { isModalOpen } = useModal('refundSalary')

  const [earnedSalary, setEarnedSalary] = useState(0)
  const [transactionsAmount, setTransactionsAmount] = useState(0)

  useEffect(() => {
    api
      .contractControllerGetBalance(props.id)
      .then(({ earnedSalaryAfterDiscount, bookedTransactionsAmount }) => {
        setEarnedSalary(earnedSalaryAfterDiscount)
        setTransactionsAmount(bookedTransactionsAmount)
      })
      .catch((error) => console.error(error))
  }, [api, props.id, isModalOpen])

  return (
    <EditBase resource="contract" id={props.id}>
      <Column width={{ xs: '100vW', sm: 300 }} bgcolor={theme.palette.secondary.main} sx={columnSx}>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={props.onCancel} size="small">
            <Close sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
        </Box>
        <Box sx={userBalanceSx}>
          <ProgressBar
            variant="UserEdit"
            total={earnedSalary}
            progress={transactionsAmount}
            size={150}
            strokeWidth={10}
            circleOneStroke={theme.palette.text.secondary}
            circleTwoStroke={theme.palette.primary.main}
          />
        </Box>
        <EditForm id={props.id} onCancel={props.onCancel} />
      </Column>
    </EditBase>
  )
}

const EditForm: FC<Props> = (props) => {
  const t = useTranslate()
  const { tRoleMap } = useRoleTranslation()
  const api = useApi()
  const theme = useTheme()
  const { organization, hasIntegration } = usePersistedUser()
  const validation = useFormValidation()
  const { record } = useEditContext<ContractRecord>()
  const refresh = useRefresh()
  const notification = useNotifications()
  const redirect = useRedirect()

  const { canAccess } = useCanAccess()
  const isAccountManager = canAccess({ roles: ['accountManager'] })
  const hasWritePermission = canAccess({ roles: ROLES_WITH_WRITE_PERMISSION })

  const { openModal } = useModal('refundSalary')

  const [startDate, setStartDate] = useState<string | undefined>(record?.contractStartDate)
  const [endDate, setEndDate] = useState<string | undefined>(record?.contractEndDate)

  const [isNameValid, setNameValid] = useState(true)
  const [isStaffNumberValid, setStaffNumberValid] = useState(true)
  const [isNetSalaryValid, setNetSalaryValid] = useState(true)
  const [isSalaryFractionValid, setSalaryFractionValid] = useState(true)
  const [isStartDateValid, setStartDateValid] = useState(true)
  const [isEndDateValid, setEndDateValid] = useState(true)

  const onSuccess = () => {
    notification(t('notifications.userEdit.success'), { variant: 'success' })
    redirect('list', 'contract')
    refresh()
  }

  const onError = (error: any) => {
    if (error.status === 409) {
      notification(t(`error.uniqueness.${error.body.field}`), { variant: 'error' })
    } else notification(t('notifications.userEdit.error'), { variant: 'error' })
  }

  const [update] = useUpdate(undefined, undefined, { onSuccess, onError })

  const transformData = (data: ContractRecord) => {
    const previousData = { ...record }
    const keys = [
      'user.iban',
      'user.firstName',
      'user.lastName',
      'roles',
      'hasAdvancesEnabled',
      'staffNumber',
      'availableSalaryFraction',
      'netSalary',
      ...(hasWritePermission ? ['user.email', 'contractEndDate', 'contractStartDate'] : []),
    ]
    const modifiedData = getOnlyModifiedFieldsObject(previousData, data, keys)

    if (modifiedData.user) {
      if (modifiedData.user.iban) modifiedData.user.iban = modifiedData.user.iban.replace(/ /g, '')
      if (modifiedData.user.firstName)
        modifiedData.user.firstName = strictCapitalizeString(modifiedData.user.firstName)
      if (modifiedData.user.lastName)
        modifiedData.user.lastName = strictCapitalizeString(modifiedData.user.lastName)
    }

    return modifiedData
  }

  const onSubmit = async (data: ContractRecord) => {
    const transformedData = transformData(data)
    const email = transformedData.user?.email
    if (email) {
      await api.contractControllerUpdateEmail({ email }, props.id)
    }
    if (
      transformedData.hasAdvancesEnabled === false ||
      transformedData.hasAdvancesEnabled === true
    ) {
      await api.contractControllerToggleAdvanceRights(
        { newValue: transformedData.hasAdvancesEnabled },
        data.id
      )
    }
    update('contract', { id: props.id, data: transformedData })
  }

  const checkNameValidity = (value: string) => {
    const invalidMessage = validation.combine(value, validation.required, validation.maxLength)
    if (invalidMessage) setNameValid(false)
    else setNameValid(true)
    return invalidMessage
  }

  const checkNetSalaryValidity = (value: number) => {
    const invalidMessage = validation.netSalary(value)
    if (invalidMessage) setNetSalaryValid(false)
    else setNetSalaryValid(true)
    return invalidMessage
  }

  const checkSalaryFractionValidity = (value: number) => {
    const invalidMessage = validation.salaryFraction(value)
    if (invalidMessage) setSalaryFractionValid(false)
    else setSalaryFractionValid(true)
    return invalidMessage
  }

  const checkStaffNumberValidity = async (staffNumber: string) => {
    if (!staffNumber) {
      setStaffNumberValid(false)
      return t('error.required')
    }
    const filters = [getOrganizationIdFilter(organization.id), getStaffNumberFilter(staffNumber)]
    const response = await api.getManyBaseContractControllerContract(undefined, undefined, filters)
    if (response.count > 0 && response.data[0].id !== props.id) {
      setStaffNumberValid(false)
      return t('error.invalidStaffNumber')
    }
    setStaffNumberValid(true)
    return undefined
  }

  const checkStartDateValidity = (startDate: string | undefined, endDate: string | undefined) => {
    if (!startDate) return undefined
    if (!endDate && !record?.contractEndDate) return undefined
    const start = new Date(startDate)
    const end = new Date(endDate ? endDate : record?.contractEndDate ? record.contractEndDate : '')
    const invalidMessage = validation.contractStartDate(start, end)
    if (invalidMessage) setStartDateValid(false)
    else setStartDateValid(true)
    return invalidMessage
  }

  const checkEndDateValidity = (startDate: string | undefined, endDate: string | undefined) => {
    if (!endDate) return undefined
    if (!startDate && !record?.contractStartDate) return undefined
    const start = new Date(
      startDate ? startDate : record?.contractStartDate ? record.contractStartDate : ''
    )
    const end = new Date(endDate)
    const invalidMessage = validation.contractEndDate(start, end)
    if (invalidMessage) setEndDateValid(false)
    else setEndDateValid(true)
    return invalidMessage
  }

  return (
    <SimpleForm
      sx={{ pt: 0, pb: 0, backgroundColor: theme.palette.secondary.main }}
      defaultValues={record}
      onSubmit={onSubmit as any}
      toolbar={
        <Toolbar sx={{ backgroundColor: theme.palette.secondary.main, marginTop: '-15px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.onCancel}
            sx={{ width: '190px' }}>
            {t('buttons.close')}
          </Button>
          <Spacer x={1} />
          <SaveButton variant="contained" color="primary" sx={{ width: '190px' }} />
        </Toolbar>
      }>
      <Spacer y={1} />
      <FunctionField
        flex="1"
        render={(record: ContractRecord) => record?.user?.name || ''}
        sx={{
          width: '100%',
          color: theme.palette.text.secondary,
          justifyContent: 'center',
          display: 'flex',
          fontWeight: 500,
          fontSize: '20px',
        }}
      />
      <FunctionField
        variant="h4"
        render={(record: ContractRecord) =>
          record?.updatedAt
            ? `${t('resources.user.modifiedDate')} ${new Date(
                record.updatedAt
              ).toLocaleDateString()}`
            : ''
        }
        sx={{
          width: '100%',
          color: theme.colors.GREY,
          justifyContent: 'center',
          display: 'flex',
        }}
      />
      <Spacer y={5} />
      <Row>
        <SecondaryOutlinedTextInput
          source="user.firstName"
          variant="outlined"
          validate={checkNameValidity}
          autoComplete="off"
          disabled={hasIntegration || !hasWritePermission}
          FormHelperTextProps={{
            sx: { height: isNameValid ? '0px' : '35px' },
          }}
        />
        <Spacer x={1} />
        <SecondaryOutlinedTextInput
          source="user.lastName"
          variant="outlined"
          validate={checkNameValidity}
          autoComplete="off"
          disabled={hasIntegration || !hasWritePermission}
          FormHelperTextProps={{
            sx: { height: isNameValid ? '0px' : '35px' },
          }}
        />
      </Row>
      <Spacer y={1} />
      <Row>
        <SecondaryOutlinedDateInput
          source="contractStartDate"
          value={startDate}
          variant="outlined"
          sx={{ width: '100%' }}
          disabled={!hasWritePermission}
          onChange={(event) => setStartDate(event.target.value)}
          validate={() => checkStartDateValidity(startDate, endDate)}
          FormHelperTextProps={{
            sx: {
              height: isStartDateValid && isEndDateValid ? '0px' : '50px',
              display: isStartDateValid && isEndDateValid ? 'none' : 'block',
            },
          }}
        />
        <Spacer x={1} />
        <SecondaryOutlinedDateInput
          source="contractEndDate"
          value={endDate}
          variant="outlined"
          sx={{ width: '100%' }}
          disabled={!hasWritePermission}
          onChange={(event) => setEndDate(event.target.value)}
          validate={() => checkEndDateValidity(startDate, endDate)}
          FormHelperTextProps={{
            sx: {
              height: isEndDateValid && isStartDateValid ? '0px' : '50px',
              display: isEndDateValid && isStartDateValid ? 'none' : 'block',
            },
          }}
        />
      </Row>
      <Spacer y={1} />
      <SecondaryOutlinedTextInput
        source="user.email"
        variant="outlined"
        disabled={!hasWritePermission}
        sx={{ width: '100%' }}
        FormHelperTextProps={{ sx: { height: '0px' } }}
      />
      <Spacer y={1} />
      <SecondaryOutlinedTextInput
        source="roles"
        variant="outlined"
        format={tRoleMap}
        disabled={true}
        sx={{ width: '100%' }}
        FormHelperTextProps={{ sx: { height: '0px' } }}
      />
      <Spacer y={1} />
      <SecondaryOutlinedTextInput
        source="staffNumber"
        variant="outlined"
        autoComplete="off"
        disabled={hasIntegration || !hasWritePermission}
        validate={checkStaffNumberValidity}
        sx={{ width: '100%' }}
        FormHelperTextProps={{
          sx: { height: isStaffNumberValid ? '0px' : '35px' },
        }}
      />
      <Spacer y={1} />
      <Row>
        <SecondaryOutlinedNumberInput
          source="netSalary"
          variant="outlined"
          validate={(value) => checkNetSalaryValidity(value)}
          FormHelperTextProps={{
            sx: { height: isNetSalaryValid && isSalaryFractionValid ? '0px' : '55px' },
          }}
          disabled={hasIntegration || !hasWritePermission}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ color: theme.palette.text.secondary }}>
                <EuroRounded sx={{ height: '20px' }} />
              </InputAdornment>
            ),
          }}
        />
        <Spacer x={1} />
        <SecondaryOutlinedNumberInput
          source="availableSalaryFraction"
          variant="outlined"
          validate={(value) => checkSalaryFractionValidity(value)}
          disabled={!hasWritePermission}
          FormHelperTextProps={{
            sx: { height: isNetSalaryValid && isSalaryFractionValid ? '0px' : '55px' },
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ color: theme.palette.text.secondary }}>
                <PercentRounded sx={{ height: '20px' }} />
              </InputAdornment>
            ),
          }}
        />
      </Row>
      <Spacer y={1} />
      <SecondaryOutlinedTextInput
        source="user.iban"
        variant="outlined"
        autoComplete="off"
        type={isAccountManager ? 'password' : 'text'}
        validate={[validation.required, validation.iban]}
        disabled={hasIntegration || !hasWritePermission}
        parse={(value) => maskIBAN(value).unmaskedValue}
        format={(value) => maskIBAN(value).value}
        sx={{ width: '100%' }}
      />
      <Spacer y={2} />
      {config.FF_REFUND_SALARY ? (
        <Row sx={{ width: '100%' }}>
          <StyledPrimaryOutlined
            source="refundSalary"
            variant="outlined"
            autoComplete="off"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end" sx={{ color: theme.palette.text.secondary }}>
                  <EuroRounded sx={{ height: '20px' }} />
                </InputAdornment>
              ),
            }}
            FormHelperTextProps={{ sx: { height: '0px' } }}
          />
          <Spacer x={1} />
          <StyledIconButton onClick={openModal}>
            <AddRounded />
          </StyledIconButton>
        </Row>
      ) : (
        <></>
      )}
      <CustomBooleanInput
        source="hasAdvancesEnabled"
        label={t('resources.contract.fields.isAdvanceServiceEnabled')}
        labelColor="secondary"
        disabled={!hasWritePermission}
        sx={{ marginLeft: 'auto' }}
      />
      <RefundSalaryDialog id={props.id} />
    </SimpleForm>
  )
}

const columnSx: SxProps = {
  height: '100%',
  display: 'flex',
  alignContent: 'center',
}
const userBalanceSx: SxProps = {
  height: '150px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '-25px',
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginTop: '-45px',
  width: '40px',
  height: '40px',
  border: '1px solid',
  color: theme.colors.WHITE,
  borderRadius: '10px',
  borderColor: theme.palette.primary.main,
  '&:hover': {
    borderColor: theme.colors.WHITE,
  },
}))
const StyledPrimaryOutlined = styled(TextInput)(({ theme }) => ({
  marginTop: '-20px',
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
  },
  '& .MuiFormHelperText-root': {
    fontSize: theme.typography.h5.fontSize,
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    borderColor: theme.palette.primary.main,
    height: '35px',
    width: '220px',
    '& fieldset': { borderColor: theme.colors.BLUE_PASTEL },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
    '&:read-only fieldset': { borderColor: theme.palette.primary.main },
    '&:focus fieldset': { borderColor: theme.colors.BLUE_PASTEL },
  },
  '& .MuiOutlinedInput-input': {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
  },
}))
