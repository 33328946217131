import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  BooleanField,
} from 'react-admin'

export const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="role" />
      <TextField source="iban" />
      <TextField source="authentication.email.email" />
      <TextField source="phone" />
      <NumberField source="available_salary_percentage" />
      <NumberField source="net_salary" />
      <NumberField source="net_bonus" />
      <BooleanField source="can_request_money" />
      <DateField source="Created Date" />
      <DateField source="Modified Date" />
      <ReferenceField source="company" reference="company">
        <TextField source="company_name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
