type EnvironmentValue = 'jest' | 'development' | 'testing' | 'production'

type Environment = {
  ENV: EnvironmentValue
  BACKEND_URL: string
  SWAN_CLIENT_ID: string
  OUIHELP_ORG_ID: string
  ROLLEE_PAYFIT_DATASOURCE_ID: string
  FF_HR_INTEGRATIONS: boolean
  FF_REFUND_SALARY: boolean
}

const ENV = process.env.REACT_APP_ENV as EnvironmentValue
const env: Environment = {
  ENV,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL as string,
  SWAN_CLIENT_ID: process.env.REACT_APP_SWAN_CLIENT_ID as string,
  OUIHELP_ORG_ID: process.env.REACT_APP_OUIHELP_ORG_ID as string,
  ROLLEE_PAYFIT_DATASOURCE_ID: process.env.REACT_APP_ROLLEE_PAYFIT_DATASOURCE_ID as string,
  FF_HR_INTEGRATIONS: parseFeatureFlag(process.env.REACT_APP_FF_HR_INTEGRATIONS),
  FF_REFUND_SALARY: parseFeatureFlag(process.env.REACT_APP_FF_REFUND_SALARY),
}

const SWAN_AUTH_URL = 'https://oauth.swan.io/oauth2/auth'
const EMPLOYEE_APP_URL = selectEnv({
  test: 'https://testing-team.nesspay.co/',
  prod: 'https://team.nesspay.co/',
})
const AXEPTIO_CLIENT_ID = selectEnv({
  test: '63c68495d7cd217c2365fa81',
  prod: '639829879a63c4d21617a288',
})
const MIXPANEL_TOKEN = selectEnv({
  test: '5d90573e68b9565e2be0a342d2f11a72',
  prod: '0500b4b22eba3ad34fa8ccf65784cf26',
})
const INTERCOM_APP_ID = selectEnv({
  test: 'p63388y2',
  prod: 'r45heggr',
})
const INTERCOM_API_URL = 'https://api-iam.intercom.io'
const INTERCOM_WIDGET_URL = 'https://widget.intercom.io/widget/'
const INTERCOM_HELP_CENTER_URL = (locale: string) =>
  `https://intercom.help/nesspay-b7927642140c/${locale}/collections/3947119-faq-nesspay`

export const config = {
  ...env,
  IS_PROD: ENV === 'production',
  EMPLOYEE_APP_URL,
  SWAN_AUTH_URL,
  AXEPTIO_CLIENT_ID,
  MIXPANEL_TOKEN,
  INTERCOM_APP_ID,
  INTERCOM_API_URL,
  INTERCOM_WIDGET_URL,
  INTERCOM_HELP_CENTER_URL,
  PUBLIC_URL: process.env.PUBLIC_URL || window.location.origin,
  HOST: `${window.location.protocol}//${window.location.host}`,
  SWAN_AUTH_REDIRECT_URL: `${window.location.protocol}//${window.location.host}/swan-auth-redirect`,
}

function parseFeatureFlag(value: any) {
  if (value === 'false') return false
  return true
}

export function selectEnv<T = any>(values: { jest?: T; dev?: T; test: T; prod: T }): T {
  if (ENV === 'jest') {
    if (values.jest) return values.jest
    else if (values.dev) return values.dev
    else return values.test
  }
  if (ENV === 'development') {
    if (values.dev) return values.dev
    else return values.test
  }
  if (ENV === 'production') return values.prod
  return values.test
}
