import { Box, Typography, SxProps, useTheme } from '@mui/material'
import { ScriptableContext, ChartOptions } from 'chart.js'
import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import { Line, Bar } from 'react-chartjs-2'

import { TimeSeriesPoint } from 'api/gen'
import { Column, Row, Spacer } from 'utils/spacing'

interface Props {
  series: TimeSeriesPoint[] | undefined
  type: 'line' | 'bar'
  gradient: boolean
  title: string
}

export const AnalyticsChart = ({ series, type, gradient, title }: Props) => {
  const theme = useTheme()
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [value, setValue] = useState<number>(0)
  const [data, setData] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])

  React.useEffect(() => {
    if (series) {
      const data: number[] = []
      const labels: string[] = []
      for (const serie of series) {
        labels.push(format(parseISO(new Date(serie.x).toISOString()), 'dd/MM'))
        data.push(serie.y)
      }
      setLabels(labels)
      setData(data)
    }
  }, [series])

  const chartPlugins: any = [
    {
      afterDatasetDraw: (chart: any) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          if (type === 'line') {
            const activePoint = chart.tooltip._active[0]
            const ctx = chart.ctx
            const x = activePoint.element.x
            const topY = chart.scales.y.top
            const bottomY = chart.scales.y.bottom
            ctx.save()
            ctx.beginPath()
            ctx.moveTo(x, topY)
            ctx.lineTo(x, bottomY)
            ctx.lineWidth = 1
            ctx.strokeStyle = theme.palette.primary.main
            ctx.stroke()
            ctx.restore()
          }
        } else setValue(0)
      },
    },
  ]

  const chartOptions: ChartOptions<'line'> & ChartOptions<'bar'> = {
    hover: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: { line: { borderJoinStyle: 'round', tension: 0.2 } },
    plugins: {
      filler: { propagate: false },
      legend: { display: false },
      tooltip: {
        position: `${type}Position`,
        mode: 'nearest',
        axis: 'x',
        intersect: false,
        titleColor: theme.palette.text.primary,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        callbacks: {
          title: (item: any) => {
            if (item && item.length) {
              setValue(item[0].raw)
              setSelectedIndex(item[0].dataIndex)
              return item[0].raw > 0 ? item[0].raw : null
            }
            return null
          },
          label: () => '',
        },
      },
      title: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: { display: false },
        ticks: { precision: 0 },
      },
      x: {
        grid: { display: false },
        ticks: {
          autoSkip: false,
          callback: (_: any, index: number) => {
            if (index === selectedIndex && value > 0) return labels[index]
            else setSelectedIndex(0)
            return index === 0 || index === labels.length - 1 ? labels[index] : ''
          },
        },
      },
    },
  }

  const chartData = {
    labels,
    datasets: [
      {
        data,
        label: '',
        fill: gradient ? 'start' : '',
        borderColor: theme.palette.secondary.main,
        backgroundColor: gradient
          ? (context: ScriptableContext<'line'> | ScriptableContext<'bar'>) => {
              const ctx = context.chart.ctx
              const gradient = ctx.createLinearGradient(0, 0, 700, 300)
              gradient.addColorStop(0, theme.palette.text.secondary)
              gradient.addColorStop(1, theme.palette.secondary.main)
              return gradient
            }
          : theme.palette.primary.main,
        pointBorderColor: theme.palette.primary.main,
        pointRadius: 0,
      },
    ],
  }

  return (
    <Box sx={containerSx}>
      <Column sx={{ ml: '40px' }}>
        <Spacer y={1} />
        <Typography variant="h2" sx={{ color: theme.colors.GREY, height: '7%' }}>
          {title}
        </Typography>
        <Row sx={{ display: 'flex', alignItems: 'end' }}>
          <Typography
            sx={{ ...typographySx, lineHeight: '25px', visibility: value ? 'visible' : 'hidden' }}>
            {value}
          </Typography>
        </Row>
      </Column>
      <Box sx={{ height: '75%' }}>
        {type === 'bar' && <Bar options={chartOptions} data={chartData} plugins={chartPlugins} />}
        {type === 'line' && <Line options={chartOptions} data={chartData} plugins={chartPlugins} />}
      </Box>
    </Box>
  )
}

const containerSx: SxProps = { height: '100%', p: '3px 15px' }
const typographySx: SxProps = {
  height: '8%',
  fontWeight: 500,
  fontSize: '25px',
}
