import {
  InfoRounded,
  DeleteForeverRounded,
  AddCircleRounded,
  WarningAmberRounded,
  SvgIconComponent,
} from '@mui/icons-material'
import {
  styled,
  useTheme,
  SxProps,
  Button,
  SvgIcon,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { Contract } from 'api/gen'
import { Spacer, Row } from 'utils/spacing'

interface DialogProps {
  open: boolean
  handleClose: () => void
  title: string
  contracts: Contract[]
  type: 'create' | 'update' | 'delete'
}

export const SummaryDialog = (props: DialogProps) => {
  const t = useTranslate()
  const theme = useTheme()

  let color: string = theme.palette.text.primary
  let Icon: SvgIconComponent = InfoRounded
  const { title, contracts, type, open, handleClose } = props

  if (type === 'create') {
    color = theme.colors.GREEN
    Icon = AddCircleRounded
  }
  if (type === 'update') {
    color = theme.colors.ORANGE
    Icon = WarningAmberRounded
  }
  if (type === 'delete') {
    color = theme.colors.RED
    Icon = DeleteForeverRounded
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={dialogSx}>
      <Spacer y={2} />
      <StyledCircle sx={{ color: color }}>
        <SvgIcon sx={{ width: '40px', height: '50px' }}>
          <Icon sx={{ color: color }} />
        </SvgIcon>
      </StyledCircle>
      <DialogTitle>
        <Typography variant="h2" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={contentSx}>
          {contracts.map((contract) => (
            <Row key={contract.id} sx={{ justifyContent: 'space-between' }}>
              <Typography align="left">
                {contract.user.firstName} {contract.user.lastName}
              </Typography>
              <Typography align="right">{contract.staffNumber}</Typography>
            </Row>
          ))}
        </Box>
        <Button variant="outlined" color="secondary" sx={buttonSx} onClick={handleClose}>
          {t('buttons.close')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      diplay: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '370px',
      height: '100%',
      maxHeight: '360px',
    },
  },
}

const buttonSx: SxProps = {
  position: 'absolute',
  width: '130px',
  height: '30px',
  bottom: '30px',
  left: '120px',
}

const contentSx: SxProps = {
  maxHeight: '120px',
  height: 'auto',
  width: '300px',
  overflowY: 'scroll',
}

const StyledCircle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  border: '1px solid',
  borderRadius: '50px',
}))
