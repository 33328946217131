import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isPlainObject from 'lodash/isPlainObject'
import replace from 'lodash/replace'
import set from 'lodash/set'
import truncate from 'lodash/truncate'
import { IMask } from 'react-imask'

import { User } from 'user/types'

export function removeNonAlphaNumericChars(str: string): string {
  if (!str) return ''
  return replace(str, /[^0-9a-z ]/gi, '')
}

const MAX_SEPA_REF_LENGTH = 35
const SEPA_REF_TRUNCATE_OPTIONS = { length: MAX_SEPA_REF_LENGTH, omission: '' }

export function formatSepaReference(str: string): string {
  if (!str) return ''
  const alphaNumStr = removeNonAlphaNumericChars(str)
  const truncatedStr = truncate(alphaNumStr, SEPA_REF_TRUNCATE_OPTIONS)
  return truncatedStr
}

const MAX_SWAN_LABEL_LENGTH = 140
const SWAN_LABEL_TRUNCATE_OPTIONS = { length: MAX_SWAN_LABEL_LENGTH, omission: '' }

export function formatSwanLabel(str: string): string {
  if (!str) return ''
  const truncatedStr = truncate(str, SWAN_LABEL_TRUNCATE_OPTIONS)
  return truncatedStr
}

export function capitalizeString(str: string) {
  if (!str) return ''
  return str.replace(/(^|[\s-])\S/g, function (match) {
    return match.toUpperCase()
  })
}

export function strictCapitalizeString(str: string) {
  if (!str) return ''
  const lowerCaseStr = str.toLowerCase()
  return capitalizeString(lowerCaseStr)
}

export function formatUserName({ firstName, lastName }: Partial<User> = {}) {
  if (firstName && lastName) return strictCapitalizeString(firstName + ' ' + lastName)
  if (firstName) return strictCapitalizeString(firstName)
  if (lastName) return strictCapitalizeString(lastName)
  return ''
}

const IBAN_PATTERN = {
  fr: 'aa** **** **** **** **** **** ***',
}
const SIREN_PATTERN = {
  fr: '*** *** ***',
}
type IbanType = keyof typeof IBAN_PATTERN
type SirenType = keyof typeof SIREN_PATTERN

export function maskSIREN(str: string, type: SirenType = 'fr') {
  const strippedStr = typeof str === 'string' ? str.replaceAll(' ', '') : ''
  const masked = IMask.createMask({ mask: SIREN_PATTERN[type] })
  masked.updateOptions({ placeholderChar: SIREN_PATTERN.fr })
  masked.resolve(strippedStr)
  return masked
}

export function maskIBAN(str: string, type: IbanType = 'fr') {
  const strippedStr = typeof str === 'string' ? str.replaceAll(' ', '') : ''
  const masked = IMask.createMask({ mask: IBAN_PATTERN[type] })
  masked.updateOptions({ placeholderChar: IBAN_PATTERN.fr.replaceAll('a', '*') })
  masked.resolve(strippedStr)
  return masked
}

export function getInitial(str: string): string {
  if (!str) return ''
  const firstChar = str.charAt(0)
  return firstChar.toUpperCase()
}

export function getInitials(...values: string[]): string {
  let initials = ''
  for (const value of values) initials += getInitial(value)
  return initials
}

export function deepIterateOnObject(object: any, fn: any) {
  const arr = []
  function recurse(obj: any, map: any, depth: any) {
    Object.keys(obj).forEach((a) => {
      fn(obj[a], obj, a, depth)
      if (typeof obj[a] == 'object' && obj[a] != null) {
        map.push(a)
        arr.push(map)
        recurse(obj[a], [...map], depth + 1)
      }
    })
  }
  recurse(object, [], 1)
}

export function getOnlyModifiedFieldsObject<T>(
  source: T,
  target: T,
  flattenKeys?: Array<string>
): Partial<T> {
  if (!isPlainObject(target)) {
    throw new Error('target must be a plain object')
  }

  const targetKeys = flattenKeys || Object.keys(flattenObjectKeys(target))

  const newObject: Partial<T> = {}
  if (!isPlainObject(source)) {
    for (const key of targetKeys) {
      set(newObject, key, get(target, key))
    }
  } else {
    for (const key of targetKeys) {
      if (!isEqual(get(source, key), get(target, key))) {
        set(newObject, key, get(target, key))
      }
    }
  }
  return newObject
}

export function flattenObjectKeys(object: any, initialPathPrefix = ''): Record<string, any> {
  if (!object || !isPlainObject(object)) {
    return [{ [initialPathPrefix]: object }]
  }
  const prefix = initialPathPrefix ? `${initialPathPrefix}.` : ''
  return Object.keys(object)
    .flatMap((key) => flattenObjectKeys(object[key], `${prefix}${key}`))
    .reduce((acc, path) => ({ ...acc, ...path }))
}

export function parseBoolean(value: string | boolean): boolean {
  return value === true || value === 'true' || value === 'yes' || value === 'oui'
}

export function stringifyBoolean(value: boolean): string {
  if (value === true) return 'yes'
  return 'no'
}
