import { Button, Card, CardContent, Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useGetList, useTranslate } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import { config } from 'config'
import { TenantSelector } from 'layout/TenantSelector'
import { useCanAccess } from 'libs/auth'
import { ConfirmLogoutDialog } from 'libs/components/ConfirmLogoutDialog'
import { useModal } from 'libs/modal'
import { CompanyRecord, ROLES_WITHOUT_EMPLOYEE } from 'resources/types'
import { Spacer } from 'utils/spacing'

export function UnauthorizedPage() {
  const t = useTranslate()
  const navigate = useNavigate()
  const { canAccess } = useCanAccess()
  const { openModal: openLogoutModal } = useModal('logout')

  useEffect(() => {
    if (canAccess({ roles: ROLES_WITHOUT_EMPLOYEE })) {
      navigate('/contract')
    }
  }, [canAccess, navigate])

  function goToEmployeeApp() {
    window.location.replace(config.EMPLOYEE_APP_URL)
  }

  const { data: organizations } = useGetList<CompanyRecord>('organization')
  const userHasMultipleOrganizations = Array.isArray(organizations) && organizations.length > 1
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center">
      <Card sx={{ width: '450px' }}>
        <CardContent sx={{ p: 3 }}>
          <Typography variant="h2">{t('unauthorized.youAreNotAuthorized')}</Typography>
          <Spacer y={4} />
          {userHasMultipleOrganizations && (
            <>
              <Typography variant="h3">{t('unauthorized.chooseAnotherTenant')}</Typography>
              <Spacer y={2} />
              <TenantSelector />
            </>
          )}
          <Spacer y={4} />
          {canAccess({ roles: ['employee'] }) && (
            <>
              <Typography variant="h3">{t('unauthorized.goToEmployeeApp')}</Typography>
              <Spacer y={2} />
              <Button variant="contained" color="primary" fullWidth onClick={goToEmployeeApp}>
                {t('unauthorized.goToEmployeeAppButton')}
              </Button>
              <Spacer y={2} />
            </>
          )}
          <Button variant="outlined" color="secondary" fullWidth onClick={openLogoutModal}>
            {t('buttons.logout.main')}
          </Button>
        </CardContent>
      </Card>
      <ConfirmLogoutDialog />
    </Box>
  )
}
