import { LoadingButton } from '@mui/lab'
import {
  Typography,
  SxProps,
  useTheme,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { useModal } from 'libs/modal'
import { Column, Row, Spacer } from 'utils/spacing'

interface Props {
  title: string
  loading: boolean
  content: React.FC
  onConfirm: () => Promise<void>
}

export function ConfirmDialog(props: Props) {
  const t = useTranslate()
  const theme = useTheme()

  const { isModalOpen, closeModal } = useModal('confirm')

  const { title, loading, content: Content, onConfirm } = props

  async function handleConfirm() {
    await onConfirm()
    closeModal()
  }

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle sx={{ backgroundColor: theme.palette.secondary.main, maxHeight: '55px' }}>
        <Typography color="primary" sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Column sx={{ display: 'flex', alignItems: 'center' }}>
          <Spacer y={3} />
          <Content />
          <Row sx={buttonsRowSx}>
            <Button variant="outlined" color="secondary" sx={buttonSx} onClick={closeModal}>
              {t('buttons.close')}
            </Button>
            <Spacer x={2} />
            <LoadingButton
              variant="contained"
              color="error"
              loading={loading}
              onClick={handleConfirm}
              sx={{ ...buttonSx, color: theme.palette.text.secondary }}>
              {t('buttons.confirm')}
            </LoadingButton>
          </Row>
        </Column>
      </DialogContent>
    </Dialog>
  )
}

const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '650px',
      height: '100%',
      maxHeight: '230px',
    },
  },
}
const buttonsRowSx: SxProps = {
  width: '100%',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '25px',
}
const buttonSx: SxProps = {
  padding: 0,
  fontWeight: 400,
  height: '34px',
  width: '150px',
  textTransform: 'none',
}
