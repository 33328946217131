import { parse, parseISO, isValid } from 'date-fns'
import { fr } from 'date-fns/locale'

export function isValidISODate(datetime: string): boolean {
  if (!datetime) return true
  const parsedDate = parseISO(datetime)
  if (!isValid(parsedDate)) return false
  return true
}

export function isValidFrenchDate(datetime: string): boolean {
  let isDateValid = true
  let isTimeValid = true
  const [date, time] = datetime.split(' ')
  const parsedDate = parse(date, 'P', new Date(), { locale: fr })
  const timeRegex = /^(?:([01]?\d|2[0-3]):([0-5]?\d):)?([0-5]?\d)$/
  if (!isValid(parsedDate)) isDateValid = false
  if (time && !timeRegex.test(time)) isTimeValid = false
  return isDateValid && isTimeValid
}

// months are starting from index 0 so "month - 1" is needed to get the correct month
export function convertFrenchDateToISOString(datetime: string): string {
  const newDate = new Date()
  const [date, time] = datetime.split(' ')
  const [day, month, year] = date.split('/')
  if (time) {
    const [hours, minutes, seconds] = time.split(':')
    newDate.setHours(parseInt(hours), parseInt(minutes), parseInt(seconds), 0)
    newDate.setMonth(parseInt(month) - 1, parseInt(day))
    newDate.setFullYear(parseInt(year))
  } else {
    newDate.setHours(2, 0, 0, 0)
    newDate.setMonth(parseInt(month) - 1, parseInt(day))
    newDate.setFullYear(parseInt(year))
  }
  return newDate.toISOString()
}
