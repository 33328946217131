import SearchIcon from '@mui/icons-material/Search'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  TextField,
  Typography,
  Button,
  createFilterOptions,
  SxProps,
  useTheme,
  styled,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslate, useRefresh } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { useNotifications } from 'libs/notifications'
import { ContractRecord, RoleType } from 'resources/types'
import { Row, Column, Spacer } from 'utils/spacing'

import { RoleSelector } from './RoleSelector'

const OPTIONS_LIMIT = 1000
const filterOptions = createFilterOptions<ContractRecord>({ limit: OPTIONS_LIMIT })

interface Props {
  records: ContractRecord[]
  handleClose: () => void
}

export function AddExistingUser(props: Props) {
  const t = useTranslate()
  const api = useApi()
  const theme = useTheme()
  const refresh = useRefresh()
  const notification = useNotifications()
  const [value, setValue] = useState<ContractRecord | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const addRolesMutation = useMutation('addRoles', addRoles)
  const [selectedRole, setSelectedRole] = React.useState<RoleType>('employer')

  useEffect(() => {
    const clearDialog = () => {
      addRolesMutation.reset()
      refresh()
      props.handleClose()
    }
    if (addRolesMutation.isSuccess) {
      notification(t(`notifications.roleManagementTab.addSuccess.${selectedRole}`), {
        variant: 'success',
      })
      clearDialog()
    }
    if (addRolesMutation.isError) {
      notification(t(`notifications.roleManagementTab.addError.${selectedRole}`), {
        variant: 'error',
      })
      clearDialog()
    }
  }, [addRolesMutation, notification, t, props, refresh, selectedRole])

  async function addRoles() {
    const id = value?.id || ''
    const body = { roles: [selectedRole] } as ContractRecord
    await api.contractControllerUpdateOne(
      { ...body, isAdvanceServiceEnabled: !body.isManuallyDisabledByAdmin },
      id
    )
  }

  return (
    <>
      <Typography variant="h2" sx={{ fontWeight: 500 }}>
        {t('administration.roleManagementTab.addUserDialog.existingUserSubtitle')}
      </Typography>
      <Spacer y={3} />
      <StyledContainer>
        <StyledIconWrapper>
          <SearchIcon />
        </StyledIconWrapper>
        <Autocomplete
          value={value}
          onChange={(_, newValue) => {
            if (typeof newValue !== 'string') setValue(newValue)
          }}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
            setValue(null)
          }}
          filterOptions={filterOptions}
          options={props.records}
          getOptionLabel={(option) => option.user.name || ''}
          renderInput={(params) => <TextField {...params} label={false} />}
          renderOption={(props, option) => <li {...props}>{option.user.name}</li>}
          sx={{ ...autoCompleteSx, pl: `calc(1em + ${theme.spacing(4)})` }}
          blurOnSelect
          freeSolo
        />
      </StyledContainer>
      <Spacer y={4} />
      <Row sx={{ width: '100%' }}>
        <RoleSelector selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
        <Spacer x={2} />
        <Column>
          <Typography variant="h4" sx={{ color: theme.colors.GREY }}>
            {t('administration.roleManagementTab.adminHelperText')}
          </Typography>
          <Spacer y={2} />
          <Typography variant="h4" sx={{ color: theme.colors.GREY }}>
            {t('administration.roleManagementTab.analystHelperText')}
          </Typography>
        </Column>
      </Row>
      <Row sx={buttonsRowSx}>
        <Button variant="outlined" color="secondary" onClick={props.handleClose} sx={buttonSx}>
          {t('buttons.close')}
        </Button>
        <Spacer x={2} />
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => addRolesMutation.mutate()}
          disabled={!value}
          loading={addRolesMutation.isLoading}
          sx={buttonSx}>
          {t('buttons.validate')}
        </LoadingButton>
      </Row>
    </>
  )
}

const buttonsRowSx: SxProps = {
  width: '100%',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '25px',
  left: 0,
  right: 0,
}

const buttonSx: SxProps = {
  padding: 0,
  fontWeight: 400,
  height: '34px',
  width: '150px',
  textTransform: 'none',
}

const autoCompleteSx: SxProps = {
  '& .MuiOutlinedInput-root': {
    height: '45px',
    alignContent: 'center',
    '& fieldset': { border: 'none' },
  },
}

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: theme.colors.BLUE_PASTEL,
  height: '45px',
}))

const StyledIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.main,
}))
