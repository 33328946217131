import { LoadingButton } from '@mui/lab'
import { Typography, SxProps } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { useNotifications } from 'libs/notifications'
import { usePersistedUser } from 'user/context'
import { Column } from 'utils/spacing'

export const SendInvitationEmails = () => {
  const api = useApi()
  const t = useTranslate()
  const navigate = useNavigate()
  const notify = useNotifications()
  const { organization, setOrganization } = usePersistedUser()

  const sendInvtationEmailsMutation = useMutation(
    QueryKey.SendInvitationEmails,
    async () => {
      await sendInvitationEmails()
    },
    {
      onSuccess: () => {
        notify(t('notifications.onboarding.sendInvites.success'), { variant: 'success' })
      },
      onError: () => {
        notify(t('notifications.onboarding.sendInvites.error'), { variant: 'error' })
      },
    }
  )

  async function sendInvitationEmails() {
    const org = await api.organizationControllerUpdateOnboardingState({
      ...organization.onboarding,
      hasCompletedOnboarding: true,
    })
    await api.organizationControllerSendEmployeeInvites()
    setOrganization(org)
    navigate('/contract')
  }

  return (
    <Column sx={columnSx}>
      <Typography
        variant="h1"
        sx={typographySx}
        component="div"
        dangerouslySetInnerHTML={{ __html: t('onboarding.sendInvitationEmails.title') }}
      />
      <LoadingButton
        variant="contained"
        sx={{ fontWeight: 400 }}
        loading={sendInvtationEmailsMutation.isLoading}
        onClick={() => sendInvtationEmailsMutation.mutate()}>
        {t('onboarding.sendInvitationEmails.button')}
      </LoadingButton>
    </Column>
  )
}

const typographySx: SxProps = {
  width: '100%',
  textAlign: 'center',
  fontWeight: 500,
}

const columnSx: SxProps = {
  zIndex: 1,
  pb: '50px',
  pt: '80px',
  display: 'flex',
  height: '100%',
  maxWidth: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
}
