import React, { useEffect } from 'react'
import { Admin, CustomRoutes, Loading, memoryStore, Resource, useSidebarState } from 'react-admin'
import { Route, useNavigate } from 'react-router-dom'

import { config } from 'config'
import { i18nProvider } from 'internationalization/i18nProvider'
import { CustomLayout } from 'layout/Layout'
import { analytics } from 'libs/analytics'
import { ProtectedRoute, useGetMe, useGetMaintenance } from 'libs/auth'
import { consent } from 'libs/consent'
import { intercom } from 'libs/intercom'
import { initializeMicrosoftClarity } from 'libs/microsoftClarity'
import { SuperAdminPage } from 'pages/admin/SuperAdminPage'
import { AdminPage } from 'pages/administration/AdminPage'
import { AnalyticsPage } from 'pages/analytics/AnalyticsPage'
import { BankPage } from 'pages/bank/BankPage'
import { FinancingPage } from 'pages/financing/FinancingPage'
import { MaintenancePage } from 'pages/Maintenance'
import { OnboardingPage } from 'pages/onboarding/OnboardingPage'
import { UnauthorizedPage } from 'pages/Unauthorized'
import { ActivityLogsList } from 'resources/activity/ActivityList'
import { TransactionList } from 'resources/transaction/TransactionList'
import { UserList } from 'resources/user/UserList'
import { UserShow } from 'resources/user/UserShow'
import { SwanAuthRedirectPage } from 'swan/SwanAuthRedirectPage'
import { SwanInitiatePaymentPage } from 'swan/SwanInitiatePaymentPage'
import { useRefreshSwanToken } from 'swan/useRefreshSwanToken'
import { usePersistedUser } from 'user/context'

import { useApiDataProvider } from './dataProvider'
import { customTheme } from './theme'

export function CustomAdmin() {
  const navigate = useNavigate()
  const apiDataProvider = useApiDataProvider()
  const [_, setSidebarOpen] = useSidebarState()
  const { maintenance } = useGetMaintenance()
  const { user, isUserReady, contract, organization } = usePersistedUser()

  const hasCompletedOnboarding = organization?.onboarding?.hasCompletedOnboarding ?? false

  consent.useInitialize({ userId: user ? user.id : '' })
  useGetMe()
  useRefreshSwanToken()

  const cookies = consent.useCookies()

  useEffect(() => {
    if (contract && user && organization) {
      intercom.initialize({
        organizationId: contract.organizationId,
        organizationName: organization.name,
        userId: contract.userId,
        email: user.email,
        name: user.name,
      })
    }
  }, [user, contract, organization])

  useEffect(() => {
    if (cookies.microsoft_clarity && config.ENV === 'production') {
      initializeMicrosoftClarity()
    }

    if (user && organization) {
      analytics.identify(user.id)
      const properties = {
        $distinct_id: user.id,
        $email: user.email,
        $name: user.name,
        organizationName: organization.name,
      }

      if (!cookies.mixpanel) {
        properties['$name'] = 'Anonymous'
        properties['$email'] = 'Anonymous'
      }
      analytics.enable()
      analytics.setProfilePersistedProperties(properties)
    }
  }, [cookies, user, organization])

  useEffect(() => {
    if (!user?.id) return
    analytics.log('Login')
  }, [user?.id])

  useEffect(() => {
    if (organization && !hasCompletedOnboarding) {
      setSidebarOpen(false)
      navigate('/onboarding')
    }
  }, [organization, hasCompletedOnboarding])

  if (maintenance) {
    return <MaintenancePage />
  }

  return (
    <Admin
      dataProvider={apiDataProvider}
      ready={Loading}
      layout={CustomLayout}
      i18nProvider={i18nProvider}
      store={memoryStore()} // each time the app is loaded, the store is reset
      theme={customTheme}>
      {isUserReady && (
        <>
          <Resource name="contract" list={UserList} show={UserShow} />
          <Resource name="transactions" list={TransactionList} />
          <Resource name="activity" list={ActivityLogsList} />
          <CustomRoutes>
            <Route
              path="swan-auth-redirect"
              element={
                <ProtectedRoute roles={['admin']}>
                  <SwanAuthRedirectPage />
                </ProtectedRoute>
              }
            />
            <Route path="administration/:tab" element={<AdminPage />} />
            <Route path="analytics" element={<AnalyticsPage />} />
            <Route path="bank" element={<BankPage />} />
            <Route path="financing/:tab" element={<FinancingPage />} />
            <Route
              path="super-admin/:tab"
              element={
                <ProtectedRoute roles={['admin']}>
                  <SuperAdminPage />
                </ProtectedRoute>
              }
            />
            <Route path="onboarding" element={<OnboardingPage />} />
          </CustomRoutes>
          <CustomRoutes noLayout>
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route
              path="initiate-payment"
              element={
                <ProtectedRoute roles={['admin']}>
                  <SwanInitiatePaymentPage />
                </ProtectedRoute>
              }
            />
          </CustomRoutes>
        </>
      )}
    </Admin>
  )
}
