import { LoadingButton } from '@mui/lab'
import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { useNotifications } from 'libs/notifications'
import { usePersistedUser } from 'user/context'
import { Spacer, Column } from 'utils/spacing'

const GOOGLE_CALENDAR_URL = 'https://calendly.com/garynesspay'

export const BookAppointment = () => {
  const api = useApi()
  const t = useTranslate()
  const notify = useNotifications()
  const { organization, setOrganization } = usePersistedUser()

  const updateOnboardingMutation = useMutation(
    QueryKey.UpdateOnboarding,
    async () => {
      const org = await api.organizationControllerUpdateOnboardingState({
        ...organization.onboarding,
        hasBookedAnAppointment: true,
      })
      setOrganization(org)
    },
    {
      onSuccess: () => {
        notify(t('notifications.onboarding.bookAppointment.success'), { variant: 'success' })
      },
      onError: () => {
        notify(t('notifications.onboarding.bookAppointment.error'), { variant: 'error' })
      },
    }
  )

  return (
    <Box sx={{ maxWidth: '75%' }}>
      <Typography variant="h2" sx={{ fontWeight: 500 }}>
        {t('onboarding.bookAppointment.title')} {organization.onboarding.firstName} !
      </Typography>
      <Spacer y={3} />
      <Typography sx={{ fontWeight: 400 }}>{t('onboarding.bookAppointment.subtitle')}</Typography>
      <Spacer y={3} />
      <Typography sx={{ fontWeight: 400 }}>
        {t('onboarding.bookAppointment.requirements.message')}
      </Typography>
      <Typography sx={{ fontWeight: 400, pl: 2 }}>
        •&nbsp;&nbsp;&nbsp;{t('onboarding.bookAppointment.requirements.id')}
      </Typography>
      <Typography sx={{ fontWeight: 400, pl: 2 }}>
        •&nbsp;&nbsp;&nbsp;{t('onboarding.bookAppointment.requirements.computer')}
      </Typography>
      <Typography sx={{ fontWeight: 400, pl: 2 }}>
        •&nbsp;&nbsp;&nbsp;{t('onboarding.bookAppointment.requirements.phone')}
      </Typography>
      <Spacer y={5} />
      <Column sx={{ display: 'flex', alignItems: 'end' }}>
        <Button
          sx={{ fontWeight: 400, width: '100%' }}
          onClick={() => window.open(GOOGLE_CALENDAR_URL, '_blank')}>
          {t('onboarding.bookAppointment.buttons.book')}
        </Button>
        <Spacer y={4} />
        <LoadingButton
          color="secondary"
          variant="outlined"
          sx={{ fontWeight: 400, width: '200px' }}
          loading={updateOnboardingMutation.isLoading}
          onClick={() => updateOnboardingMutation.mutate()}>
          {t('onboarding.bookAppointment.buttons.done')}
        </LoadingButton>
      </Column>
    </Box>
  )
}
