import {
  SwanTransactionStatusInfo,
  Organization,
  Transaction,
  User,
  Contract,
  ActivityLog,
} from 'api/gen'

export enum TransactionStatus {
  INPROGRESS = Transaction.StatusEnum.INPROGRESS,
  CANCEL = Transaction.StatusEnum.CANCEL,
  DONE = Transaction.StatusEnum.DONE,
}

export enum SwanTransactionStatus {
  Booked = SwanTransactionStatusInfo.StatusEnum.Booked,
  Canceled = SwanTransactionStatusInfo.StatusEnum.Canceled,
  Pending = SwanTransactionStatusInfo.StatusEnum.Pending,
  Rejected = SwanTransactionStatusInfo.StatusEnum.Rejected,
  Released = SwanTransactionStatusInfo.StatusEnum.Released,
  Upcoming = SwanTransactionStatusInfo.StatusEnum.Upcoming,
}

export enum Role {
  Employee = Contract.RolesEnum.Employee,
  Employer = Contract.RolesEnum.Employer,
  Analyst = Contract.RolesEnum.Analyst,
  Admin = Contract.RolesEnum.Admin,
}
export type RoleType = `${Contract.RolesEnum}`
export const ROLES = Object.values(Contract.RolesEnum)
export const NESSPAY_ONLY_ROLES = [Contract.RolesEnum.Admin, Contract.RolesEnum.AccountManager]
export const ROLES_WITHOUT_EMPLOYEE = ROLES.filter((role) => role !== Contract.RolesEnum.Employee)
export const ROLES_WITHOUT_ADMIN = ROLES.filter((role) => role !== Contract.RolesEnum.Admin)
export const ROLES_WITH_WRITE_PERMISSION = [Contract.RolesEnum.Admin, Contract.RolesEnum.Employer]
export const ROLES_WITHOUT_ADMIN_AND_EMPLOYEE = ROLES.filter(
  (role) => role !== Contract.RolesEnum.Admin && role !== Contract.RolesEnum.Employee
)
export const ROLES_WITHOUT_NESSPAY_ROLES_AND_EMPLOYEE = ROLES.filter(
  (role) =>
    role !== Contract.RolesEnum.Admin &&
    role !== Contract.RolesEnum.AccountManager &&
    role !== Contract.RolesEnum.Employee
)

export interface ContractRecord extends Contract {
  hasAdvancesEnabled: boolean
}

export interface TransactionRecord extends Omit<Transaction, 'swanStatus'> {
  // Since this status is defined by swan, we did not defined a DB enum for it,
  // So we have no enum generated in the entity's type
  swanStatus: SwanTransactionStatus
}

export interface UserRecord extends User {}

export interface CompanyRecord extends Organization {}

export interface ActivityLogRecord extends ActivityLog {}

export type ResourceType = 'contract' | 'transactions' | 'organization'
