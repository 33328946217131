import { AddRounded } from '@mui/icons-material'
import {
  IconButton,
  Typography,
  Box,
  useTheme,
  SxProps,
  CircularProgress,
  Popover,
  Stack,
  Button,
} from '@mui/material'
import React, { FC } from 'react'
import { useTranslate, useListFilterContext } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { ExportConfig, FileFormat, TransactionExportBody } from 'api/gen'
import { Column, Spacer } from 'utils/spacing'

import { exportTo } from './exporter'

type Props = {
  type: ExportConfig.TypeEnum
}

export const ExportAdvanceRequestsButton: FC<Props> = ({ type }) => {
  const t = useTranslate()
  const theme = useTheme()
  const api = useApi()
  const { filterValues } = useListFilterContext()
  const exportMutation = useMutation('getExportFileType' + type, getExportFile)

  const groupId = `export-button-column-${type}`
  const exportButtonTitle = {
    [ExportConfig.TypeEnum.Silae]: t('buttons.export.type.silae'),
    [ExportConfig.TypeEnum.Payfit]: t('buttons.export.type.payfit'),
    [ExportConfig.TypeEnum.Raw]: t('buttons.export.type.raw'),
  }[type]

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const openPopover = () => {
    const anchorElement = document.getElementById(groupId)
    setAnchorEl(anchorElement)
  }
  const closePopover = () => {
    setAnchorEl(null)
  }
  const isPopoverOpen = Boolean(anchorEl)
  const popoverId = isPopoverOpen ? 'export-popover' : undefined

  async function getExportFile(fileFormat: FileFormat.TypeEnum) {
    closePopover()
    const body: TransactionExportBody = {
      config: {
        fileName: 'transactions_export',
        fileFormat: { type: fileFormat },
        type,
      },
      filters: {
        startDate: filterValues['createdAt'].start,
        endDate: filterValues['createdAt'].end,
      },
    }
    const response = await api.advancesControllerGetAdvancesExport(body)
    const now = new Date()
    const filename =
      'transactions_export_' + now.toLocaleDateString() + '_' + now.toLocaleTimeString()
    exportTo(fileFormat, response, filename)
  }

  return (
    <>
      <Column id={groupId} aria-describedby={popoverId}>
        <Box sx={{ ...boxSx, backgroundColor: theme.palette.secondary.main }}>
          {exportMutation.isLoading ? (
            <CircularProgress size={45} sx={progressSx} />
          ) : (
            <IconButton
              onClick={openPopover}
              sx={{ height: '100%', width: '100%', color: theme.palette.primary.main }}>
              <AddRounded sx={{ height: '50px', width: '50px' }} />
            </IconButton>
          )}
        </Box>
        <Spacer y={3} />
        <Typography sx={{ textAlign: 'center' }}>{exportButtonTitle}</Typography>
      </Column>
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'center', horizontal: 110 }}>
        <Stack spacing={2} sx={{ padding: 2, backgroundColor: 'transparent' }}>
          <Typography>{t('buttons.export.format.chooseFormat')}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportMutation.mutate(FileFormat.TypeEnum.Xlsx)}>
            {t('buttons.export.format.xls')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportMutation.mutate(FileFormat.TypeEnum.Csv)}>
            {t('buttons.export.format.csv')}
          </Button>
        </Stack>
      </Popover>
    </>
  )
}

const progressSx: SxProps = { alignSelf: 'center', width: '100%', marginLeft: '30%' }
const boxSx: SxProps = {
  height: '115px',
  width: '115px',
  borderRadius: '10px',
  display: 'flex',
}
