import { EuroRounded, PercentRounded } from '@mui/icons-material'
import { SxProps, Button, Typography, InputAdornment, useTheme, styled } from '@mui/material'
import React, { useState } from 'react'
import {
  SaveButton,
  SimpleForm,
  TextInput,
  useCreate,
  useTranslate,
  useRefresh,
  NumberInput,
} from 'react-admin'
import type { FieldValues } from 'react-hook-form'

import { useApi } from 'api'
import { Contract } from 'api/gen'
import { CustomBooleanInput } from 'libs/components/CustomBooleanInput'
import { StyledSwitch } from 'libs/components/StyledSwitch'
import { useNotifications } from 'libs/notifications'
import { useFormValidation } from 'libs/validation/form'
import { RoleType } from 'resources/types'
import { getContractData, getUserData } from 'resources/user/utils'
import { usePersistedUser } from 'user/context'
import { maskIBAN } from 'utils/format'
import { Column, Row, Spacer } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

import { RoleSelector } from './RoleSelector'

interface Props {
  handleClose: () => void
}

const getStaffNumberFilter = (value: string) => 'staffNumber||$eq||' + value
const getOrganizationIdFilter = (value: string) => 'organizationId||$eq||' + value

export const AddNewUser = (props: Props) => {
  const t = useTranslate()
  const [create] = useCreate()
  const refresh = useRefresh()
  const { handleClose } = props
  const theme = useTheme()
  const api = useApi()
  const validation = useFormValidation()
  const notification = useNotifications()
  const { tRole } = useRoleTranslation()
  const { organization, hasIntegration } = usePersistedUser()
  const [selectedRole, setSelectedRole] = useState<RoleType>('employer')
  const [shouldAddEmployeeRole, setAddEmployeeRole] = useState(false)

  const checkStaffNumberValidity = async (staffNumber: string) => {
    if (!staffNumber) return undefined
    const filters = [getOrganizationIdFilter(organization.id), getStaffNumberFilter(staffNumber)]
    const response = await api.getManyBaseContractControllerContract(undefined, undefined, filters)
    const { data: contracts } = response
    if (contracts && contracts.length > 0) {
      if (contracts.some((contract) => contract.active === true)) {
        return t('error.invalidStaffNumber')
      }
    }
    return undefined
  }

  async function inviteUser(data: FieldValues) {
    await create(
      'contract',
      {
        data: !shouldAddEmployeeRole
          ? { user: getUserData(data.user), roles: [selectedRole] }
          : getContractData(data, organization.id, [selectedRole, Contract.RolesEnum.Employee]),
      },
      {
        onSuccess: () => {
          notification(t(`notifications.roleManagementTab.addSuccess.${selectedRole}`), {
            variant: 'success',
          })
          handleClose()
          refresh()
        },
        onError: (error: any) => {
          console.log(error)
          if (error.status === 409) {
            notification(t(`error.uniqueness.${error.body.field}`), { variant: 'error' })
          } else
            notification(t(`notifications.roleManagementTab.addError.${selectedRole}`), {
              variant: 'error',
            })
        },
      }
    )
  }

  const EmployeeFields = () => (
    <>
      <Row>
        <StyledTextInput
          source="user.phone"
          label={t('resources.user.fields.phone')}
          variant="outlined"
          isRequired={false}
          validate={validation.frenchPhone}
          autoComplete="off"
          fullWidth
        />
      </Row>
      <StyledTextInput
        source="user.iban"
        label={t('resources.user.fields.iban')}
        variant="outlined"
        isRequired
        validate={[validation.required, validation.iban]}
        autoComplete="off"
        parse={(value) => maskIBAN(value).unmaskedValue}
        format={(value) => maskIBAN(value).value}
      />
      <Row>
        <StyledNumberInput
          source="netSalary"
          label={t('resources.contract.fields.netSalary')}
          variant="outlined"
          defaultValue={0}
          autoComplete="off"
          validate={validation.netSalary}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ color: theme.palette.secondary.main }}>
                <EuroRounded />
              </InputAdornment>
            ),
          }}
        />
        <Spacer x={2} />
        <StyledNumberInput
          source="availableSalaryFraction"
          label={t('resources.contract.fields.availableSalaryFraction')}
          variant="outlined"
          defaultValue={0}
          autoComplete="off"
          validate={validation.salaryFraction}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ color: theme.palette.secondary.main }}>
                <PercentRounded />
              </InputAdornment>
            ),
          }}
        />
      </Row>
      <Row>
        <StyledTextInput
          source="staffNumber"
          label={t('resources.contract.fields.staffNumber')}
          variant="outlined"
          isRequired
          autoComplete="off"
          validate={[validation.required, checkStaffNumberValidity]}
        />
        <Spacer x={10} />
        <CustomBooleanInput
          source="isAdvanceServiceEnabled"
          label={t('resources.contract.fields.isAdvanceServiceEnabled')}
          labelColor="primary"
        />
      </Row>
    </>
  )

  return (
    <SimpleForm onSubmit={inviteUser} sx={{ p: 0, m: 0 }} toolbar={false}>
      <Column>
        <Row>
          <StyledTextInput
            source="user.firstName"
            label={t('resources.user.fields.firstName')}
            variant="outlined"
            autoComplete="off"
            FormHelperTextProps={{ sx: { mt: 0 } }}
            validate={[validation.required, validation.maxLength]}
            isRequired
          />
          <Spacer x={2} />
          <StyledTextInput
            source="user.lastName"
            label={t('resources.user.fields.lastName')}
            variant="outlined"
            autoComplete="off"
            FormHelperTextProps={{ sx: { mt: 0 } }}
            validate={[validation.required, validation.maxLength]}
            isRequired
          />
        </Row>
        <StyledTextInput
          source="user.email"
          label={t('resources.user.fields.email')}
          variant="outlined"
          autoComplete="off"
          FormHelperTextProps={{ sx: { mt: 0 } }}
          validate={[validation.required, validation.email]}
          isRequired
        />
        {shouldAddEmployeeRole && <EmployeeFields />}
        <Row>
          <RoleSelector selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
          {hasIntegration ? null : (
            <>
              <Spacer x={12} />
              <Typography variant="h2" sx={{ fontWeight: 500 }}>
                {tRole('employee')}
              </Typography>
              <Spacer x={3} />
              <StyledSwitch
                checked={shouldAddEmployeeRole}
                onChange={() => setAddEmployeeRole(!shouldAddEmployeeRole)}
              />
            </>
          )}
        </Row>
        <Spacer y={1} />
        <Column>
          <Typography variant="h4" sx={{ color: theme.colors.GREY }}>
            {t('administration.roleManagementTab.adminHelperText')}
          </Typography>
          <Typography variant="h4" sx={{ color: theme.colors.GREY }}>
            {t('administration.roleManagementTab.analystHelperText')}
          </Typography>
        </Column>
        <Spacer y={5} />
        <Row sx={buttonsRowSx}>
          <Button variant="outlined" color="secondary" onClick={handleClose} sx={buttonSx}>
            {t('buttons.close')}
          </Button>
          <Spacer x={2} />
          <SaveButton
            label={t('buttons.validate')}
            variant="contained"
            color="primary"
            icon={<></>}
            sx={buttonSx}
          />
        </Row>
      </Column>
    </SimpleForm>
  )
}

const buttonSx: SxProps = {
  padding: 0,
  fontWeight: 400,
  height: '34px',
  width: '150px',
  textTransform: 'none',
}

const buttonsRowSx: SxProps = {
  width: '100%',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '25px',
  left: 0,
  right: 0,
}

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  margin: '2px',
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    height: '42px',
    minWidth: '250px',
    maxWidth: '520px',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))

const StyledNumberInput = styled(NumberInput)(({ theme }) => ({
  margin: '2px',
  // remove the arrows Mui displays on NumberInputs
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    height: '42px',
    maxWidth: '250px',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))
