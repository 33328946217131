import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { TopToolbar } from 'react-admin'

export const CustomHeader: React.FC<{ title: string }> = (props) => (
  <StyledToolbar>
    <Box flex="1">
      <Typography variant="h1" sx={{ p: '15px', fontWeight: 500 }}>
        {props.title}
      </Typography>
    </Box>
  </StyledToolbar>
)

const StyledToolbar = styled(TopToolbar)(({ theme }) => ({
  margin: '-16px -16px 0px -16px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
  backgroundColor: theme.palette.text.secondary,
}))
