import { Box, SxProps, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { FinancingBorrower } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { Column, Row, Spacer } from 'utils/spacing'

export const BorrowerStatus = () => {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()

  const [borrower, setBorrower] = useState<FinancingBorrower>()

  useQuery(QueryKey.GetBorrower, async () => {
    try {
      const borrower = await api.financingControllerGetBorrower()
      setBorrower(borrower)
    } catch (error) {
      console.error(error)
    }
  })

  function getStatusColor() {
    if (borrower && borrower.status === FinancingBorrower.StatusEnum.READY)
      return theme.colors.GREEN_PASTEL
    return theme.colors.RED_PASTEL
  }

  const BorrowerInfos = () => {
    const tosSignedAt = borrower?.signed_at ? new Date(borrower.signed_at).toLocaleDateString() : ''
    const iban = borrower?.accounts ? borrower?.accounts[0].account_number : ''
    return (
      <Box sx={{ width: '700px' }}>
        <Row>
          <TextField
            label={t('resources.user.fields.firstName')}
            value={borrower?.directors[0].first_name}
            autoComplete="off"
            disabled
            sx={{ width: '50%' }}
          />
          <Spacer x={2} />
          <TextField
            label={t('resources.user.fields.lastName')}
            value={borrower?.directors[0].last_name}
            autoComplete="off"
            disabled
            sx={{ width: '50%' }}
          />
        </Row>
        <Spacer y={2} />
        <TextField
          label={t('resources.user.fields.email')}
          value={borrower?.directors[0].business_email}
          autoComplete="off"
          disabled
          sx={{ width: '100%' }}
        />
        <Spacer y={2} />
        <TextField
          label={t('financing.loansTab.createBorrower.dialog.siren')}
          value={borrower?.identifier}
          autoComplete="off"
          disabled
          sx={{ width: '100%' }}
        />
        <Spacer y={2} />
        <TextField
          label={t('financing.loansTab.createBorrower.dialog.iban')}
          value={iban}
          autoComplete="off"
          disabled
          sx={{ width: '100%' }}
        />
        <Spacer y={2} />
        <Row>
          <TextField
            label={t('financing.borrowerTab.tosSignedAt')}
            value={tosSignedAt}
            autoComplete="off"
            disabled
            sx={{ width: '50%' }}
          />
          <Spacer x={2} />
          <TextField
            label={t('financing.loansTab.table.status')}
            value={borrower?.status}
            autoComplete="off"
            disabled
            InputProps={{ sx: { backgroundColor: getStatusColor() } }}
            sx={{ width: '50%' }}
          />
        </Row>
      </Box>
    )
  }

  return (
    <Column sx={{ display: 'flex', width: '100%' }}>
      <Spacer y={3} />
      <Typography variant="h2" align="left" sx={{ fontWeight: 500, alignSelf: 'center' }}>
        {t('financing.borrowerTab.message')}
      </Typography>
      <Spacer y={3} />
      <Column sx={columnSx}>
        <BorrowerInfos />
      </Column>
    </Column>
  )
}

const columnSx: SxProps = {
  display: 'flex',
  height: '400px',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  mt: -3,
}
