import { useMemo } from 'react'

import { useAuthContext } from 'libs/auth/provider'
import { usePersistedUser } from 'user/context'

import { config } from '../config'

import { Configuration } from './gen'
import { DefaultApi } from './gen/api'

const basePath = config.BACKEND_URL

export function useApi() {
  const { auth } = useAuthContext()
  const { organization } = usePersistedUser()

  const accessToken = auth ? auth.accessToken : ''
  const organizationId = organization ? organization.id : ''

  const api = useMemo(() => {
    const configuration: Configuration = {
      basePath,
      accessToken,
      organizationId,
    }
    return new DefaultApi(configuration)
  }, [accessToken, organizationId])

  return api
}
