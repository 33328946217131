import { Typography, styled } from '@mui/material'
import React, { ReactElement } from 'react'
import { useTranslate } from 'react-admin'

import { ReactComponent as CheckMarkLogo } from 'assets/check.svg'
import { useModal } from 'libs/modal'

interface Props {
  message: string
  logo: ReactElement
  isCompleted: boolean
  id?: string
}

export function OnboardingItem(props: Props) {
  const t = useTranslate()
  const { message, logo, isCompleted, id } = props
  const { closeModal } = useModal('onboarding')

  function startTour() {
    // TODO : start tour logic
    closeModal()
  }

  return (
    <>
      <StyledCircle id={id} onClick={startTour}>
        {isCompleted ? <CheckMarkLogo width="80%" height="80%" /> : logo}
      </StyledCircle>
      <Typography variant="h2" sx={{ maxWidth: '270px', marginLeft: '40px' }}>
        {t(`onboarding.${message}`)}
      </Typography>
    </>
  )
}

const StyledCircle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: '25%',
  marginBottom: '20px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '170px',
  width: '170px',
  borderRadius: '90px',
  border: '2px solid',
  borderColor: theme.palette.secondary.main,
  cursor: 'pointer',
}))
