function verify(number: string, size: number) {
  if (number.length != size) return false
  let bal = 0
  let total = 0
  for (let i = size - 1; i >= 0; i--) {
    const step = (number.charCodeAt(i) - 48) * (bal + 1)
    total += step > 9 ? step - 9 : step
    bal = 1 - bal
  }
  return total % 10 == 0 ? true : false
}

export function isValidSiren(siren: string) {
  return verify(siren, 9)
}
