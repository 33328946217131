import { LoadingButton } from '@mui/lab'
import { TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { useNotifications } from 'libs/notifications'
import { Column, Spacer } from 'utils/spacing'

export function CreateFakeDataTabView() {
  const api = useApi()
  const t = useTranslate()
  const notification = useNotifications()

  const [nbOfUsers, setNbOfUsers] = useState(0)
  const [nbOfTransactions, setNbOfTransactions] = useState(10)
  const [nbOfDaysFromNow, setNbOfDaysFromNow] = useState(90)

  const isSubmitDisabled =
    (nbOfDaysFromNow === 0 && nbOfUsers === 0) || (nbOfDaysFromNow === 0 && nbOfTransactions === 0)

  const createFakeDataMutation = useMutation(
    async () => {
      if (nbOfUsers > 0) {
        const response = await api.adminControllerCreateFakeUsers({
          nbOfUsers,
          nbOfTransactions,
          nbOfDaysFromNow,
        })
        return response
      } else {
        const response = await api.adminControllerCreateFakeTransaction({
          nbOfTransactions,
          nbOfDaysFromNow,
        })
        return response
      }
    },
    {
      onSuccess: () => {
        notification(t('notifications.createFakeData.success'), { variant: 'success' })
      },
      onError: () => {
        notification(t('notifications.createFakeData.error'), { variant: 'error' })
      },
    }
  )

  return (
    <Column sx={{ width: '450px' }}>
      <Spacer y={1} />
      <Typography variant="body1">{t('admin.createFakeData.description')}</Typography>
      <Spacer y={3} />
      <TextField
        label={t('admin.createFakeData.nbOfUsers')}
        value={nbOfUsers}
        type="number"
        autoComplete="off"
        onChange={(event) => setNbOfUsers(event?.target?.value as unknown as number)}
        sx={{ width: '100%' }}
      />
      <Spacer y={3} />
      <TextField
        label={t('admin.createFakeData.nbOfTransactions')}
        value={nbOfTransactions}
        type="number"
        autoComplete="off"
        onChange={(event) => setNbOfTransactions(event?.target?.value as unknown as number)}
        sx={{ width: '100%' }}
      />
      <Spacer y={3} />
      <TextField
        label={t('admin.createFakeData.nbOfDaysFromNow')}
        value={nbOfDaysFromNow}
        type="number"
        autoComplete="off"
        onChange={(event) => setNbOfDaysFromNow(event?.target?.value as unknown as number)}
        sx={{ width: '100%' }}
      />
      <Spacer y={3} />
      <LoadingButton
        variant="contained"
        onClick={() => createFakeDataMutation.mutate()}
        loading={createFakeDataMutation.isLoading}
        disabled={isSubmitDisabled || createFakeDataMutation.isLoading}
        sx={{ width: '100%' }}>
        {t('buttons.save')}
      </LoadingButton>
    </Column>
  )
}
