import { BackupTableRounded } from '@mui/icons-material'
import { IconButton, Typography, Box, useTheme, SxProps } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useTranslate, Exporter, ExportButton as ReactAdminExportButton } from 'react-admin'

import { Column, Spacer } from 'utils/spacing'

interface Props {
  type: 'csv' | 'xls'
  exporter: Exporter | ((records: any[]) => void)
}

export function ExportButton(props: Props) {
  const t = useTranslate()
  const theme = useTheme()

  const buttonId = useMemo(() => `export-button-id-${props.type}`, [props.type])
  const title = useMemo(() => t(`buttons.export.format.${props.type}`), [t, props.type])
  const onClick = useCallback(() => {
    const exportButton = document.getElementById(buttonId)
    if (exportButton) exportButton.click()
  }, [buttonId])

  return (
    <Column>
      <Box sx={{ ...boxSx, backgroundColor: theme.palette.secondary.main }}>
        <IconButton
          onClick={onClick}
          sx={{ height: '100%', width: '100%', color: theme.palette.text.secondary }}>
          <BackupTableRounded sx={{ height: '50px', width: '50px' }} />
        </IconButton>
      </Box>
      <Spacer y={3} />
      <Typography sx={{ textAlign: 'center' }}>{title}</Typography>
      <ReactAdminExportButton
        exporter={props.exporter}
        maxResults={1000}
        id={buttonId}
        sx={{ display: 'none' }}
      />
    </Column>
  )
}

const boxSx: SxProps = {
  height: '115px',
  width: '115px',
  borderRadius: '10px',
  display: 'flex',
}
