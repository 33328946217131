import { RolleeView } from '@getrollee/connect-react-sdk'
import { LoadingButton } from '@mui/lab'
import { CircularProgress, Typography, useTheme } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { config } from 'config'
import { sleep } from 'utils/async'
import { Spacer } from 'utils/spacing'

export function RolleeLogin() {
  const t = useTranslate()
  const theme = useTheme()
  const api = useApi()
  const queryClient = useQueryClient()

  const { rolleeAccount, rolleeUser, isLoading: isLoadingRolleeAccount } = useRolleeAccount()

  const createIntegrationMutation = useMutation(
    async () => {
      await api.integrationControllerRolleeIntegration()
      await sleep(1000)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKey.Me)
        await sleep(1000)
        location.reload()
      },
    }
  )

  const isReady =
    !isLoadingRolleeAccount && !createIntegrationMutation.isLoading && Boolean(rolleeUser)
  const rolleeAccountAlreadyConnected = Boolean(rolleeAccount?.connected)

  const rolleeConfig = useMemo(
    () => ({
      sessionToken: rolleeUser?.session || '',
      setup: {
        production: config.ENV === 'production',
        introScreenSkip: true,
        introScreenTitle: t('administration.integration.rollee.connect'),
        ctaTextColor: 'white',
        ctaBackgroundColor: theme.colors.BLUE,
        datasource: config.ROLLEE_PAYFIT_DATASOURCE_ID,
      },
    }),
    [t, theme, rolleeUser]
  )

  const onLoginSuccess = useCallback(() => {
    createIntegrationMutation.mutate()
  }, [createIntegrationMutation])

  if (!isReady) {
    return <CircularProgress size={50} />
  }
  if (!rolleeAccountAlreadyConnected) {
    return <RolleeView config={rolleeConfig} onLoginSuccess={onLoginSuccess} />
  }
  return (
    <>
      <Typography variant="h2" color={theme.palette.text.primary}>
        {t('administration.integration.rollee.reconnectDescription')}
      </Typography>
      <Spacer y={5} />
      <LoadingButton
        variant="contained"
        sx={{ width: '100%' }}
        onClick={() => createIntegrationMutation.mutate()}
        loading={createIntegrationMutation.isLoading}
        disabled={createIntegrationMutation.isLoading}>
        {t('administration.integration.rollee.reconnect')}
      </LoadingButton>
    </>
  )
}

export function useRolleeAccount() {
  const api = useApi()
  const rolleeAccountQuery = useQuery(
    QueryKey.RolleeAccount,
    () => api.rolleeControllerGetAccount(),
    {
      // The stale time value is based on the default expiration time
      // of the Rollee token, which is 30 minutes. To be safe, we subtract 5 minutes.
      staleTime: (30 - 5) * 60 * 1000,
    }
  )
  const { user: rolleeUser, account: rolleeAccount } = rolleeAccountQuery.data || {}
  return { rolleeUser, rolleeAccount, isLoading: rolleeAccountQuery.isLoading }
}
