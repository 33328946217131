import { AccountBalanceRounded, ContentCopyRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  styled,
  Typography,
  SxProps,
  Paper,
  useTheme,
  Grid,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'
import { subMonths, startOfMonth } from 'date-fns'
import { jsPDF } from 'jspdf'
import React, { SyntheticEvent } from 'react'
import { useTranslate, useLocaleState } from 'react-admin'
import { renderToStaticMarkup } from 'react-dom/server'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { SwanTransaction, SwanTransactionStatusInfo as SwanStatus } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import LogoPNG from 'assets/logo_blue_night.png'
import { ReactComponent as LogoSVG } from 'assets/logo_blue_night.svg'
import { analytics } from 'libs/analytics'
import { useCanAccess } from 'libs/auth'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { KpiContainer } from 'pages/analytics/KpiContainer'
import { usePersistedUser } from 'user/context'
import { maskIBAN } from 'utils/format'
import { Column, Row, Spacer } from 'utils/spacing'

import { DownloadStatementsDialog } from './DownloadStatementsDialog'

interface RowDetailsProps {
  id: string
  value: string
}

function blur(event: SyntheticEvent) {
  const target = event.target as HTMLElement
  target.blur()
}

export const BankPageContent = () => {
  const t = useTranslate()
  const theme = useTheme()
  const api = useApi()
  const [locale] = useLocaleState()
  const { user } = usePersistedUser()
  const notification = useNotifications()
  const { openModal } = useModal('downloadStatements')
  const isAccountManager = useCanAccess().canAccess({ roles: ['accountManager'] })

  const currentDate = new Date()
  const lastSemesterDate = subMonths(startOfMonth(currentDate), 6)

  const swanAccountResponse = useQuery(
    QueryKey.SwanAccountWithTransactions,
    () =>
      api.swanControllerGetAccountWithTransactions({
        isAfterUpdatedAt: lastSemesterDate.toISOString(),
        isBeforeUpdatedAt: currentDate.toISOString(),
      }),
    {
      onError: console.error,
    }
  )
  const balance = swanAccountResponse.data?.account?.balances?.available?.value
  const transactions = swanAccountResponse.data?.transactions.slice(0, 5)
  const iban = swanAccountResponse.data?.account?.IBAN as string
  const bic = swanAccountResponse.data?.account?.BIC as string

  function downloadBankDetails(event: SyntheticEvent) {
    const pdfFile = new jsPDF('portrait', 'mm', [1000, 1350])

    const htmlBody = document.createElement('body')
    htmlBody.innerHTML = renderToStaticMarkup(PDFContent)

    pdfFile.html(htmlBody, {
      callback: (file: jsPDF) => {
        const date = new Date().toISOString().substring(0, 10)
        const filename = 'rib_nesspay_' + date + '.pdf'
        file.addImage(LogoPNG, 'PNG', 600, 30, 0, 0)
        file.save(filename)
      },
      autoPaging: 'text',
      windowWidth: 1000,
      width: 1000,
      x: 25,
      y: 25,
    })
    blur(event)
  }

  function downloadStatements(event: SyntheticEvent) {
    analytics.log('click on Download statements')
    openModal()
    blur(event)
  }

  async function copyToClipboard(event: React.SyntheticEvent, id: string) {
    const target = event.target as HTMLElement
    const copyTextArea = document.getElementById(id) as HTMLInputElement
    try {
      await navigator.clipboard.writeText(copyTextArea.innerText)
      notification(t('notifications.copyToClipboard.success'), { variant: 'success' })
    } catch (error) {
      console.log(error)
      notification(t('notifications.copyToClipboard.error'), { variant: 'error' })
    }
    target.blur()
  }

  const BankDetailsRow = (props: RowDetailsProps) => (
    <Row>
      <StyledSecondaryText id={props.id}>{props.value}</StyledSecondaryText>
      <Tooltip title={t('bank.tooltipCopy')}>
        <IconButton
          sx={{ height: '15px', ml: '-10px', ':hover': { borderColor: theme.colors.WHITE } }}
          onClick={(event) => copyToClipboard(event, props.id)}>
          <ContentCopyRounded sx={{ height: '15px', width: '15px', color: theme.colors.GREY }} />
        </IconButton>
      </Tooltip>
    </Row>
  )

  const BankDetails = () => {
    const maskedIBAN = isAccountManager ? maskIBAN(iban).placeholderChar : maskIBAN(iban).value
    return (
      <>
        <StyledDetail>{t('resources.contract.fields.user.iban')}</StyledDetail>
        <BankDetailsRow id="copy-text-bank-iban" value={maskedIBAN} />
        <StyledDetail>{t('bank.details.bic')}</StyledDetail>
        <BankDetailsRow id="copy-text-bank-bic" value={bic} />
        <StyledDetail>{t('bank.details.institutionNameTitle')}</StyledDetail>
        <BankDetailsRow id="copy-text-bank-name" value={t('bank.details.institutionName')} />
        <StyledDetail>{t('bank.details.institutionAdressTitle')}</StyledDetail>
        <BankDetailsRow id="copy-text-bank-adress" value={t('bank.details.institutionAdress')} />
      </>
    )
  }

  const SwanStatusTypography = ({ status }: { status: SwanStatus.StatusEnum }) => {
    const color =
      status === SwanStatus.StatusEnum.Booked
        ? theme.colors.GREEN_PASTEL
        : status === SwanStatus.StatusEnum.Rejected
        ? theme.colors.RED_PASTEL
        : theme.colors.YELLOW_PASTEL
    return (
      <Box sx={{ ...swanStatusSx, backgroundColor: color }}>
        <Typography sx={{ color: theme.palette.text.primary, fontSize: '15px' }}>
          {t(`resources.contract.swanStatus.${status.toLocaleLowerCase()}`)}
        </Typography>
      </Box>
    )
  }

  const BankSupplies = ({ transaction }: { transaction: SwanTransaction }) => (
    <>
      <StyledSecondaryText sx={{ color: theme.colors.GREY, fontSize: '12px' }}>
        {new Date(transaction.executionDate).toLocaleDateString()}
      </StyledSecondaryText>
      <Grid container sx={{ ml: '20px' }}>
        <Grid item xs={6}>
          <Typography sx={{ padding: '10px 20px 0px' }}>{transaction.reference}</Typography>
        </Grid>
        <Grid item xs={3}>
          <SwanStatusTypography status={transaction.statusInfo.status} />
        </Grid>
        <Grid item xs={3}>
          <Typography sx={{ padding: '10px 20px 0px' }}>
            {Number(transaction.amount.value).toLocaleString(locale, { maximumFractionDigits: 0 }) +
              ' €'}
          </Typography>
        </Grid>
        <Divider orientation="horizontal" flexItem sx={dividerSx} />
      </Grid>
    </>
  )

  const PDFContent: React.ReactElement =
    !iban || !bic ? (
      <></>
    ) : (
      <Column>
        <Typography variant="h5" fontWeight={700}>
          {t('bank.pdfContent.title')}
        </Typography>
        <Spacer y={3} />
        <Row>
          <Typography>{t('bank.pdfContent.downloadedBy')}</Typography>
          <Typography>&nbsp;</Typography>
          <Typography fontWeight={700}>{user.name}</Typography>
        </Row>
        <Row>
          <Typography>{t('bank.pdfContent.downloadedOn')} </Typography>
          <Typography>&nbsp;</Typography>
          <Typography>{new Date().toLocaleDateString(locale)}</Typography>
          <Typography>&nbsp;</Typography>
          <Typography>{t('bank.pdfContent.downloadedAt')}</Typography>
          <Typography>&nbsp;</Typography>
          <Typography>{new Date().toLocaleTimeString(locale)}</Typography>
        </Row>
        <Spacer y={5} />
        <Typography fontWeight={700}>{t('bank.pdfContent.institution')}</Typography>
        <Typography>{t('bank.details.institutionName')}</Typography>
        <Typography>{t('bank.details.institutionAdress')}</Typography>
        <Spacer y={5} />
        <Row sx={{ display: 'flex', justifyContent: 'space-between', width: '40%' }}>
          <Typography fontWeight={700}>{t('resources.contract.fields.user.iban')}</Typography>
          <Typography fontWeight={700}>{t('bank.details.bic')}</Typography>
        </Row>
        <Spacer y={1} />
        <Divider orientation="horizontal" flexItem sx={{ mr: 10, borderBottomWidth: '0.5px' }} />
        <Spacer y={1} />
        <Row>
          <Typography>{maskIBAN(iban).value}</Typography>
          <Spacer x={12} />
          <Typography>{bic}</Typography>
        </Row>
        <Spacer y={3} />
        <Row style={{ display: 'flex', justifyContent: 'space-between', width: '75%' }}>
          <Typography fontWeight={700}>{t('bank.pdfContent.bankCode')}</Typography>
          <Typography fontWeight={700}>{t('bank.pdfContent.branchCode')}</Typography>
          <Typography fontWeight={700}>{t('bank.pdfContent.accountNumber')}</Typography>
          <Typography fontWeight={700}>{t('bank.pdfContent.RIBKey')}</Typography>
        </Row>
        <Spacer y={1} />
        <Divider orientation="horizontal" flexItem sx={{ mr: 10, borderBottomWidth: '0.5px' }} />
        <Spacer y={1} />
        <Row>
          <Typography>{iban.substring(4, 9)}</Typography>
          <Spacer x={22} />
          <Typography>{iban.substring(9, 14)}</Typography>
          <Spacer x={21} />
          <Typography>{iban.substring(14, 25)}</Typography>
          <Spacer x={20} />
          <Typography>{iban.substring(25, 27)}</Typography>
        </Row>
      </Column>
    )

  return (
    <>
      <DownloadStatementsDialog />
      <Row sx={rowSx}>
        <KpiContainer
          title={t('dashboard.balance')}
          color="primary"
          value={
            (balance ? Number(balance).toLocaleString(locale, { maximumFractionDigits: 0 }) : '0') +
            ' €'
          }
          icon={<AccountBalanceRounded />}
          loading={false}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={downloadStatements}
          disabled={isAccountManager}
          sx={buttonSx}>
          {t('bank.downloadStatements')}
        </Button>
      </Row>
      <Row>
        <Column sx={{ width: '50%' }}>
          <Paper sx={{ height: '130px' }}>
            <StyledTitle variant="h2">{t('bank.fund.title')}</StyledTitle>
            <Spacer y={2} />
            <StyledSecondaryText variant="body1" sx={{ color: theme.colors.GREY, mt: '-10px' }}>
              {t('bank.fund.helperText')}
            </StyledSecondaryText>
          </Paper>
          <Spacer y={2} />
          <Paper sx={{ height: '400px' }}>
            <Row>
              <StyledTitle variant="h2" sx={{ fontWeight: 500 }}>
                {t('bank.details.title')}
              </StyledTitle>
              <Button
                variant="outlined"
                color="secondary"
                onClick={downloadBankDetails}
                disabled={isAccountManager}
                sx={downloadDetailsButtonSx}>
                {t('bank.downloadDetails')}
              </Button>
            </Row>
            <Spacer y={2} />
            <StyledSecondaryText variant="body1" sx={{ color: theme.colors.GREY, mt: '-10px' }}>
              {t('bank.details.helperText')}
            </StyledSecondaryText>
            <BankDetails />
            <Spacer y={2} />
            <Row sx={{ display: 'flex', justifyContent: 'center' }}>
              <LogoSVG width={40} />
              <Spacer x={0.5} />
              <StyledSecondaryText variant="h5" sx={{ p: 0, color: theme.colors.GREY }}>
                {t('bank.details.footer')}
              </StyledSecondaryText>
            </Row>
          </Paper>
        </Column>
        <Spacer x={2} />
        <Column sx={{ width: '50%', maxHeight: '600px' }}>
          <Paper sx={{ height: '550px', overflowY: 'auto' }}>
            <StyledTitle
              variant="h2"
              sx={{ ...bankSuppliesTitleSx, backgroundColor: theme.colors.WHITE }}>
              {t('bank.supplies.title')}
            </StyledTitle>
            <Spacer y={9} />
            {transactions && transactions.length ? (
              transactions.map((transaction) => (
                <BankSupplies key={transaction.id} transaction={transaction} />
              ))
            ) : (
              <StyledSecondaryText variant="h3" sx={{ color: theme.colors.GREY }}>
                {t('bank.supplies.helperText')}
              </StyledSecondaryText>
            )}
          </Paper>
        </Column>
      </Row>
    </>
  )
}

const StyledTitle = styled(Typography)(() => ({
  padding: '20px 20px 0px',
  fontWeight: 500,
}))
const StyledDetail = styled(Typography)(({ theme }) => ({
  padding: '15px 20px 0px',
  fontWeight: 400,
  color: theme.palette.primary.main,
}))
const StyledSecondaryText = styled(Typography)(() => ({
  padding: '0px 20px 0px',
  fontWeight: 300,
}))

const rowSx: SxProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  height: '100px',
}
const buttonSx: SxProps = {
  fontWeight: 400,
}
const dividerSx: SxProps = {
  mt: '15px',
  ml: '20px',
  mb: '20px',
  width: '500px',
}
const swanStatusSx: SxProps = {
  mt: '10px',
  width: '70px',
  height: '25px',
  textAlign: 'center',
  borderRadius: '3px',
}
const downloadDetailsButtonSx: SxProps = {
  fontWeight: 400,
  minHeight: '5px',
  fontSize: '12px',
  height: '25px',
  mt: '15px',
}
const bankSuppliesTitleSx: SxProps = {
  position: 'fixed',
  borderRadius: 5,
  width: '35%',
  pb: 3,
}
