import { CloseRounded, EditRounded, CheckRounded } from '@mui/icons-material'
import {
  IconButton,
  Box,
  Table,
  TableHead,
  TableBody as MuiTableBody,
  TableCell,
  TableContainer,
  TableRow,
  SxProps,
  Stack,
  Tooltip,
  useTheme,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { useGetList, useTranslate } from 'react-admin'

import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { ContractRecord, ROLES_WITHOUT_NESSPAY_ROLES_AND_EMPLOYEE, RoleType } from 'resources/types'
import { useRoleTranslation } from 'utils/user'

import { ConfirmRemoveRoleDialog } from './RemoveRoleDialog'
import { UpdateRolesDialog } from './UpdateRolesDialog'

interface Props {
  role: RoleType | undefined
}

export const RoleManagementTable = (props: Props) => {
  const t = useTranslate()
  const theme = useTheme()
  const notification = useNotifications()
  const { tRoleMap, tRolePermissions } = useRoleTranslation()

  const { role } = props
  const { data: records } = useGetList<ContractRecord>('contract', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'user.firstName', order: 'ASC' },
    filter: { roles: role ? [role] : ROLES_WITHOUT_NESSPAY_ROLES_AND_EMPLOYEE, active: [true] },
  })

  const { openModal: openRemoveRoleModal } = useModal('removeRole')
  const { openModal: openEditRoleModal } = useModal('editRole')

  const [hasManyRoles, setHasManyRoles] = useState<boolean>(false)
  const [idToUpdate, setIdToUpdate] = useState<string>('')

  function handleOpenRemoveRoleDialog(event: React.SyntheticEvent, id: string) {
    const record = records?.filter((record) => record.id === id)
    const target = event.target as HTMLElement
    target.blur()

    if (records && records.length <= 1 && role !== 'analyst') {
      notification(t('notifications.roleManagementTab.onlyOneAdminWarning'), { variant: 'warning' })
      return
    }
    if (record && record.length) setHasManyRoles(record[0].roles.length > 1)

    setIdToUpdate(id)
    openRemoveRoleModal()
  }

  function handleOpenEditRoleDialog(event: React.SyntheticEvent, id: string) {
    const target = event.target as HTMLElement
    setIdToUpdate(id)
    openEditRoleModal()
    target.blur()
  }

  if (!records) return <></>

  const MfaStatus = ({ status }: { status: boolean }) =>
    status ? (
      <CheckRounded sx={{ ...infoIconSx, color: theme.colors.GREEN }} />
    ) : (
      <CloseRounded sx={{ ...infoIconSx, color: theme.colors.RED }} />
    )

  const ActionButtons = ({ id }: { id: string }) => {
    const isNesspayRole = role === 'admin' || role === 'accountManager'
    return (
      <Stack direction="row" spacing={1}>
        {!isNesspayRole && (
          <Tooltip title={t('administration.roleManagementTab.tooltips.edit')} placement="left">
            <StyledIconButton onClick={(event) => handleOpenEditRoleDialog(event, id)}>
              <EditRounded sx={{ ...iconButtonSx, color: theme.colors.GREY }} />
            </StyledIconButton>
          </Tooltip>
        )}
        <Tooltip title={t('administration.roleManagementTab.tooltips.remove')} placement="right">
          <StyledIconButton onClick={(event) => handleOpenRemoveRoleDialog(event, id)}>
            <CloseRounded sx={{ ...iconButtonSx, color: theme.colors.GREY }} />
          </StyledIconButton>
        </Tooltip>
      </Stack>
    )
  }

  const TableHeader = () => (
    <StyledTableHead>
      <TableRow>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('administration.roleManagementTab.account')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('resources.contract.fields.user.email')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('resources.contract.fields.roles')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('administration.roleManagementTab.permissions.header')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('resources.user.fields.mfaEnabled')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none', textAlign: 'center' }}>
          {t('administration.roleManagementTab.actions')}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  )

  const TableBody = () => (
    <MuiTableBody>
      {records.map((record) => (
        <TableRow key={record.id}>
          <TableCell scope="row" sx={tableCellSx}>
            {record.user.name}
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            {record.user.email}
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            {tRoleMap(record.roles)}
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            {tRolePermissions(record.roles)}
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            <MfaStatus status={record.user.mfaEnabled} />
          </TableCell>
          <TableCell scope="row" sx={tableCellSx}>
            <ActionButtons id={record.id} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  )

  return (
    <>
      <TableContainer component={Box} sx={{ height: '420px', width: '900px' }}>
        <Table stickyHeader={true}>
          <TableHeader />
          <TableBody />
        </Table>
      </TableContainer>
      <ConfirmRemoveRoleDialog
        roleToRemove={role}
        contractId={idToUpdate}
        hasManyRoles={hasManyRoles}
        shouldDeactivateContract={!hasManyRoles}
      />
      <UpdateRolesDialog record={records?.filter((record) => record.id === idToUpdate)} />
    </>
  )
}

const infoIconSx: SxProps = { width: '20px', height: '20px' }
const iconButtonSx: SxProps = { width: '15px', height: '15px' }
const tableCellSx: SxProps = {
  borderBottom: 0,
  fontSize: '12px',
  pt: '5px',
  pb: '5px',
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.text.secondary,
    border: 'none',
  },
}))
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.colors.GREY,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
  },
}))
