import isPlainObject from 'lodash/isPlainObject'
import { useCallback, useState } from 'react'

import { storage, StorageKey } from 'user/storage'

import { useDeepMemo } from './useDeepHooks'

export function useStateWithStorage<T = Record<string, any>>(
  storageKey: StorageKey,
  defaultValue: T
): [T, (state: Partial<T>) => void] {
  const [state, _setState] = useState<T>(storage.getObject<T>(storageKey) || defaultValue)

  const setState = useCallback(
    (state: Partial<T>) => {
      _setState((prevState) => {
        let newState = state as T
        if (isPlainObject(state) && isPlainObject(prevState)) {
          newState = { ...prevState, ...state }
        }
        storage.save(storageKey, newState)
        return newState
      })
    },
    [storageKey]
  )

  return useDeepMemo(() => [state, setState], [state, setState])
}

export function useArrayStateWithStorage<T>(
  storageKey: StorageKey,
  defaultValue: Array<T>
): [Array<T>, (state: Array<T>) => void] {
  const [state, _setState] = useState<Array<T>>(
    storage.getObject<Array<T>>(storageKey) || defaultValue
  )

  const setState = useCallback(
    (newState: Array<T>) => {
      storage.save(storageKey, newState)
      _setState(newState)
    },
    [storageKey]
  )

  return useDeepMemo(() => [state, setState], [state, setState])
}
