import {
  FormControl,
  FormControlProps,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'
import { useLocaleState, useTranslate } from 'react-admin'

type Props = FormControlProps

export const LocalSelector: React.FC<Props> = (props) => {
  const t = useTranslate()
  const [locale, setLocale] = useLocaleState()

  const handleChange = (event: SelectChangeEvent) => {
    setLocale(event.target.value)
  }

  return (
    <FormControl {...props} sx={{ width: '200px', ...props.sx }}>
      <InputLabel>{t('language.name')}</InputLabel>
      <Select value={locale} label={t('language.name')} onChange={handleChange}>
        <MenuItem value="fr">Français</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Select>
    </FormControl>
  )
}
