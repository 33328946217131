import { BusinessCenterRounded } from '@mui/icons-material'
import {
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  SvgIcon,
  SxProps,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useGetList, useRefresh, useSidebarState } from 'react-admin'

import { useApi } from 'api'
import { useAuthContext } from 'libs/auth/provider'
import { CompanyRecord } from 'resources/types'
import { usePersistedUser } from 'user/context'
import { Row, Spacer } from 'utils/spacing'
import { useDeepCallback } from 'utils/useDeepHooks'

export const TenantSelector = () => {
  const [isSidebarOpen] = useSidebarState()
  const { organization } = usePersistedUser()
  const { setAuth } = useAuthContext()
  const api = useApi()
  const refresh = useRefresh()

  const { data: organizations = [organization], refetch } =
    useGetList<CompanyRecord>('organization')

  const userHasOnlyOneTenant = organizations.length === 1

  useEffect(() => {
    if (isSidebarOpen) refetch()
  }, [isSidebarOpen])

  const onChange = useDeepCallback(
    async (event: SelectChangeEvent<string>) => {
      const result = await api.authControllerChangeOrganization({
        organizationId: event.target.value,
      })
      setAuth({ accessToken: result.token })
      window.location.reload()
    },
    [refresh]
  )

  const renderSelectedOrganization = useDeepCallback(
    (organizationId) => {
      if (!isSidebarOpen) return <StyledSvgIcon component={BusinessCenterRounded} />
      const selectedOrganization = organizations.find(({ id }) => id === organizationId)
      if (!selectedOrganization) return null
      return (
        <Row>
          <StyledSvgIcon component={BusinessCenterRounded} />
          <Spacer x={3} />
          <Typography>{selectedOrganization.name}</Typography>
        </Row>
      )
    },
    [organizations, isSidebarOpen, userHasOnlyOneTenant]
  )

  const renderSelectOptions = useDeepCallback(() => {
    const sortedOrganizations = organizations.sort((a, b) => a.name.localeCompare(b.name))
    return sortedOrganizations.map((org) => (
      <MenuItem key={org.id} value={org.id}>
        {org.name}
      </MenuItem>
    ))
  }, [organizations])

  return (
    <Select
      value={organization?.id}
      renderValue={renderSelectedOrganization}
      onChange={onChange}
      sx={selectSx}
      IconComponent={userHasOnlyOneTenant || !isSidebarOpen ? () => null : undefined}
      input={<StyledInputBase readOnly={userHasOnlyOneTenant} />}>
      {renderSelectOptions()}
    </Select>
  )
}

const selectSx: SxProps = {
  width: '100%',
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    border: 0,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 5,
    paddingRight: 32,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      boxShadow: `0 0 0 0.2rem rgba(255,255,255,.25)`,
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.secondary,
    right: 12,
  },
}))

const StyledSvgIcon = styled(SvgIcon)<{ component: React.ReactNode }>(({ theme }) => ({
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 50,
  padding: 6,
}))
