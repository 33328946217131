import isNumber from 'lodash/isNumber'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslate } from 'react-admin'

import './ProgressBar.css'

type Props = {
  variant: 'UserEdit' | 'Onboarding'
  size: number
  total?: number
  progress: number
  strokeWidth: number
  circleOneStroke: string
  circleTwoStroke: string
}

export const ProgressBar = (props: Props) => {
  const t = useTranslate()
  const [offset, setOffset] = useState(0)
  const circleRef: any = useRef(null)
  const { variant, size, total, progress, strokeWidth, circleOneStroke, circleTwoStroke } = props

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    if (isNumber(progress)) {
      const steps = total ?? 100
      const progressOffset = ((steps - progress) / steps) * circumference
      setOffset(progressOffset)
    }
    if (!circleRef.current) return
    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out'
  }, [setOffset, progress, circumference, offset, total])

  if (variant === 'UserEdit') {
    return (
      <>
        <svg className="svg" width={size} height={size}>
          <circle
            className="svg-circle"
            stroke={circleOneStroke}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
          />
          <circle
            className="svg-circle"
            ref={circleRef}
            stroke={circleTwoStroke}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
          />
          <text className="svg-circle-text-primary" x={`${center}`} y={`${center - 5}`}>
            {progress}€
          </text>
          <text className="svg-circle-text-secondary" x={`${center}`} y={`${center + 20}`}>
            {t('resources.user.withdrawn')} {total}€
          </text>
        </svg>
      </>
    )
  } else {
    return (
      <>
        <svg className="svg" width={size} height={size}>
          <circle
            className="svg-circle-bg"
            stroke="#e6e6e6"
            fill="none"
            cx={center}
            cy={center}
            r={radius}
          />
          <circle
            className="svg-circle-progress"
            ref={circleRef}
            stroke={circleTwoStroke}
            fill="none"
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
          />
          <text className="svg-circle-text-primary-2" x={`${center}`} y={`${center - 15}`}>
            {t('bank.onboarding.circleProgress')}
          </text>
          <text className="svg-circle-text-secondary-2" x={`${center}`} y={`${center + 35}`}>
            {progress}%
          </text>
        </svg>
      </>
    )
  }
}
