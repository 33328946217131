import mixpanel from 'mixpanel-browser'

import { config } from 'config'

mixpanel.init(config.MIXPANEL_TOKEN, { opt_out_tracking_by_default: true })

export interface Analytics {
  identify: (userId: string) => void
  enable: () => void
  disable: () => void
  /** Set properties sent with all events.
   * Be careful : every call will override previous values. */
  setEventPersistedProperties: (properties?: Record<string, any>) => void
  /* Be careful : every call will override previous values. */
  setProfilePersistedProperties: (properties: Partial<AnalyticsProfileProperties>) => void
  log: (name: string, params?: { [key: string]: any }) => void
}

export type AnalyticsProfileProperties = {
  $created: string
  $distinct_id: string
  $email: string
  $name: string
  organizationName: string
}

export const analytics: Analytics = {
  enable: async () => {
    mixpanel.opt_in_tracking()
  },
  identify: (userId: string) => {
    mixpanel.identify(userId)
  },
  setEventPersistedProperties: async (properties: Record<string, any> = {}) => {
    mixpanel.register(properties)
  },
  disable: async () => {
    mixpanel.opt_out_tracking()
  },
  log: async (name: string, params?: { [key: string]: any }) => {
    mixpanel.track(name, params)
  },
  setProfilePersistedProperties: (properties: Partial<AnalyticsProfileProperties>) => {
    mixpanel.people.set(properties)
  },
}
