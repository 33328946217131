import { LoadingButton } from '@mui/lab'
import { TextField, FormControlLabel, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery } from 'react-query'

import { useApi } from 'api'
import { OrganizationRequestDto } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { useCanAccess, CanAccess } from 'libs/auth'
import { VerticalDivider } from 'libs/components/Divider'
import { StyledSwitch } from 'libs/components/StyledSwitch'
import { useNotifications } from 'libs/notifications'
import { NESSPAY_ONLY_ROLES, ROLES_WITH_WRITE_PERMISSION } from 'resources/types'
import { usePersistedUser } from 'user/context'
import { maskIBAN } from 'utils/format'
import { Row, Column, Spacer } from 'utils/spacing'

import { CompanyTabAdminForm } from './CompanyTabAdminForm'

export function AdminTabCompanyView() {
  const api = useApi()
  const t = useTranslate()
  const notification = useNotifications()
  const { canAccess } = useCanAccess()
  const canWrite = canAccess({ roles: ROLES_WITH_WRITE_PERMISSION })
  const isAccountManager = canAccess({ roles: ['accountManager'] })
  const { organization, setOrganization } = usePersistedUser()

  const [errors, setErrors] = useState({
    bufferDays: false,
  })

  const [canEmployeeRequest, setCanEmployeeRequest] = useState<boolean>(true)
  const [bufferDays, setBufferDays] = useState<number>(0)
  const [payPeriodStart, setPayPeriodStart] = useState<number>(0)

  const { data: swanAccount } = useQuery(
    QueryKey.SwanAccount,
    () => api.swanControllerGetAccount(),
    { onError: console.error }
  )
  const iban = swanAccount?.account?.IBAN || ''
  const bic = swanAccount?.account?.BIC || ''

  useEffect(() => {
    setErrors({ bufferDays: false })
    setBufferDays(organization.bufferDays)
    setPayPeriodStart(organization.payPeriodStart)
    setCanEmployeeRequest(organization.canEmployeeRequest)
  }, [organization])

  const disableSubmitButton = () => {
    if (!organization) return true
    if (errors.bufferDays) return true
    if (
      organization.canEmployeeRequest === canEmployeeRequest &&
      organization.bufferDays === bufferDays
    )
      return true
    return false
  }

  const handleBufferDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event?.target?.value)
    if (value < 0 || value > 15) setErrors((prevErrors) => ({ ...prevErrors, bufferDays: true }))
    else setErrors((prevErrors) => ({ ...prevErrors, bufferDays: false }))
    setBufferDays(value)
  }

  const updateOrganizationMutation = useMutation(
    async () => {
      return await api.organizationControllerUpdateOne({
        canEmployeeRequest,
        bufferDays,
        payPeriodStart,
      } as OrganizationRequestDto)
    },
    {
      onSuccess: () => {
        notification(t('notifications.organizationEdit.success'), { variant: 'success' })
        setOrganization({ canEmployeeRequest, bufferDays, payPeriodStart })
      },
      onError: (error) => {
        console.log(error)
        notification(t('notifications.organizationEdit.error'), { variant: 'error' })
      },
    }
  )

  const CompanyTabEmployerForm = () => (
    <Column sx={{ width: '450px' }}>
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <StyledSwitch
            color="primary"
            checked={canEmployeeRequest}
            onChange={() => setCanEmployeeRequest((prevValue) => !prevValue)}
          />
        }
        label={
          <Typography variant="body1" marginLeft={1}>
            {t('resources.organization.fields.canEmployeeRequest')}
          </Typography>
        }
        disabled={!canWrite}
      />
      <Spacer y={3} />
      <TextField
        label={t('resources.organization.fields.bufferDays')}
        type="number"
        value={bufferDays}
        onChange={handleBufferDaysChange}
        helperText={t('resources.organization.helperTexts.bufferDays')}
        error={errors.bufferDays}
        disabled={!canWrite}
        sx={{ width: '100%' }}
      />
      <Spacer y={3} />
      <TextField
        label={t('resources.organization.fields.payPeriodStart')}
        type="number"
        value={payPeriodStart}
        helperText={t('resources.organization.helperTexts.payPeriodStart')}
        sx={{ width: '100%' }}
        disabled
      />
      <Spacer y={3} />
      <Row>
        <TextField
          label={t('administration.companyTab.bankName')}
          value={'SWAN'}
          sx={{ width: '50%' }}
          disabled
        />
        <Spacer x={2} />
        <TextField
          label={t('administration.companyTab.bic')}
          value={bic}
          sx={{ width: '50%' }}
          disabled
        />
      </Row>
      <Spacer y={2} />
      <TextField
        label={t('resources.user.fields.iban')}
        value={maskIBAN(iban).value}
        type={isAccountManager ? 'password' : 'text'}
        helperText={t('resources.organization.helperTexts.iban')}
        sx={{ width: '100%' }}
        disabled
      />
      <Spacer y={3} />
      <LoadingButton
        variant="contained"
        loading={updateOrganizationMutation.isLoading}
        onClick={() => updateOrganizationMutation.mutate()}
        disabled={disableSubmitButton()}
        sx={{ width: '100%' }}>
        {t('buttons.save')}
      </LoadingButton>
    </Column>
  )

  return (
    <Row>
      <CompanyTabEmployerForm />
      <CanAccess roles={NESSPAY_ONLY_ROLES}>
        <Spacer x={4} />
        <VerticalDivider />
        <Spacer x={2} />
        <CompanyTabAdminForm />
      </CanAccess>
    </Row>
  )
}
