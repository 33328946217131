import React, { FC, useCallback, useMemo } from 'react'

import { Contract } from 'api/gen'
import { RoleType } from 'resources/types'
import { usePersistedUser } from 'user/context'

export interface AccessControlParams {
  roles?: RoleType[]
  permissions?: string[]
}

export const CanAccess: FC<AccessControlParams> = (props) => {
  const { canAccess } = useCanAccess()
  const { roles, permissions, children } = props
  if (canAccess({ roles, permissions })) {
    return <>{children}</>
  }
  return null
}

export function useCanAccess() {
  const { contract } = usePersistedUser()
  const canAccess = useCallback(
    ({ roles = [] }: AccessControlParams) => {
      return contractHasRoles(contract, ...roles)
    },
    [contract]
  )
  return useMemo(() => ({ canAccess }), [canAccess])
}

export function contractHasRoles(contract: Partial<Contract>, ...roles: RoleType[]) {
  if (!contract || !contract.roles) return false
  if (!roles) return true
  for (const role of contract.roles) {
    if (roles.includes(role)) return true
  }
  return false
}
