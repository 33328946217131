export type StorageKey =
  | '@User'
  | '@Contract'
  | '@Auth'
  | '@Organization'
  | '@HasEntityNames'
  | 'raColumnsConfig'
  | '@SwanSecurityState'

export const storage = {
  getString(key: StorageKey, defaultValue?: string): string | null {
    try {
      const item = localStorage.getItem(key)
      if (item) return item
      if (defaultValue) return defaultValue
      return null
    } catch (error) {
      console.log(error)
      return null
    }
  },
  getObject<T>(key: StorageKey, defaultValue?: T): T | null {
    try {
      const item = localStorage.getItem(key)
      if (item) return JSON.parse(item)
      if (defaultValue) return defaultValue
      return null
    } catch (error) {
      console.log(error)
      return null
    }
  },
  save(key: StorageKey, value?: string | object | boolean | number | null | Record<string, any>) {
    try {
      if (value == undefined || value == null) {
        return localStorage.removeItem(key)
      }
      if (typeof value === 'string') {
        return localStorage.setItem(key, value)
      }
      return localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(error)
    }
  },
  remove(key: StorageKey) {
    try {
      return localStorage.removeItem(key)
    } catch (error) {
      console.log(error)
    }
  },
}
