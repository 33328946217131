import polyglotI18nProvider from 'ra-i18n-polyglot'
import { LocalesMenuButtonProps, TranslationMessages } from 'react-admin'

import { englishMessages } from './messagesEN'
import { frenchMessages } from './messagesFR'

export type Locale = 'fr' | 'en'

const DEFAULT_LOCALE: Locale = 'fr'

function getMessages(locale: Locale | string): TranslationMessages {
  if (locale === 'fr') return frenchMessages
  return englishMessages
}

export const i18nProvider = polyglotI18nProvider(getMessages, DEFAULT_LOCALE)

export const languages: LocalesMenuButtonProps['languages'] = [
  { locale: 'fr', name: 'Français' },
  { locale: 'en', name: 'English' },
]
