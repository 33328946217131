import { isEmail } from 'class-validator'
import isNumber from 'lodash/isNumber'
import { useMemo } from 'react'
import { Translate, useTranslate } from 'react-admin'

import { isValidFrenchDate, isValidISODate } from './date'
import { isValidIban } from './iban'
import { isValidFrenchPhone } from './phone'
import { isValidSiren } from './siren'

export function useFormValidation() {
  const t = useTranslate()
  return useMemo(
    () => ({
      combine,
      required: required.bind(null, t),
      email: email.bind(null, t),
      date: date.bind(null, t),
      iban: iban.bind(null, t),
      siren: siren.bind(null, t),
      frenchPhone: frenchPhone.bind(null, t),
      netSalary: netSalary.bind(null, t),
      salaryFraction: salaryFraction.bind(null, t),
      salaryRefund: salaryRefund.bind(null, t),
      maxLength: maxLength.bind(null, t),
      contractStartDate: contractStartDate.bind(null, t),
      contractEndDate: contractEndDate.bind(null, t),
    }),
    [t]
  )
}

type Validator = (value: any) => string | undefined

function combine(value: any, ...validators: Validator[]): string | undefined {
  for (const validator of validators) {
    const result = validator(value)
    if (result) return result
  }
  return undefined
}

function required(t: Translate, value: any) {
  if (!value && !isNumber(value)) return t('error.required')
  return undefined
}

function date(t: Translate, value: string) {
  if (!value) return undefined
  if (!isValidFrenchDate(value) && !isValidISODate(value)) return t('error.invalidDate')
}

function email(t: Translate, value: string) {
  if (!value) return undefined
  if (!isEmail(value)) return t('error.invalidEmail')
  return undefined
}

function maxLength(t: Translate, value: string) {
  if (!value) return undefined
  if (value.length > 255) return t('error.lengthExceeded')
  return undefined
}

function frenchPhone(t: Translate, value: string) {
  if (!value) return undefined
  if (!isValidFrenchPhone(value)) return t('error.invalidPhone')
  return undefined
}

function iban(t: Translate, value: string) {
  if (!value) return undefined
  if (!isValidIban(value)) return t('error.invalidIban')
  return undefined
}

function netSalary(t: Translate, value: number) {
  if (value < 0 || value > 10000) return t('error.invalidNetSalary')
  return undefined
}

function salaryFraction(t: Translate, value: number) {
  if (value < 0 || value > 100) return t('error.invalidSalaryFraction.outOfRange')
  if (value % 1 !== 0) return t('error.invalidSalaryFraction.decimalNumber')
  return undefined
}

function salaryRefund(t: Translate, value: number) {
  if (value < 0) return t('error.invalidSalaryRefund')
  return undefined
}

function contractStartDate(t: Translate, startDate: Date, endDate: Date) {
  if (startDate > endDate) return t('error.invalidStartDate')
  return undefined
}

function contractEndDate(t: Translate, startDate: Date, endDate: Date) {
  if (endDate < startDate) return t('error.invalidEndDate1')
  if (endDate < new Date()) return t('error.invalidEndDate2')
  return undefined
}

function siren(t: Translate, value: string) {
  if (!value) return undefined
  if (!isValidSiren(value)) return t('error.invalidSiren')
  return undefined
}
