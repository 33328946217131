import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { ROLES_WITHOUT_EMPLOYEE } from 'resources/types'
import { usePersistedUser } from 'user/context'

import { contractHasRoles } from './access'
import { useAuthContext } from './provider'

export function useGetMe() {
  const navigate = useNavigate()
  const { auth } = useAuthContext()
  const { organization, setUser, setOrganization, setContract, setHasEntityNames } =
    usePersistedUser()
  const api = useApi()

  useQuery(
    QueryKey.Me,
    async () => {
      const { user, ...contract } = await api.meControllerGet()
      setOrganization(contract.organization)
      setUser(user)
      setContract(contract)
      if (!contractHasRoles(contract, ...ROLES_WITHOUT_EMPLOYEE)) {
        navigate('/unauthorized')
      }
    },
    {
      enabled: Boolean(auth.accessToken),
      onError: (error: any) => {
        if (error.response) console.error(error.response)
        else console.error(error)
      },
    }
  )
  useQuery(
    QueryKey.EntityNames,
    async () => {
      const entityNames = await api.contractControllerGetEntityNames(organization.id)
      setHasEntityNames(Boolean(entityNames.length))
    },
    {
      onError: () => console.error,
      enabled: Boolean(organization),
    }
  )
}
