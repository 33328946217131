import { QuestionMarkRounded } from '@mui/icons-material'
import { styled, SxProps, Button, SvgIcon, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslate } from 'react-admin'

import { ImportUsersContext, ValidationSteps } from 'libs/import/context'
import { Column, Spacer, Row } from 'utils/spacing'

export const ImportFlushData = () => {
  const t = useTranslate()
  const { setCurrentStep, setValidationStep, flushContext } = useContext(ImportUsersContext)

  function handleContinueImport() {
    setValidationStep(ValidationSteps.COLUMNS_MATCHING)
    setCurrentStep(1)
  }

  function handleResetImport() {
    flushContext()
  }

  return (
    <Column sx={columnSx}>
      <Spacer y={10} />
      <StyledSquare>
        <StyledCircle>
          <SvgIcon sx={{ width: '40px', height: '50px' }}>
            <QuestionMarkRounded />
          </SvgIcon>
        </StyledCircle>
        <Spacer y={5} />
        <StyledTypography variant="h2" sx={{ fontWeight: 500 }}>
          {t('resources.contract.helperTexts.importTab.flushImportTitle')}
        </StyledTypography>
        <Spacer y={3} />
        <Row>
          <Button variant="outlined" color="secondary" onClick={handleResetImport} sx={buttonSx}>
            {t('resources.contract.helperTexts.importTab.flushImportConfirm')}
          </Button>
          <Spacer x={4} />
          <Button variant="contained" color="primary" onClick={handleContinueImport} sx={buttonSx}>
            {t('resources.contract.helperTexts.importTab.flushImportContinue')}
          </Button>
        </Row>
      </StyledSquare>
    </Column>
  )
}

const buttonSx: SxProps = {
  width: '200px',
  height: '55px',
  fontWeight: 400,
  padding: 3,
}
const columnSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
}

const StyledSquare = styled(Column)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '650px',
  height: '390px',
  border: '2px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '10px',
}))

const StyledCircle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  border: '1px solid',
  borderRadius: '50px',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  height: '50px',
  width: '400px',
  textAlign: 'center',
  fontSize: '16px',
  color: theme.palette.secondary.main,
}))
