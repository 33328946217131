import { Close } from '@mui/icons-material'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid,
} from '@mui/material'
import React from 'react'
import { FieldTitle, useTranslate } from 'react-admin'

interface Props {
  columns: any
  selection: object
  onClose: () => any
  resource: string
  onColumnClicked: (columnName: string) => any
}

const defaultProps: Props = {
  columns: [] || {},
  selection: {},
  onClose: function () {
    return
  },
  resource: '',
  onColumnClicked: function () {
    return
  },
}

export function SelectColumnModal(props: Props) {
  const t = useTranslate()
  const { columns, selection, onClose, resource } = props

  const onColumnClicked = ({ target: { value: columnName } }: { target: { value: any } }) => {
    props.onColumnClicked(columnName)
  }

  return (
    <Dialog maxWidth="xs" fullWidth onClose={onClose} open>
      <Grid container>
        <Grid item xs={6}>
          <DialogTitle>{t('filters.columnsModal')}</DialogTitle>
        </Grid>
        <Grid item xs={6}>
          <DialogActions>
            <Button onClick={props.onClose} color="primary">
              <Close />
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
      <DialogContent>
        <FormGroup>
          {columns.map(({ source, label }: { source: string; label: string }) => (
            <FormControlLabel
              key={source}
              control={
                <Checkbox
                  checked={!!selection[source as keyof typeof selection]}
                  onChange={onColumnClicked}
                  value={source}
                  sx={{ ml: 5 }}
                />
              }
              label={<FieldTitle label={label} source={source} resource={resource} />}
            />
          ))}
        </FormGroup>
      </DialogContent>
    </Dialog>
  )
}

SelectColumnModal.defaultProps = defaultProps
