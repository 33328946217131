import { CloseRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  IconButton,
  useTheme,
  SxProps,
  DialogTitle,
  DialogContent,
  Typography,
  Dialog,
  TextField,
} from '@mui/material'
import React, { useState, FC } from 'react'
import { useTranslate, useRefresh } from 'react-admin'

import { useApi } from 'api'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { useFormValidation } from 'libs/validation/form'
import { Column, Spacer } from 'utils/spacing'

interface Props {
  id: string
}

export const RefundSalaryDialog: FC<Props> = (props) => {
  const api = useApi()
  const t = useTranslate()
  const refresh = useRefresh()
  const theme = useTheme()
  const notification = useNotifications()
  const validation = useFormValidation()

  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const { isModalOpen, closeModal } = useModal('refundSalary')
  const [refundSalary, setRefundSalary] = useState<number>(0)

  async function addSalaryRefund() {
    setLoading(true)
    await api.contractControllerAddRefund({ amount: refundSalary }, props.id)
    notification(t('notifications.userEdit.refundModal.success'), {
      variant: 'success',
    })
    refresh()
    closeModal()
    setLoading(false)
  }

  function handleChange(event: React.FormEvent<HTMLElement>) {
    const target = event.target as HTMLInputElement
    setRefundSalary(parseInt(target.value))
    setError(validation.salaryRefund(parseInt(target.value)))
  }

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle>
        <Spacer y={3} />
        <Typography color="secondary" variant="h2" sx={dialogTitleSx}>
          {t('refundModal.title')}
        </Typography>
        <IconButton
          onClick={closeModal}
          color="secondary"
          sx={{ ...closeButtonSx, ':hover': { borderColor: theme.palette.text.secondary } }}>
          <CloseRounded sx={{ width: '30px', height: '30px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Column sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{ color: theme.colors.GREY, fontSize: '16px', textAlign: 'center', p: '0px 60px' }}>
            {t('refundModal.subtitle')}
          </Typography>
          <Spacer y={3} />
          <TextField
            type="number"
            helperText={error}
            label={t('resources.contract.fields.refundSalary')}
            onChange={handleChange}
            sx={textFieldSx}
            FormHelperTextProps={{
              sx: { ml: '-60px', height: '0px', width: '300px', color: theme.colors.RED },
            }}
          />
          <Spacer y={4} />
          <LoadingButton
            loading={loading}
            disabled={loading || Boolean(error)}
            onClick={addSalaryRefund}
            color="primary"
            variant="contained"
            sx={{ width: '150px' }}>
            {t('buttons.create')}
          </LoadingButton>
        </Column>
      </DialogContent>
    </Dialog>
  )
}

const textFieldSx: SxProps = {
  width: '120px',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
}
const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '520px',
      height: '100%',
      maxHeight: '300px',
    },
  },
}
const dialogTitleSx: SxProps = {
  textAlign: 'center',
  fontWeight: 500,
}
const closeButtonSx: SxProps = {
  position: 'absolute',
  right: 20,
  top: 13,
}
