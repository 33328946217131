import { Box, Step, StepLabel, Stepper, styled } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

const TRANSLATION_KEY = 'resources.contract.helperTexts.importTab.steps.'

interface Props {
  activeStep: number
}

export function HorizontalStepper(props: Props) {
  const t = useTranslate()
  const { activeStep } = props
  const steps = [t(TRANSLATION_KEY + '0'), t(TRANSLATION_KEY + '1'), t(TRANSLATION_KEY + '2')]

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          return (
            <StyledStep key={label}>
              <StepLabel>{label}</StepLabel>
            </StyledStep>
          )
        })}
      </Stepper>
    </Box>
  )
}

const StyledStep = styled(Step)(({ theme }) => ({
  '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel': {
    color: theme.palette.secondary.main,
  },
}))
