import { FormControlLabel, FormGroup, FormHelperText, InputLabel, useTheme } from '@mui/material'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { BooleanInputProps, InputHelperText, sanitizeInputRestProps, useInput } from 'react-admin'

import { StyledSwitch } from './StyledSwitch'

type Props = BooleanInputProps & {
  labelColor?: 'primary' | 'secondary'
}

export const CustomBooleanInput = (props: Props) => {
  const theme = useTheme()
  const {
    className,
    row = false,
    color = 'primary',
    labelColor = 'secondary',
    defaultValue = false,
    format,
    label,
    helperText,
    onBlur,
    onChange,
    onFocus,
    disabled,
    parse,
    resource,
    source,
    validate,
    sx,
    ...rest
  } = props
  const {
    id,
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    onBlur,
    onChange,
    type: 'checkbox',
    validate,
    ...rest,
  })

  const handleChange = useCallback(
    (event) => {
      field.onChange(event)
      // Ensure field is considered as touched
      field.onBlur()
    },
    [field]
  )

  return (
    <FormGroup className={clsx('ra-input', `ra-input-${source}`, className)} row={row} sx={sx}>
      <FormControlLabel
        labelPlacement="start"
        sx={{ marginLeft: 0 }}
        control={
          <StyledSwitch
            color={color}
            id={id}
            name={field.name}
            onChange={handleChange}
            onFocus={onFocus}
            checked={field.value}
            {...sanitizeInputRestProps(rest)}
            disabled={disabled}
            sx={{ marginLeft: 2, marginRight: 2 }}
          />
        }
        label={
          <InputLabel sx={{ color: theme.palette.text[labelColor] }} required={isRequired}>
            {label}
          </InputLabel>
        }
      />
      <FormHelperText error={(isTouched || isSubmitted) && invalid}>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  )
}
