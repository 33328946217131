import get from 'lodash/get'
import React, { ComponentProps, useEffect, useState } from 'react'
import {
  useTranslate,
  useRecordContext,
  useUpdate,
  BooleanField,
  useResourceContext,
} from 'react-admin'

import { useApi } from 'api'
import { StyledSwitch } from 'libs/components/StyledSwitch'
import { useNotifications } from 'libs/notifications'
import { Row } from 'utils/spacing'
import { useDebouncedCallback } from 'utils/useDebounceCallback'

type Props = ComponentProps<typeof BooleanField>

export const DeactivateNesspayToggle = (props: Props) => {
  const t = useTranslate()
  const api = useApi()
  const notification = useNotifications()
  const resource = useResourceContext(props)
  const record = useRecordContext(props)
  const [update] = useUpdate()

  const value = get(record, props.source || '')
  const [isChecked, setIsChecked] = useState(value)

  useEffect(() => {
    setIsChecked(value)
  }, [value])

  async function updateBooleanProperty() {
    if (!resource || !props.source) return
    const oldIsChecked = isChecked
    const newIsChecked = !isChecked
    try {
      setIsChecked(newIsChecked)
      if (props.source === 'hasAdvancesEnabled') {
        await api.contractControllerToggleAdvanceRights({ newValue: newIsChecked }, record.id)
      } else {
        await update(resource, {
          id: record.id,
          data: { [props.source]: newIsChecked },
        })
      }
      notification(t(`notifications.advanceServiceSwitchEdit.${newIsChecked}`), {
        variant: 'success',
      })
    } catch (error) {
      setIsChecked(oldIsChecked)
      notification(t('notifications.userEdit.error'), { variant: 'error' })
      console.log(error)
    }
  }

  const debouncedOnChange = useDebouncedCallback(updateBooleanProperty, 500)

  if (!record) return <></>
  return (
    <Row
      onClick={(event) => event.stopPropagation()}
      sx={{ width: '100%', justifyContent: props.textAlign, ...props.sx }}>
      <StyledSwitch color="secondary" checked={isChecked} onChange={debouncedOnChange} />
    </Row>
  )
}
