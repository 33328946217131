import extendWith from 'lodash/extendWith'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { AxeptioVendorsResponse } from 'api/gen'
import { config } from 'config'

export type Cookies = AxeptioVendorsResponse

type ConsentContextType = {
  cookies: Cookies
  setCookies: (cookies: Cookies) => void
}

const INITIAL_CONSENT_CONTEXT: ConsentContextType = {
  cookies: { mixpanel: false, microsoft_clarity: false },
  setCookies: () => {
    // do nothing
  },
}

const ConsentContext = React.createContext<ConsentContextType>(INITIAL_CONSENT_CONTEXT)

export const ConsentProvider: React.FC = (props) => {
  const [cookies, _setCookies] = useState<Cookies>(INITIAL_CONSENT_CONTEXT.cookies)
  const setCookies = useCallback(
    (newCookies: Cookies) => {
      _setCookies((previousCookies) => {
        const updatedCookies = extendWith(newCookies, previousCookies, (dest: any, src: any) =>
          dest === undefined ? src : dest
        )
        return updatedCookies
      })
    },
    [_setCookies]
  )
  const contextValue = useMemo(() => ({ cookies, setCookies }), [cookies, setCookies])
  return <ConsentContext.Provider value={contextValue}>{props.children}</ConsentContext.Provider>
}

function useInitialize({ userId = '' }: { userId?: string } = {}) {
  const { setCookies } = useContext(ConsentContext)

  useEffect(() => {
    if (window.axeptioSDK) {
      return
    }
    window.axeptioSettings = {
      clientId: config.AXEPTIO_CLIENT_ID,
      cookiesVersion: 'nesspay-fr',
      ...(userId ? { token: userId } : {}),
    }
    ;(function (d, s) {
      const AXEPTIO_SDK_PATH = '//static.axept.io/sdk-slim.js'
      const t = d.getElementsByTagName(s)[0],
        e = d.createElement(s) as any
      e.async = true
      e.src = AXEPTIO_SDK_PATH
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      t.parentNode!.insertBefore(e, t)
    })(document, 'script')
  }, [])

  useEffect(() => {
    if (window.axeptioSDK && userId) {
      window.axeptioSDK.setToken(userId)
    }
  }, [userId])

  useEffect(() => {
    window._axcb = window._axcb || []
    const onComplete = (newChoices: Cookies) => {
      setCookies(newChoices)
    }
    window._axcb.push(function (axeptio: any) {
      axeptio.on('cookies:complete', onComplete)
    })
    return () => {
      window._axcb.push(function (axeptio: any) {
        axeptio.off('cookies:complete', onComplete)
      })
    }
  }, [setCookies])
}

function useCookies(): Cookies {
  const {
    cookies: { mixpanel, microsoft_clarity },
  } = useContext(ConsentContext)
  return useMemo(() => ({ mixpanel, microsoft_clarity }), [mixpanel, microsoft_clarity])
}

export const consent = {
  useInitialize,
  useCookies,
}
