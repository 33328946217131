import { Typography, useTheme } from '@mui/material'
import React from 'react'
import { useTranslate, useLocaleState } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { Row } from 'utils/spacing'

export function CompanyBalance() {
  const t = useTranslate()
  const [locale] = useLocaleState()
  const theme = useTheme()
  const api = useApi()

  const { data: swanAccount } = useQuery(
    QueryKey.SwanAccount,
    () => api.swanControllerGetAccount(),
    { onError: console.error }
  )

  const balance = swanAccount?.account?.balances?.available?.value
  const balanceString = balance
    ? Number(balance).toLocaleString(locale, { maximumFractionDigits: 0 }) + ' €'
    : ''

  return (
    <Row sx={{ display: 'flex', alignItems: 'end' }}>
      <Typography variant="h3" color={theme.palette.primary.main}>
        {t('userMenu.balance') + ' :'}
      </Typography>
      <Typography variant="h3">&nbsp;</Typography>
      <Typography
        sx={{ fontWeight: 500, lineHeight: '20px' }}
        variant="h2"
        color={theme.palette.primary.main}>
        {balanceString}
      </Typography>
    </Row>
  )
}
