import { CalendarTodayRounded, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material'
import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  LoadingButton,
  LocalizationProvider,
} from '@mui/lab'
import {
  Button,
  Typography,
  SxProps,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useState, useMemo } from 'react'
import { useTranslate } from 'react-admin'

import { useApi } from 'api'
import { GetAccountStatementsBody as AccountStatements } from 'api/gen'
import { dateAdapter, DateType, toTimezoneISOString } from 'libs/date'
import { useModal } from 'libs/modal'
import { sleep } from 'utils/async'
import { Column, Row, Spacer } from 'utils/spacing'

const DesktopDatePickerInput: DesktopDatePickerProps['renderInput'] = (params) => {
  const t = useTranslate()
  const theme = useTheme()
  const [month, year] = params.inputProps?.value.split('/')

  return (
    <TextField
      {...params}
      variant="standard"
      autoComplete="off"
      label={false}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start" sx={{ color: theme.palette.secondary.main }}>
            <CalendarTodayRounded sx={{ height: '20px', width: '20px' }} />
          </InputAdornment>
        ),
        disableUnderline: true,
        style: { marginTop: 0 },
      }}
      inputProps={{
        value: t(`months.${parseInt(month)}`) + ' ' + year,
        readOnly: true,
      }}
      sx={dataPickerFieldSx}
    />
  )
}

export const DownloadStatementsDialog = () => {
  const t = useTranslate()
  const api = useApi()
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const { isModalOpen, closeModal } = useModal('downloadStatements')

  const Formats = AccountStatements.FormatEnum
  const [format, setFormat] = useState<AccountStatements.FormatEnum | undefined>(undefined)

  const currentDate = useMemo(() => dateAdapter.startOfMonth(dateAdapter.date()), [])
  const [endDate, setEndDate] = useState<DateType>(currentDate)
  const [startDate, setStartDate] = useState<DateType>(dateAdapter.addMonths(currentDate, -1))

  function handleClose() {
    setFormat(undefined)
    setLoading(false)
    closeModal()
  }

  function onDateChange(date: DateType | null) {
    if (!date) return
    setStartDate(date)
    setEndDate(dateAdapter.addMonths(date, 1))
  }

  async function downloadStatements() {
    setLoading(true)
    const response = await api.swanControllerGetAccountStatements({
      startDate: toTimezoneISOString(startDate),
      endDate: toTimezoneISOString(endDate),
      format: format as AccountStatements.FormatEnum,
    })
    const result = await response.json()
    if (result) {
      // a timeout is set to make sure the file is ready before the page loads
      await sleep(20000)
      window.open(result.accountStatements[0].url, '_blank')
    }
    setLoading(false)
  }

  const HelperTexts = () => {
    if (!loading) return <></>
    return (
      <>
        <Spacer y={2} />
        <Typography variant="h3" sx={{ color: theme.colors.GREY }}>
          {t('bank.downloadModal.loading') + (format ? format : '') + ' ...'}
        </Typography>
        <Spacer y={1} />
        <Typography variant="h3" sx={{ color: theme.colors.GREY }}>
          {t('bank.downloadModal.helperTexts.1')}
        </Typography>
        <Spacer y={2} />
        <Typography sx={{ color: theme.colors.GREY, fontSize: '18px' }}>
          {t('bank.downloadModal.helperTexts.2')}
        </Typography>
        <Typography sx={{ color: theme.colors.GREY, fontSize: '18px' }}>
          {t('bank.downloadModal.helperTexts.3')}
        </Typography>
        <Typography sx={{ color: theme.colors.GREY, fontSize: '18px' }}>
          {t('bank.downloadModal.helperTexts.4')}
        </Typography>
      </>
    )
  }

  return (
    <Dialog open={isModalOpen} onClose={handleClose} sx={dialogSx(loading)}>
      <DialogTitle>
        <Spacer y={3} />
        <Typography color="secondary" variant="h2" sx={dialogTitleSx}>
          {t('bank.downloadModal.title')}
        </Typography>
        <IconButton
          onClick={handleClose}
          color="secondary"
          sx={{ ...closeButtonSx, ':hover': { borderColor: theme.palette.text.secondary } }}>
          <CloseRounded sx={{ width: '30px', height: '30px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Column sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{ color: theme.colors.GREY, fontSize: '16px', textAlign: 'center', p: '0px 60px' }}>
            {t('bank.downloadModal.subtitle')}
          </Typography>
          <Spacer y={3} />
          <Row>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setFormat(Formats.CSV)}
              disabled={format === Formats.CSV || loading}
              sx={{ fontWeight: 400, borderRadius: '15px' }}>
              {t('bank.downloadModal.csvButton')}
            </Button>
            <Spacer x={4} />
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setFormat(Formats.PDF)}
              disabled={format === Formats.PDF || loading}
              sx={{ fontWeight: 400, borderRadius: '15px' }}>
              {t('bank.downloadModal.pdfButton')}
            </Button>
          </Row>
          <Spacer y={4} />
          <LocalizationProvider dateAdapter={DateAdapter}>
            <Row>
              <DesktopDatePicker
                disableOpenPicker={loading}
                label={t('filters.date.start')}
                value={startDate}
                inputFormat="MM/yyyy"
                maxDate={dateAdapter.addMonths(currentDate, -1)}
                onChange={onDateChange}
                renderInput={DesktopDatePickerInput}
                views={['year', 'month']}
                InputAdornmentProps={{ position: 'end' }}
                OpenPickerButtonProps={{ sx: openPickerButtonSx }}
                components={{
                  OpenPickerIcon: KeyboardArrowDownRounded,
                }}
              />
            </Row>
          </LocalizationProvider>
          <Spacer y={4} />
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!format}
            onClick={downloadStatements}
            loading={loading}
            sx={buttonSx}>
            {t('buttons.export.name')}
          </LoadingButton>
          <HelperTexts />
        </Column>
      </DialogContent>
    </Dialog>
  )
}

const dialogTitleSx: SxProps = { textAlign: 'center', fontWeight: 500 }
const closeButtonSx: SxProps = { position: 'absolute', right: 20, top: 13 }
const buttonSx: SxProps = { width: '220px', height: '40px' }
const openPickerButtonSx: SxProps = { ':hover': { border: 'none' } }

const dialogSx = (loading: boolean): SxProps => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: loading ? '900px' : '520px',
      height: '100%',
      maxHeight: loading ? '500px' : '370px',
    },
  },
})
const dataPickerFieldSx: SxProps = {
  width: '180px',
  minHeight: '35px',
  justifyContent: 'center',
  p: '5px 10px',
  borderRadius: '10px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}
