import { ArrowDropDown, EuroRounded } from '@mui/icons-material'
import {
  LoadingButton,
  DesktopDatePicker,
  DesktopDatePickerProps,
  LocalizationProvider,
} from '@mui/lab'
import { Typography, SxProps, styled, TextField, useTheme, InputAdornment } from '@mui/material'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import { addHours, addMonths } from 'date-fns'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useApi } from 'api'
import { RequestLoanBody } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { Column, Spacer, Row } from 'utils/spacing'

const renderDesktopDatePickerInput: DesktopDatePickerProps['renderInput'] = (params) => (
  <StyledTextField {...params} autoComplete="off" sx={{ ...params.sx, width: 200 }} />
)

export const CreateLoanRequestForm = () => {
  const api = useApi()
  const theme = useTheme()
  const t = useTranslate()
  const notify = useNotifications()
  const currentDate = new Date()

  const [amount, setAmount] = useState<number>(0)
  const [toPayAt, setToPayAt] = useState<Date | null>(addHours(currentDate, 1))
  const [toRepayAt, setToRepayAt] = useState<Date | null>(addMonths(currentDate, 1))

  const { closeModal } = useModal('createLoanRequest')

  const isValid = amount && Boolean(toPayAt) && Boolean(toRepayAt)

  const queryClient = useQueryClient()
  const createLoanRequestMutation = useMutation(QueryKey.CreateLoanRequest, createLoanRequest, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKey.GetLoanRequests)
      notify(t('notifications.financing.requestLoan.success'), { variant: 'success' })
    },
    onError: () => {
      notify(t('notifications.financing.requestLoan.error'), { variant: 'error' })
    },
  })
  const { data: swanAccount } = useQuery(
    QueryKey.SwanAccount,
    async () => await api.swanControllerGetAccount(),
    { onError: console.error }
  )

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  // dates parameters are guaranteed to be non-null in this block
  async function createLoanRequest() {
    const iban = swanAccount?.account.IBAN || ''
    const body: RequestLoanBody = {
      iban: iban,
      amount: amount,
      toPayAt: toPayAt!.toISOString(),
      toRepayAt: toRepayAt!.toISOString(),
    }
    await api.financingControllerRequestLoan(body)
    closeModal()
  }

  return (
    <Column sx={{ display: 'flex' }}>
      <Typography sx={typographySx}>{t('financing.loansTab.createLoanRequest.message')}</Typography>
      <Spacer y={3} />
      <Row>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            value={toPayAt}
            inputFormat="dd/MM/yyyy"
            onChange={(value) => setToPayAt(value)}
            renderInput={renderDesktopDatePickerInput}
            components={{ OpenPickerIcon: ArrowDropDown }}
            label={t('financing.loansTab.createLoanRequest.toPayAt')}
          />
        </LocalizationProvider>
        <Spacer x={2} />
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            value={toRepayAt}
            inputFormat="dd/MM/yyyy"
            onChange={(value) => setToRepayAt(value)}
            renderInput={renderDesktopDatePickerInput}
            components={{ OpenPickerIcon: ArrowDropDown }}
            label={t('financing.loansTab.createLoanRequest.toRepayAt')}
          />
        </LocalizationProvider>
      </Row>
      <Spacer y={3} />
      <StyledTextField
        value={amount}
        type={'number'}
        onChange={(event) => setAmount(parseInt(event.target.value))}
        label={t('resources.transactions.fields.amount')}
        sx={numberFieldSx}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ color: theme.palette.secondary.main }}>
              <EuroRounded />
            </InputAdornment>
          ),
        }}
      />
      <Spacer y={3} />
      <LoadingButton
        sx={buttonSx}
        color="primary"
        variant="contained"
        disabled={!isValid}
        loading={createLoanRequestMutation.isLoading}
        onClick={() => createLoanRequestMutation.mutate()}>
        {t('buttons.create')}
      </LoadingButton>
    </Column>
  )
}

const typographySx: SxProps = { fontWeight: 500, fontSize: '25px' }
const buttonSx: SxProps = {
  padding: 0,
  margin: 0,
  fontWeight: 400,
  height: '34px',
  width: '150px',
  textTransform: 'none',
  alignSelf: 'center',
}
const numberFieldSx: SxProps = {
  width: 200,
  alignSelf: 'center',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  maxWidth: '420px',
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))
