import { useMemo } from 'react'
import { useTranslate } from 'react-admin'

import { Contract } from 'api/gen'
import { ROLES_WITHOUT_ADMIN, RoleType } from 'resources/types'

export function useRoleTranslation() {
  const t = useTranslate()
  return useMemo(() => {
    function tRole(role: RoleType, plural = false) {
      return t(`resources.contract.roles.${role}`, plural ? 2 : 1)
    }
    function tRoleMap(roles: RoleType[], plural = false) {
      if (!roles || roles.length === 0) return ''
      return roles
        .map((role) => tRole(role, plural))
        .sort()
        .join(', ')
    }
    function tRolePermissions(roles: RoleType[]) {
      const Roles = Contract.RolesEnum
      if (roles.includes(Roles.Admin) || roles.includes(Roles.Employer)) {
        return t('administration.roleManagementTab.permissions.manager')
      } else return t('administration.roleManagementTab.permissions.analyst')
    }
    function roleOptions(roles: RoleType[] = ROLES_WITHOUT_ADMIN, plural = false) {
      return roles.map((role) => ({ id: role, value: tRole(role, plural) }))
    }
    return { tRole, tRoleMap, tRolePermissions, roleOptions }
  }, [t])
}
