import { Close } from '@mui/icons-material'
import { Box, IconButton, SxProps, useTheme, Button, Typography } from '@mui/material'
import * as React from 'react'
import {
  EditBase,
  SimpleForm,
  useTranslate,
  FunctionField,
  useRecordContext,
  useLocaleState,
} from 'react-admin'

import { Transaction } from 'api/gen'
import { analytics } from 'libs/analytics'
import { useCanAccess } from 'libs/auth'
import { intercom, Locale } from 'libs/intercom'
import { TransactionRecord } from 'resources/types'
import { SecondaryOutlinedTextInput } from 'ui/inputs'
import { maskIBAN } from 'utils/format'
import { Column, Spacer } from 'utils/spacing'

interface Props {
  onCancel: () => void
  id: string
}

const AmountField = () => {
  let color = ''
  const theme = useTheme()
  const [locale] = useLocaleState()
  const record = useRecordContext()

  if (record.status === Transaction.StatusEnum.DONE) color = theme.palette.text.secondary
  if (record.status === Transaction.StatusEnum.INPROGRESS) color = theme.colors.YELLOW_PASTEL
  if (record.status === Transaction.StatusEnum.CANCEL) color = theme.colors.RED_PASTEL

  return (
    <Typography sx={{ ...amountSx, color: color }}>
      {Number(record.amount).toLocaleString(locale)}€
    </Typography>
  )
}

export const TransactionEdit = ({ onCancel, ...props }: Props) => {
  const t = useTranslate()
  const theme = useTheme()
  const [locale] = useLocaleState()
  const isAccountManager = useCanAccess().canAccess({ roles: ['accountManager'] })

  const onClickNeedHelp = () => {
    analytics.log('click on Need help')
    intercom.openHelpCenter(locale as Locale, undefined)
  }

  return (
    <EditBase resource="transactions" id={props.id}>
      <Column width={{ xs: '100vW', sm: 300 }} bgcolor={theme.palette.secondary.main} sx={columnSx}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}>
          <IconButton onClick={onCancel} size="small">
            <Close sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
        </Box>
        <AmountField />
        <FunctionField
          render={(record: any) =>
            t(`resources.transactions.status.${record.status.toLowerCase()}`)
          }
          sx={{
            width: '100%',
            fontSize: '15px',
            color: theme.colors.GREY,
            textAlign: 'center',
          }}
        />
        <Spacer y={2} />
        <FunctionField
          render={(record: TransactionRecord) => record.user.firstName + ' ' + record.user.lastName}
          sx={{
            width: '100%',
            fontSize: '25px',
            color: theme.palette.text.secondary,
            textAlign: 'center',
            fontWeight: 500,
          }}
        />
        <SimpleForm
          sx={{ pt: 0, pb: 0, backgroundColor: theme.palette.secondary.main }}
          toolbar={false}>
          <Spacer y={8} />
          <SecondaryOutlinedTextInput
            source="user.iban"
            label={t('resources.user.fields.iban')}
            variant="outlined"
            disabled
            type={isAccountManager ? 'password' : 'text'}
            parse={(value) => maskIBAN(value).unmaskedValue}
            format={(value) => maskIBAN(value).value}
            sx={{ width: '100%' }}
          />
          <Spacer y={3} />
          <SecondaryOutlinedTextInput
            source="createdAt"
            format={(value) =>
              new Date(value).toLocaleDateString() + ' - ' + new Date(value).toLocaleTimeString()
            }
            variant="outlined"
            disabled
            sx={{ width: '100%' }}
          />
          <Spacer y={3} />
          <SecondaryOutlinedTextInput
            source="swanBookingDate"
            format={(value) =>
              (value ? new Date(value).toLocaleDateString() : '') +
              ' - ' +
              (value ? new Date(value).toLocaleTimeString() : '')
            }
            variant="outlined"
            disabled
            sx={{ width: '100%' }}
          />
          <Button variant="contained" color="secondary" onClick={onClickNeedHelp} sx={buttonSx}>
            {t('buttons.intercom.needHelp')}
          </Button>
        </SimpleForm>
      </Column>
    </EditBase>
  )
}

const buttonSx: SxProps = {
  position: 'absolute',
  width: '270px',
  height: '40px',
  bottom: '25px',
}
const amountSx: SxProps = {
  marginBottom: '-15px',
  width: '100%',
  fontSize: '55px',
  textAlign: 'center',
  fontWeight: 500,
}
const columnSx: SxProps = {
  height: '100%',
  display: 'flex',
  alignContent: 'center',
}
