import { showKomboConnect } from '@kombo-api/connect'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { useNotifications } from 'libs/notifications'
import { sleep } from 'utils/async'

export function OtherHrisLogin() {
  const t = useTranslate()
  const api = useApi()
  const queryClient = useQueryClient()
  const notification = useNotifications()

  const komboLinkQuery = useQuery(QueryKey.KomboLink, async () => {
    const response = await api.komboControllerCreateLink()
    return response
  })

  const connectHrisMutation = useMutation(
    async (link?: string) => {
      if (!link) return
      const token = await showKomboConnect(link)
      const response = await api.integrationControllerKomboIntegration({ token })
      await sleep(1000)
      return response
    },
    {
      onSuccess: async () => {
        notification(t('notifications.komboConnect.success'), { variant: 'success' })
        queryClient.invalidateQueries(QueryKey.Me)
        await sleep(1000)
        location.reload()
      },
      onError: (error: any) => {
        notification(t('notifications.komboConnect.error'), { variant: 'error' })
        console.error(error)
      },
    }
  )

  const link = komboLinkQuery?.data?.link
  return (
    <LoadingButton
      loading={komboLinkQuery.isLoading || connectHrisMutation.isLoading}
      variant="contained"
      disabled={komboLinkQuery.isLoading || !link}
      onClick={() => connectHrisMutation.mutate(link)}
      fullWidth>
      {t('administration.integration.kombo.connect')}
    </LoadingButton>
  )
}
