import { KeyboardArrowDownRounded } from '@mui/icons-material'
import {
  Typography,
  styled,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { useCanAccess } from 'libs/auth'
import {
  RoleType,
  ROLES_WITHOUT_ADMIN_AND_EMPLOYEE,
  ROLES_WITHOUT_NESSPAY_ROLES_AND_EMPLOYEE,
} from 'resources/types'
import { Row } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

interface Props {
  selectedRole: RoleType
  setSelectedRole: (role: RoleType) => void
}

export function RoleSelector(props: Props) {
  const t = useTranslate()
  const { canAccess } = useCanAccess()
  const { tRole } = useRoleTranslation()
  const isAdmin = canAccess({ roles: ['admin'] })
  const { selectedRole, setSelectedRole } = props

  const renderSelectedRole = () => (
    <Row>
      <Typography>{tRole(selectedRole)}</Typography>
    </Row>
  )

  const renderSelectOptions = () => {
    const roles = isAdmin
      ? ROLES_WITHOUT_ADMIN_AND_EMPLOYEE
      : ROLES_WITHOUT_NESSPAY_ROLES_AND_EMPLOYEE
    return roles.map((role) => (
      <MenuItem key={role} value={role}>
        {tRole(role)}
      </MenuItem>
    ))
  }

  return (
    <FormControl>
      <InputLabel id={`select-role-label`}>{t('resources.contract.fields.roles')}</InputLabel>
      <Select
        value={selectedRole}
        labelId={`select-role-label`}
        input={<StyledOutlinedInput label={t('resources.contract.fields.roles')} />}
        renderValue={renderSelectedRole}
        IconComponent={KeyboardArrowDownRounded}
        onChange={(event) => setSelectedRole(event.target.value as RoleType)}
        sx={{ minWidth: '230px' }}>
        {renderSelectOptions()}
      </Select>
    </FormControl>
  )
}

const StyledOutlinedInput = styled(OutlinedInput)({
  width: 200,
})
