import { ContentCopyRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Typography,
  Tooltip,
  IconButton,
  SxProps,
  useTheme,
  styled,
} from '@mui/material'
import React, { SyntheticEvent } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery, useMutation } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { ReactComponent as LogoSVG } from 'assets/logo_blue_night.svg'
import { useNotifications } from 'libs/notifications'
import { usePersistedUser } from 'user/context'
import { maskIBAN } from 'utils/format'
import { Spacer, Row } from 'utils/spacing'

const FUND_ACCOUNT_NOTION_URL =
  'https://nesspay.notion.site/Approvisionner-votre-compte-493b00c8d1584fed8cb665cc9e6f30b9?pvs=4'

interface RowDetailsProps {
  id: string
  value: string
}

export const FundAccount = () => {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()
  const notify = useNotifications()
  const { organization, setOrganization } = usePersistedUser()

  const { data: swanAccount } = useQuery(
    QueryKey.SwanAccount,
    async () => await api.swanControllerGetAccount(),
    { onError: console.error, refetchOnWindowFocus: false }
  )

  const updateOnboardingMutation = useMutation(QueryKey.UpdateOnboarding, async () => {
    const org = await api.organizationControllerUpdateOnboardingState({
      ...organization.onboarding,
      hasAddedFundsOnAccount: true,
    })
    setOrganization(org)
  })

  const bic = swanAccount?.account?.BIC as string
  const iban = swanAccount?.account?.IBAN as string

  async function copyToClipboard(event: SyntheticEvent, id: string) {
    const target = event.target as HTMLElement
    const copyTextArea = document.getElementById(id) as HTMLInputElement
    try {
      await navigator.clipboard.writeText(copyTextArea.innerText)
      notify(t('notifications.copyToClipboard.success'), { variant: 'success' })
    } catch (error) {
      console.error(error)
      notify(t('notifications.copyToClipboard.error'), { variant: 'error' })
    }
    target.blur()
  }

  const BankDetailsRow = (props: RowDetailsProps) => (
    <Row>
      <StyledSecondaryText id={props.id}>{props.value}</StyledSecondaryText>
      <Tooltip title={t('bank.tooltipCopy')}>
        <IconButton
          sx={{ height: '15px', ml: '-10px', ':hover': { borderColor: theme.colors.WHITE } }}
          onClick={(event) => copyToClipboard(event, props.id)}>
          <ContentCopyRounded sx={{ height: '15px', width: '15px', color: theme.colors.GREY }} />
        </IconButton>
      </Tooltip>
    </Row>
  )

  const BankDetails = () => (
    <>
      <StyledDetail>{t('resources.contract.fields.user.iban')}</StyledDetail>
      <BankDetailsRow id="copy-text-bank-iban" value={maskIBAN(iban).value} />
      <StyledDetail>{t('bank.details.bic')}</StyledDetail>
      <BankDetailsRow id="copy-text-bank-bic" value={bic} />
      <StyledDetail>{t('bank.details.institutionNameTitle')}</StyledDetail>
      <BankDetailsRow id="copy-text-bank-name" value={t('bank.details.institutionName')} />
      <StyledDetail>{t('bank.details.institutionAdressTitle')}</StyledDetail>
      <BankDetailsRow id="copy-text-bank-adress" value={t('bank.details.institutionAdress')} />
    </>
  )

  const BankDetailsFooter = () => (
    <Row sx={{ display: 'flex', justifyContent: 'center' }}>
      <LogoSVG width={40} />
      <Spacer x={0.5} />
      <StyledSecondaryText variant="h5" sx={{ color: theme.colors.GREY, p: 0 }}>
        {t('bank.details.footer')}
      </StyledSecondaryText>
    </Row>
  )

  return (
    <Box sx={{ maxWidth: '75%' }}>
      <Typography variant="h2" fontWeight={500}>
        {t('onboarding.fundAccount.title')}
      </Typography>
      <Spacer y={2} />
      <Typography sx={{ maxWidth: '91%' }}>{t('onboarding.fundAccount.helperText')}</Typography>
      <Spacer y={2} />
      <BankDetails />
      <Spacer y={2} />
      <BankDetailsFooter />
      <Spacer y={2} />
      <Row>
        <Button
          sx={buttonSx}
          color="secondary"
          variant="outlined"
          onClick={() => window.open(FUND_ACCOUNT_NOTION_URL, '_blank')}>
          {t('onboarding.fundAccount.buttons.how')}
        </Button>
        <Spacer x={2} />
        <LoadingButton
          sx={buttonSx}
          variant="contained"
          loading={updateOnboardingMutation.isLoading}
          onClick={() => updateOnboardingMutation.mutate()}
          disabled={organization.onboarding.hasAddedFundsOnAccount}>
          {organization.onboarding.hasAddedFundsOnAccount
            ? '✓'
            : t('onboarding.fundAccount.buttons.done')}
        </LoadingButton>
      </Row>
    </Box>
  )
}

const buttonSx: SxProps = {
  width: '220px',
  fontWeight: 400,
}

const StyledDetail = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '18px',
  padding: '20px 0px 5px',
  color: theme.palette.primary.main,
}))
const StyledSecondaryText = styled(Typography)(() => ({
  fontWeight: 300,
  paddingRight: '10px',
}))
