import React, { useCallback, useMemo } from 'react'

type ModalState = {
  isOpen: boolean
}

export type ModalStates = {
  onboarding: ModalState
  logout: ModalState
  selectColumns: ModalState
  addOrImportUsers: ModalState
  export: ModalState
  addRole: ModalState
  editRole: ModalState
  removeRole: ModalState
  downloadStatements: ModalState
  refundSalary: ModalState
  displayApiToken: ModalState
  confirm: ModalState
  createBorrower: ModalState
  createLoanRequest: ModalState
}

export type ModalName = keyof ModalStates

type ModalContextType = {
  modals: ModalStates
  setModalState: (modalName: ModalName, newState: Partial<ModalState>) => void
}

function doNothingFn() {
  // do nothing
}

const initialModalState = { isOpen: false }
const initialModalsStates: ModalStates = {
  addOrImportUsers: initialModalState,
  export: initialModalState,
  logout: initialModalState,
  onboarding: initialModalState,
  selectColumns: initialModalState,
  addRole: initialModalState,
  editRole: initialModalState,
  removeRole: initialModalState,
  downloadStatements: initialModalState,
  refundSalary: initialModalState,
  displayApiToken: initialModalState,
  confirm: initialModalState,
  createBorrower: initialModalState,
  createLoanRequest: initialModalState,
}
const INITIAL_MODAL_CONTEXT: ModalContextType = {
  modals: initialModalsStates,
  setModalState: doNothingFn,
}

export const ModalContext = React.createContext<ModalContextType>(INITIAL_MODAL_CONTEXT)

export const ModalProvider: React.FC = (props) => {
  const [modalsStates, setModalsStates] = React.useState<ModalStates>(initialModalsStates)

  const setModalState = useCallback((modalName: ModalName, newState: Partial<ModalState>) => {
    setModalsStates((prevState) => {
      return {
        ...prevState,
        [modalName]: { ...prevState[modalName], ...newState },
      }
    })
  }, [])

  const contextValue = useMemo<ModalContextType>(
    () => ({
      modals: modalsStates,
      setModalState: setModalState,
    }),
    [modalsStates, setModalState]
  )

  return <ModalContext.Provider value={contextValue}>{props.children}</ModalContext.Provider>
}
