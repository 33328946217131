import { LoadingButton } from '@mui/lab'
import { Typography, SxProps, TextField, styled, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate, useLocaleState } from 'react-admin'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { useApi } from 'api'
import { CreateBorrowerBody, DefactoDirector, FinancingBorrower } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { useCanAccess } from 'libs/auth'
import { countries } from 'libs/countries'
import { useFormValidation } from 'libs/validation/form'
import { maskIBAN, maskSIREN } from 'utils/format'
import { Spacer, Column, Row } from 'utils/spacing'

interface Props {
  setStatus: (status: FinancingBorrower.StatusEnum) => void
}

export const CreateBorrowerForm = (props: Props) => {
  let iban = ''
  let email = ''
  let country = ''
  let lastName = ''
  let firstName = ''
  let birthDate = ''
  let nationality = ''

  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()
  const [locale] = useLocaleState()
  const queryClient = useQueryClient()
  const isAccountManager = useCanAccess().canAccess({ roles: ['accountManager'] })

  const [siren, setSiren] = useState<string>('')
  const { siren: validateSiren } = useFormValidation()
  const sirenError = validateSiren(maskSIREN(siren).unmaskedValue)
  const isValidSiren = Boolean(siren) && !Boolean(sirenError)

  const { data: swanAccount } = useQuery(
    QueryKey.SwanAccount,
    async () => await api.swanControllerGetAccount(),
    { onError: console.error }
  )

  if (swanAccount && swanAccount.account) {
    const { legalRepresentativeMembership } = swanAccount.account
    const countryArray = countries.filter((country) => {
      return country.code === swanAccount.account.country
    })
    if (countryArray.length > 0) country = countryArray[0].name
    iban = swanAccount.account.IBAN || ''
    email = legalRepresentativeMembership.email
    if (legalRepresentativeMembership.user) {
      const { user } = legalRepresentativeMembership
      lastName = user.lastName || ''
      firstName = user.firstName || ''
      birthDate = user.birthDate || ''
      nationality = user.nationalityCCA3 || ''
    }
  }

  const createBorrowerMutation = useMutation(QueryKey.CreateBorrower, createBorrower, {
    onSuccess: async () => {
      props.setStatus(FinancingBorrower.StatusEnum.TOSIGN)
      await queryClient.invalidateQueries(QueryKey.GetBorrower)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  async function createBorrower() {
    const director: DefactoDirector = {
      first_name: firstName,
      last_name: lastName,
      address: { country },
      business_email: email,
      birth_date: birthDate,
      nationality: nationality,
    }
    const body: CreateBorrowerBody = {
      iban: iban,
      directors: [director],
      identifier: maskSIREN(siren).unmaskedValue,
    }
    await api.financingControllerCreateBorrower(body)
  }

  return (
    <Column sx={{ display: 'flex' }}>
      <Typography sx={typographySx}>
        {t('financing.loansTab.createBorrower.dialog.subtitle1')}
      </Typography>
      <Spacer y={2} />
      <Row>
        <StyledTextField value={firstName} label={t('resources.user.fields.firstName')} disabled />
        <Spacer x={2} />
        <StyledTextField value={lastName} label={t('resources.user.fields.lastName')} disabled />
      </Row>
      <Spacer y={2} />
      <Row>
        <StyledTextField
          value={country}
          label={t('financing.loansTab.createBorrower.dialog.country')}
          disabled
        />
        <Spacer x={2} />
        <StyledTextField
          value={nationality}
          label={t('financing.loansTab.createBorrower.dialog.nationality')}
          disabled
        />
      </Row>
      <Spacer y={2} />
      <StyledTextField
        value={email}
        label={t('financing.loansTab.createBorrower.dialog.email')}
        disabled
      />
      <Spacer y={2} />
      <StyledTextField
        value={new Date(birthDate).toLocaleDateString(locale)}
        label={t('financing.loansTab.createBorrower.dialog.birthdate')}
        sx={{ width: 205 - parseInt(theme.spacing(1)) }}
        disabled
      />
      <Spacer y={2} />
      <Typography sx={typographySx}>
        {t('financing.loansTab.createBorrower.dialog.subtitle2')}
      </Typography>
      <Spacer y={2} />
      <StyledTextField
        value={maskSIREN(siren).value}
        autoComplete="off"
        label={t('financing.loansTab.createBorrower.dialog.siren')}
        onChange={(event) => setSiren(event.target.value)}
        helperText={sirenError ? sirenError : ''}
        FormHelperTextProps={{
          sx: { color: sirenError ? theme.colors.RED : theme.colors.WHITE },
        }}
      />
      <Spacer y={2} />
      <StyledTextField
        value={maskIBAN(iban).value}
        type={isAccountManager ? 'password' : 'text'}
        autoComplete="off"
        label={t('resources.user.fields.iban')}
        disabled
      />
      <Spacer y={4} />
      <LoadingButton
        color="primary"
        variant="contained"
        disabled={!isValidSiren || isAccountManager}
        loading={createBorrowerMutation.isLoading}
        sx={{ ...buttonSx, mt: sirenError ? -3 : 0 }}
        onClick={() => createBorrowerMutation.mutate()}>
        {t('buttons.create')}
      </LoadingButton>
    </Column>
  )
}

const typographySx: SxProps = { fontWeight: 500, fontSize: '25px' }
const buttonSx: SxProps = {
  padding: 0,
  margin: 0,
  fontWeight: 400,
  height: '34px',
  width: '150px',
  textTransform: 'none',
  alignSelf: 'center',
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  maxWidth: '410px',
  '& .MuiInputLabel-root': { color: theme.palette.secondary.main },
  '& .MuiFormHelperText-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '& fieldset': { borderColor: theme.palette.secondary.main },
    '&:hover fieldset': { borderColor: theme.palette.primary.main },
  },
}))
