import { exportToExcel, exportToCSV } from 'libs/export/exporter'
import { ContractRecord } from 'resources/types'
import { stringifyBoolean } from 'utils/format'

function getRowsToExportWithoutIban(contracts: ContractRecord[]) {
  const rows = new Array(contracts.length)
  for (let i = 0; i < contracts.length; ++i) {
    const contract = contracts[i]
    const user = contract.user
    rows[i] = {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      phone: user.phone,
      staff_number: contract.staffNumber,
      net_salary: contract.netSalary,
      available_salary_percentage: contract.availableSalaryFraction,
      can_request_money: stringifyBoolean(
        contract.isNotAbsent && !contract.isManuallyDisabledByAdmin
      ),
      contract_start_date: contract.contractStartDate,
      contract_end_date: contract.contractEndDate,
    }
  }
  return rows
}

function getRowsToExport(contracts: ContractRecord[]) {
  const rows = new Array(contracts.length)
  for (let i = 0; i < contracts.length; ++i) {
    const contract = contracts[i]
    const user = contract.user
    rows[i] = {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      phone: user.phone,
      iban: user.iban,
      staff_number: contract.staffNumber,
      net_salary: contract.netSalary,
      available_salary_percentage: contract.availableSalaryFraction,
      can_request_money: stringifyBoolean(
        contract.isNotAbsent && !contract.isManuallyDisabledByAdmin
      ),
      contract_start_date: contract.contractStartDate,
      contract_end_date: contract.contractEndDate,
    }
  }
  return rows
}

function xls(contracts: ContractRecord[]) {
  const rows = getRowsToExport(contracts)
  const columnsInfos = [
    { wch: 15 },
    { wch: 15 },
    { wch: 30 },
    { wch: 12 },
    { wch: 30 },
    { wch: 12 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
  ]
  exportToExcel(rows, 'employes', columnsInfos)
}

function xlsxWithoutIban(contracts: ContractRecord[]) {
  const rows = getRowsToExportWithoutIban(contracts)
  const columnsInfos = [
    { wch: 15 },
    { wch: 15 },
    { wch: 30 },
    { wch: 12 },
    { wch: 12 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
  ]
  exportToExcel(rows, 'employes', columnsInfos)
}

function csv(contracts: ContractRecord[]) {
  const rows = getRowsToExport(contracts)
  exportToCSV(rows, 'employes')
}

function csvWithoutIban(contracts: ContractRecord[]) {
  const rows = getRowsToExportWithoutIban(contracts)
  exportToCSV(rows, 'employes')
}

export const userListExporters = {
  csv,
  xls,
  csvWithoutIban,
  xlsxWithoutIban,
}
