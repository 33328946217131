import React from 'react'

import { ImportedUser } from 'api/gen'
import { ImportUsersContextType } from 'libs/types'

export enum ValidationSteps {
  COLUMNS_MATCHING = 'columns_matching',
  HAS_ERRORS = 'has_error',
  TABLE_EDIT = 'table_edit',
  FLUSH_DATA = 'flush_data',
}

const INITIAL_IMPORT_USERS_CONTEXT: ImportUsersContextType = {
  filename: '',
  currentStep: 0,
  validationStep: ValidationSteps.COLUMNS_MATCHING,
  fileContent: [],
  missingFields: [],
  importedFields: [],
  usersToDelete: [],
  setFilename: () => undefined,
  setCurrentStep: () => undefined,
  setValidationStep: () => undefined,
  setFileContent: () => undefined,
  setMissingFields: () => undefined,
  setImportedFields: () => undefined,
  flushContext: () => undefined,
  setUsersToDelete: () => undefined,
}

export const ImportUsersContext = React.createContext<ImportUsersContextType>(
  INITIAL_IMPORT_USERS_CONTEXT
)

export const ImportUsersContextProvider: React.FC = (props) => {
  const [filename, setFilename] = React.useState<string>('')
  const [currentStep, setCurrentStep] = React.useState<number>(0)
  const [validationStep, setValidationStep] = React.useState<any>(ValidationSteps.COLUMNS_MATCHING)
  const [fileContent, setFileContent] = React.useState<any>('')
  const [missingFields, setMissingFields] = React.useState<string[]>([])
  const [importedFields, setImportedFields] = React.useState<any>('')
  const [usersToDelete, setUsersToDelete] = React.useState<ImportedUser[]>([])

  const flushContext = React.useCallback(() => {
    setFilename('')
    setFileContent([])
    setMissingFields([])
    setImportedFields([])
    setUsersToDelete([])
    setValidationStep(ValidationSteps.COLUMNS_MATCHING)
    setCurrentStep(0)
  }, [])

  const contextValue = React.useMemo(
    () => ({
      filename,
      currentStep,
      validationStep,
      fileContent,
      missingFields,
      importedFields,
      usersToDelete,
      setFilename,
      setCurrentStep,
      setValidationStep,
      setFileContent,
      setMissingFields,
      setImportedFields,
      setUsersToDelete,
      flushContext,
    }),
    [
      filename,
      currentStep,
      validationStep,
      fileContent,
      missingFields,
      importedFields,
      usersToDelete,
      setFilename,
      setCurrentStep,
      setValidationStep,
      setFileContent,
      setMissingFields,
      setImportedFields,
      setUsersToDelete,
      flushContext,
    ]
  )

  return (
    <ImportUsersContext.Provider value={contextValue}>{props.children}</ImportUsersContext.Provider>
  )
}
