import isString from 'lodash/isString'

import { removeNonAlphaNumericChars } from 'utils/format'

const A = 'A'.charCodeAt(0)
const Z = 'Z'.charCodeAt(0)

/**
 * Prepare an IBAN for mod 97 computation by moving the first 4 chars to the end and transforming the letters to
 * numbers (A = 10, B = 11, ..., Z = 35), as specified in ISO13616.
 * @param {string} iban the IBAN
 * @returns {string} the prepared IBAN
 */
function iso13616Prepare(iban: string) {
  iban = iban.toUpperCase()
  iban = iban.substr(4) + iban.substr(0, 4)
  return iban
    .split('')
    .map(function (n) {
      const code = n.charCodeAt(0)
      if (code >= A && code <= Z) {
        // A = 10, B = 11, ... Z = 35
        return code - A + 10
      } else {
        return n
      }
    })
    .join('')
}

/**
 * Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064.
 *
 * @param iban
 * @returns {number}
 */
function iso7064Mod97_10(iban: string) {
  let remainder = iban
  let block
  while (remainder.length > 2) {
    block = remainder.slice(0, 9)
    remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length)
  }
  return parseInt(remainder, 10) % 97
}

/**
 * Parse the BBAN structure used to configure each IBAN Specification and returns a matching regular expression.
 * A structure is composed of blocks of 3 characters (one letter and 2 digits). Each block represents
 * a logical group in the typical representation of the BBAN. For each group, the letter indicates which characters
 * are allowed in this group and the following 2-digits number tells the length of the group.
 * @param {string} structure the structure to parse
 * @returns {RegExp}
 */
function parseStructure(structure: string) {
  // split in blocks of 3 chars
  const regex = structure.match(/(.{3})/g)?.map(function (block) {
    // parse each structure block (1-char + 2-digits)
    let format
    const pattern = block.slice(0, 1)
    const repeats = parseInt(block.slice(1), 10)

    switch (pattern) {
      case 'A':
        format = '0-9A-Za-z'
        break
      case 'B':
        format = '0-9A-Z'
        break
      case 'C':
        format = 'A-Za-z'
        break
      case 'F':
        format = '0-9'
        break
      case 'L':
        format = 'a-z'
        break
      case 'U':
        format = 'A-Z'
        break
      case 'W':
        format = '0-9a-z'
        break
    }
    return '([' + format + ']{' + repeats + '})'
  })

  return new RegExp('^' + regex?.join('') + '$')
}

/**
 * @param iban
 * @returns {string}
 */
function electronicFormat(iban: string): string {
  if (!iban) return ''
  return removeNonAlphaNumericChars(iban).toUpperCase()
}

class Specification {
  countryCode: string
  length: number
  structure: any
  exampleIban: string
  _cachedRegex: RegExp
  constructor(countryCode: string, length: number, structure: any, exampleIban: string) {
    this.countryCode = countryCode
    this.length = length
    this.structure = structure
    this._cachedRegex = parseStructure(structure)
    this.exampleIban = exampleIban
  }
  _regex() {
    return this._cachedRegex
  }
  isValid(iban: string) {
    return (
      this.length == iban.length &&
      this.countryCode === iban.slice(0, 2) &&
      this._regex().test(iban.slice(4)) &&
      iso7064Mod97_10(iso13616Prepare(iban)) == 1
    )
  }
  isValidBBAN(bban: string) {
    return this.length - 4 == bban.length && this._regex().test(bban)
  }
}

const countries: Record<string, Specification> = {}

function addSpecification(ibanSpec: Specification) {
  countries[ibanSpec.countryCode] = ibanSpec
}

addSpecification(new Specification('BG', 22, 'U04F04F02A08', 'BG80BNBG96611020345678'))
addSpecification(new Specification('ES', 24, 'F04F04F01F01F10', 'ES9121000418450200051332'))
addSpecification(new Specification('FR', 27, 'F05F05A11F02', 'FR1420041010050500013M02606'))
addSpecification(new Specification('GB', 22, 'U04F06F08', 'GB29NWBK60161331926819'))
addSpecification(new Specification('LT', 20, 'F05F11', 'LT121000011101001000'))
addSpecification(new Specification('LU', 20, 'F03A13', 'LU280019400644750000'))
addSpecification(new Specification('NL', 18, 'U04F10', 'NL91ABNA0417164300'))

// The following are regional and administrative French Republic subdivision IBAN specification (same structure as FR, only country code vary)
addSpecification(new Specification('GF', 27, 'F05F05A11F02', 'GF121234512345123456789AB13'))
addSpecification(new Specification('GP', 27, 'F05F05A11F02', 'GP791234512345123456789AB13'))
addSpecification(new Specification('MQ', 27, 'F05F05A11F02', 'MQ221234512345123456789AB13'))
addSpecification(new Specification('RE', 27, 'F05F05A11F02', 'RE131234512345123456789AB13'))
addSpecification(new Specification('PF', 27, 'F05F05A11F02', 'PF281234512345123456789AB13'))
addSpecification(new Specification('TF', 27, 'F05F05A11F02', 'TF891234512345123456789AB13'))
addSpecification(new Specification('YT', 27, 'F05F05A11F02', 'YT021234512345123456789AB13'))
addSpecification(new Specification('NC', 27, 'F05F05A11F02', 'NC551234512345123456789AB13'))
addSpecification(new Specification('BL', 27, 'F05F05A11F02', 'BL391234512345123456789AB13'))
addSpecification(new Specification('MF', 27, 'F05F05A11F02', 'MF551234512345123456789AB13'))
addSpecification(new Specification('PM', 27, 'F05F05A11F02', 'PM071234512345123456789AB13'))
addSpecification(new Specification('WF', 27, 'F05F05A11F02', 'WF621234512345123456789AB13'))

export function isValidIban(iban: string) {
  if (!iban) return true
  if (!isString(iban)) return false
  iban = electronicFormat(iban)
  const countryStructure = countries[iban.slice(0, 2)]
  return !!countryStructure && countryStructure.isValid(iban)
}
