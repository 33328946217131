import raEnglishMessages from 'ra-language-english'

import { ReactAdminTranslationMessage } from './types'

export const englishMessages: ReactAdminTranslationMessage = {
  language: {
    name: 'Language',
    english: 'English',
    french: 'French',
  },
  error: {
    lengthExceeded: 'Value is too long',
    invalidPhone: 'Invalid phone number',
    invalidIban: 'Invalid IBAN number',
    invalidEmail: 'Invalid email',
    invalidSiren: 'Invalid SIREN number',
    companyNotFound: 'You are not associated with a company',
    invalidNetSalary: 'Net salary must be superior to 0',
    invalidSalaryRefund: 'Expense report must be superior to 0',
    invalidSalaryFraction: {
      outOfRange: 'Value must be between 0 and 100',
      decimalNumber: 'Value must be an integer',
    },
    invalidStaffNumber: 'This staff number already exist',
    invalidServiceActivation: "Value should be 'yes' or 'no'",
    invalidDate: 'Invalid date: date format should be french or ISO',
    invalidStartDate: 'The date must be less than the end date',
    invalidEndDate1: 'Date must be greater than start date',
    invalidEndDate2: "Date must be greater than today's date",
    required: 'Required field',
    uniqueness: {
      unspecific: 'Value already exist for this field',
      iban: 'The IBAN provided is already used',
      email: 'The email provided is already used',
      phone: 'The phone number provided is already used',
    },
    stringRequired: 'Value format must be "Plain Text"',
  },
  unauthorized: {
    youAreNotAuthorized: 'You are not authorized to use this app 😢',
    chooseAnotherTenant: 'Switch to another organization :',
    goToEmployeeApp: 'As a user, you may use the NessPay application :',
    goToEmployeeAppButton: 'Go to the user app 🚀',
  },
  notifications: {
    onboarding: {
      createAccount: {
        success: 'Your account has been successfully created',
        error: 'An error has occurred while creating your account',
      },
      bookAppointment: {
        success: 'Your appointment request has been taken into account',
        error: 'An error occurred while making your appointment',
      },
      sendInvites: {
        success: 'Your employees have been invited to Nesspay!',
        error: 'An error occurred while sending invitations',
      },
    },
    createOrganization: {
      success: 'The organization was successfully created',
      error: 'Error : there was an issue with the organization creation',
    },
    createFakeData: {
      success: 'The data was successfully created',
      error: 'Error : there was an issue with the data creation',
    },
    cancelTransaction: {
      success: 'The earned wages request was successfully cancelled',
      error: 'Error : the earned wages request could not be cancelled',
    },
    deleteIntegration: {
      error: 'Error : the integration could not be removed',
      success: 'Integration successfully removed',
    },
    silaeConnect: {
      error: 'Silae connection failed',
      success: 'Silae connection succeeded',
    },
    komboConnect: {
      error: 'Connection to HR software failed',
      success: 'Connection to HR software succeeded',
    },
    organizationEdit: {
      error: 'Failed to update organization',
      success: 'Organization updated successfully',
    },
    userCreate: {
      error: 'Failed to create user',
      success: 'User created successfully',
    },
    userEdit: {
      error: 'Failed to update user',
      success: 'User updated successfully',
      refundModal: {
        success: 'Note de frais ajoutée avec succès',
        error: "Erreur lors de l'ajout de la note de frais",
      },
    },
    profileEdit: {
      error: 'Failed to update your profile',
      success: 'Profile updated successfully',
    },
    advanceServiceSwitchEdit: {
      true: 'Nesspay access enabled',
      false: 'Nesspay access disabled',
    },
    roleManagementTab: {
      removeSuccess: {
        undefined: 'Roles removed successfully',
        admin: 'Nesspay admin removed successfully',
        employer: 'Admin removed successfully',
        analyst: 'Analyst removed successfully',
        employee: 'Employee removed successfully',
        accountManager: 'CSM removed successfully',
      },
      removeError: {
        undefined: 'An error occured while deleteing roles',
        admin: 'An error occurred while deleting the Nesspay administrator',
        employer: 'An error occurred while deleting the administrator',
        analyst: 'An error occurred while deleting the analyst',
        employee: 'An error occurred while deleting the employee',
        accountManager: 'An error occurred while deleting the CSM',
      },
      addSuccess: {
        employer: 'Admin added successfully',
        analyst: 'Analyst added successfully',
        accountManager: 'CSM added successfully',
      },
      addError: {
        employer: 'An error occurred while adding the administrator',
        analyst: 'An error occurred while adding the analyst',
        accountManager: 'An error occurred while adding the CSM',
      },
      editSuccess: {
        employer: 'Admin updated successfully',
        analyst: 'Analyst updated successfully',
      },
      editError: {
        employer: 'An error occurred while updating the administrator',
        analyst: 'An error occurred while updating the analyst',
      },
      onlyOneAdminWarning: 'You cannot delete this admin. You must have an admin.',
    },
    copyToClipboard: {
      error: 'An error occurred while copying',
      success: 'Copied to clipboard',
    },
    apiTab: {
      createToken: {
        error: 'An error occurred while creating the token',
        success: 'Token created successfully',
      },
      deleteToken: {
        error: 'An error occurred while deleting the token',
        success: 'Token deleted successfully',
      },
    },
    financing: {
      requestLoan: {
        error: 'An error occurred during the loan request',
        success: 'Loan request successful',
      },
    },
    transactionsList: {
      insufficientBalance:
        'The available balance of the account is not sufficient for the sum of the transfers',
      loanRequested: 'A loan has just been requested',
      loanAlreadyRequested:
        'A loan has already been requested in the last 24 hours. Please try again in a few moments',
    },
    intercomSync: {
      success: 'Successful synchronization',
      error: 'Synchronization failed',
    },
    invalidCode: 'Invalid code',
  },
  buttons: {
    admin: 'admin',
    logout: {
      main: 'Logout',
      title: 'Sign out ?',
      confirm: 'Yes, sign out',
      cancel: 'Cancel',
    },
    analytics: 'Dashboard',
    bank: 'Wallet',
    financing: 'Financing',
    settings: 'Settings',
    intercom: {
      open: 'Close chat',
      close: 'Open chat',
      faq: 'FAQs',
      needHelp: 'Need Help ?',
    },
    save: 'Save',
    import: {
      importFile: 'Import file',
      replaceFile: 'Replace file',
      backToUsers: 'Back to users',
      nextStep: 'Next',
      prevStep: 'Back',
      updateFileContent: 'Revise',
      completeImport: 'Complete the import',
    },
    downloadImportTemplate: 'Download template',
    export: {
      name: 'Export',
      tooltip: 'Export data up to 2000 rows',
      format: { xls: '.xls Format', csv: '.csv Format', chooseFormat: 'Choose the file format :' },
      type: { raw: 'Raw export', silae: 'For SILAE', payfit: 'For Payfit' },
    },
    exportSilae: 'Export Silae',
    getStarted: 'Get Started',
    onboarding: 'Onboarding',
    close: 'Close',
    create: 'Add',
    remove: 'Remove',
    delete: 'Delete',
    validate: 'Validate',
    modify: 'Update',
    confirm: 'Confirm',
    swanInitiatePaymentsButton: {
      isLogged: 'Initiate credit transfers',
      isNotLogged: 'You are not connected to Swan',
    },
    intercomSync: 'Synchronize with Intercom',
  },
  filters: {
    date: {
      start: 'Start',
      end: 'End',
      clear: 'Clear the date',
    },
    columnsModal: 'Configuration',
  },
  userMenu: {
    administration: 'Administration',
    balance: 'Balance',
  },
  onboarding: {
    title: 'Onboarding',
    step: 'Step',
    outOf: 'Out of',
    createAccount: {
      title: 'Create your Nesspay account',
      helperText: 'I am the legal representative',
      fields: {
        company: 'Company name',
        hrSoftware: 'Payroll software',
        siren: 'SIREN',
      },
      button: 'Create my account',
      legalRepresentativeEmail: 'Legal representative email',
    },
    bookAppointment: {
      title: 'Hello, ',
      subtitle: 'Please choose an appointment slot to open your NessPay payment account.',
      requirements: {
        message: 'During your appointment, bring:',
        id: 'Your ID',
        computer: 'Your computer',
        phone: 'Your smartphone',
      },
      buttons: {
        book: 'See availability',
        done: "It's done !",
      },
    },
    connectHrSoftware: {
      title: 'Connect your payroll software',
      dialogTitle: 'Silae login',
      helperTexts: {
        1: 'Connect',
        2: 'in order to import your employees automatically onto NessPay',
        3: 'To do this, add the address',
        4: 'as an administrator on your account',
        5: 'To do this, subscribe to the contract',
        6: 'Provision of an API catalog',
        7: 'on your account',
      },
      buttons: {
        how: 'How?',
        done: "It's done!",
        connectSilae: 'Connect SILAE',
      },
      reviewInProgress: 'Your account is being validated by the NessPay team',
      notLegalRepresentative:
        'Make sure your legal representative has given you a power of attorney',
    },
    fundAccount: {
      title: 'Fund your account',
      helperText:
        'Please make a transfer of the desired amount to the account below so that your employees can start using Nesspay.',
      buttons: {
        how: 'How?',
        done: "It's done!",
      },
    },
    sendInvitationEmails: {
      title: 'Congratulations, you are ready <br /> to use NessPay.',
      button: 'Send invitation emails to my employees',
    },
    helperText:
      "Veillez à finaliser les étapes d'inscription pour avoir entièrement accès à l'application",
    discoverNessPay: 'Discover NessPay',
    createEmployee: 'Create a user',
    reloadAccount: 'Reload your NessPay account balance',
  },
  dashboard: {
    title: 'Dashboard',
    dateSelector: {
      currentMonth: 'Current month',
      lastThreeMonths: 'Current trimester',
      lastYear: 'Current year',
    },
    balance: 'Balance',
    registrationRate: 'Registration rate (Total)',
    numberOfTransactions: 'Transactions',
    averageAmount: 'Average amount',
    loggedUsersSeries: 'Evolution of the number of employees with access to the NessPay service',
    transactionsSeries: 'Evolution of the number of transactions',
    averageTransactionsSeries: 'Evolution of the average amount of transactions',
  },
  refundModal: {
    title: 'Add an expense report',
    subtitle: 'Enter an expense report amount to be credited',
  },
  bank: {
    title: 'Wallet',
    downloadStatements: 'Download your statements',
    downloadDetails: 'Download',
    fund: {
      title: 'Fund your wallet',
      helperText: 'Please transfer the desired amount using the bank details provided below',
    },
    tooltipCopy: 'Copy to clipboard',
    onboarding: {
      title: 'Bank account opening',
      openSwanOnboarding: 'Open the onboarding process',
      openSwanDashboardOnboarding: 'Open the onboarding process on Swan Dashboard',
      statusTitle: 'Onboarding progress states',
      isInformationCollected: 'All the onboarding process information was collected',
      isUsersVerified: 'All legal representatives are verified',
      isDocumentsCollected: 'All company documents are collected',
      isDocumentsVerified: 'All company documents are verified',
      accountAvailableSoon:
        'All steps have been successfully completed, your account will be available in a few hours!',
      circleProgress: 'Your progress:',
    },
    details: {
      title: 'Bank details',
      helperText: 'First transfer. Share your IBAN or use it to fund your account',
      bic: 'BIC / SWIFT',
      institutionNameTitle: 'Name of the financial institution',
      institutionName: 'Swan',
      institutionAdressTitle: 'Financial institution address',
      institutionAdress: '28 rue des Paradisiers, 75 001 Paris',
      footer: 'powered by Swan',
    },
    supplies: {
      title: 'Your latest supplies',
      helperText: 'No supply in the last six months',
    },
    downloadModal: {
      title: 'Download account statements',
      subtitle: 'Please select the format and period of your bank statement',
      csvButton: 'CSV format',
      pdfButton: 'PDF format',
      loading: 'We prepare your file ',
      helperTexts: {
        1: 'The download may take up to 30 seconds',
        2: 'Nothing is happening?',
        3: 'Click in the url of your browser and check that it does not block the download',
        4: 'Check that your ad blockers are not preventing the download (Adblock, uBlock, AdGuard...)',
      },
    },
    pdfContent: {
      title: 'Bank details statement',
      institution: 'Financial institution',
      downloadedBy: 'Downloaded by',
      downloadedOn: 'On',
      downloadedAt: 'at',
      bankCode: 'Bank code',
      branchCode: 'Branch code',
      accountNumber: 'Account Number',
      RIBKey: 'RIB key',
    },
  },
  financing: {
    title: 'Financing',
    infoTab: {
      title: 'INFORMATIONS',
      capacity: 'Financing capacity',
      expirationDate: 'Expiration date',
      outstanding: 'Outstanding',
      balance: 'Current balance',
      debitDate: 'Next direct debit date',
      debitAmount: 'Next direct debit amount',
    },
    loansTab: {
      title: 'Loan applications',
      table: {
        reference: 'Reference',
        submittedAt: 'Submitted at',
        amount: 'Amount',
        status: 'Status',
        toPayAt: 'To receive the',
        toRepayAt: 'To repay before',
        type: 'Type',
      },
      createBorrower: {
        message: 'You have not yet activated your financing capacity',
        button: 'Enable',
        dialog: {
          title: 'Create a borrower',
          subtitle1: 'Legal representative information',
          subtitle2: 'Banking information',
          country: 'Country of residence',
          nationality: 'Nationality',
          email: 'Professional email',
          birthdate: 'Birthdate',
          siren: 'SIREN',
          tosHelperText: 'Before continuing, please accept',
          TOS: 'the terms of service.',
          iban: 'IBAN',
          button: 'Button',
        },
      },
      createLoanRequest: {
        title: 'Loan request',
        message: 'Loan request informations',
        toPayAt: 'Loan date',
        toRepayAt: 'Loan repayment date',
        button: 'Make a new request',
      },
    },
    paymentsTab: {
      title: 'Loan payments',
      paidAt: 'Paid at',
    },
    creditLinesTab: {
      title: 'Credit lines',
      table: {
        contractNumber: 'Contract',
        balanceAvailable: 'Available balance',
        feesDailyRate: 'Daily fees rate',
        startDate: 'Start date',
        endDate: 'End date',
      },
    },
    borrowerTab: {
      title: 'Borrower status',
      message: 'Borrower informations',
      tosSignedAt: 'TOS signed at',
    },
    billsTab: {
      title: 'Bills',
      table: {
        id: 'Id',
        dueDate: 'Due date',
        status: 'Status',
        download: 'Download',
      },
    },
  },
  months: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'Décember',
  },
  admin: {
    createOrganization: {
      title: 'New organization',
      description:
        'Choose a name for the new organization. Super admins will be automatically associated with this organization once created.',
    },
    createFakeData: {
      title: 'Demo data',
      description:
        'Create demo data (randomly generated) with the following parameters: 1. number of users to create, 2. number of down payment requests to create, 3. up to how many days in the past the data can be generated . If the number of users is left at 0, then down payment requests will be assigned to existing users.',
      nbOfUsers: 'Number of users',
      nbOfTransactions: 'Number of wage requests',
      nbOfDaysFromNow: 'Earliest date data will be generated',
    },
  },
  administration: {
    profile: {
      title: 'Profile',
      cookies: 'Cookie management',
      reminderHelperText:
        "How often you want to be reminded so you don't forget to update your Nesspay user base:",
      reminderFrequency: {
        weekly: 'Every week',
        bimonthly: 'Every two weeks',
        monthly: 'Every month',
      },
    },
    companyTab: {
      title: 'Company',
      bic: 'BIC',
      bankName: 'Name of financial establishment',
    },
    authentication: {
      title: 'Authentication',
      title2: 'Setup the 2FA authentication',
      instructions:
        'You can find the instructions for setting up 2FA authentication on the FAQ article available here.',
      scanQrCode: 'Scan the following QR code with the authenticator application of your choice:',
      enterToken: 'Input the single use code displayed on your authenticator application :',
      tokenInputLabel: 'Single use code',
      verifyToken: 'Verify the code',
      mfaEnabled: '2FA authentication is enabled 🚀',
      recoveryCodeInstructions:
        '⚠️ Please take note of the following restoration code. This will only be displayed once and you will need it if you can no longer login:',
      disableMfa: 'Deactivate 2FA authentication.',
      disableMfaNote:
        'Note: If you previously disabled 2FA, remember to replace the account on your authenticator app before enabling 2FA again..',
      notifyCouldNotDetermineIfMfaEnabled: '2FA status could not be determined',
      notifyCodeVerified: 'The code was successfully verified',
      notifyCouldNotVerifyCode: 'The code could not be verified',
      notify2FADisabled: '2FA authentication disabled',
      notifyCouldNotDisable2FA: 'The 2FA authentication could not be disabled',
    },
    hrSoftware: 'HR & payroll integration',
    integration: {
      configured: 'Your integration with %{type} is configured 🚀.',
      deactivate: 'To deactivate your integration, please contact the support.',
      delete: 'Delete the integration (cannot be undone)',
      silae: {
        name: 'Silae',
        connect: 'Connect to Silae',
      },
      rollee: {
        name: 'Rollee',
        connect: 'Connect to Rollee',
        reconnect: 'Reconnect to Rollee',
        reconnectDescription: 'We detected that your Rollee session is still active.',
      },
      kombo: {
        name: 'Other payroll / HR software',
        connect: 'Connect your payroll / HR software',
        disconnect: 'Disconnect your payroll / HR software',
      },
      sync: 'Start synchronization',
    },
    silaeHelperTexts: {
      instructions: 'instructions',
      0: 'Complete this SILAE login request form: ',
      1: 'Choose the model "Model 1A: Internal payroll use"',
      2: 'Following completion of this form, you (or your payroll firm) will receive, from Silae, access to their API portal',
      3: 'Fill in the information received in the fields below:',
      formLink: 'SILAE form',
    },
    roleManagementTab: {
      title: 'Role management',
      account: 'Account',
      allAccounts: 'All',
      actions: 'Actions',
      addButton: 'Add an account',
      mainText: 'Administrator Accounts',
      secondaryText:
        'Find here all the active administrators within your company. You can add or remove an administrator.',
      adminHelperText: 'Administrator: Read, write and manage roles',
      analystHelperText: 'Analyst: Read only',
      dialogTitle: {
        undefined: {
          hasManyRoles: {
            true: 'Remove admin roles',
            false: 'Delete admin',
          },
        },
        employer: {
          hasManyRoles: {
            true: 'Remove admin',
            false: 'Delete admin',
          },
        },
        analyst: {
          hasManyRoles: {
            true: 'Remove analyst',
            false: 'Delete analyst',
          },
        },
        employee: {
          hasManyRoles: {
            true: 'Remove user',
            false: 'Delete user',
          },
        },
        accountManager: {
          hasManyRoles: {
            true: 'Remove CSM',
            false: 'Delete CSM',
          },
        },
        bulkDelete: 'Delete users',
      },
      dialogText: {
        undefined: {
          hasManyRoles: {
            true: 'Are you sure you want to remove this user admin roles? Following this action, the NessPay service user role will still be active',
            false: 'Are you sure you want to delete this admin?',
          },
        },
        employer: {
          hasManyRoles: {
            true: 'Are you sure you want to remove this user admin role? Following this action, the NessPay service user role will still be active',
            false: 'Are you sure you want to delete this admin?',
          },
        },
        analyst: {
          hasManyRoles: {
            true: 'Are you sure you want to remove this user analyst role? Following this action, the NessPay service user role will still be active',
            false: 'Are you sure you want to delete this analyst?',
          },
        },
        employee: {
          hasManyRoles: {
            true: 'Are you sure you want to remove this user? Following this action, other roles the user had will still be active',
            false: 'Are you sure you want to delete this user?',
          },
        },
        accountManager: {
          hasManyRoles: {
            true: 'Are you sure you want to remove this user CSM role? Following this action, other roles the user had will still be active',
            false: 'Are you sure you want to delete this CSM?',
          },
        },
        bulkDelete:
          'Are you sure you want to remove these users? Following this action, other roles users had will still be active',
      },
      permissions: {
        header: 'Rights',
        manager: 'Read, Write, manage roles',
        analyst: 'Read only',
      },
      addUserDialog: {
        dialogTitle: 'Add an account',
        existingUserTitle: 'This role is for :',
        existingUserSubtitle: 'Existing users',
        existingUserRadio: 'A user of the NessPay service',
        newUserTitle: 'This account is for :',
        newUserRadio: 'A person outside the company',
      },
      updateUserDialog: {
        dialogTitle: 'Edit account',
        dialogSubtitle: 'Edit account of: :',
      },
      tooltips: {
        edit: 'Edit roles',
        remove: 'Remove roles',
      },
    },
    labels: {
      silaeNumerosDossiers: 'Silae payrolls numbers',
      silaeServerUrl: 'Silae server URL',
      silaeId: 'Identifier',
      silaePassword: 'Password',
      silaeWebServiceId: 'Customer Web Service Identifier',
      silaeWebServicePassword: 'Customer Web Service Password',
    },
    apiTab: {
      title: 'API',
      mainText: 'Access tokens',
      secondaryText:
        'Find here all the active tokens within your company. You can add or remove a token.',
      addButton: 'Add a token',
      table: {
        tokenId: 'Token Id',
        createdAt: 'Creation date',
        action: 'Action',
        tooltip: 'Delete token',
      },
      dialog: {
        title: 'Your token',
        tooltip: 'Copy token',
        helperTexts: {
          1: "Be careful: you won't be able to see your access token again!",
          2: 'Make sure to copy your token now.',
        },
      },
      confirm: {
        title: 'Delete token',
        helperTexts: {
          1: "Be careful: this action can't be reversed!",
          2: 'Are you sure you want to delete this token?',
        },
      },
    },
  },
  resources: {
    contract: {
      helperTexts: {
        user: {
          firstName: 'Search by first name...',
          lastName: 'Search by last name...',
        },
        importTab: {
          steps: {
            0: 'File selection',
            1: 'File verification',
            2: 'Import',
          },
          errorMessage: {
            fileError:
              'A value is wrong or is duplicated in the file |||| Some values are wrong or are duplicates in the file',
            dbError:
              'A value already exists in the database |||| Some values already exist in the database',
          },
          uploadFile: 'Upload an Excel file to create, delete or modify collaborators',
          validFileUploaded: 'Great, your file is in the correct format!',
          invalidFileUploaded: "Your file isn't an Excel file ! (.xlsx ou .csv)!",
          noFileSelected: 'No file selected',
          numberFormatHelperText:
            'Please import a file whose numbers are in US formats (example: 1,355.50€)',
          missingColumnName: 'Oh, oh, an error was found',
          noColumnsMissing: 'Great, your file is ready!',
          allColumnsMatch: 'All properties have a match',
          columnDidntMatch: 'A property has no match',
          requiredColumnDidntMatch: 'A required property has no match',
          columnDidntMatchSubtitleStart: 'To complete your import, you must have a ',
          columnDidntMatchSubtitleEnd: ' as NessPay property',
          userColumnsNames: 'Titre de la colonne du fichier',
          nesspayColumnsNames: 'Propriété NessPay',
          ignoreField: 'Ignorer',
          noCellsMissingTitle: 'No properties are missing',
          noCellsMissingSubtitle: 'Cells are correctly filled in',
          missingCellsTitle: 'Mandatory properties missing',
          missingCellsSubtitle:
            'cell is empty or incorrectly filled in |||| cells are empty or incorrectly filled in',
          importIsReady: 'You are now ready to import!',
          importHasFailed: 'Something went wrong...',
          importHasSucceded: 'Successful import!',
          createSummary: 'Collaborator to add |||| Collaborators to add',
          createFinalSummary: 'Collaborator added |||| Collaborators added',
          modifySummary: 'Collaborator to update |||| Collaborators to update',
          modifySummaryFinal: 'Collaborator updated |||| Collaborators updated',
          deleteSummary: 'Collaborator to delete |||| Collaborators to delete',
          deleteSummaryFinal: 'Collaborator deleted |||| Collaborators deleted',
          importInProgress:
            'Import in progress... Please do not refresh or close this page until the import is complete',
          displayAllRows: 'Show only rows with invalid values',
          createDialog: 'user will be added |||| users will be added',
          createDialogSuccess: 'user added |||| users added',
          updateDialog: 'user will be updated |||| users will be updated',
          updateDialogSuccess: 'user updated |||| users updated',
          deleteDialog: 'user will be deleted |||| users will be deleted',
          deleteDialogSuccess: 'user deleted |||| users deleted',
          flushImportTitle: 'A file import is in progress',
          flushImportConfirm: 'Import a new file',
          flushImportContinue: 'Continue import',
        },
      },
      addImportTabs: {
        create: 'Add a user',
        import: 'Import a file',
      },
      roles: {
        employee: 'User |||| Users',
        employer: 'Admin |||| Admins',
        analyst: 'Analyst |||| Analysts',
        admin: 'NessPay Admin |||| NessPay Admins',
        accountManager: 'NessPay CSM |||| NessPay CSMs',
      },
      exportModal: {
        title: 'How would you like to export your users ?',
      },
      bulkUpdateModal: {
        title: 'Edit many users',
      },
      usageStatus: {
        NeverLoggedIn: 'Never logged in',
        LoggedInAtLeastOnce: 'Logged in at least once',
      },
      accessValue: {
        yes: 'Yes',
        no: 'No',
      },
      swanStatus: {
        booked: 'Booked',
        canceled: 'Canceled',
        pending: 'Pending',
        rejected: 'Rejected',
        released: 'Released',
        upcoming: 'Upcoming',
      },
      fields: {
        id: 'id',
        silaePayrollFolder: 'Silae payroll',
        entityName: 'Entity',
        isAdvanceServiceEnabled: 'NessPay service',
        availableSalaryFraction: 'Down payment allowed',
        contractEndDate: 'Contract end date',
        contractStartDate: 'Contract start date',
        createdAt: 'Created at',
        netSalary: 'Net salary',
        organization: {
          name: 'Company',
          canEmployeeRequest: 'NessPay service',
        },
        organizationId: 'Organization Id',
        roles: 'Role',
        staffNumber: 'Staff number',
        updatedAt: 'Modified at',
        usageStatus: 'Status',
        active: 'Active',
        hasAdvancesEnabled: 'Advances enabled',
        isAppAccessEnabled: 'App access enabled',
        isNotAbsent: 'Present (not absent)',
        refundSalary: 'Expense report',
        user: {
          id: 'id',
          createdAt: 'Created at',
          email: 'Email',
          firstName: 'First name',
          fronteggId: 'Frontegg Id',
          iban: 'IBAN',
          lastName: 'Name',
          phone: 'Phone',
          socialSecurityNumber: 'Social security number',
          swanId: 'SwanId',
          updatedAt: 'Modified at',
        },
        userId: 'User Id',
        inviteRemindersSent: 'Invite reminders sent',
        availableBalance: 'Available balance',
      },
    },
    organization: {
      name: 'Company',
      pluralName: 'Companies',
      helperTexts: {
        bufferDays: 'In number of days. Value between 0 and 15.',
        payPeriodStart:
          'Chosen date in the month. Value between 1 and 30. To modify it please contact the support.',
        iban: 'You can make a SEPA transfer to the above account in order to top-up the available balance for your users.',
      },
      fields: {
        id: 'id',
        integrations: 'integrations',
        contracts: 'contracts',
        transactions: 'transactions',
        alertBalance: 'Alert balance',
        bufferDays: 'Number of differed days payroll',
        isToutesMesAidesEnabled: 'Klaro service enabled',
        payPeriodStart: 'Start date of payroll',
        name: 'Name',
        swanAccountId: 'Swan account id',
        swanOnboardingId: 'Swan onboarding id',
        isSwanOnboardingComplete: 'Swan onboarding complete',
        canEmployeeRequest: 'Advances service enabled',
        createdAt: 'Created at',
        updatedAt: 'Modified at',
        mergeAccountToken: 'Merge account token',
        activityLogs: 'Activity logs',
        reminderFrequencyUpdateUsers: 'Reminder frequency update users',
        defaultNetSalary: 'Default net salary',
        defaultAvailableSalaryFraction: 'Default Available Salary Fraction',
        borrowerId: 'Borrower id',
        borrowingLoanAmount: 'Loan amount',
        borrowingRepaymentDelay: 'Repayment delay',
        borrowingEnabled: 'Borrowing enabled',
        sendInvitationEmails: 'Send invitation emails',
        klaroPrivateKey: 'Klaro private key',
        encryptionIV: 'encryption IV',
        onboarding: 'Onboarding',
      },
    },
    transactions: {
      name: 'Transaction',
      pluralName: 'Transactions',
      exportModal: {
        title: 'How would you like to export your transactions ?',
      },
      status: {
        done: 'validated',
        inprogress: 'in progress',
        cancel: 'canceled',
      },
      createdDate: 'Created on',
      tooltips: {
        cancel: 'Cancel the earned wages request',
      },
      fields: {
        id: 'id',
        amount: 'Amount',
        status: 'Status',
        swanId: 'Swan id',
        swanStatus: 'Swan status',
        swanConsentId: 'Swan consent id',
        pendingSwanConsent: 'Pending Swan consent',
        swanExecutionDate: 'Execution date',
        swanBookingDate: 'Booking date',
        swanPaymentId: 'Swan payment id',
        organization: 'company',
        organizationId: 'company id',
        user: 'user',
        userId: 'user id',
        createdAt: 'created at',
        updatedAt: 'modified at',
        entityName: 'EntityName',
      },
    },
    user: {
      name: 'User',
      helperTexts: {
        searchbar: 'First name, last name, email, IBAN...',
        selectInput: 'Select',
      },
      tooltips: {
        deactivate: 'Delete user |||| Remove user',
        delete: "Delete user [ADMINS] Warning, this action can't be reversed",
      },
      emptySelection: '-- none --',
      modifiedDate: 'Last update on',
      withdrawn: 'withdrawn on',
      fields: {
        contracts: 'contracts',
        transactions: 'transactions',
        fronteggId: 'id frontegg',
        id: 'id',
        iban: 'IBAN',
        phone: 'Phone',
        name: 'Name',
        firstName: 'First name',
        lastName: 'Name',
        organizationIds: "Organizations' IDs",
        email: 'Email',
        createdAt: 'Created at',
        updatedAt: 'Modified at',
        activityLogs: 'Activity logs',
        byActivityLogs: 'Activity logs',
        mfaEnabled: '2FA',
        metadata: 'Metadata',
      },
    },
    activity: {
      name: 'Activity log',
      pluralName: 'Activity logs',
      exportModal: {
        title: 'How would you like to export your activity logs ?',
      },
      fields: {
        id: 'Id',
        organization: 'Company',
        organizationId: 'Company id',
        user: 'User',
        userId: 'User id',
        createdAt: 'Created at',
        updatedAt: 'Modified at',
        action: 'Action',
        info: 'Information',
        entity: 'Entity',
        modifiedFields: 'Modified fields',
        byUser: 'By user',
        byUserId: 'By user id',
      },
    },
  },
  ra: { ...raEnglishMessages.ra },
}
