import { Box, Paper, Typography, SxProps, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslate, useSidebarState } from 'react-admin'
import { useQuery, useMutation } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { CustomHeader } from 'libs/components/CustomHeader'
import { ProgressBar } from 'libs/components/ProgressBar/ProgressBar'
import { usePersistedUser } from 'user/context'
import { Spacer, Row, Column } from 'utils/spacing'

import { BookAppointment } from './BookAppointment'
import { ConnectSoftwares } from './ConnectSoftwares'
import { CreateAccount } from './CreateAccount'
import { FundAccount } from './FundAccount'
import { SendInvitationEmails } from './SendInvitationEmails'

enum Steps {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  BOOK_APPOINTMENT = 'BOOK_APPOINTMENT',
  CONNECT_HR_SOFTWARE = 'CONNECT_HR_SOFTWARE',
  FUND_ACCOUNT = 'FUND_ACCOUNT',
  SEND_INVITATION_EMAILS = 'SEND_INVITATION_EMAILS',
}

type OnboardingStepsType = Steps

interface CircularProgressProps {
  progressPercent: number
  onboardingStep: OnboardingStepsType
}

const CircleProgress = ({ progressPercent, onboardingStep }: CircularProgressProps) => {
  const t = useTranslate()
  const theme = useTheme()
  const progress = progressPercent / 25 + 1
  return (
    <Column sx={progressBarSx(onboardingStep)}>
      {progress < 5 && (
        <Row>
          <Typography variant="h2" sx={{ fontWeight: 500 }}>
            {t('onboarding.step')} {progress}
          </Typography>
          <Typography>&nbsp;</Typography>
          <Typography variant="h2" sx={{ fontWeight: 500 }}>
            {t('onboarding.outOf')} {'4'}
          </Typography>
        </Row>
      )}
      <Spacer y={2} />
      <ProgressBar
        variant="Onboarding"
        progress={progressPercent}
        size={230}
        strokeWidth={20}
        circleOneStroke={theme.palette.text.secondary}
        circleTwoStroke={theme.palette.primary.main}
      />
    </Column>
  )
}

export const OnboardingPage = () => {
  const api = useApi()
  const t = useTranslate()
  const [_, setSidebarOpen] = useSidebarState()
  const { isSwanActive, organization, setOrganization } = usePersistedUser()

  const [progressPercent, setProgressPercent] = useState<number>(0)
  const [onboardingStep, setOnboardingSteps] = useState<OnboardingStepsType>(Steps.CREATE_ACCOUNT)

  const { data: swanAccount } = useQuery(
    QueryKey.SwanAccount,
    async () => await api.swanControllerGetAccount(),
    { onError: console.error }
  )

  const accountBalance = swanAccount?.account?.balances?.available?.value

  const initOnboardingMutation = useMutation(QueryKey.UpdateOnboarding, async () => {
    const org = await api.organizationControllerUpdateOnboardingState({
      hasBookedAnAppointment: false,
      hasConnectedHrSoftware: false,
      hasAddedFundsOnAccount: false,
      hasCompletedOnboarding: false,
    })
    setOrganization(org)
  })

  useEffect(() => {
    switch (onboardingStep) {
      case Steps.CREATE_ACCOUNT:
        setProgressPercent(0)
        break
      case Steps.BOOK_APPOINTMENT:
        setProgressPercent(25)
        break
      case Steps.CONNECT_HR_SOFTWARE:
        setProgressPercent(50)
        break
      case Steps.FUND_ACCOUNT:
        setProgressPercent(75)
        break
      case Steps.SEND_INVITATION_EMAILS:
        setProgressPercent(100)
        break
      default:
        setProgressPercent(0)
        break
    }
  }, [onboardingStep])

  useEffect(() => {
    const { onboarding } = organization
    if (!onboarding) {
      initOnboardingMutation.mutate()
      return
    }
    setSidebarOpen(true)
    if (onboarding.hasAddedFundsOnAccount && Boolean(accountBalance)) {
      setOnboardingSteps(Steps.SEND_INVITATION_EMAILS)
    } else if (onboarding.hasConnectedHrSoftware && isSwanActive) {
      setOnboardingSteps(Steps.FUND_ACCOUNT)
    } else if (onboarding.hasBookedAnAppointment) {
      setOnboardingSteps(Steps.CONNECT_HR_SOFTWARE)
    } else if (Boolean(onboarding.siren)) {
      setOnboardingSteps(Steps.BOOK_APPOINTMENT)
    } else {
      setSidebarOpen(false)
      setOnboardingSteps(Steps.CREATE_ACCOUNT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, isSwanActive])

  const Footer = () => (
    <Row sx={footerSx(onboardingStep)}>
      <Typography>{t('onboarding.helperText')}</Typography>
      <Typography>&nbsp;</Typography>
      <Typography sx={{ fontWeight: 600 }}>{'NessPay'}</Typography>
    </Row>
  )

  return (
    <Box sx={{ height: '100%' }}>
      <CustomHeader title={t('onboarding.title')} />
      <Spacer y={4} />
      <Paper sx={paperSx(onboardingStep)}>
        <Row sx={{ position: 'relative' }}>
          <Column sx={columnSx(onboardingStep)}>
            {onboardingStep === Steps.CREATE_ACCOUNT && <CreateAccount />}
            {onboardingStep === Steps.BOOK_APPOINTMENT && <BookAppointment />}
            {onboardingStep === Steps.CONNECT_HR_SOFTWARE && <ConnectSoftwares />}
            {onboardingStep === Steps.FUND_ACCOUNT && <FundAccount />}
            {onboardingStep === Steps.SEND_INVITATION_EMAILS && <SendInvitationEmails />}
          </Column>
          <CircleProgress progressPercent={progressPercent} onboardingStep={onboardingStep} />
        </Row>
        {onboardingStep !== Steps.SEND_INVITATION_EMAILS && <Footer />}
      </Paper>
    </Box>
  )
}

const progressBarSx = (step: OnboardingStepsType): SxProps => ({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: step === Steps.SEND_INVITATION_EMAILS ? '100%' : '40%',
  position: step === Steps.SEND_INVITATION_EMAILS ? 'absolute' : 'static',
})

const footerSx = (step: OnboardingStepsType): SxProps => ({
  justifyContent: 'center',
  height: step === Steps.CREATE_ACCOUNT || step === Steps.BOOK_APPOINTMENT ? '50px' : '110px',
})

const columnSx = (step: OnboardingStepsType): SxProps => ({
  height: step === Steps.SEND_INVITATION_EMAILS ? '570px' : '520px',
  width: step === Steps.SEND_INVITATION_EMAILS ? '100%' : '60%',
})

const paperSx = (step: OnboardingStepsType): SxProps => ({
  p: 3,
  pb: 0,
  height: '100%',
  maxHeight: step === Steps.CREATE_ACCOUNT || step === Steps.BOOK_APPOINTMENT ? '570px' : '600px',
})
