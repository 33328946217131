import { FileDownload } from '@mui/icons-material'
import { Box, SxProps, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { TextField, DateField, ListBase, ListView, useTranslate, FunctionField } from 'react-admin'

import { User } from 'api/gen'
import { ListHeader } from 'layout/ListHeader'
import { CanAccess } from 'libs/auth'
import { SelectColumnsButton } from 'libs/columnModal/SelectColumnsButton'
import { CustomizableDatagrid } from 'libs/datagrid/CustomizableDatagrid'
import { ExportModal, ExportButton } from 'libs/export'
import { useModal } from 'libs/modal'
import { ActivityLogRecord, NESSPAY_ONLY_ROLES } from 'resources/types'
import { UserSearchBar } from 'resources/user/UserSearchBar'

import { CustomPagination, RowsPerPage } from '../Pagination'

import { activityLogListExporters } from './ActivityList.exporters'

const ActivityLogsListHeader = () => {
  const t = useTranslate()
  const { openModal } = useModal('export')

  return (
    <ListHeader
      actions={
        <>
          <CanAccess roles={NESSPAY_ONLY_ROLES}>
            <SelectColumnsButton />
          </CanAccess>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FileDownload />}
            onClick={openModal}>
            {t('buttons.export.name')}
          </Button>
        </>
      }
      filters={
        <>
          <UserSearchBar resource="activity" />
        </>
      }
    />
  )
}

export const ActivityLogsList = () => {
  const t = useTranslate()
  const { isModalOpen } = useModal('export')

  const FieldList = () => (
    <CustomizableDatagrid
      isRowSelectable={() => false}
      resource="activity"
      bulkActionButtons={undefined}>
      <TextField source="action" sx={justifyLeftSx} />
      <FunctionField source="byUser" render={renderUser} sx={justifyLeftSx} />
      <FunctionField source="user" render={renderUser} sx={justifyLeftSx} />
      <FunctionField source="entity" render={renderEntity} sx={justifyLeftSx} />
      <DateField source="createdAt" showTime sx={justifyLeftSx} />
      <TextField
        label="resources.activity.fields.organization"
        source="organization.name"
        sx={justifyLeftSx}
      />
    </CustomizableDatagrid>
  )

  return (
    <Box display="flex">
      <ListBase perPage={RowsPerPage.TEN} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Box sx={{ flexGrow: 1 }}>
          <ListView
            empty={false}
            title={t('resources.activity.pluralName')}
            filters={<ActivityLogsListHeader />}
            actions={false}
            pagination={<CustomPagination />}>
            <FieldList />
          </ListView>
        </Box>
        {isModalOpen && (
          <ExportModal>
            <Stack direction="row" spacing={20} sx={stackSx}>
              <ExportButton type="xls" exporter={activityLogListExporters.xls} />
              <ExportButton type="csv" exporter={activityLogListExporters.csv} />
            </Stack>
          </ExportModal>
        )}
      </ListBase>
    </Box>
  )
}

const stackSx: SxProps = { height: '75%', justifyContent: 'center', alignItems: 'center' }
const justifyLeftSx: SxProps = { display: 'flex', textAlign: 'left' }

function renderEntity({ entity }: ActivityLogRecord) {
  if (!entity) return 'none'
  let str = ''
  for (const [key, value] of Object.entries(entity)) {
    if (typeof value === 'string' && value.includes('==>')) {
      str += `<span style="color:red">• <b>${key} :</b> ${value}</span>\n`
    } else {
      str += `• <b>${key} :</b> ${value}\n`
    }
  }
  return (
    <Typography
      dangerouslySetInnerHTML={{ __html: str }}
      variant="body2"
      p={1}
      component="pre"></Typography>
  )
}

function renderUser(record: ActivityLogRecord, source: string | undefined) {
  // @ts-ignore : source should be either 'user' or 'byUser'
  const user = record[source] as User
  if (!user) return 'none'
  let str = `• ${user.email}\n`
  str += `• ${user.name}`
  return (
    <Typography
      dangerouslySetInnerHTML={{ __html: str }}
      variant="body2"
      component="pre"></Typography>
  )
}
