import { config } from 'config'

type IntercomUserInfos = {
  userId: string
  name: string
  email: string
  organizationId: string
  organizationName: string
}
export type Locale = 'fr' | 'en'

const articles = {
  '2FA_setup': (locale: Locale) =>
    `https://intercom.help/nesspay-b7927642140c/${locale}/articles/7946940-activer-l-authentification-a-2-facteurs-2fa`,
}

type Article = keyof typeof articles

function isIntercomInitialized() {
  return window && window.Intercom && window.Intercom.booted
}

function initializeIntercom(infos: IntercomUserInfos) {
  window.intercomSettings = {
    api_base: config.INTERCOM_API_URL,
    app_id: config.INTERCOM_APP_ID,
    hide_default_launcher: true,
    user_id: infos.userId || '',
    email: infos.email || '',
    name: infos.name,
    company: {
      company_id: infos.organizationId || '',
      name: infos.organizationName || '',
    },
  }
  ;(function () {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i: any = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments)
      }
      i.q = []
      i.c = function (args: any) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = config.INTERCOM_WIDGET_URL + config.INTERCOM_APP_ID
        const x = d.getElementsByTagName('script')[0]
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        x.parentNode!.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
}

export const intercom = {
  initialize(infos: IntercomUserInfos) {
    if (!isIntercomInitialized()) {
      initializeIntercom(infos)
    }
  },
  openChat() {
    if (!isIntercomInitialized()) return
    window.Intercom('showMessages')
  },
  closeChat() {
    if (!isIntercomInitialized()) return
    window.Intercom('hide')
  },
  openOnboardingTasks() {
    if (!isIntercomInitialized()) return
    window.Intercom('startChecklist', 33974804)
  },
  openHelpCenter(locale: Locale, article?: Article) {
    if (article) window.open(articles[article](locale), '_blank')
    else window.open(config.INTERCOM_HELP_CENTER_URL(locale), '_blank')
  },
}
