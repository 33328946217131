import { Close, ContentCopyRounded, WarningAmberRounded } from '@mui/icons-material'
import {
  Box,
  Typography,
  useTheme,
  styled,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  SxProps,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { Column, Spacer } from 'utils/spacing'

interface Props {
  token: string
}

export function DisplayApiTokenDialog(props: Props) {
  const { token } = props

  const t = useTranslate()
  const theme = useTheme()
  const notify = useNotifications()

  const { isModalOpen, closeModal } = useModal('displayApiToken')

  async function copyToken() {
    try {
      await navigator.clipboard.writeText(token)
      notify(t('notifications.copyToClipboard.success'), { variant: 'success' })
    } catch (error) {
      console.log(error)
      notify(t('notifications.copyToClipboard.error'), { variant: 'error' })
    }
  }

  return (
    <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="md">
      <DialogActions sx={{ height: '10px', mt: 1 }}>
        <StyledCloseButton size="small" onClick={closeModal}>
          <Close />
        </StyledCloseButton>
      </DialogActions>
      <DialogContent sx={{ height: '350px', padding: '0px 30px' }}>
        <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h2" sx={typographySx}>
            {t('administration.apiTab.dialog.title')}
          </Typography>
          <Tooltip title={t('administration.apiTab.dialog.tooltip')} placement="top">
            <StyledCopyButton onClick={copyToken}>
              <ContentCopyRounded sx={{ ...copyIconSx, color: theme.colors.GREY }} />
            </StyledCopyButton>
          </Tooltip>
        </Stack>
        <Box sx={{ ...boxSx, borderColor: theme.colors.YELLOW_DARK }}>
          <Typography variant="h3" sx={{ wordWrap: 'break-word' }}>
            {token}
          </Typography>
        </Box>
        <Spacer y={4} />
        <Stack direction="row" spacing={2}>
          <Column sx={{ dispay: 'flex', justifyContent: 'center' }}>
            <WarningAmberRounded sx={{ height: '40px', width: '40px', color: theme.colors.RED }} />
          </Column>
          <Column>
            <Typography>{t('administration.apiTab.dialog.helperTexts.1')}</Typography>
            <Typography>{t('administration.apiTab.dialog.helperTexts.2')}</Typography>
          </Column>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const typographySx: SxProps = { fontWeight: 500, textAlign: 'center', marginBottom: '20px' }
const copyIconSx: SxProps = { height: '15px', width: '15px', mb: '18px' }
const boxSx: SxProps = { border: '1px solid', padding: 2, borderRadius: '10px' }

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.text.secondary,
    border: 'none',
  },
}))

const StyledCopyButton = styled(IconButton)(({ theme }) => ({
  ':hover': { borderColor: theme.colors.WHITE, backgroundColor: theme.colors.WHITE },
  ':focus-within': { color: theme.colors.WHITE },
}))
