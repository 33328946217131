import { styled, Theme } from '@mui/material'
import { NumberInput, TextInput, DateInput } from 'react-admin'

function secondaryOutlinedStyle(theme: Theme) {
  return {
    marginTop: '-20px',
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFormHelperText-root': {
      fontSize: theme.typography.h5.fontSize,
      width: '100%',
      margin: 0,
    },
    '& .MuiOutlinedInput-root': {
      borderColor: theme.colors.WHITE,
      height: '35px',
      '& fieldset': { borderColor: theme.colors.BLUE_PASTEL },
      '&:hover fieldset': { borderColor: theme.palette.primary.main },
      '&:read-only fieldset': { borderColor: theme.colors.BLUE_PASTEL },
      '&:focus fieldset': { borderColor: theme.colors.BLUE_PASTEL },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: theme.typography.h4.fontSize,
      color: theme.palette.text.secondary,
    },
  }
}

export const SecondaryOutlinedTextInput = styled(TextInput)(({ theme }) => ({
  ...secondaryOutlinedStyle(theme),
}))

export const SecondaryOutlinedNumberInput = styled(NumberInput)(({ theme }) => ({
  ...secondaryOutlinedStyle(theme),
  // remove the arrows Mui displays on NumberInputs
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
}))

export const SecondaryOutlinedDateInput = styled(DateInput)(({ theme }) => ({
  ...secondaryOutlinedStyle(theme),
}))
