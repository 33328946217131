import { RequestQueryBuilder, CondOperator, QuerySortOperator } from '@dataui/crud-request'
import { useCallback, useMemo } from 'react'
import {
  CreateResult,
  DataProvider,
  DeleteResult,
  GetOneResult,
  UpdateResult,
  UpdateParams,
  RaRecord,
  Identifier,
} from 'react-admin'

import { Contract } from 'api/gen'
import { deepIterateOnObject } from 'utils/format'

import { useReactAdminClient } from './client'
import { getCrudSearch } from './constraints'

function getFieldsToJoin(resource: string) {
  if (resource === 'contract') return [{ field: 'organization' }, { field: 'user' }]
  if (resource === 'transactions') return [{ field: 'organization' }, { field: 'user' }]
  if (resource === 'activity')
    return [{ field: 'organization' }, { field: 'user' }, { field: 'byUser' }]
  if (resource === 'organization') return [{ field: 'integrations' }]
  return undefined
}

const setFalsyFieldsToNull = (element: any, parent: any, key: string) => {
  if ((typeof element !== 'string' && typeof element !== undefined) || element.length) return
  parent[key] = null
}

export function useApiDataProvider(): DataProvider {
  const dataClient = useReactAdminClient()

  const getList: DataProvider['getList'] = useCallback(
    async (resource, { pagination, sort, filter }) => {
      const query = RequestQueryBuilder.create({
        search: getCrudSearch(resource, filter),
        join: getFieldsToJoin(resource),
        sort: [{ field: sort.field, order: sort.order as QuerySortOperator }],
        limit: pagination.perPage,
        page: pagination.page,
      }).query()

      const path = `/${resource}?${query}`
      const { json } = await dataClient(path)
      const { total, data } = json

      if (resource === 'contract') {
        return {
          data: data.map((contract: Contract) => ({
            ...contract,
            hasAdvancesEnabled: !contract.isManuallyDisabledByAdmin,
          })),
          total,
        }
      }

      return { total, data }
    },
    [dataClient]
  )

  const getOne: DataProvider['getOne'] = useCallback(
    async (resource, { id }) => {
      const query = RequestQueryBuilder.create({
        join: getFieldsToJoin(resource),
      }).query()
      const path = `/${resource}/${id}?${query}`
      const { json } = await dataClient(path)

      if (resource === 'contract') {
        return {
          data: {
            ...json,
            hasAdvancesEnabled: !json.isManuallyDisabledByAdmin,
          },
        }
      }
      return { data: json } as GetOneResult
    },
    [dataClient]
  )

  const getMany: DataProvider['getMany'] = useCallback(
    async (resource, { ids }) => {
      const query = RequestQueryBuilder.create({
        join: [{ field: 'organization' }],
        filter: [{ field: 'id', operator: CondOperator.IN, value: ids }],
      }).query()
      const path = `/${resource}?${query}`
      const { json } = await dataClient(path)
      const { total, data } = json
      return { total, data }
    },
    [dataClient]
  )

  const update: DataProvider['update'] = useCallback(
    async (resource, { id, data }) => {
      deepIterateOnObject(data, setFalsyFieldsToNull)
      const { json } = await dataClient(`/${resource}/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })
      return { data: json } as UpdateResult
    },
    [dataClient]
  )

  const updateMany: DataProvider['updateMany'] = useCallback(
    async (resource, { ids, data, meta }) => {
      const updatedRecordsIds: Identifier[] = []
      for (const id of ids) {
        const params = { id, data, meta } as UpdateParams
        const { data: record } = await update<RaRecord>(resource, params)
        if (record.id) updatedRecordsIds.push(record.id)
      }
      return { data: updatedRecordsIds }
    },
    [update]
  )

  const create: DataProvider['create'] = useCallback(
    async (resource, { data }) => {
      deepIterateOnObject(data, setFalsyFieldsToNull)
      const { json } = await dataClient(`/${resource}`, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      return { data: json } as CreateResult<any>
    },
    [dataClient]
  )

  /* NOT IMPLEMENTED YET */
  const getManyReference: DataProvider['getManyReference'] = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (resource, params) => {
      return { data: [] }
    },
    []
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteFn: DataProvider['delete'] = useCallback(async (resource, params) => {
    return {} as DeleteResult<any>
  }, [])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteMany: DataProvider['deleteMany'] = useCallback(async (resource, params) => {
    return {}
  }, [])

  const authProvider = useMemo(
    () => ({
      getList,
      getOne,
      getMany,
      getManyReference,
      update,
      updateMany,
      create,
      delete: deleteFn,
      deleteMany,
    }),
    [getList, getOne, getMany, getManyReference, update, updateMany, create, deleteFn, deleteMany]
  )

  return authProvider
}
