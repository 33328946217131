import { CancelRounded, CheckCircleRounded } from '@mui/icons-material'
import {
  Button,
  Typography,
  useTheme,
  SvgIcon,
  Box,
  CircularProgress,
  SxProps,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { usePersistedUser } from 'user/context'
import { Column, Row, Spacer } from 'utils/spacing'

type StatusProps = {
  state: boolean
  message: string
}

export const SwanOnboarding = () => {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()
  const { organization } = usePersistedUser()

  const { data: onboarding, isLoading } = useQuery(
    QueryKey.SwanOnboarding,
    async () => await api.swanControllerGetOnboarding(),
    { onError: console.error }
  )

  function openSwanOnboarding() {
    if (!onboarding?.onboardingUrl) return
    window.open(onboarding.onboardingUrl, '_blank')
  }

  const Status = (props: StatusProps) => {
    const color = organization.swanAccountId
      ? theme.colors.GREY
      : props.state
      ? theme.palette.text.primary
      : theme.colors.GREY
    return (
      <Row>
        <SvgIcon sx={{ color: props.state ? theme.colors.GREEN : theme.colors.GREY }}>
          {props.state ? <CheckCircleRounded /> : <CancelRounded />}
        </SvgIcon>
        <Spacer x={3} />
        <Typography fontWeight={400} sx={{ color }}>
          {props.message}
        </Typography>
      </Row>
    )
  }

  const informationCollected = onboarding?.isInformationCollected ? true : false

  if (isLoading)
    return <Box sx={CircularProgressSx}>{<CircularProgress size={50} color="primary" />}</Box>

  return (
    <Column>
      <Button
        color="primary"
        variant="contained"
        disabled={informationCollected}
        onClick={openSwanOnboarding}
        sx={{ fontWeight: 400, width: '385px' }}>
        {t('bank.onboarding.openSwanOnboarding')}
      </Button>
      <Spacer y={3} />
      <Status
        state={onboarding?.isInformationCollected}
        message={t('bank.onboarding.isInformationCollected')}
      />
      <Spacer y={3} />
      <Status state={onboarding?.isUsersVerified} message={t('bank.onboarding.isUsersVerified')} />
      <Spacer y={3} />
      <Status
        state={onboarding?.isDocumentsCollected}
        message={t('bank.onboarding.isDocumentsCollected')}
      />
      <Spacer y={3} />
      <Status
        state={onboarding?.isDocumentsVerified}
        message={t('bank.onboarding.isDocumentsVerified')}
      />
    </Column>
  )
}

const CircularProgressSx: SxProps = {
  width: '55%',
  height: '225px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
