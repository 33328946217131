import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

import { AccessControlParams, useCanAccess } from './access'

interface ProtectedRouteProps extends AccessControlParams {
  redirectTo?: string
  children: ReactElement
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { roles, permissions, redirectTo = '/', children } = props
  const { canAccess } = useCanAccess()

  if (!canAccess({ roles, permissions })) {
    return <Navigate to={redirectTo} replace />
  }

  return children
}
