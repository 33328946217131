import React from 'react'
import { Pagination, PaginationProps } from 'react-admin'

export enum RowsPerPage {
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50,
  HUNDRED_FIFTY = 150,
}

export const CustomPagination: React.FC<PaginationProps> = (props) => {
  return (
    <Pagination
      rowsPerPageOptions={[
        RowsPerPage.TEN,
        RowsPerPage.TWENTY,
        RowsPerPage.FIFTY,
        RowsPerPage.HUNDRED_FIFTY,
      ]}
      {...props}
    />
  )
}
