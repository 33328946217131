import { LoadingButton } from '@mui/lab'
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SxProps,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { OrganizationOnboarding, SendPowerOfAttorneyReminderBody } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { StyledSwitch } from 'libs/components/StyledSwitch'
import { useNotifications } from 'libs/notifications'
import { useFormValidation } from 'libs/validation/form'
import { usePersistedUser } from 'user/context'
import { maskSIREN } from 'utils/format'
import { Column, Row, Spacer } from 'utils/spacing'

enum PayrollSoftware {
  SILAE = 'Silae',
  DEEL = 'Deel',
  EURECIA = 'Eurecia',
  FACTORIAL = 'Factorial',
  LUCCA = 'Lucca',
  PAYFIT = 'Payfit',
  SAGE = 'Sage',
}

interface FieldsProps {
  firstName: string
  setFirstName: (value: string) => void
  lastName: string
  setLastName: (value: string) => void
  email: string
  setEmail: (value: string) => void
  company: string
  setCompany: (value: string) => void
  hrSoftware: PayrollSoftware | null
  setHrSoftware: (value: PayrollSoftware) => void
  siren: string
  setSiren: (value: string) => void
}

interface LegalRepresentativeFieldsProps {
  legalRepresentativeFirstName: string
  setLegalRepresentativeFirstName: (value: string) => void
  legalRepresentativeLastName: string
  setLegalRepresentativeLastName: (value: string) => void
  legalRepresentativeEmail: string
  setLegalRepresentativeEmail: (value: string) => void
}

const LegalRepresentativeFields = (props: LegalRepresentativeFieldsProps) => {
  const t = useTranslate()
  const { email: validateEmail } = useFormValidation()

  const {
    legalRepresentativeFirstName,
    setLegalRepresentativeFirstName,
    legalRepresentativeLastName,
    setLegalRepresentativeLastName,
    legalRepresentativeEmail,
    setLegalRepresentativeEmail,
  } = props

  const legalRepresentativeEmailError = validateEmail(legalRepresentativeEmail)

  return (
    <>
      <Spacer y={2} />
      <Row>
        <TextField
          value={legalRepresentativeFirstName}
          onChange={(ev) => setLegalRepresentativeFirstName(ev.target.value)}
          label={t('resources.user.fields.firstName')}
          sx={{ width: '50%' }}
          autoComplete="off"
        />
        <Spacer x={2} />
        <TextField
          value={legalRepresentativeLastName}
          onChange={(ev) => setLegalRepresentativeLastName(ev.target.value)}
          label={t('resources.user.fields.lastName')}
          sx={{ width: '50%' }}
          autoComplete="off"
        />
        <Spacer x={2} />
        <TextField
          value={legalRepresentativeEmail}
          onChange={(ev) => setLegalRepresentativeEmail(ev.target.value)}
          label={t('onboarding.createAccount.legalRepresentativeEmail')}
          error={Boolean(legalRepresentativeEmailError)}
          autoComplete="off"
          fullWidth
        />
      </Row>
    </>
  )
}

const Fields = (props: FieldsProps) => {
  const t = useTranslate()
  const { email: validateEmail, siren: validateSiren } = useFormValidation()

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    company,
    setCompany,
    hrSoftware,
    setHrSoftware,
    siren,
    setSiren,
  } = props

  const emailError = validateEmail(email)
  const sirenError = validateSiren(maskSIREN(siren).unmaskedValue)

  return (
    <Column>
      <Row>
        <TextField
          value={firstName}
          onChange={(ev) => setFirstName(ev.target.value)}
          label={t('resources.user.fields.firstName')}
          sx={{ width: '50%' }}
          autoComplete="off"
        />
        <Spacer x={2} />
        <TextField
          value={lastName}
          onChange={(ev) => setLastName(ev.target.value)}
          label={t('resources.user.fields.lastName')}
          sx={{ width: '50%' }}
          autoComplete="off"
        />
      </Row>
      <Spacer y={2} />
      <TextField
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
        label={t('resources.user.fields.email')}
        error={Boolean(emailError)}
        autoComplete="off"
        fullWidth
      />
      <Spacer y={2} />
      <Row>
        <TextField
          value={company}
          onChange={(ev) => setCompany(ev.target.value)}
          label={t('onboarding.createAccount.fields.company')}
          sx={{ width: '50%' }}
          autoComplete="off"
        />
        <Spacer x={2} />
        <FormControl sx={{ width: '50%' }}>
          <InputLabel id="hr-software-label">
            {t('onboarding.createAccount.fields.hrSoftware')}
          </InputLabel>
          <Select
            displayEmpty
            value={hrSoftware}
            labelId="hr-software-label"
            label={t('onboarding.createAccount.fields.hrSoftware')}
            onChange={(ev) => setHrSoftware(ev.target.value as PayrollSoftware)}>
            {Object.values(PayrollSoftware).map((software) => (
              <MenuItem key={software} value={software}>
                {software}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Row>
      <Spacer y={2} />
      <TextField
        value={maskSIREN(siren).value}
        onChange={(ev) => setSiren(ev.target.value)}
        label={t('onboarding.createAccount.fields.siren')}
        error={Boolean(sirenError)}
        autoComplete="off"
        fullWidth
      />
    </Column>
  )
}

export const CreateAccount = () => {
  const api = useApi()
  const t = useTranslate()
  const notify = useNotifications()
  const { organization, setOrganization } = usePersistedUser()
  const { email: validateEmail, siren: validateSiren } = useFormValidation()

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [hrSoftware, setHrSoftware] = useState<PayrollSoftware | null>(null)
  const [siren, setSiren] = useState<string>('')

  const [isLegalRepresentative, setIsLegalRepresentative] = useState<boolean>(true)
  const [legalRepresentativeEmail, setLegalRepresentativeEmail] = useState<string>('')
  const [legalRepresentativeLastName, setLegalRepresentativeLastName] = useState<string>('')
  const [legalRepresentativeFirstName, setLegalRepresentativeFirstName] = useState<string>('')

  const emailError = validateEmail(email)
  const sirenError = validateSiren(maskSIREN(siren).unmaskedValue)
  const legalRepresentativeEmailError = validateEmail(legalRepresentativeEmail)

  const updateOnboardingMutation = useMutation(
    QueryKey.UpdateOnboarding,
    async () => {
      await updateOnboarding()
      if (!isLegalRepresentative) {
        await sendPowerOfAttorneyReminder()
      }
    },
    {
      onSuccess: () => {
        notify(t('notifications.onboarding.createAccount.success'), { variant: 'success' })
      },
      onError: () => {
        notify(t('notifications.onboarding.createAccount.error'), { variant: 'error' })
      },
    }
  )

  async function updateOnboarding() {
    const body: OrganizationOnboarding = {
      ...organization.onboarding,
      firstName,
      lastName,
      email,
      company,
      siren,
      hrSoftware: hrSoftware as string,
      ...(!isLegalRepresentative
        ? { legalRepresentativeFirstName, legalRepresentativeLastName, legalRepresentativeEmail }
        : {}),
    }
    const org = await api.organizationControllerUpdateOnboardingState(body)
    setOrganization(org)
  }

  async function sendPowerOfAttorneyReminder() {
    const body: SendPowerOfAttorneyReminderBody = {
      email: legalRepresentativeEmail,
      lastName: legalRepresentativeLastName,
      firstName: legalRepresentativeFirstName,
    }
    await api.organizationControllerSendPowerOfAttorneyReminder(body)
  }

  const isFormValid = (): boolean => {
    const areFieldsValid =
      Boolean(firstName) &&
      Boolean(lastName) &&
      Boolean(email) &&
      Boolean(company) &&
      Boolean(hrSoftware) &&
      Boolean(siren) &&
      !Boolean(emailError) &&
      !Boolean(sirenError)
    if (isLegalRepresentative) {
      return areFieldsValid
    }
    const areLegalRepresentativeFieldsValid =
      Boolean(legalRepresentativeFirstName) &&
      Boolean(legalRepresentativeLastName) &&
      Boolean(legalRepresentativeEmail) &&
      !Boolean(legalRepresentativeEmailError)
    return areFieldsValid && areLegalRepresentativeFieldsValid
  }

  return (
    <Box sx={{ maxWidth: '80%' }}>
      <Typography variant="h2" sx={{ fontWeight: 500 }}>
        {t('onboarding.createAccount.title')}
      </Typography>
      <Spacer y={4} />
      <Fields
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        company={company}
        setCompany={setCompany}
        hrSoftware={hrSoftware}
        setHrSoftware={setHrSoftware}
        siren={siren}
        setSiren={setSiren}
      />
      <Spacer y={3} />
      <Row>
        <Typography>{t('onboarding.createAccount.helperText')}</Typography>
        <Spacer x={2} />
        <StyledSwitch
          color="primary"
          checked={isLegalRepresentative}
          onChange={() => setIsLegalRepresentative((currentValue) => !currentValue)}
        />
      </Row>
      <Box sx={boxTransitionSx(isLegalRepresentative)}>
        {!isLegalRepresentative && (
          <LegalRepresentativeFields
            legalRepresentativeFirstName={legalRepresentativeFirstName}
            setLegalRepresentativeFirstName={setLegalRepresentativeFirstName}
            legalRepresentativeLastName={legalRepresentativeLastName}
            setLegalRepresentativeLastName={setLegalRepresentativeLastName}
            legalRepresentativeEmail={legalRepresentativeEmail}
            setLegalRepresentativeEmail={setLegalRepresentativeEmail}
          />
        )}
      </Box>
      <Spacer y={3} />
      <LoadingButton
        sx={buttonSx}
        variant="contained"
        disabled={!isFormValid()}
        loading={updateOnboardingMutation.isLoading}
        onClick={() => updateOnboardingMutation.mutate()}>
        {t('onboarding.createAccount.button')}
      </LoadingButton>
    </Box>
  )
}

const buttonSx: SxProps = { width: '100%', fontWeight: 400 }

const boxTransitionSx = (isLegalRepresentative: boolean): SxProps => ({
  transition: 'all 0.3s ease-out',
  overflow: 'hidden',
  maxHeight: isLegalRepresentative ? '0' : '75px',
  opacity: isLegalRepresentative ? 0 : 1,
})
