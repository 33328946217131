import { useCallback } from 'react'
import { fetchUtils } from 'react-admin'

import { config } from 'config'
import { useAuthContext } from 'libs/auth/provider'
import { usePersistedUser } from 'user/context'

export function useReactAdminClient() {
  const { auth } = useAuthContext()
  const { organization } = usePersistedUser()

  const accessToken = auth ? auth.accessToken : ''
  const organizationId = organization ? organization.id : ''

  const client: typeof fetchUtils.fetchJson = useCallback(
    async (path, options = {}) => {
      const url = config.BACKEND_URL + '/api' + path
      options.headers = new Headers({
        Organization: organizationId,
        Authorization: `Bearer ${accessToken}`,
        ...(options.headers || {}),
      })
      return fetchUtils.fetchJson(url, options)
    },
    [accessToken, organizationId]
  )

  return client
}
