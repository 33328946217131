import { Box, styled } from '@mui/material'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, {
  ComponentProps,
  useState,
  useEffect,
  useCallback,
  ReactElement,
  FC,
  useMemo,
} from 'react'
import { Datagrid, useListContext } from 'react-admin'

import { useModal } from 'libs/modal'

import DatagridLocalStorage from './DatagridLocalStorage'
import { SelectColumnModal } from './SelectColumnModal'

import './datagrid.css'

interface Props extends ComponentProps<typeof Datagrid> {
  default_columns?: Element[]
  storage?: typeof DatagridLocalStorage
}

const arrayToSelection = (values: any) =>
  values.reduce((selection: any, columnName: string) => {
    selection[columnName] = true
    return selection
  }, {})

export const CustomizableDatagrid: FC<Props> = (props) => {
  const {
    children,
    resource = '',
    storage = DatagridLocalStorage,
    default_columns = [],
    bulkActionButtons = <></>,
    sx,
    ...rest
  } = props

  const { isModalOpen, closeModal } = useModal('selectColumns')
  const [selection, setSelection] = useState({})
  const { selectedIds } = useListContext()

  const getColumnNames = useCallback(() => {
    const columnsArray: string[] = []
    const { children } = props
    if (children && !['string', 'number', 'boolean'].includes(typeof children)) {
      filter(
        React.Children.map(children as ReactElement, (field: ReactElement) => {
          if (field) columnsArray.push(field.props['source'])
        })
      )
    }
    return columnsArray
  }, [props])

  const getInitialSelection = useCallback(() => {
    const previousSelection = storage.get(resource)
    if (!isEmpty(previousSelection)) return previousSelection
    if (!isEmpty(default_columns)) return arrayToSelection(default_columns)
    return arrayToSelection(getColumnNames())
  }, [resource, default_columns, storage, getColumnNames])

  useEffect(() => {
    setSelection(getInitialSelection())
  }, [])

  const getColumnLabels = useCallback(() => {
    return filter(
      React.Children.map(
        children,
        (field) =>
          field && {
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
          }
      ),
      (item) => item && item.source
    )
  }, [children])

  const toggleColumn = useCallback(
    (columnName: string) => {
      const previousSelection = selection
      const newSelection = {
        ...previousSelection,
        [columnName]: !previousSelection[columnName as keyof typeof previousSelection],
      }
      storage.set(resource, newSelection)
      setSelection(newSelection)
    },
    [resource, selection, storage]
  )

  const renderChild = useCallback(
    (child: any) => {
      const source = get(child, ['props', 'source'])
      if (child && (!source || selection[source as keyof typeof selection])) {
        return React.cloneElement(child, {})
      }
      return null
    },
    [selection]
  )

  const isRowSelected = useMemo(() => Boolean(selectedIds.length), [selectedIds])

  const datagridSx = useMemo(
    () => ({
      marginTop: isRowSelected ? '48px' : '0px',
      transition: 'margin-top 300ms',
      ...sx,
    }),
    [isRowSelected, sx]
  )

  return (
    <Box sx={{ maxHeight: '65vh', width: '100%', overflowY: 'auto' }}>
      {isModalOpen && (
        <SelectColumnModal
          selection={selection}
          columns={getColumnLabels()}
          onColumnClicked={toggleColumn}
          onClose={closeModal}
        />
      )}
      <StyledDatagrid
        rowClick="edit"
        bulkActionButtons={bulkActionButtons}
        resource={resource}
        sx={datagridSx}
        {...rest}>
        {React.Children.map(children, renderChild)}
      </StyledDatagrid>
    </Box>
  )
}

const StyledDatagrid = styled(Datagrid)(({ theme }) => ({
  '& .MuiCheckbox-root': {
    color: theme.colors.GREY,
    opacity: 0.8,
    padding: 10,
  },
  '& .MuiTableCell-head.RaDatagrid-headerCell': {
    padding: 0,
    height: '20px',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
    '&:first-of-type': { borderTopLeftRadius: 10 },
    '&:last-of-type': { borderTopRightRadius: 10 },
    // When you click on a header to sort the rows, the class Mui-active is added
    '& .Mui-active': { color: theme.palette.text.secondary },
  },
  '& .RaDatagrid-row': {
    color: theme.palette.text.primary,
    height: '45px',
    '& .MuiTypography-root': {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: 400,
    },
    '& .MuiTableCell-root': {
      maxWidth: '45px',
      border: 'none',
      padding: '0 5px',
      '&:first-of-type': { paddingLeft: 20 },
    },
    '& .MuiLink-root': {
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        border: 'none',
      },
    },
  },
}))
