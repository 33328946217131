import { Box } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { CustomHeader } from 'libs/components/CustomHeader'
import { Spacer } from 'utils/spacing'

import { BankPageContent } from './BankPageContent'

export const BankPage = () => {
  const t = useTranslate()
  return (
    <Box sx={{ height: '100%' }}>
      <CustomHeader title={t('bank.title')} />
      <Spacer y={4} />
      <BankPageContent />
    </Box>
  )
}
