import { Close } from '@mui/icons-material'
import { IconButton, DialogContent, Typography, Dialog, styled, DialogActions } from '@mui/material'
import React from 'react'
import { useTranslate, useResourceContext } from 'react-admin'

import { useModal } from 'libs/modal'

export const ExportModal: React.FC<any> = ({ children }) => {
  const t = useTranslate()
  const resource = useResourceContext()
  const { isModalOpen, closeModal } = useModal('export')

  return (
    <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="md">
      <DialogActions sx={{ height: '10px', mt: 1 }}>
        <StyledIconButton size="small" onClick={closeModal}>
          <Close />
        </StyledIconButton>
      </DialogActions>
      <DialogContent sx={{ height: '300px', padding: 0 }}>
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, textAlign: 'center', marginBottom: '20px' }}>
          {t(`resources.${resource}.exportModal.title`)}
        </Typography>
        {children}
      </DialogContent>
    </Dialog>
  )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&:hover': {
    borderColor: theme.palette.text.secondary,
    backgroundColor: theme.palette.text.secondary,
  },
}))
