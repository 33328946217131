import { LoadingButton } from '@mui/lab'
import { TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { useNotifications } from 'libs/notifications'
import { Column, Spacer } from 'utils/spacing'

export function CreateOrganizationTabView() {
  const api = useApi()
  const t = useTranslate()
  const notification = useNotifications()

  const [name, setName] = useState('')

  const isSubmitDisabled = !name

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event?.target?.value)
  }

  const createOrganizationMutation = useMutation(
    async () => {
      const response = await api.adminControllerCreateOrganization({ name })
      return response
    },
    {
      onSuccess: () => {
        notification(t('notifications.createOrganization.success'), { variant: 'success' })
        location.reload()
      },
      onError: () => {
        notification(t('notifications.createOrganization.error'), { variant: 'error' })
      },
    }
  )

  return (
    <Column sx={{ width: '450px' }}>
      <Spacer y={1} />
      <Typography variant="body1">{t('admin.createOrganization.description')}</Typography>
      <Spacer y={3} />
      <TextField
        label={t('resources.organization.fields.name')}
        value={name}
        autoComplete="off"
        onChange={onChange}
        sx={{ width: '100%' }}
      />
      <Spacer y={3} />
      <LoadingButton
        variant="contained"
        onClick={() => createOrganizationMutation.mutate()}
        loading={createOrganizationMutation.isLoading}
        disabled={isSubmitDisabled || createOrganizationMutation.isLoading}
        sx={{ width: '100%' }}>
        {t('buttons.save')}
      </LoadingButton>
    </Column>
  )
}
