import CrossIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import { Button, ButtonProps } from '@mui/material'
import React, { FC } from 'react'

import { useAuthContext } from 'libs/auth/provider'

import { config } from '../config'

function onClickAuthButton(swanSecurityState: string) {
  const url = `https://oauth.swan.io/oauth2/auth?response_type=code&client_id=${config.SWAN_CLIENT_ID}&redirect_uri=${config.SWAN_AUTH_REDIRECT_URL}&scope=openid%20offline%20idverified&state=${swanSecurityState}`
  window.open(url, '_self')
}

type Props = ButtonProps

export const SwanAuthButton: FC<Props> = (props) => {
  const { auth, swanSecurityState } = useAuthContext()

  const isLoggedInSwan = auth.swanRefreshToken
  const startIcon = isLoggedInSwan ? <CheckIcon color="success" /> : <CrossIcon color="error" />
  const onClick = isLoggedInSwan ? undefined : () => onClickAuthButton(swanSecurityState)
  return (
    <Button {...props} onClick={onClick} variant="contained" startIcon={startIcon}>
      Swan
    </Button>
  )
}
