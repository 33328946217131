import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Contract, Integration, Organization } from 'api/gen'
import { useAuthContext } from 'libs/auth/provider'
import { doNothing } from 'utils/doNothing'
import { useStateWithStorage } from 'utils/useStateWithStorage'

import { IntegrationMap, User, UserContextType } from './types'

const INITIAL_USER_CONTEXT: UserContextType = {
  clearUser: doNothing,
  logout: doNothing,
  setContract: doNothing,
  setUser: doNothing,
  setOrganization: doNothing,
  setHasEntityNames: doNothing,
  isUserReady: false,
  isSwanActive: false,
  hasIntegration: false,
  hasEntityNames: false,
  // These variables can be null on initialization, but for the sake of typing
  // everywhere else in the app, we make them appear as not nullable
  user: null as unknown as User,
  contract: null as unknown as Contract,
  organization: null as unknown as Organization,
  integrationMap: {} as IntegrationMap,
  integration: {} as Integration,
}

const UserContext = React.createContext<UserContextType>(INITIAL_USER_CONTEXT)

const LOGIN_ROUTE = '/account/login'
const LOGOUT_ROUTE = '/account/logout'

export const UserProvider: React.FC = (props) => {
  const navigate = useNavigate()
  const { auth, setAuth } = useAuthContext()

  const [user, setUser] = useStateWithStorage<User | null>('@User', null)
  const [contract, setContract] = useStateWithStorage<Contract | null>('@Contract', null)
  const [organization, setOrganization] = useStateWithStorage<Organization | null>(
    '@Organization',
    null
  )
  const [hasEntityNames, setHasEntityNames] = useStateWithStorage<boolean>('@HasEntityNames', false)

  const clearUser = React.useCallback(() => {
    setUser(null)
    setContract(null)
    setOrganization(null)
    setHasEntityNames(false)
    setAuth({
      accessToken: undefined,
      isAuthenticated: undefined,
      swanSecurityState: '',
      swanAuthRedirectState: undefined,
      swanAccessToken: undefined,
      swanRefreshToken: undefined,
    })
  }, [setUser, setContract, setOrganization, setHasEntityNames])

  const logout = React.useCallback(() => {
    navigate(LOGOUT_ROUTE)
    clearUser()
  }, [clearUser, navigate])

  useEffect(() => {
    if (!auth.isAuthenticated) {
      clearUser()
      navigate(LOGIN_ROUTE)
    }
  }, [auth.isAuthenticated, clearUser, navigate])

  const contextValue = React.useMemo(() => {
    const integrationMap = {} as IntegrationMap
    let hasIntegration = false
    let isSwanActive = false
    if (organization) {
      if (organization.isSwanOnboardingComplete && organization.swanAccountId) {
        isSwanActive = true
      }
      if (organization.integrations?.length > 0) {
        hasIntegration = true
        for (const integration of organization.integrations) {
          integrationMap[integration.type] = integration
        }
      }
    }

    return {
      setUser,
      setContract,
      setOrganization,
      setHasEntityNames,
      clearUser,
      logout,
      isUserReady: Boolean(user && contract && organization),
      isSwanActive,
      hasIntegration,
      hasEntityNames,
      // These variables can be null on initialization, but for the sake of typing
      // everywhere else in the app, we make them appear as not nullable
      user: user as User,
      contract: contract as Contract,
      organization: organization as Organization,
      integrationMap,
      integration:
        integrationMap.SILAE ||
        integrationMap.MERGE ||
        integrationMap.ROLLEE ||
        integrationMap.KOMBO,
    }
  }, [
    user,
    setUser,
    clearUser,
    logout,
    contract,
    setContract,
    organization,
    setOrganization,
    hasEntityNames,
    setHasEntityNames,
  ])

  return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>
}

export function usePersistedUser() {
  return React.useContext(UserContext)
}
