import { Exporter } from 'react-admin'

import { exportToExcel, exportToCSV } from 'libs/export/exporter'
import { ActivityLogRecord } from 'resources/types'

function getRowsToExport(records: ActivityLogRecord[]) {
  const rows = new Array(records.length)
  for (let i = 0; i < records.length; ++i) {
    const { user, ...activityLog } = records[i]
    rows[i] = {
      action: activityLog.action,
      firstName: user.firstName,
      lastName: user.lastName,
      createdAt: activityLog.createdAt,
      info: activityLog.info,
    }
  }
  return rows
}

const xls: Exporter = (records) => {
  const rows = getRowsToExport(records)
  return exportToExcel(rows, 'activitylogs')
}

const csv: Exporter = (records) => {
  const rows = getRowsToExport(records)
  return exportToCSV(rows, 'activitylogs')
}

export const activityLogListExporters = {
  xls,
  csv,
}
