import { Localisation } from './types'

export const LOCALIZATIONS: Localisation = {
  en: {
    title: 'Sign in',
    continue: 'Continue',
    email: {
      emailInputLabel: 'Your email',
      emailInputHelperText: 'Invalid email',
    },
    otc: {
      otcTitle: 'Check your email',
      otcMessage: 'We sent you a six digit code at',
      otcResend: 'Resend a new code',
      otcResent: 'We sent you a new six digit code',
      otcIsRequired: 'The code is required',
      otcInputHelperText: 'Invalid code',
    },
  },
  fr: {
    title: 'Connexion',
    continue: 'Continuer',
    email: {
      emailInputLabel: 'Votre email',
      emailInputHelperText: 'Email invalide',
    },
    otc: {
      otcTitle: 'Vérifiez vos emails',
      otcMessage: 'Nous vous avons envoyé un code de 6 chiffres sur',
      otcResend: 'Envoyer un nouveau code',
      otcResent: 'Nous vous avons envoyé un nouveau code',
      otcIsRequired: 'Le code est requis',
      otcInputHelperText: 'Code invalide',
    },
  },
}
