import { Card, CardContent, Tabs, Tab, SxProps } from '@mui/material'
import React, { useMemo } from 'react'
import { Translate, useTranslate } from 'react-admin'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'

import { useCanAccess } from 'libs/auth'
import { ROLES_WITH_WRITE_PERMISSION } from 'resources/types'

import { ApiTabView } from './api/ApiTabView'
import { AdminTabAuthenticationView } from './AuthenticationTabView'
import { AdminTabCompanyView } from './company/CompanyTabView'
import { AdminTabProfileView } from './ProfileTabView'
import { AdminTabRoleManagementView } from './roleManagement/RoleManagementTabView'
import { AdminTabSoftwareView } from './software/SoftwareTabView'

type TabValue = 'profile' | 'company' | 'hrSoftware' | 'roleManagement' | 'authentication' | 'api'

type TabConfig = {
  value: TabValue
  path: string
  label: string
  sx: SxProps
  component: React.FC
}
const adminPath = (path: string) => '/administration/' + path

function getTabsConfigs(
  t: Translate,
  hasWritePermission: boolean,
  isAccountManager: boolean
): TabConfig[] {
  return [
    {
      value: 'profile',
      path: adminPath('profil'),
      label: t('administration.profile.title'),
      sx: { ...tabSx, width: '135px' },
      component: AdminTabProfileView,
    },
    {
      value: 'company',
      path: adminPath('entreprise'),
      label: t('administration.companyTab.title'),
      sx: { ...tabSx, width: '164px' },
      component: AdminTabCompanyView,
    },
    ...(hasWritePermission || isAccountManager
      ? [
          {
            value: 'hrSoftware',
            path: adminPath('logicielRH'),
            label: t('administration.hrSoftware'),
            sx: { ...tabSx, width: '248px' },
            component: AdminTabSoftwareView,
          } as TabConfig,
        ]
      : []),
    ...(hasWritePermission
      ? [
          {
            value: 'roleManagement',
            path: adminPath('roles'),
            label: t('administration.roleManagementTab.title'),
            sx: { ...tabSx, width: '230px' },
            component: AdminTabRoleManagementView,
          } as TabConfig,
        ]
      : []),
    {
      value: 'authentication',
      path: adminPath('authentification'),
      label: t('administration.authentication.title'),
      sx: { ...tabSx, width: '164px' },
      component: AdminTabAuthenticationView,
    },
    {
      value: 'api',
      path: adminPath('api'),
      label: t('administration.apiTab.title'),
      sx: { ...tabSx, width: '160px' },
      component: ApiTabView,
    },
  ]
}

function matchPathnameToTabConfig(pathname: string, tabsConfigs: TabConfig[]) {
  for (const tabConfig of tabsConfigs) {
    if (matchPath(tabConfig.path, pathname)) {
      return tabConfig
    }
  }
  return tabsConfigs[0]
}

export function AdminPage() {
  const t = useTranslate()
  const { canAccess } = useCanAccess()
  const isAccountManager = canAccess({ roles: ['accountManager'] })
  const hasWritePermission = canAccess({ roles: ROLES_WITH_WRITE_PERMISSION })
  const tabsConfigs = useMemo(
    () => getTabsConfigs(t, hasWritePermission, isAccountManager),
    [t, hasWritePermission, isAccountManager]
  )
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [selectedTabConfig, setSelectedTabConfig] = React.useState<TabConfig>(
    matchPathnameToTabConfig(pathname, tabsConfigs)
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    for (const tabConfig of tabsConfigs) {
      if (newValue === tabConfig.value) {
        navigate(tabConfig.path)
        setSelectedTabConfig(tabConfig)
        return
      }
    }
  }

  return (
    <Card sx={{ width: '1100px', minHeight: 400 }}>
      <Tabs value={selectedTabConfig.value} onChange={handleTabChange}>
        {tabsConfigs.map((tabConfig) => {
          return (
            <Tab
              key={tabConfig.value}
              label={tabConfig.label}
              value={tabConfig.value}
              sx={tabConfig.sx}
            />
          )
        })}
      </Tabs>
      <CardContent sx={{ p: 3 }}>
        <selectedTabConfig.component />
      </CardContent>
    </Card>
  )
}

const tabSx: SxProps = { p: '16px 24px 16px 16px' }
