import { CloseRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  SxProps,
  useTheme,
  FormLabel,
} from '@mui/material'
import React, { useState } from 'react'
import { useLocaleState, useTranslate } from 'react-admin'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'

import { useApi } from 'api'
import { FinancingBorrower } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { useModal } from 'libs/modal'
import { Spacer, Column, Row } from 'utils/spacing'

import { CreateBorrowerForm } from './CreateBorrowerForm'

const FRENCH_TOS = 'https://www.nesspay.co/conditions-generales-de-vente-emprunteur'
const ENGLISH_TOS = 'https://www.nesspay.co/general-terms-and-conditions-of-sale'

type StatusType = FinancingBorrower.StatusEnum | undefined

export const CreateBorrowerDialog = () => {
  const t = useTranslate()
  const api = useApi()
  const theme = useTheme()
  const [locale] = useLocaleState()
  const queryClient = useQueryClient()
  const { isModalOpen, closeModal } = useModal('createBorrower')

  const [status, setStatus] = useState<StatusType>(FinancingBorrower.StatusEnum.TOSIGN)

  const signTermsOfServiceMutation = useMutation(QueryKey.CreateBorrower, signTermsOfService, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKey.GetBorrower)
      closeModal()
    },
    onError: (error) => {
      console.error(error)
    },
  })

  useQuery(QueryKey.GetBorrower, async () => {
    if (status !== FinancingBorrower.StatusEnum.TOSIGN) return
    try {
      const borrower = await api.financingControllerGetBorrower()
      setStatus(borrower.status)
    } catch (error) {
      setStatus(undefined)
      console.error(error)
    }
  })

  function openTermsOfService() {
    if (locale === 'fr') window.open(FRENCH_TOS, '_blank')
    if (locale === 'en') window.open(ENGLISH_TOS, '_blank')
  }

  async function signTermsOfService() {
    await api.financingControllerSignTermsAndConditions({
      termsAndConditionsSignedAt: new Date().toISOString(),
    })
  }

  const SignTermsAndConditions = () => {
    return (
      <Column sx={{ display: 'flex', alignItems: 'center', maxWidth: '400px' }}>
        <Spacer y={2} />
        <Typography>{t('financing.loansTab.createBorrower.dialog.tosHelperText')}</Typography>
        <FormLabel>
          <Link to="" onClick={openTermsOfService}>
            {t('financing.loansTab.createBorrower.dialog.TOS')}
          </Link>
        </FormLabel>
        <Spacer y={3} />
        <LoadingButton
          color="primary"
          variant="contained"
          loading={signTermsOfServiceMutation.isLoading}
          onClick={() => signTermsOfServiceMutation.mutate()}>
          {t('financing.loansTab.createBorrower.dialog.button')}
        </LoadingButton>
      </Column>
    )
  }

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx(status)}>
      <DialogTitle sx={{ backgroundColor: theme.palette.secondary.main, maxHeight: '55px' }}>
        <Row sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={iconButtonSx} />
          <Typography color="primary">
            {t('financing.loansTab.createBorrower.dialog.title')}
          </Typography>
          <IconButton onClick={closeModal} sx={{ ...iconButtonSx, ':hover': { border: 'none' } }}>
            <CloseRounded sx={{ color: theme.colors.WHITE }} />
          </IconButton>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Column sx={{ display: 'flex', alignItems: 'center' }}>
          <Spacer y={2} />
          {status !== FinancingBorrower.StatusEnum.TOSIGN && (
            <CreateBorrowerForm setStatus={setStatus} />
          )}
          {status === FinancingBorrower.StatusEnum.TOSIGN && <SignTermsAndConditions />}
        </Column>
      </DialogContent>
    </Dialog>
  )
}

const iconButtonSx: SxProps = { width: '15px', height: '15px' }
const dialogSx = (status: StatusType) => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: status !== FinancingBorrower.StatusEnum.TOSIGN ? '650px' : '450px',
      height: '100%',
      maxHeight: status !== FinancingBorrower.StatusEnum.TOSIGN ? '700px' : '250px',
    },
  },
})
