import { useState } from 'react'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'

export function useGetMaintenance() {
  const api = useApi()
  const [maintenance, setMaintenance] = useState<boolean>(false)
  useQuery(
    QueryKey.Maintenance,
    async () => {
      const maintenance = await api.meControllerGetMaintenanceState()
      setMaintenance(maintenance)
    },
    {
      onError: (error) => {
        console.error(error)
      },
    }
  )
  return { maintenance }
}
