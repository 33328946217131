import isEqual from 'lodash/isEqual'
import { DependencyList, EffectCallback, useCallback, useEffect, useMemo, useRef } from 'react'

import { config } from 'config'

export function useDeepCompareMemoize(deps: DependencyList = []): DependencyList {
  checkDeps(deps)

  const depsRef = useRef<DependencyList>([])
  const signalRef = useRef<number>(0)

  if (!isEqual(deps, depsRef.current)) {
    depsRef.current = deps
    signalRef.current += 1
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => depsRef.current, [signalRef.current])
}

function checkDeps(deps: DependencyList) {
  if (config.ENV === 'production') return
  if (!deps || !deps.length) {
    console.error('useDeep**** should not be used with no dependencies. Use normal hook instead.')
    return
  }
  if (deps.every(isPrimitive)) {
    console.error(
      'useDeep**** should not be used with all primitive dependencies. Use normal hook instead.'
    )
    return
  }
}
function isPrimitive(val: unknown) {
  return val == null || /^[sbn]/.test(typeof val)
}

export function useDeepEffect(effect: EffectCallback, deps?: DependencyList): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(effect, useDeepCompareMemoize(deps))
}

export function useDeepCallback(
  callback: (...args: any[]) => any,
  deps: DependencyList
): (...args: any[]) => any {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, useDeepCompareMemoize([callback, ...deps]))
}

export function useDeepMemo<T>(factory: () => T, deps: DependencyList): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<T>(factory, useDeepCompareMemoize(deps))
}
