import {
  MenuItem,
  MenuItemProps,
  ListItemIcon,
  Tooltip,
  TooltipProps,
  useMediaQuery,
  Theme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useTranslate } from 'ra-core'
import React, { forwardRef, useCallback, ReactElement, ReactNode } from 'react'
import { useSidebarState } from 'react-admin'
import { Link, LinkProps, useMatch } from 'react-router-dom'

import { analytics } from 'libs/analytics'

type Props = {
  leftIcon?: ReactElement
  primaryText?: ReactNode
  tooltipProps?: TooltipProps
  variant?: 'contained'
} & Partial<LinkProps> &
  MenuItemProps<'li'> & {
    to?: string
    itemName?: string
  }

export const MenuItemLink = forwardRef(function MenuItemlink(props: Props, ref) {
  const { className, primaryText, leftIcon, onClick, to, tooltipProps, disabled, ...rest } = props

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const translate = useTranslate()
  const [open, setOpen] = useSidebarState()

  const handleClick = useCallback(
    (e) => {
      if (isSmall) setOpen(false)
      onClick && onClick(e)
    },
    [setOpen, isSmall, onClick]
  )

  const isLink = !!to
  let path = ''
  if (typeof to === 'string') path = to

  const match = useMatch({ path: '/' + path.split('/')[1] + '*' })
  const isActive = isLink ? !!match : false

  let title: string | boolean | React.ReactChild | React.ReactFragment | React.ReactPortal = ''
  if (typeof primaryText === 'string') title = translate(primaryText, { _: primaryText })
  else if (primaryText) title = primaryText

  const renderMenuItem = () => {
    return (
      <StyledMenuItem
        itemName={props.itemName}
        className={clsx(className, {
          [MenuItemLinkClasses.active]: isActive,
        })}
        component={isLink ? LinkRef : undefined}
        to={isLink ? path : undefined}
        // @ts-ignore : Type 'unknown' is not assignable to type 'HTMLLIElement | null'
        ref={ref}
        {...rest}
        onClick={handleClick}
        disabled={disabled}>
        {leftIcon && <ListItemIcon className={MenuItemLinkClasses.icon}>{leftIcon}</ListItemIcon>}
        {title}
      </StyledMenuItem>
    )
  }

  return open ? (
    renderMenuItem()
  ) : (
    <Tooltip title={title} placement="right" {...tooltipProps}>
      {renderMenuItem()}
    </Tooltip>
  )
})

const PREFIX = 'CustomRaMenuItemLink'

const MenuItemLinkClasses = {
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
}

const StyledMenuItem = styled(MenuItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})<{ variant: string }>(({ variant, theme }) => ({
  boxSizing: 'border-box',
  color: theme.palette.text.secondary,
  height: 28,
  width: '100%',
  borderRadius: 10,
  padding: 10,
  marginTop: 10,
  marginBottom: 10,
  border: variant === 'contained' ? `1px solid ${theme.palette.text.secondary}` : 'none',
  [`& .${MenuItemLinkClasses.icon}`]: {
    color: theme.palette.text.secondary,
    paddingLeft: 2,
    width: theme.spacing(6),
  },
  [`&.${MenuItemLinkClasses.active}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.colors.BLUE_PASTEL,
    [`& .${MenuItemLinkClasses.icon}`]: {
      color: theme.palette.text.primary,
    },
  },
}))

const trackClickOnMenuItem = (itemName?: string) => {
  if (itemName) {
    analytics.log('click on menu item', { item: itemName })
  }
}

const LinkRef = forwardRef<HTMLAnchorElement, LinkProps & { itemName?: string }>(function linkRef(
  props,
  ref
) {
  return <Link ref={ref} {...props} onClick={() => trackClickOnMenuItem(props.itemName)} />
})
