import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  SxProps,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import React, { useState } from 'react'
import { useGetList, useTranslate } from 'react-admin'

import { useModal } from 'libs/modal'
import { ContractRecord, Role } from 'resources/types'
import { Spacer } from 'utils/spacing'

import { AddExistingUser } from './AddExistingUser'
import { AddNewUser } from './AddNewUser'

const LIST_PARAMS = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: 'user.firstName', order: 'ASC' },
  filter: { roles: [Role.Employee], active: [true] },
}

export const AddRoleDialog = () => {
  const t = useTranslate()
  const theme = useTheme()
  const { isModalOpen, closeModal } = useModal('addRole')
  const [isNewUser, setIsNewUser] = useState<boolean>(false)
  const { data: contracts } = useGetList<ContractRecord>('contract', LIST_PARAMS, {
    enabled: isModalOpen,
  })
  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle sx={{ backgroundColor: theme.palette.secondary.main, maxHeight: '55px' }}>
        <Typography color="primary" sx={{ textAlign: 'center' }}>
          {t('administration.roleManagementTab.addUserDialog.dialogTitle')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Spacer y={2} />
        <Typography variant="h2" sx={{ fontWeight: 500 }}>
          {isNewUser
            ? t('administration.roleManagementTab.addUserDialog.newUserTitle')
            : t('administration.roleManagementTab.addUserDialog.existingUserTitle')}
        </Typography>
        <Spacer y={3} />
        <RadioGroup defaultValue={0}>
          <FormControlLabel
            value={0}
            control={<Radio color="secondary" onClick={() => setIsNewUser(false)} />}
            label={t('administration.roleManagementTab.addUserDialog.existingUserRadio')}
            sx={{
              '.MuiFormControlLabel-label': {
                color: !isNewUser ? theme.palette.secondary.main : theme.colors.GREY,
              },
            }}
          />
          <FormControlLabel
            value={1}
            control={<Radio color="secondary" onClick={() => setIsNewUser(true)} />}
            label={t('administration.roleManagementTab.addUserDialog.newUserRadio')}
            sx={{
              '.MuiFormControlLabel-label': {
                color: isNewUser ? theme.palette.secondary.main : theme.colors.GREY,
              },
            }}
          />
        </RadioGroup>
        <Spacer y={3} />
        {!isNewUser && contracts && (
          <AddExistingUser records={contracts} handleClose={closeModal} />
        )}
        {isNewUser && contracts && <AddNewUser handleClose={closeModal} />}
      </DialogContent>
    </Dialog>
  )
}

const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '600px',
      minHeight: '550px',
      maxHeight: '1700px',
    },
  },
}
