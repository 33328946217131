import {
  AddCircleOutlineRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material'
import {
  styled,
  Box,
  Button,
  SxProps,
  Divider,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody as MuiTableBody,
  CircularProgress,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { FinancingLoan, PaginationBody } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { useModal } from 'libs/modal'
import { Column, Row, Spacer } from 'utils/spacing'

import { CreateLoanRequestDialog } from './LoanRequestsDialog'

const PAGE_SIZE = 10

export const LoanRequests = () => {
  const api = useApi()
  const t = useTranslate()
  const [loans, setLoans] = useState<FinancingLoan[]>([])
  const { openModal } = useModal('createLoanRequest')

  const [cursors, setCursors] = useState<Array<string | undefined>>([undefined])
  const [nextPage, setNextPage] = useState<string | undefined>(undefined)

  const { isFetching } = useQuery([QueryKey.GetLoanRequests, cursors], async () => {
    try {
      const cursor = cursors[cursors.length - 1]
      const paginationBody: PaginationBody = { pageSize: PAGE_SIZE, cursor: cursor }
      const { data: loans, next_page } = await api.financingControllerGetLoans(paginationBody)
      if (loans) {
        setLoans(loans)
        setNextPage(next_page)
      }
    } catch (error) {
      throw error
    }
  })

  function getPrevPage() {
    const cursorsArray = [...cursors]
    cursorsArray.pop()
    setCursors(cursorsArray)
  }

  function getNextPage() {
    const urlParams = new URLSearchParams(nextPage)
    const newCursor = urlParams.get('cursor')
    if (newCursor) {
      const cursorsArray = [...cursors]
      cursorsArray.push(newCursor)
      setCursors(cursorsArray)
    }
  }

  const TableHeader = () => (
    <StyledTableHead>
      <TableRow sx={{ backgroundColor: 'lightblue' }}>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.reference')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.submittedAt')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.amount')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.status')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.toPayAt')}
        </TableCell>
        <TableCell scope="header" sx={{ borderBottom: 'none' }}>
          {t('financing.loansTab.table.toRepayAt')}
        </TableCell>
      </TableRow>
    </StyledTableHead>
  )

  const TableBody = () => (
    <MuiTableBody>
      {loans.map((loan) => {
        let reference = ''
        if (loan.loan_to_references && loan.loan_to_references.length)
          reference = loan.loan_to_references[0]
        return (
          <TableRow key={loan.id}>
            <TableCell scope="row" sx={tableCellSx}>
              {reference}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {new Date(loan.submitted_at).toLocaleDateString()}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {loan.amount / 100} €
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {loan.status}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {new Date(loan.to_pay_at).toLocaleDateString()}
            </TableCell>
            <TableCell scope="row" sx={tableCellSx}>
              {new Date(loan.to_repay_at).toLocaleDateString()}
            </TableCell>
          </TableRow>
        )
      })}
    </MuiTableBody>
  )

  const PaginationButtons = () => (
    <Row sx={{ display: 'flex', justifyContent: 'end', alignItems: 'self-end' }}>
      <IconButton onClick={getPrevPage} sx={iconButtonSx} disabled={cursors.length <= 1}>
        <KeyboardArrowLeftRounded />
      </IconButton>
      <IconButton onClick={getNextPage} sx={iconButtonSx} disabled={!Boolean(nextPage)}>
        <KeyboardArrowRightRounded />
      </IconButton>
    </Row>
  )

  const LoadingIcon = () => (
    <Box sx={loadingSx}>
      <CircularProgress />
    </Box>
  )

  return (
    <Column sx={{ height: 510 }}>
      <Button
        sx={buttonSx}
        color="primary"
        variant="contained"
        onClick={openModal}
        startIcon={<AddCircleOutlineRounded />}>
        {t('financing.loansTab.createLoanRequest.button')}
      </Button>
      <Divider sx={{ mt: 3 }} />
      <Spacer y={3} />
      <TableContainer component={Box} sx={{ height: '100%', width: '100%' }}>
        <Table stickyHeader={true}>
          <TableHeader />
          {!isFetching && <TableBody />}
        </Table>
        {isFetching && <LoadingIcon />}
      </TableContainer>
      <PaginationButtons />
      <CreateLoanRequestDialog />
    </Column>
  )
}

const iconButtonSx: SxProps = { ':hover': { border: 'none' } }
const buttonSx: SxProps = {
  padding: 0,
  margin: 0,
  fontWeight: 400,
  height: '34px',
  width: '300px',
  textTransform: 'none',
  alignSelf: 'center',
}
const loadingSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}
const tableCellSx: SxProps = {
  width: '10px',
  borderBottom: 0,
  fontSize: '12px',
  pt: '7px',
  pb: '7px',
}

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.colors.GREY,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
  },
}))
