import { AddCircleOutlineRounded } from '@mui/icons-material'
import { Button, Typography, SxProps } from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { analytics } from 'libs/analytics'
import { useModal } from 'libs/modal'
import { Spacer, Column } from 'utils/spacing'

import { CreateBorrowerDialog } from './CreateBorrowerDialog'

export const CreateBorrower = () => {
  const t = useTranslate()
  const { openModal } = useModal('createBorrower')

  const onClickActivateBorrowing = () => {
    analytics.log('click on Activate borrowing')
    openModal()
  }

  return (
    <Column>
      <Typography sx={typographySx}>{t('financing.loansTab.createBorrower.message')}</Typography>
      <Spacer y={3} />
      <Button
        sx={buttonSx}
        color="primary"
        variant="contained"
        onClick={onClickActivateBorrowing}
        startIcon={<AddCircleOutlineRounded />}>
        {t('financing.loansTab.createBorrower.button')}
      </Button>
      <CreateBorrowerDialog />
    </Column>
  )
}

const buttonSx: SxProps = { width: '80%', alignSelf: 'center', fontWeight: 400 }
const typographySx: SxProps = { textAlign: 'center', p: '0px 35px 0px' }
